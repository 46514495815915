import { Alert, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useMemo, useState } from 'react';
import { DocumentField } from '../../../frontoffice/DocumentField';
import GuidanceModal from '../../../frontoffice/components/inputs/GuidanceModal';

export function DocumentFormBlock({
  fields,
  artifactsUsed: rawArtifactsUsed,
  fieldCounts,
  handleFieldChange,
  values,
  document,
  user,
  userProfileData
}) {
  const pocetVlastnikovNehnutelnostiCustom = 'pocet_vlastnikov_nehnutelnosti_custom';
  const plnomocenstvoHeaderCustom = 'plnomocenstvo_header_custom';
  const plnomocenstvoHeaderEstablishmentCustom = 'plnomocenstvo_establishment_header_custom';
  const generateProtocolCustom = 'generate-protocol-custom';
  const buyerCustomKey = 'buyer_custom';
  const sellerCustomKey = 'seller_custom';
  const representationKey = 'representation_custom';
  const municipalityNamesKey = 'municipalityNamesKey';
  const artifactsUsed = [
    ...rawArtifactsUsed,
    pocetVlastnikovNehnutelnostiCustom,
    representationKey,
    plnomocenstvoHeaderCustom,
    plnomocenstvoHeaderEstablishmentCustom,
    buyerCustomKey,
    sellerCustomKey,
    generateProtocolCustom
  ];

  const memoizedFieldsGroupped = useMemo(() => {
    const _fields = [];
    let hasVoting = false;
    let requireLesson = false


    if (
      (document.html || document.value.html).indexOf('BUYER-CLIENT-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Kupujúci',
        id: buyerCustomKey,
        index: "BUYER-CLIENT-DATA-OBJECT",
        type: buyerCustomKey,
        name: buyerCustomKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf('SELLER-CLIENT-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Predávajúci',
        id: sellerCustomKey,
        index: "SELLER-CLIENT-DATA-OBJECT",
        type: sellerCustomKey,
        name: sellerCustomKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf('REPRESENTATION-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Zastupovanie',
        id: representationKey,
        index: "REPRESENTATION-DATA-OBJECT",
        type: representationKey,
        name: representationKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf('GENERATE-PROTOCOL-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Generovať protokol',
        id: generateProtocolCustom,
        index: "GENERATE-PROTOCOL-DATA-OBJECT",
        type: generateProtocolCustom,
        name: generateProtocolCustom,
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'POCET-VLASTNIKOV-NEHNUTELNOSTI-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Počet vlastníkov nehnuteľnosti',
        id: pocetVlastnikovNehnutelnostiCustom,
        index: "POCET-VLASTNIKOV-NEHNUTELNOSTI-DATA-OBJECT",
        type: 'pocet_vlastnikov_nehnutelnosti_custom',
        name: 'pocet_vlastnikov_nehnutelnosti_custom',
      });
    }
    if (
      (document.html || document.value.html).indexOf('PLNOMOCENSTVO-HEADER-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Plnomocenstvo',
        id: plnomocenstvoHeaderCustom,
        index: "PLNOMOCENSTVO-HEADER-DATA-OBJECT",
        type: plnomocenstvoHeaderCustom,
        name: plnomocenstvoHeaderCustom,
      });
    }
    if (
      (document.html || document.value.html).indexOf('PLNOMOCENSTVO-HEADER-ESTABLISHMENT-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Plnomocenstvo',
        id: plnomocenstvoHeaderEstablishmentCustom,
        index: "PLNOMOCENSTVO-HEADER-ESTABLISHMENT-DATA-OBJECT",
        type: plnomocenstvoHeaderEstablishmentCustom,
        name: plnomocenstvoHeaderEstablishmentCustom
      });
    }
    if (
      document.value.isRepeatable &&
      document.value.repeatableValue &&
      document.name.split(' č.')[1] === '1'
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Pocet opakovani',
        id: document.id + '-count',
        type: 'text',
        name: document.value.repeatableValue,
      });
    }
    if (document.value.requireLesson && (document.id.split("-").length === 1 || document.id.split("-")[1] === "0")) {
      requireLesson = true
      // _fields.push({
      //   binding: '',
      //   condition: null,
      //   condition_false: null,
      //   condition_true: null,
      //   description: document.value.lesson,
      //   id: document.id,
      //   type: 'lesson',
      //   name: document.id,
      // });
    }

    const _acknowledgements = [];
    const _acknowledgementsValues = {};

    fields.forEach((field) => {
      if (field.type === 'acknowledge') {
        _acknowledgements.push(field);
        _acknowledgementsValues[field.id] = values[field.id];
      } else {
        _fields.push(field);
      }
    });
    _fields.sort(
      (a, b) => {
        if (a.index && b.index) {
          return artifactsUsed.indexOf(a.index) - artifactsUsed.indexOf(b.index)
        } else if (a.index) {
          return artifactsUsed.indexOf(a.index) - artifactsUsed.indexOf(b.id)
        } else if (b.index) {
          return artifactsUsed.indexOf(a.id) - artifactsUsed.indexOf(b.index)
        }
        return artifactsUsed.indexOf(a.id) - artifactsUsed.indexOf(b.id)
      }
    );
    return {
      fields: _fields,
      hasVoting: hasVoting,
      requireLesson: requireLesson,
      acknowledgements: _acknowledgements,
      allAccepted:
        Object.values(_acknowledgementsValues).filter((item) => item !== true)
          .length === 0,
    };
  }, [fields, values, document]);


  return (
    <div className="firstMainFrontColumn" id={document.name}>
      <Tooltip placement='topLeft' title={document.value.tooltip}>
        <Title level={3}>{document.name}</Title>
      </Tooltip>

      {memoizedFieldsGroupped.requireLesson && (
        <GuidanceModal.Mutator name={document.id} description={document.value.lesson} />
      )}

      <div className="twoColumnGrid">
        {memoizedFieldsGroupped.acknowledgements.map((field) => {
          if (artifactsUsed.includes(field.id)) {
            fieldCounts += 1;
            return (
              <DocumentField
                user={user}
                userProfileData={userProfileData}
                key={`${document.id}-${field.id}`}
                field={field}
                handleFieldChange={handleFieldChange}
                fieldValues={values}
                documentId={document.id}
                parentStepId={document.parentStepId}
              />
            );
          }
          return null;
        })}
      </div>

      <div className="twoColumnGrid">
        {memoizedFieldsGroupped.allAccepted &&
          memoizedFieldsGroupped.fields.map((field) => {
            if (artifactsUsed.indexOf(field.id) !== -1) {
              fieldCounts += 1;
              return (
                <DocumentField
                  user={user}
                  userProfileData={userProfileData}
                  key={`${document.id}-${field.id}`}
                  field={field}
                  handleFieldChange={handleFieldChange}
                  fieldValues={values}
                  documentId={document.id}
                  parentStepId={document.parentStepId}
                />
              );
            }
            return null;
          })}
      </div>

      {fieldCounts === 0 && !memoizedFieldsGroupped.hasVoting && (
        <Alert message="V tomto kroku nie je potrebné nič vyplniť" type="info" />
      )}
    </div>
  );
}
