import moment, { invalid } from "moment"
import AddressAutocompleteArtifact from "../features/frontoffice/components/inputs/AddressAutocompleteArtifact";
import PlnomocenstvoEstablishmentSignature from "../features/frontoffice/components/inputs/Plnomocenstvo/PlnomocenstvoEstablishmentSignature";
import PlnomocenstvoSignature from "../features/frontoffice/components/inputs/Plnomocenstvo/PlnomocenstvoSignature";
import Person from "../features/frontoffice/components/inputs/Person/Person";
import Retribution from "../features/frontoffice/components/inputs/Payments/Retribution";


export function getInvalidFields(artifactId, fieldValues, cachedFieldsAsObject, documentId) {
    let invalidFields = []
    console.log(artifactId)
    if (!fieldValues[artifactId]) {
        switch (artifactId) {
            case "snippet-start": {
                break;
            }
            case "snippet-stop": {
                break;
            }
            case "REPRESENTATION-DATA-OBJECT": {
                break;
            }
            default: {
                invalidFields = getDynamicArtifactInvalidFields(artifactId, fieldValues, cachedFieldsAsObject)
                break
            }
        }
    } else {
        invalidFields = getDynamicArtifactInvalidFields(artifactId, fieldValues, cachedFieldsAsObject)
    }

    return invalidFields;
}
export function evaluateArtifactValidity(artifactId, fieldValues, cachedFieldsAsObject, documentId) {
    let isValid = true
    if (!fieldValues[artifactId]) {
        switch (artifactId) {
            case "snippet-start": {
                break;
            }
            case "snippet-stop": {
                break;
            }
            case "REPRESENTATION-DATA-OBJECT": {
                break;
            }
            default: {
                isValid = evaluateDynamicArtifactValidity(artifactId, fieldValues, cachedFieldsAsObject)
                break
            }
        }
    } else {
        isValid = evaluateDynamicArtifactValidity(artifactId, fieldValues, cachedFieldsAsObject)
    }

    return isValid;
}

function evaluateDynamicArtifactValidity(artifactId, fieldValues, cachedFieldsAsObject) {
    let isValid = true
    if (cachedFieldsAsObject[artifactId.split("-")[0]]) {
        switch (cachedFieldsAsObject[artifactId.split("-")[0]].type) {
            case "address-autofill": {
                if (!cachedFieldsAsObject[artifactId.split("-")[0]].binding || cachedFieldsAsObject[artifactId.split("-")[0]].binding === "") {
                    isValid = AddressAutocompleteArtifact.Validator(fieldValues, artifactId)
                }
                break
            }
            case "qrcode": {
                break
            }
            case "person-select": {
                console.log(artifactId)
                isValid = Person.Validator(artifactId, artifactId, fieldValues, cachedFieldsAsObject[artifactId.split("-")[0]])
                break
            }
            case "retribution": {
                isValid = Retribution.Validator(fieldValues, artifactId)
                break
            }
            default: {
                if (!cachedFieldsAsObject[artifactId.split("-")[0]].binding || cachedFieldsAsObject[artifactId.split("-")[0]].binding === "") {
                    if (!fieldValues[artifactId] || fieldValues[artifactId] === '' || (Array.isArray(fieldValues[artifactId]) && fieldValues[artifactId].length === 0)) {
                        isValid = false
                    }
                }
                break
            }
        }
    }
    return isValid
}

function getDynamicArtifactInvalidFields(artifactId, fieldValues, cachedFieldsAsObject) {
    let invalidFields = []
    if (cachedFieldsAsObject[artifactId.split("-")[0]]) {
        switch (cachedFieldsAsObject[artifactId.split("-")[0]].type) {
            case "address-autofill": {
                if (!cachedFieldsAsObject[artifactId.split("-")[0]].binding || cachedFieldsAsObject[artifactId.split("-")[0]].binding === "") {
                    invalidFields = invalidFields.concat(AddressAutocompleteArtifact.FieldValidator(fieldValues, artifactId))
                }
                break
            }
            case "qrcode": {
                break
            }
            case "person-select": {
                invalidFields = Person.FieldsValidator(artifactId, artifactId, fieldValues, cachedFieldsAsObject[artifactId.split("-")[0]])
                break
            }
            case "retribution": {
                invalidFields = Retribution.FieldsValidator(fieldValues, artifactId)
                break
            }
            default: {
                if (!cachedFieldsAsObject[artifactId.split("-")[0]].binding || cachedFieldsAsObject[artifactId.split("-")[0]].binding === "") {
                    if (!fieldValues[artifactId] || fieldValues[artifactId] === '' || (Array.isArray(fieldValues[artifactId]) && fieldValues[artifactId].length === 0)) {
                        invalidFields = [artifactId]
                    }
                }
                break
            }
        }
    }
    return invalidFields
}