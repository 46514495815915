import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { FC, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { COMPANY_LOOKUP_HOST, PDF_GENERATION_HOST, PROD_ENV, capiURI } from '../../constants';
import { isAdmin } from '../../utils/AuthorizationValidation';
import { getAllUrlParams } from '../../utils/url';
import {
  editorSlice,
  selectFormStatePayload,
  selectOrderPassword,
} from '../editor/editorSlice';
import DocumentDownloadPage from './DocumentDownload.page';

interface Props {
  user: any
}

const DocumentDownloadContainer: FC<Props> = ({ user }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const formState = useSelector(selectFormStatePayload);

  const [password, setPassword] = useState(useSelector(selectOrderPassword));
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isRedirecting, setRedirecting] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [orderId, setOrderId] = useState('');
  const [marketingId, setMarketingId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    let search = window.location.search
    let params: any = getAllUrlParams()
    if (params.adm === undefined && isAdmin()) {
      if (search.indexOf("?") !== -1) {
        search += '&adm=01'
      } else {
        search += '?adm=01'
      }
      if (history) {
        history.push({
          pathname: window.location.pathname,
          search: search
        })
      }
    }
  }, [user])

  const createDocs = async (params: any) => {
    setRedirecting(true);
    // await dispatch(editorSlice.actions.setOrderPassword(generatedPassword));
    await axios
      .post(
        `${PDF_GENERATION_HOST}/order/post`,
        {
          flow_id: params.flow_id,
          formState,
          formData: params.formData,
          steps: getAllUrlParams(document.location.href),
          docs: params.steps,
          price: params.price,
          shortId: params.shortId,
          newPossibleUser: params.newPossibleUser
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(async (res) => {
        window.location.href = res.data.url
      }).catch(async (err) => {
        console.log(err);
        setRedirecting(false)
        setIsAuthenticated(false)
        setIsLoading(false)
        setErrorMessage("Chyba pri spracovaní objednávky, kontaktujte prosim podporu emailom: info@legaltechfactory.eu")
      });
  };

  const sendDataLayer = async (dataLayerToPush: any) => {
    let _fbp = ""
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === "_fbp") {
        _fbp = value;
      }
    }
    TagManager.dataLayer({
      dataLayer: { ...dataLayerToPush, _fbp }
    });
    if (PROD_ENV) {
      axios.post(capiURI + "/event/push", { ...dataLayerToPush, _fbp })
    }
  };

  const fetchDocs = async (orderId?: string, token?: string) => {
    setIsLoading(true);

    await axios
      .post(
        `${PDF_GENERATION_HOST}/order/download`,
        {
          id: orderId,
          token: token,
          password
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((res) => {
        setDocuments(res.data.docs || []);
        setEmail(res.data.email.To);
        dispatch(editorSlice.actions.setOrderPassword(password));
        setIsAuthenticated(true);
        setErrorMessage('');
        setOrderId(res.data.id)
        setMarketingId(res.data.marketingId)
        if (res.data.token) {
          setToken(res.data.token)
        }
        if (res.data.dataLayer) {
          sendDataLayer(res.data.dataLayer)
        }
      })
      .catch((e) => {
        setErrorMessage('Objednávka alebo heslo sú nesprávne.');
        setPassword('');
        setIsAuthenticated(false);
      });

    setIsLoading(false);
  };

  const handleEnterPassword = (password: any) => {
    setPassword(password);
    if (!orderId) {
      setErrorMessage('Objednávka alebo heslo sú nesprávne.');
    }
  };

  const handleTriggerDownload = async (documentItem: any) => {
    const filename = documentItem.name;
    await axios.get(`${COMPANY_LOOKUP_HOST}/order/download-file/${documentItem.id}/${orderId ? orderId : undefined}`, {
      responseType: 'blob',
      headers: {
        'Authorization': `${token ? token : undefined}`
      }
    },
    ).then((res) => {
      saveAs(res.data, filename + ".pdf");
    });
  };

  const handleTriggerDownloadAll = async () => {
    let zip = new JSZip();
    Promise.all(
      documents?.map(async (documentItem: any) => {
        if (!documentItem) {
          return null;
        }
        const { name } = documentItem;
        await axios.get(`${COMPANY_LOOKUP_HOST}/order/download-file/${documentItem.id}/${orderId ? orderId : undefined}`, {
          responseType: 'blob',
          headers: {
            'Authorization': `${token ? token : undefined}`
          }
        },
        ).then((res) => {
          zip.file(name + '.pdf', res.data, { binary: true });
        });
      }),
    ).then(() =>
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, 'dokumenty');
      }),
    );
  };

  const search = useLocation().search;

  let interval: any

  useEffect(() => {
    if (orderId !== '' && !interval) {
      interval = setInterval(() => {
        window.location.href = `/user-profile`
      }, 20 * 60 * 1000);

      return () => clearInterval(interval);
    }
  }, [orderId]);

  useEffect(() => {
    const urlParams = getAllUrlParams();
    let order = new URLSearchParams(search).get("order");
    let newToken = new URLSearchParams(search).get("token");
    if (formState) {
      createDocs(history.location.state);
    } else if (order) {
      setOrderId(order);
    } else if (newToken) {
      fetchDocs(undefined, newToken)
      setToken(newToken)
    }
  }, []);

  useEffect(() => {
    let orderId = new URLSearchParams(search).get("orderId");
    if (orderId && user !== undefined) {
      fetchDocs(orderId)
    }
  }, [user]);

  useEffect(() => {
    if (password !== '' && orderId !== '') {
      fetchDocs(orderId);
    }
  }, [password, orderId]);

  return (
    <DocumentDownloadPage
      isAuthenticated={isAuthenticated}
      documents={documents}
      email={email}
      marketingId={marketingId}
      isRedirecting={isRedirecting}
      isLoading={isLoading}
      errorMessage={errorMessage}
      onEnterPassword={handleEnterPassword}
      triggerDownload={handleTriggerDownload}
      triggerDownloadAll={handleTriggerDownloadAll}
      // saveFeedback={SaveFeedbackAction}
      token={token}
      orderId={orderId}
    />
  );
};

const mapStateToProps = ({ appState }: any) => ({
  user: appState.cognitoUser
});

export default connect(mapStateToProps)(DocumentDownloadContainer);
