import LocalizedStrings from "react-localization"

export const languages = ["sk", "en", "de"]
export const translations = new LocalizedStrings({
    sk: {
        artifacts: {
            AdressAutocompleteInputsBox: {
                adress: "ulica a č., PSČ, mesto",
            },
            AddressAutoCompleteSeperatedInputBox: {
                street: "Ulica",
                streetSmall: "- ulica",
                streetSmallSecond: "Zadajte ulicu",
                adressNumber: "Číslo",
                adressNumberPlaceholder: "Zadajte číslo",
                city: "Mesto",
                cityPlaceholder: "Zadajte Mesto",
                poscode: "PSČ",
                poscodePlaceholder: "Zadajte PSČ",
                chooseCountry: "Vyberte krajinu",
                country: "Krajina",
            },
            BaseInvestmentBox: {
                baseFinance: "Základné imanie"
            },
            BusinessTransferBox: {
                companyPart: "časť podniku",
                fullCompany: "celý podnik",
                enterPartCompanyName: "Zadajte názov časti podniku",
                partCompanyName: "Názov časti podniku",
            },
            CompanyAvailabilityInputBox: {
                forSure: "Vami zvolené obchodné meno zatiaľ nepoužíva žiadna iná spoločnosť. Pre zvýšenie Vašej právnej istoty sme to aktuálne skontrolovali na",
                orsrsk: "orsr.sk",
                unavailable: "Vami zvolené obchodné meno už niekto používa. Vyberte iné",
                available: 'Dostupná'
            },
            ContractModalBox: {
                title: "Zmluva o poskytovaní služieb",
                parties: "Zmluvné strany",
                lawyersOffice: "Advokátska kancelária Matejka Friedmannová s. r. o.",
                withCompanyAdress: ", so sídlom na adrese: Dunajská ul. 48, 811 08 Bratislava, IČO: 47 248 998, IČ DPH: SK 2023808765, obchodná spoločnosť zapísaná v Obchodnom registri vedenom Mestským súdom Bratislava III, oddiel Sro, vložka č. 90113/B, v mene ktorej koná: JUDr. Ondrej Matejka, konateľ oprávnený konať samostatne (ďalej v texte len „",
                and: "a",
                lawyer: "Advokát",
                orAnd: "“ a/alebo „",
                operator: "Prevádzkovateľ",
                withAdress: ", so sídlom na adrese: ",
                companyWrittenIn: ", obchodná spoločnosť zapísaná v Obchodnom registri vedenom: ",
                part: ", oddiel: ",
                insert: ", vložka č.: ",
                order: ", v mene ktorej je oprávnená samostatne a bez akýchkoľvek obmedzení konať osoba uskutočňujúca Dokončenie objednávky (ďalej v texte len „",
                client: "Klient",
                user: "Používateľ",
                canActAlone: ", v mene ktorej je oprávnená samostatne a bez akýchkoľvek obmedzení konať osoba uskutočňujúca Dokončenie objednávky (ďalej v texte len „",
                articleOne: "Článok I",
                baseAgreement: "Základné zmluvné dojednania",
                firstRule: "Advokát sa zaväzuje poskytnúť Klientovi Služby Aplikácie za podmienok dojednaných touto Zmluvou o poskytnutí služieb a za podmienok stanovených VOP",
                definatedDown: "pojem definovaný nižšie v texte",
                secondRule: "Potrebu poskytnutia právnej pomoci sa Klient zaväzuje špecifikovať Advokátovi výlučne len zadaním požiadaviek Používateľa do Aplikácie, pričom za validné zadanie požiadaviek Používateľa na poskytnutie Služieb Aplikácie sa považuje výlučne len Dokončenie objednávky.",
                thirdRule: "Advokát začína poskytovať Klientovi Služby Aplikácie okamihom bezprostredne nasledujúcim po Dokončení objednávky.",
                fourthRule: "Advokát zodpovedá za Právnu časť Dokumentov, ktoré vytvoril počas trvania tejto Zmluvy o poskytnutí služieb.",
                fifthRule: "Klient sa zaväzuje podrobne sa v Aplikácii oboznámiť so všetkými dostupnými poučeniami Advokáta, s VOP ",
                fifthRuleTwo: ", so zásadami nakladania so súbormi cookie, a so zásadami spracovávania osobných údajov. ",
                sixsthRule: "Advokát odovzdáva Klientovi poskytnuté Služby Aplikácie po komplexnom ukončení spracovania celej požiadavky Klienta.",
                seventhRule: "Advokát odovzdáva Klientovi poskytnuté Služby Aplikácie výlučne len spôsobom Doručenia Vygenerovaných Dokumentov Používateľovi.",
                eightRule: "Advokát uskutočňuje Doručenie Vygenerovaných Dokumentov Používateľovi po uhradení Ceny, spravidla v lehote piatich minút po uhradení Ceny; nedodržanie tejto lehoty môže byť spôsobené najmä nestálosťou internetového pripojenia Používateľa a/alebo akoukoľvek inou technickou nedostatočnosťou internetového pripojenia Používateľa a/alebo akoukoľvek technickou nedostatočnosťou Zariadenia Používateľa a/alebo Aplikáciou.",
                ninethRule: "Klient je oprávnený reklamovať Advokátom poskytnuté Služby Aplikácie podľa príslušných ustanovení Obchodného zákonníka. ",
                articleTwo: "Článok II ",
                lawyersPrize: "Odmena advokáta a platobné podmienky",
                tenthRule: "Klient sa zaväzuje za poskytnutie Služieb Aplikácie zaplatiť Advokátovi Cenu.",
                eleventhRule: "Cena je dojednaná ako paušálna odmena a riadi sa vyhláškou MS SR č. 655/2004 Z. z. o odmenách a náhradách advokátov za poskytovanie právnych služieb a touto Zmluvou o poskytnutí služieb.",
                twelveRule: "Advokát fakturuje Cenu za poskytnuté Služby Aplikácie vždy po komplexnom ukončení spracovania celej požiadavky Klienta.",
                thirtteenthRule: "Podmienky vystavenia faktúry, odoslania faktúry a doručenia faktúry sú obsiahnuté vo VOP ",
                articleThree: "Článok III",
                termsAndConditions: "Vzťah Zmluvy o poskytnutí služieb a Všeobecných obchodných podmienok",
                fourthteenthRule: " Advokát ustanovil Všeobecné obchodné podmienky pre poskytovanie",
                fourthteenthRuleSecond: "právnych služieb použitím Aplikácie a na Internetovej adrese ich Advokát zverejnil pred uzatvorením tejto Zmluvy o poskytnutí služieb v znení, v akom sú VOP účinné ku dňu uzatvorenia tejto Zmluvy o poskytnutí služieb (ďalej v texte len „",
                VOP: "VOP",
                fifthteenthRule: "Neoddeliteľnou súčasťou tejto Zmluvy o poskytnutí služieb sú VOP.",
                sixteenthRule: "V prípade rozporu/kolízie/nesúladu medzi textom tejto Zmluvy o poskytnutí služieb a textom VOP, majú prednosť dojednania obsiahnuté v tejto Zmluve o poskytnutí služieb.",
                articleFour: "Článok IV",
                commonProvisions: "Common provisions",
                seventeenthRule: "Táto Zmluva o poskytnutí služieb sa medzi zmluvnými stranami uzatvára elektronicky použitím Aplikácie.",
                eighteenthRule: "Uskutočnením Dokončenia objednávky nastáva okamih, kedy je medzi Prevádzkovateľom a Používateľom uzatvorená táto Zmluva o poskytnutí služieb.",
                nineteenthRule: "Táto Zmluva o poskytnutí služieb nadobúda platnosť okamihom jej uzatvorenia. ",
                twentiethRule: "Ak nie je uvedené inak, táto Zmluva o poskytnutí služieb vyvoláva právne účinky okamihom jej uzatvorenia.",
                twentyFirstRule: "Povinnosť Prevádzkovateľa Doručiť Vygenerované Dokumenty Používateľovi, nastáva až okamihom zaplatenia Ceny. Používateľ berie na vedomie a výslovne súhlasí s tým, že Prevádzkovateľ nie je povinný Používateľovi Doručiť Vygenerované Dokumenty skôr, než Používateľ zaplatí Prevádzkovateľovi Cenu.",
                twentySecondRule: "Táto Zmluva o poskytnutí služieb sa uzatvára na neurčitý čas. ",
                twentyThirdRule: "Táto Zmluva o poskytnutí služieb je platná a záväzná aj pre právnych nástupcov zmluvných strán. ",
                twentyFourRule: "Advokát sa pri poskytovaní právnej pomoci a právnych služieb riadi platnými právnymi predpismi upravujúcimi výkon advokácie a záväznými predpismi a usmerneniami Slovenskej advokátskej komory. ",
                twentyFiveRule: "Klient udeľuje Advokátovi súhlas použiť verejne dostupné informácie o Klientovi na účely prezentácie Advokáta, najmä udeľuje Advokátovi súhlas použiť obchodné meno a ochrannú známku Klienta na účely referencií Advokáta.",
                articleFive: "Článok V",
                finalProvisions: "Záverečné ustanovenia",
                twentySixRule: "Zmluvné strany považujú obsah tejto Zmluvy o poskytnutí služieb za dôverný a zaväzujú sa ho chrániť. Zmluvné strany sa dohodli, že žiadna z nich samostatne, bez predchádzajúceho súhlasu druhej strany, nebude tretie osoby informovať o obsahu tejto Zmluvy o poskytnutí služieb, ani o právach a povinnostiach z nej pre zmluvné strany vyplývajúcich; uvedené sa nevzťahuje na prípady informačných a oznamovacích povinností vyplývajúcich zmluvným stranám z osobitných právnych predpisov.",
                twentySevenRule: "Klient svojim podpisom na tejto Zmluve o poskytnutí služieb potvrdzuje, že na uzatvorenie tejto Zmluvy o poskytnutí služieb v mene Klienta sú splnené všetky podmienky a predpoklady stanovené jeho internými predpismi a všeobecne záväznými právnymi predpismi, a že táto Zmluva o poskytnutí služieb je za zmluvnú stranu Klienta uzatvorená osobou na to bez obmedzení a bez výhrad oprávnenou v zmysle všeobecne záväzných právnych predpisov.",
                twentyEightRule: "Zmluvné strany vyhlasujú, že sa zdržia akéhokoľvek konania, ktoré by znemožnilo alebo sťažilo naplnenie predmetu tejto Zmluvy o poskytnutí služieb.",
                twentyNineRule: "Klient vyhlasuje, že si túto Zmluvu o poskytnutí služieb pozorne prečítal, jej obsahu dobre porozumel, že táto Zmluva o poskytnutí služieb je prejavom jeho slobodnej, vážnej a určitej vôle, že túto Zmluvu o poskytnutí služieb uzatvára slobodne a nie v tiesni a ani nie za nevýhodných podmienok, na znak čoho Klient pristúpil k uzatvoreniu tejto Zmluvy o poskytnutí služieb.",
                thirtyRule: "Táto zmluva sa vyhotovuje elektronicky.",
            },
            EstablishmentPeriodBox: {
                companyIsMake: "Spoločnosť sa zakladá na dobu",
                forever: "Na dobu neurčitú",
                onDate: "Do dátumu",
                chooseDate: "Vyberte dátum",
            },
            GenerateProtocol: {
                generateProtocol: "Generovať protokol o prevzatí vozidla?"
            },
            GuidanceModalBox: {
                lawyersInfo: "Rady a odporúčania advokáta",
                complexInfo: "Zhrnuli sme pre Vás dôležité informácie. Ďakujeme a pokračujeme",
                readInfo: "Odporúčania advokáta som si prečítal(a)",
                lawyerAdvice: "Rady a odporúčania advokáta",
            },
            NewCompanyAddressBox: {
                companyAdress: "Sídlo spoločnosti bude na adrese",
                cityAdress: "Ulica a č., PSČ, Mesto",
                tooltip: "Je potrebné správne napísať celú adresu, na ktorej bude nové sídlo spoločnosti, aby ste nemali problémy s doručovaním písomností"
            },
            NewCompanyNameBox: {
                companyName: "Názov spoločnosti"
            },
            numberOfPropertyOwners: {
                numberOfPropertyOwnersText: "Koľko osôb vlastní nehnuteľnosť kde bude sídlo?",
                numberOfOwnersTooltip: "Ak si neprajete vygenerovať súhlas vlastníka nehnuteľnosti, nechajte prázdne, alebo zvoľte 0",
                doNotGenerate: "Negenerovať súhlasy vlastníka/ov nehnuteľnosti."
            },
            RecognizeAbilityBox: {
                recognizeAbilityText: "Podľa Obchodného zákonníka na konanie valného zhromaždenia a na prijímanie rozhodnutí o jednotlivých bodoch programu musia byť prítomní spoločníci, ktorí majú aspoň 50% zo všetkých hlasov, ale v spoločenskej zmluve sa môže určiť aj vyšší alebo nižší počet potrebných hlasov. Ako je to v tejto spoločnosti? Koľko % hlasov všetkých spoločníkov je potrebných?",
                noLimits: " Táto spoločnosť nemá žiadne špeciálne podmienky pre konanie valného zhromaždenia a pre hlasovanie, než tie, aké určuje Obchodný zákonník"
            },
            RepresentationBox: {
                representation: "Podáme to za Vás",
                wantRepresentation: "Chcem, aby ste za mňa spravili podanie na Obchodný register",
                freeService: "Ak si službu vyberiete teraz, bude bezplatná,",
                moreInfo: "inak bude spoplatnená podľa ",
                priceList: "Cenníka.",
                yes: "Áno",
                no: "Nie",
                thankYou: "Ďakujeme, radi Vás budeme zastupovať.",
                orsr: "na Obchodný register?",

            }, SelectBoardBox: {
                nameTitleSurname: "Titul, meno, priezvisko"
            },
            KonatelBox: {
                otherAdress: "Iná adresa",
                otherPerson: "Iná osoba",
                enterTitle: "Zadajte tituly pred menom",
                enterTitleAfter: "Zadajte tituly za menom",
                titleAfter: "Tituly za menom",
                enterName: "Zadajte meno",
                name: "Meno",
                enterSurname: "Zadajte priezvisko",
                surname: "Priezvisko",
                beforeName: "Tituly pred menom",
                selectAdress: "Zadajte adresu",
                enterName: "Meno konateľa je",
                nameTitleSurname: "Titul, meno, priezvisko"
            },
            LikvidatorBox: {
                otherPerson: "Iná osoba",
                otherAdress: "Iná adresa",
                selectAdress: "Zadajte adresu",
                enterName: "Meno likvidátora je",
                nameTitleSurname: "Titul, meno, priezvisko",
                enterTitlesBeforeName: "Zadajte tituly pred menom",
                titlesBefore: "Tituly pred menom",
                enterTitlesAfter: "Zadajte tituly za menom",
                titlesAfter: "Tituly za menom",
                enterName: "Zadajte meno",
                enterSurname: "Zadajte priezvisko",
                surname: "Priezvisko",
                name: "Meno",
            },
            SelectPaymentBox: {
                choose: "- Vyberte -",
                cash: "V hotovosti",
                transfer: "Prevodom na účet",
                didRecieve: "Prevzal prevodca dojednanú odplatu?",
            },
            ProkuristaBox: {
                otherAdress: "Iná adresa",
                otherPerson: "Iná osoba",
                enterTitles: "Zadajte tituly pred menom",
                titlesBefore: "Tituly pred menom",
                enterTitlesAfter: "Zadajte tituly za menom",
                titlesAfter: "Tituly za menom",
                enterName: "Zadajte meno",
                name: "Meno",
                enterSurname: "Zadajte priezvisko",
                surname: "Priezvisko",
                selectAdress: "Zadajte adresu",
                nameTitleSurname: "Titul, meno, priezvisko"
            },
            RegistrationCertificateArtifact: {
                labelOwner: "Vlastník vozidla",
                labelHolder: "Držiteľ vozidla",
                labelData: "Údaje o vozidle",
                tooltipTextOwner: "Zadajte údaje o vlastníkovi vozidla",
                tooltipHolderText: "Zadajte údaje o držitelovi vozidla",
                tooltipDataText: "Zadajte údajej o vozidle",
                differentHolder: "Je držiteľ odlišný od vlastníka?",
                owner: "Vlastníkom je",
                holder: "Držiteľom je",
                uploadImageForScanning: "Nahrať/Odfotiť TP",
                uploadImageForScanningTooltip: "Nahrajte foto/scan TP a údaje sa automaticky vyplnia.",
                savedPersonsButton: "Vyberte inú osobu ako na TP"
            },
            RegistrationCertificatePersonBox: {
                choosePersonType: 'Je to človek?',
                individual: "Človek",
                legalPerson: "Právnická osoba",
                selectName: "Zadajte meno",
                selectAdress: "Zadajte adresu",
                searchOrsr: "Vyhľadať v ORSR",
                enterICO: "IČO / identifikátor je",
                enterBirthID: "Rodné číslo / identifikátor je",
                enterICOTooltip: "V niektorých krajinách neprideľujú právnickým osobám IČO, ale osoby dostávajú iné identifikačné údaje. Je potrebné ich sem vyplniť.",
                enterBirthIDTooltip: "V niektorých krajinách neprideľujú osobám rodné číslo, ale osoby dostávajú iné identifikačné údaje. Je potrebné ich sem vyplniť.",
                enterICOPlaceholder: 'napíšte IČO alebo iný identifikačný udaj',
                enterBirthIDPlaceholder: 'napíšte rodné číslo alebo iný identifikačný udaj',
                chooseBirthDate: "Vyberte dátum narodenia",
                birthDate: 'Dátum narodenia',
            },
            RegistrationCertificateDataBox: {
                registrationNumber: "Číslo technického preukazu",
                registrationNumberId: "Číslo technického preukazu",
                yearOfProduction: "Rok výroby",
                yearOfProductionId: "B",
                model: "Druh",
                modelId: "1",
                brand: "Znacka",
                brandId: "D.1",
                type: "Typ",
                typeId: "D.2",
                color: "Farba",
                colorId: "22 R",
                vin: "VIN",
                vinId: "E",
                licensePlate: "Poznávacia značka",
                licensePlateId: "A",
            },
            SpolocnikBox: {
                otherPerson: "Iná osoba",
                otherAdress: "Iná adresa",
                selectAdress: "Zadajte adresu",
                nameTitleSurname: "Titul, meno, priezvisko",
                enterName: "Zadajte meno",
            },
            TitleNameSurnameArtifact: {
                titlesBegoreName: "Tituly pred menom",
                titlesAfterName: "Tituly za menom",
                name: "Meno",
                surname: "Priezvisko",
            },
            MunicipalitySummaryAIArtifact: {
                municipalityName: "Názov obce",
                prepareDescription: "Vytvor popis",
                prepareDescriptionExtended: "Rozšírené vyhľadávanie",
                municipalityDescription: "Popis AI",
                gemini: "Gemini"
            },
            VOPmodalBox: {
                vopIntro: "Advokátska kancelária Matejka Friedmannová s. r. o., so sídlom na adrese: Dunajská 48, 811 08 Bratislava – m. č. Staré Mesto, Slovenská republika, IČO: 47 248 998, zapísaná v Obchodnom registri vedenom Mestským súdom Bratislava III, oddiel Sro, vložka č. 90113/B, ustanovila pre používanie aplikácie ORSR.HELP a pre poskytovanie právnych služieb použitím aplikácie ORSR.HELP Všeobecné obchodné podmienky v následovnom znení (ďalej len „",
                vop: "VOP",
                articleOne: "Článok I",
                mainDefinitions: "Základné pojmy a definície",
                aplication: "„Aplikácia“ ",
                b2bApp: "znamená softvérová B2B [business-to-business] aplikácia s názvom ORSR.HELP vyvinutá Prevádzkovateľom a používaná Prevádzkovateľom ",
                contract: "uzatvorenie Zmluvy o poskytnutí služieb s Používateľom, ",
                using: "na poskytovanie Služieb Aplikácie a",
                payPrice: "na zabezpečenie zaplatenia Ceny za poskytnutie Služieb Aplikácie; súčasťou Aplikácie je internetová stránka dostupná z Internetovej adresy;",
                copyrightLaw: "„Autorský zákon“",
                copyMeans: "znamená zákon č. 185/2015 Z. z. autorský zákon v znení neskorších predpisov;",
                price: "„Cena“ ",
                definition: "znamená sumu elektronicky dohodnutú podľa Cenníka Služieb medzi Prevádzkovateľom a Používateľom za poskytnutie jednotlivých Služieb Aplikácie Používateľovi, ktorú sa Používateľ zaväzuje zaplatiť Prevádzkovateľovi použitím zabezpečenej platobnej brány Aplikácie. Cena je uvedená najmä v časti Aplikácie s názvom Košík, pričom výška Ceny sa odvíja od rozsahu Používateľovej objednávky Služieb Aplikácie. Cena neobsahuje žiadne skryté poplatky. Cena je konečná a je uvádzaná v členení na sumu základu dane z pridanej hodnoty a sumu dane z pridanej hodnoty. V Cene je zahrnutá aj náhrada hotových administratívnych výdavkov Prevádzkovateľa;",
                priceList: "„Cenník Služieb“ ",
                priceDefinition: "znamená cenník určený Prevádzkovateľom pre poskytovanie Služieb Aplikácie a aktuálny ku dňu poskytnutia Služieb Aplikácie Používateľovi. Cenník Služieb obsahuje najmä sumy cien a poplatkov a je zverejnený na Internetovej adrese;",
                civilOrder: "„Civilný mimosporový poriadok“",
                lawDefinition: "znamená zákon č. 161/2015 Z. z. Civilný mimosporový poriadok v znení neskorších predpisov;",
                completeOrder: "„Dokončenie objednávky“",
                itMeans: "znamená vyplnenie všetkých povinných polí objednávkového formulára Aplikácie Používateľom a potvrdenie objednávky Používateľa uskutočnením aktívneho kliknutia Používateľa na príslušné tlačidlo v Aplikácii ",
                finishOrder: " s nápisom „Dokončiť objednávku s povinnosťou platby“ alebo ",
                button: "na príslušné tlačidlo v Aplikácii s iným nápisom rovnakého obsahového významu;",
                document: "„Dokument“",
                partsOfDocument: "znamená ucelenú a vzájomne prepojenú časť Aplikácie, zobrazujúcu Používateľovi textové pole s obsahom súvisiacim od rozsahu Používateľovej objednávky Služieb Aplikácie; Dokument sa člení na",
                firstPartDocument: "Právnu časť Dokumentu a ",
                infoByUser: "Údaje vyplnené Používateľom. Dokument sa Používateľovi v Aplikácii zobrazuje najmä za tým účelom, aby Prevádzkovateľ poskytol Používateľovi online náhľad na konzistenciu a gramatickú súvsťažnosť Údajov vyplnených Používateľom s Právnou časťou Dokumentu. Zobrazovaním Dokumentu Aplikácia umožňuje Používateľovi online kontrolou zistiť chybu/nedostatok/nesprávnosť Údajov vyplnených Používateľom a umožňuje Používateľovi vykonať opravu/doplnenie Údajov vyplnených Používateľom v používateľskom rozhraní Aplikácie;",
                deliverDocuments: "„Doručenie Vygenerovaných Dokumentov“",
                means: "znamená",
                downloadDocuments: "sprístupnenie možnosti stiahnutia Vygenerovaných Dokumentov v Aplikácii do Zariadenia Používateľa a/alebo",
                hyperLink: "odoslanie hyperlinku pre možnosť stiahnutia Vygenerovaných Dokumentov na e-mailovú adresu zadanú Používateľom v objednávkovom formulári Aplikácie a odoslanie hesla na otvorenie zaslaného hyperlinku na mobilné telefónne číslo zadané Používateľom v objednávkovom formulári Aplikácie. Validné Doručenie Vygenerovaných Dokumentov Používateľovi nastane aj vtedy, ak ",
                downloadFromApp: "Používateľ nevyužije možnosť stiahnuť si z Aplikácie do Zariadenia Používateľa Vygenerované Dokumenty a/alebo ",
                badUserInfo: "ak Používateľ v objednávkovom formulári Aplikácie uvedie neexistujúcu/nefungujúcu/nesprávnu/neplatnú e-mailovú adresu a/alebo neexistujúce/nefungujúce/nesprávne/neplatné mobilné telefónne číslo;",
                internetAdress: "„Internetová adresa“",
                anyAdress: "znamená akúkoľvek internetovú adresu, z ktorej je dostupná Aplikácia, najmä internetová stránka www.orsr.help;",
                itAdmin: "„IT admin“",
                itAdminMeans: "znamená označenie pre každú fyzickú osobu vyslovene oprávnenú Prevádzkovateľom na vstup do admin rozhrania Aplikácie;",
                gdpr: "„Nariadenie GDPR“",
                gdprMeans: "znamená Nariadenie Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (všeobecné nariadenie o ochrane údajov);",
                register: "„Obchodný register SR“",
                registerMeans: "znamená verejný zoznam, do ktorého sa zapisujú zákonom stanovené údaje týkajúce sa podnikateľov, prípadne iných osôb, o ktorých to ustanovuje osobitný zákon;",
                commercialCode: "„Obchodný zákonník“",
                commercialMeans: "znamená zákon č. 513/1991 Zb. Obchodný zákonník v znení neskorších predpisov;",
                civilCode: "„Občiansky zákonník“",
                civilMeans: "znamená zákon č. 40/1964 Zb. Občiansky zákonník v znení neskorších predpisov;",
                personInfo: "„Osobné údaje“",
                personMeans: "znamená údaje týkajúce sa identifikovanej fyzickej osoby alebo identifikovateľnej fyzickej osoby, ktorú možno identifikovať priamo alebo nepriamo, najmä na základe všeobecne použiteľného identifikátora, iného identifikátora, ako je napríklad meno, priezvisko, identifikačné číslo, lokalizačné údaje, alebo online identifikátor, alebo na základe jednej alebo viacerých charakteristík alebo znakov, ktoré tvoria jej fyzickú identitu, fyziologickú identitu, genetickú identitu, psychickú identitu, mentálnu identitu, ekonomickú identitu, kultúrnu identitu alebo sociálnu identitu.",
                user: "„Používateľ“",
                userMeans: "znamená obchodnú spoločnosť zapísanú v Obchodnom registri SR; alebo obchodnú spoločnosť, ktorá bude Službou Aplikácie založená; ktorá používa Aplikáciu za účelom kúpenia Služieb Aplikácie. Používateľ nie je fyzická osoba. Používateľ nie je spotrebiteľ. Používateľ je povinný na vlastnú zodpovednosť zabezpečiť, že Aplikáciu používajú v jeho mene osoby na to oprávnené v zmysle ustanovení § 20 Občianskeho zákonníka;",
                lawDocument: "„Právna časť Dokumentu“",
                lawDocumentMeans: "znamená textové pole Dokumentu vytvorené Prevádzkovateľom a obsahujúce úplný text a/alebo časť textácie o.i. zápisnice z valného zhromaždenia, zmluvy, dohody, vyhlásenia, alebo v Aplikácii inak označeného Dokumentu právne relevantného pre konanie vo veciach obchodného registra podľa Civilného mimosporového poriadku;",
                operator: "„Prevádzkovateľ“",
                operatorMeans: "znamená obchodnú spoločnosť, advokátsku kanceláriu Matejka Friedmannová s. r. o., so sídlom na adrese: Dunajská 48, 811 08 Bratislava – m. č. Staré Mesto, Slovenská republika, IČO: 47 248 998, email: info@legaltechfactory.eu, zapísanú v Obchodnom registri vedenom Mestským súdom Bratislava III, oddiel Sro, vložka č. 90113/B, ktorá je vlastníkom a administrátorom Aplikácie. Prevádzkovateľ v zmysle Nariadenia GDPR a Zákona o ochrane osobných údajov vymedzil účel a prostriedky spracúvania Osobných údajov a spracúva Osobné údaje vo vlastnom mene;",
                appServices: "„Služby Aplikácie“",
                appServicesMeans: "znamená spoločné označenie pre jednorazové poskytnutie právnej služby Používateľovi, ktorú Prevádzkovateľ poskytuje Používateľovi podľa Zákona o advokácii, za podmienok dojednaných v Zmluve o poskytnutí služieb a výlučne len použitím Aplikácie, a obsahom ktorej [právnej služby] je ",
                appServicesMeansOne: "spísanie listín na zmenu údajov zapísaných Používateľovi v Obchodnom registri SR podľa požiadaviek Používateľa zadaných v Aplikácii Dokončením objednávky a/alebo spísanie listín na založenie Používateľa podľa požiadaviek Používateľa zadaných v Aplikácii Dokončením objednávky, a",
                appServicesMeansTwo: "vyhotovenie Dokumentov, a",
                documentGeneration: "Vygenerovanie Dokumentov, a",
                deliverGeneratedDocuments: "Doručenie Vygenerovaných Dokumentov Používateľovi;",
                consumer: "„Spotrebiteľ“ ",
                consumerMeans: "znamená fyzická osoba, ktorá pri používaní Aplikácie nekoná v rámci predmetu svojej obchodnej činnosti alebo inej podnikateľskej činnosti.",
                dataByUser: "„Údaje vyplnené Používateľom“",
                dataByUserMeans: "znamená textové pole Dokumentu vytvorené Používateľom v používateľskom rozhraní Aplikácie;",
                generatedDocument: "„Vygenerovaný Dokument“",
                generatedDocumentsMeans: "znamená needitovateľný súbor formátu „pdf“, „docx“, „odt“, „rtf“ a/alebo iného bežne používaného formátu s obsahom textovo totožným obsahu Dokumentu v okamihu Dokončenia objednávky;",
                advocacyLaw: "„Zákon o advokácii“",
                advocacyLawMeans: "znamená zákon č. 586/2003 Z. z. o advokácii a o zmene a doplnení zákona č. 455/1991 Zb. o živnostenskom podnikaní (živnostenský zákon) v znení neskorších predpisov;",
                eGoverment: "„Zákon o e-Governmente“",
                eGovermentMeans: "znamená zákon č. 305/2013 Z. z. o elektronickej podobe výkonu pôsobnosti orgánov verejnej moci a o zmene a doplnení niektorých zákonov (zákon o e-Governmente) v znení neskorších predpisov;",
                userDataLaw: "„Zákon o ochrane osobných údajov“",
                userDataLawMeans: "znamená zákon č, 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov;",
                userDevice: "„Zariadenie Používateľa“",
                userDeviceMeans: "znamená akékoľvek zariadenie, najmä desktop PC, laptop, notebook, smart-phone a iné zariadenie, použitím ktorého Používateľ používa Aplikáciu;",
                userRepresentation: "„Zastupovanie Používateľa“",
                userRepresentationOne: "znamená právne zastupovanie Používateľa podľa príslušných ustanovení Civilného mimosporového poriadku, podľa Zákona o advokácii a podľa ostatných príslušných právnych predpisov právne záväzných v Slovenskej republike, na základe osobitného písomného plnomocenstva udeleného Prevádzkovateľovi, ",
                userRepresentationTwo: "v konaní o zápis zmeny/zmien údajov zapísaných o Používateľovi v Obchodnom registri SR, výlučne však len v rozsahu Služieb Aplikácie poskytnutých Používateľovi spôsobom Doručenia Vygenerovaných Dokumentov a/alebo ",
                userRepresentationThree: "v konaní o prvozápis Používateľa do Obchodného registra SR, výlučne však len v rozsahu Služieb Aplikácie poskytnutých Používateľovi spôsobom Doručenia Vygenerovaných Dokumentov. Prevádzkovateľ je povinný uskutočniť Zastupovanie Používateľa len ak sú kumulatívne splnené všetky následovné podmienky:",
                printDocuments: "Používateľ vytlačil všetky Vygenerované Dokumenty a nespravil do ich textu žiaden zásah a zabezpečil na nich podpisy konajúcich osôb tak, ako to vyplýva z Vygenerovaných Dokumentov,",
                allDocuments: "Používateľ všetky dokumenty podľa bodu 26.1. týchto VOP do 25 dní od Doručenia Vygenerovaných Dokumentov doručil Prevádzkovateľovi, a to ",
                allDocumentsOne: "formou originálu jedného rovnopisu každého Vygenerovaného Dokumentu v listinnej podobe a doručeného Prevádzkovateľovi na adresu sídla Prevádzkovateľa, alebo",
                allDocumentsTwo: "formou elektronického dokumentu podpísaného kvalifikovaným elektronickým podpisom [KEP] a doručeného do elektronickej schránky Prevádzkovateľa zriadenej na Ústrednom portáli verejnej správy na adrese www.slovensko.sk podľa Zákona o e-Governmente.",
                finishOrderLaw: "Používateľ Dokončením objednávky udeľuje Prevádzkovateľovi výslovný, neodvolateľný, nepodmienený, nemenný, časovo ani inak neobmedzený pokyn, aby Prevádzkovateľ pri Zastupovaní Používateľa neuskutočnil žiadnu kontrolu správnosti/úplnosti/pravdivosti Údajov vyplnených Používateľom a aby Prevádzkovateľ neuskutočnil žiadnu kontrolu úplnosti dokumentov požadovaných právnymi predpismi na Zastupovanie Používateľa.",
                userPresentation: "Prevádzkovateľ uskutoční Zastupovanie Používateľa ",
                recordChanges: "konaní o zápis zmeny/zmien údajov zapísaných o Používateľovi v Obchodnom registri SR podaním elektronického návrhu na príslušný registrový súd alebo ",
                recordChangesOne: " v konaní o ohlásenie živnostenských oprávnení a zápis zmien údajov zapísaných o Používateľovi v Obchodnom registri SR na základe ustanovení Smernice 2006/123/ES o službách na vnútornom trhu podaním elektronického návrhu na elektronické jednotné kontaktné miesto v Slovenskej republike alebo ",
                recordChangesTwo: "v konaní o ohlásenie živnostenských oprávnení a prvozápis Používateľa v Obchodnom registri SR na základe ustanovení Smernice 2006/123/ES o službách na vnútornom trhu podaním elektronického návrhu na elektronické jednotné kontaktné miesto v Slovenskej republike, a ",
                recordChangesThree: "doručením rozhodnutia vydaného v konaní podľa predchádzajúcich písm. (a), (b) alebo (c) tejto vety na e-mailovú adresu zadanú Používateľom v objednávkovom formulári Aplikácie;",
                changesContract: "„Zmluva o poskytnutí služieb“ ",
                changesContractMeans: "znamená písomnú zmluvu elektronicky uzatvorenú v Aplikácii medzi Používateľom a Prevádzkovateľom, na základe ktorej sa Prevádzkovateľ zaväzuje poskytnúť Používateľovi Služby Aplikácie a Používateľ sa zaväzuje zaplatiť Prevádzkovateľovi Cenu. Zmluva o poskytnutí služieb upravuje práva a povinností Prevádzkovateľa a Používateľa týkajúce sa poskytnutia Služieb Aplikácie;",
                articleNumberTwo: "Článok II.",
                rulesOfApp: "Pravidlá a podmienky používania Aplikácie",
                thisVopEdits: "Tieto VOP upravujú ",
                rulesOfUse: "podmienky a pravidlá používania Aplikácie a ",
                lawBetween: " vzájomné práva a povinnosti medzi Prevádzkovateľom a Používateľom založené Zmluvou o poskytnutí služieb.",
                appNotFor: "Aplikácia nie je určená pre Spotrebiteľa. Spotrebiteľovi sa zakazuje používať Aplikáciu.",
                flawless: "Prevádzkovateľ nikomu negarantuje nepretržitú funkčnosť Aplikácie a ani bezchybnú činnosť Aplikácie.",
                operatorIsNot: "Prevádzkovateľ nie je povinný poskytovať Služby Aplikácie okamžite, nepretržite alebo bez výpadkov.",
                everybodyUse: "Každý, kto používa Aplikáciu, má povinnosti Používateľa vyplývajúce z týchto VOP a z právnych predpisov právne záväzných v Slovenskej republike.",
                everybodyHaveTo: "Každý je povinný pri používaní Aplikácie dodržiavať povinnosti Používateľa vyplývajúce z týchto VOP a z právnych predpisov právne záväzných v Slovenskej republike.",
                everybodyCould: "Každý je oprávnený používať Aplikáciu výlučne len v používateľskom rozhraní Aplikácie.",
                onlyAdmin: "Prevádzkovateľ zabezpečuje admin rozhranie Aplikácie proti náhodnému vstupu/preniknutiu tretích osôb; do admin rozhrania Aplikácie má prístup len IT admin.",
                nobodyBesidesAdmin: "Všetkým sa zakazuje vstupovať do admin rozhrania Aplikácie.",
                appPurpose: "Účelom Aplikácie je výlučne len uzatvorenie Zmluvy o poskytnutí služieb, poskytovanie Služieb Aplikácie a zabezpečenie zaplatenia Ceny za poskytnutie Služieb Aplikácie.",
                appOnlyForSale: "Používateľ je povinný používať Aplikáciu len za účelom kúpenia Služieb Aplikácie a zaplatenia Ceny za poskytnutie Služieb Aplikácie.",
                userUsingApp: "Používateľ a osoba konajúca v mene Používateľa pri používaní Aplikácie, sú v plnom rozsahu a bez akýchkoľvek obmedzení, spoločne a nerozdielne zodpovední Prevádzkovateľovi za škodu spôsobenú porušením povinností Používateľa vyplývajúcich z týchto VOP.",
                userAccept: "Používateľ používaním Aplikácie tieto VOP akceptuje, zaväzuje sa nimi riadiť a zaväzuje sa korektne dodržiavať všetky povinnosti vyplývajúce Používateľovi z týchto VOP.",
                usingAppToBuy: "Používanie Aplikácie, kúpenie Služieb Aplikácie a zaplatenie Ceny za poskytnutie Služieb Aplikácie, sú podmienené prístupom na internet. Prístup na internet nie je súčasťou Aplikácie ani súčasťou Služieb Aplikácie.",
                operatorCan: "Prevádzkovateľ nie je zodpovedný za žiadne obmedzenia používania Aplikácie, za žiadne vady Služieb Aplikácie a za žiadne vady platenia Ceny za poskytnutie Služieb Aplikácie, ktoré sú spôsobené nestálosťou internetového pripojenia Používateľa a/alebo akoukoľvek inou technickou nedostatočnosťou internetového pripojenia Používateľa a/alebo akoukoľvek technickou nedostatočnosťou Zariadenia Používateľa a/alebo Aplikáciou.",
                operatorDont: "Prevádzkovateľ nie je zodpovedný za žiadne škody spôsobené Používateľovi v súvislosti s používaním Aplikácie ani v súvislosti s poskytovaním Služieb Aplikácie ani v súvislosti s platením Ceny za poskytnutie Služieb Aplikácie, ktoré boli Používateľovi spôsobené nestálosťou internetového pripojenia Používateľa a/alebo akoukoľvek inou technickou nedostatočnosťou internetového pripojenia Používateľa a/alebo akoukoľvek technickou nedostatočnosťou Zariadenia Používateľa a/alebo Aplikáciou.",
                operatorAndRisk: "Prevádzkovateľ nikomu nezodpovedá za žiadnu nepriamu, náhodnú, následnú, mimoriadnu, trestnú a/alebo podobnú škodu, vrátane [najmä, nie však výlučne] zodpovednosti za stratu príjmu, stratu zisku, stratu dát alebo prevádzkové poruchy (prerušenie prevádzky) alebo za iné nehmotné straty (i keď takéto straty sú kvalifikované), ktoré vzniknú z/alebo akokoľvek súvisia s ",
                withThisVOP: "týmito VOP a/alebo",
                appService: "Službami Aplikácie a/alebo",
                appForContract: "Aplikáciou, či už na základe Zmluvy o poskytnutí služieb, úmyselného porušenia práva alebo na základe iného právneho titulu a bez ohľadu na to, či Prevádzkovateľ upozornil Používateľa [resp. či Používateľ bol upozornený] na možnosť takejto škody.",
                appPrice: "Používateľ používaním Aplikácie konkludentne ubezpečuje Prevádzkovateľa, že Používateľovi nevznikajú žiadne náklady vyvolané používaním Aplikácie, najmä žiadne osobitné náklady za použitie komunikačných prostriedkov na diaľku.",
                userRespects: "Používateľ je pri používaní Aplikácie povinný rešpektovať práva a oprávnené záujmy Prevádzkovateľa, najmä pri nakladaní s autorskými dielami a inými predmetmi práva duševného vlastníctva.",
                userCant: "Používateľovi sa zakazuje používať Aplikáciu takým spôsobom, ktorý spôsobí porušenie Aplikácie a/alebo obmedzenie prevádzky Aplikácie a/alebo obmedzenie funkčnosti Aplikácie.",
                userCantOne: "Používateľovi sa zakazuje akýmkoľvek spôsobom zasahovať do Aplikácie alebo jej častí, meniť, kopírovať a/alebo rozširovať vzhľad, grafické prvky, rozhranie, technický charakter a ďalšie vlastnosti Aplikácie.",
                userCantTwo: "Používateľovi sa zakazuje sprístupňovať Aplikáciu tretím osobám.",
                userCantThree: "Používateľovi sa zakazuje prostredníctvom Aplikácie poskytovať služby tretím osobám.",
                userHaveTo: "Používateľ je povinný zabezpečiť, aby v prípade zneužívania alebo podozrenia zo zneužívania Aplikácie boli vykonané všetky potrebné úkony smerujúce k upusteniu od takéhoto stavu alebo od činností porušujúcich právne predpisy alebo zmluvné dojednania medzi ním a Prevádzkovateľom, a tiež bezodkladne odstrániť všetky následky takýchto činností a zásahov do oprávnených záujmov Prevádzkovateľa; tým nie je dotknutá zodpovednosť Používateľa za škodu spôsobenú Prevádzkovateľovi.",
                userCould: "Používateľ je oprávnený objednať si u Prevádzkovateľa Služby Aplikácie uzatvorením Zmluvy o poskytovaní služieb.",
                isForbidden: "Používateľovi je pri používaní Aplikácie zakázané:",
                useProfanity: "používať vulgarizmy, slovné spojenia a/alebo slovné alebo znakové výrazy, ktorých priamy alebo nepriamy význam je v rozpore so všeobecne akceptovateľnou spoločenskou morálkou a dobrými mravmi,",
                useReservations: "používať vyhrážky a osobné útoky voči Prevádzkovateľovi, iným Používateľom alebo akýmkoľvek tretím osobám,",
                useLies: "uvádzať nepravdivé, neoverené, zavádzajúce, urážlivé alebo klamlivé informácie o inej osobe,",
                useViolence: "propagovať násilie a otvorene alebo skrytou formou podnecovať k nenávisti na základe pohlavia, rasy, farby pleti, jazyka, viery a náboženstva, politického či iného zmýšľania, národného alebo sociálneho pôvodu, príslušnosti k národnosti alebo k etnickej skupine alebo na základe akéhokoľvek iného dôvodu,",
                useWar: "propagovať vojnu alebo opisovať kruté alebo inak neľudské konanie spôsobom, ktorý je ich nevhodným zľahčovaním, ospravedlňovaním alebo schvaľovaním,",
                useAlcohol: "otvorene alebo skrytou formou propagovať alkohol, alkoholizmus, fajčenie, užívanie omamných látok, jedov a prekurzorov alebo zľahčovať následky užívania uvedených látok,",
                useFear: "ohrozovať fyzický, psychický alebo morálny vývin maloletých alebo narúšať ich duševné zdravie a emocionálny stav,",
                useChildPornography: "propagovať detskú pornografiu,",
                useBadTexts: "zverejňovať nezmyselné alebo nezrozumiteľné texty, ako aj vykonávať iné aktivity, ktoré môžu čo i len potenciálne viesť k obmedzeniu alebo zníženiu kvality Aplikácie a/alebo k obmedzeniu alebo zníženiu kvality poskytovaných Služieb Aplikácie,",
                usePrivateInfo: "zverejňovať osobné údaje o iných osobách, najmä ich meno, priezvisko, adresu alebo telefónne číslo, pokiaľ k tomu uvedená osoba neudelila súhlas,",
                shareReservations: "šíriť obscénne, vulgárne a urážlivé materiály a protizákonné materiály, pričom zakázané je aj zverejňovať odkaz na akékoľvek webové stránky s takýmto obsahom,",
                takeUserInfo: "získavať prihlasovacie mená a/alebo heslá iných Používateľov,",
                editApp: "zneužívať, blokovať, modifikovať alebo inak meniť, upravovať akúkoľvek súčasť Aplikácie, alebo sa čo len pokúsiť narušiť stabilitu, integritu, chod alebo dáta Aplikácie,",
                hackApp: "používať mechanizmy, programové vybavenie alebo iné postupy, ktoré by mohli mať negatívny vplyv na prevádzku Aplikácie,",
                shareCompetition: "zverejňovať akékoľvek informácie, ktoré svojím obsahom môžu naplniť znaky nekalej súťaže podľa ustanovenia § 44 – 51 zákona č. 513/1991 Zb. Obchodný zákonník v znení neskorších predpisov,",
                shareCommercial: "zverejňovať akúkoľvek reklamu alebo propagáciu výrobkov, tovarov alebo služieb.",
                articleThree: "Článok III.",
                contractOfProvision: "Zmluva o poskytnutí služieb",
                finishOrderMoment: "Dokončením objednávky nastáva okamih, kedy je medzi Prevádzkovateľom a Používateľom uzatvorená Zmluva o poskytnutí služieb.",
                acceptVOP: "Používateľ vyhlasuje, že sa pred uzatvorením Zmluvy o poskytnutí služieb dôkladne a detailne oboznámil so znením Zmluvy o poskytnutí služieb a so znením týchto VOP.",
                userAcceptContract: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje, že ustanovenia Zmluvy o poskytnutí služieb a ustanovenia VOP sú mu zrozumiteľné a jasné.",
                partOfContractIsVOP: "Neoddeliteľnou súčasťou Zmluvy o poskytnutí služieb sú tieto VOP.",
                contractIsActive: "Zmluva o poskytnutí služieb je platná a záväzná aj pre právnych nástupcov Prevádzkovateľa a pre právnych nástupcov Používateľa.",
                userAcceptActiveContract: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje, že Prevádzkovateľ nie je povinný preveriť ani žiadnym spôsobom skontrolovať údaje uvádzané Používateľom v objednávkovom formulári Aplikácie, najmä Prevádzkovateľ nie je povinný skontrolovať či sa nejedná o neexistujúcu/nefungujúcu/nesprávnu/neplatnú e-mailovú adresu osoby konajúcej v mene Používateľa a/alebo či sa nejedná o neexistujúce/nefungujúce/nesprávne/neplatné mobilné telefónne číslo osoby konajúcej v mene Používateľa.",
                userCloseContract: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje, že Prevádzkovateľ nie je povinný preveriť ani žiadnym spôsobom skontrolovať Údaje vyplnené Používateľom.",
                operatorAccept: "Prevádzkovateľ nikomu negarantuje, že Údaje vyplnené Používateľom je možné zapísať do Obchodného registra SR.",
                operatorInform: "Prevádzkovateľ oznamuje Používateľovi a Používateľ berie na vedomie skutočnosť, že uzatvorením Zmluvy o poskytnutí služieb Používateľ záväzne žiada Prevádzkovateľa o bezodkladné začatie poskytovania Služieb Aplikácie.",
                userAcceptService: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje, že pred uzavretím Zmluvy o poskytnutí služieb ho Prevádzkovateľ prostredníctvom Aplikácie riadne a v dostatočnom rozsahu oboznámil s predpokladaným rozsahom právnych úkonov, ktoré je potrebné uskutočniť na poskytnutie Služieb Aplikácie v rozsahu Používateľovej objednávky.",
                operatorAutomaticaly: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje skutočnosť, že Prevádzkovateľ automaticky začína poskytovať Používateľovi Služby Aplikácie okamihom uzatvorenia Zmluvy o poskytnutí služieb, ešte pred zaplatením Ceny.",
                operatorOnline: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje, že Používateľ využil možnosť online zistiť chybu/nedostatok/nesprávnosť Údajov vyplnených Používateľom ",
                controllData: "prednostne preverením správnosti a úplnosti údajov vyplnených Používateľom v Aplikácii a",
                controllText: "kontrolou textu Dokumentu v používateľskom rozhraní Aplikácie.",
                onlyInApp: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje, že dopĺňanie a/alebo opravovanie chýb/nedostatkov/nesprávností Údajov vyplnených Používateľom, je možné vykonať výlučne len v používateľskom rozhraní Aplikácie pred Dokončením objednávky.",
                editDocument: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje, že Vygenerované Dokumenty je z technických dôvodov objektívne nemožné editovať [t.j. najmä meniť, dopĺňať, vymazávať a pod.].",
                incidentsFromContract: "Povinnosť Prevádzkovateľa Doručiť Vygenerované Dokumenty Používateľovi zakotvená v Zmluve o poskytnutí služieb, vyvoláva právne účinky až okamihom zaplatenia Ceny. Používateľ berie na vedomie a výslovne súhlasí s tým, že Prevádzkovateľ nie je povinný Používateľovi Doručiť Vygenerované Dokumenty skôr, než Používateľ zaplatí Prevádzkovateľovi Cenu.",
                goodPrice: "Používateľ potvrdzuje, že Cenu považuje za zodpovedajúcu poskytovaným Službám Aplikácie a že takto dohodnutú Cenu nepovažuje za odporujúcu dobrým mravom.",
                afterOrderFinish: "Po Dokončení objednávky je Používateľ Aplikáciou presmerovaný na zabezpečenú platobnú bránu Aplikácie za účelom zaplatenia Ceny.",
                userAcceptPay: "Používateľ je povinný zaplatiť Prevádzkovateľovi Cenu výlučne len použitím zabezpečenej platobnej brány Aplikácie; použitie iných spôsobov platby nemá za následok splnenie povinnosti Používateľa zaplatiť Prevádzkovateľovi Cenu.",
                threeTimes: "Na zaplatenie Ceny použitím zabezpečenej platobnej brány Aplikácie má Používateľ tri [3] pokusy.",
                afterPay: "Proti pohľadávke Prevádzkovateľa na zaplatenie Ceny nie je možné započítať žiadnu pohľadávku Používateľa voči Prevádzkovateľovi ani žiadnu pohľadávku tretej osoby voči Prevádzkovateľovi.",
                paymentDocument: "Prevádzkovateľ vystaví Používateľovi riadnu faktúru podľa rozsahu poskytnutých Služieb Aplikácie. Súčasťou faktúry je špecifikácia poskytnutých Služieb Aplikácie.",
                serviceTax: "Používateľ týmto vyslovuje v zmysle § 71 ods. 1 písm. b) zákona č. 222/2004 Z. z. o dani z pridanej hodnoty súhlas s vydaním elektronickej faktúry. Faktúra sa doručuje Používateľovi výlučne len elektronicky zaslaním na e-mailovú adresu zadanú Používateľom do objednávkového formulára Aplikácie pri Dokončení objednávky. Prevádzkovateľ odošle Používateľovi faktúru bezodkladne po zaplatení Ceny. Faktúra sa považuje za doručenú Používateľovi okamihom odoslania faktúry na e-mailovú adresu zadanú Používateľom do objednávkového formulára Aplikácie pri Dokončení objednávky. Validné Doručenie faktúry Používateľovi nastane aj vtedy, ak Používateľ v objednávkovom formulári Aplikácie uvedie neexistujúcu/nefungujúcu/nesprávnu/neplatnú e-mailovú adresu.",
                noInterest: "Prevádzkovateľ a Používateľ sa dohodli, že Prevádzkovateľ nie je povinný po skončení poskytovania Služieb Aplikácie odovzdať Používateľovi protokolárne, osobne ani nijak inak spisovú agendu, nakoľko Používateľ má záujem len o Doručenie Vygenerovaných Dokumentov a okrem toho Používateľ nemá žiaden záujem o spisovú agendu.",
                operatorAcceptLaw: "Prevádzkovateľ sa pri poskytovaní Služieb Aplikácie riadi právnymi predpismi právne záväznými v Slovenskej republike a záväznými predpismi a usmerneniami Slovenskej advokátskej komory.",
                contractCancel: "Zmluva o poskytnutí služieb sa automaticky zrušuje márnym uplynutím tretieho pokusu Používateľa o zaplatenie Ceny použitím zabezpečenej platobnej brány Aplikácie; takéto zrušenie Zmluvy o poskytnutí služieb má za následok",
                contractCancelOne: " stratu všetkých údajov vyplnených Používateľom v Aplikácii a ",
                contractCancelTwo: "presmerovanie Používateľa na domovskú stránku Aplikácie.",
                operatorAfterContract: "Nároky Prevádzkovateľa vyplývajúce zo Zmluvy o poskytnutí služieb nezanikajú ani po zániku Zmluvy o poskytnutí služieb.",
                articleNumberFour: "Článok IV.",
                responsibility: "Zodpovednosť za obsah Vygenerovaných Dokumentov",
                makeLawPart: "Prevádzkovateľ je povinný vytvoriť Právnu časť Dokumentu tak, aby v rozsahu Používateľom zvolenej objednávky Služieb Aplikácie, Vygenerované Dokumenty obsahovali všetky podstatné náležitosti právne relevantné pre konanie vo veciach obchodného registra podľa Civilného mimosporového poriadku.",
                deleteOne: "Prevádzkovateľ si vyhradzuje právo kedykoľvek zmazať obsah Údajov vyplnených Požívateľom,",
                deleteTwo: "ktorý porušuje ustanovenia Článku II. bod 30 týchto VOP alebo",
                deleteThree: "pri ktorom je podozrenie, že by čo i len potenciálne mohol porušovať tieto VOP.",
                operatorTakes: "Prevádzkovateľ si vyhradzuje právo:",
                partTime: "dočasne alebo natrvalo, v celom rozsahu alebo čo i len čiastočne prerušiť možnosť zadania Údajov vyplnených Používateľom;",
                anyTime: "kedykoľvek vykonať technickú odstávku Aplikácie, a to aj bez akéhokoľvek predchádzajúceho upozornenia,",
                without: "bez obmedzení vykonávať akékoľvek práva, ktoré Prevádzkovateľovi vyplývajú z týchto VOP alebo z právnych predpisov právne záväzných v Slovenskej republike.",
                userDontTake: "Prevádzkovateľ nezodpovedá za správnosť/pravdivosť/úplnosť Údajov vyplnených Používateľom.",
                userAcceptResponsibility: "Používateľ prijíma výhradnú plnú zodpovednosť za ním uskutočnené konanie pri používaní Aplikácie, najmä pri zvolení, používaní, nastavení a zadaní Údajov vyplnených Používateľom.",
                content: "Zadanie obsahu Údajov vyplnených Používateľom neznamená, že sa Prevádzkovateľ stotožňuje s ich obsahom, správnosťou, pravdivosťou a/alebo úplnosťou.",
                artivleNumberFive: "Článok V.",
                lawOfAuthor: "Právo duševného vlastníctva",
                author: "Aplikácia je autorským dielom, ku ktorému majetkové práva vykonáva Prevádzkovateľ a je chránená predovšetkým Autorským zákonom a ďalšími príslušnými právnymi predpismi právne záväznými v Slovenskej republike.",
                operatorProperty: "Prevádzkovateľ má všetky majetkové práva k Aplikácii a výlučne Prevádzkovateľ je oprávnený poskytnúť súhlas/licenciu na používanie Aplikácie. Podmienky používania Aplikácie určuje, vydáva a mení výhradne Prevádzkovateľ.",
                operatorCanUse: "Prevádzkovateľ umožňuje Používateľovi používanie Aplikácie výlučne len pre osobné a nekomerčné potreby Používateľa. Akceptáciou týchto VOP Používateľ súhlasí, že nebude používať žiadne Služby Aplikácie za iným účelom ako je uvedený v predchádzajúcej vete. Doručením Vygenerovaných Dokumentov končí oprávnenie Používateľa na používanie Aplikácie.",
                cantAnalyse: "Používateľ nie je oprávnený Aplikáciu analyzovať, meniť, upravovať a dopĺňať, rozmnožovať, spracovávať, upravovať, distribuovať, vyhotovovať preklad Aplikácie zo strojového kódu do zdrojového jazyka, ani voľne modifikovať, ani adaptovať Aplikáciu podľa svojej potreby, a to ani prostredníctvom tretích osôb.",
                noCopy: "Používateľ nie je oprávnený vyhotoviť záložnú rozmnoženinu Aplikácie, a to ani prostredníctvom tretích osôb, nakoľko takáto rozmnoženina Aplikácie nie je potrebná pre fungovanie Aplikácie ani pre používanie Aplikácie.",
                cantTest: "Používateľ nie je oprávnený preskúmať ani testovať funkčnosť Aplikácie za účelom zistenia princípov, na ktorých bola Aplikácia založená a vytvorená, ani za účelom zistenia princípov, na ktorých bol založený a vytvorený ktorýkoľvek prvok Aplikácie. Porušenie VOP podľa tohto bodu Používateľom je dôvodom na okamžité zablokovanie Používateľa v Aplikácii, s čím Používateľ vyjadruje svoj výslovný a bezvýhradný súhlas. Nárok Prevádzkovateľa na náhradu škody voči Používateľovi nie je týmto dotknutý.",
                cantOwn: "Používateľ využívaním Aplikácie nenadobúda vlastnícke právo, ani žiadne majetkové ani osobnostné práva k Aplikácii, softvéru, ani k žiadnej ich súčasti.",
                userAndOperator: "Používateľ a Prevádzkovateľ sa týmto dohodli,",
                operatorOwn: "že akékoľvek údaje vložené Používateľom do databázy obsiahnutej v Aplikácii sú časťou databázy zhromaždenej Prevádzkovateľom a",
                databaseOwner: "že zriaďovateľom databázy je výhradne Prevádzkovateľ. Používateľovi nevzniká žiadne právo k databáze obsiahnutej v Aplikácii.",
                articleNumber: "Článok VI.",
                finalDecision: "Záverečné ustanovenia",
                userAgree: "Používateľ súhlasí ",
                userAgreeOne: " so spracovaním osobných údajov v rozsahu podľa Súhlasu so spracovaním osobných údajov a ",
                userAgreeTwo: "so spracovaním súborov cookies v rozsahu podľa Súhlasu so spracovaním súborov cookies.",
                lawOfState: "Tieto VOP sa riadia právnym poriadkom Slovenskej republiky. Právny vzťah medzi Prevádzkovateľom a Používateľom sa riadi právom Slovenskej republiky, predovšetkým Obchodným zákonníkom. Prípadné spory medzi Prevádzkovateľom a Používateľom budú rozhodované vecne a miestne príslušným súdom podľa sídla Prevádzkovateľa.",
                changeVOP: "Prevádzkovateľ si vyhradzuje právo na zmenu týchto VOP. Zmena VOP je platná a účinná dňom uvedeným vo VOP. Prevádzkovateľ zverejní nové VOP umiestnením na Internetovej stránke.",
                userAndOperatorAccept: "Prevádzkovateľ a používateľ sa dohodli, že plne uznávajú elektronickú formu komunikácie prostredníctvom elektronickej pošty a internetovej siete ako platnú a záväznú pre obe zmluvné strany."
            },
            VotingImputBox: {
                voteYes: "Za",
                votingMaybe: "Zdržal sa",
                voteNo: "Proti",
                numberOfVotes: "Množstvo hlasov:",
                forChange: 'Ak sa tento spoločník nezúčastnil na hlasovaní o tomto bode programu, najskôr vypnite možnosť  "Na valnom zhromaždení boli prítomní všetci spoločníci", ktorú ste zaškrtli v úvodnom bode 2. s názvom "Ktorí spoločníci sa zúčastnili na valnom zhromaždení?"',
                forChangeTwo: 'Ak tento spoločník nehlasoval za prijatie rozhodnutia o tomto bode programu, najskôr vypnite možnosť "Všetci spoločníci hlasovali za schválenie všetkých bodov programu" v úvodnom v bode 2. s názvom "Ktorí spoločníci sa zúčastnili na valnom zhromaždení?"',
                here: "Zúčastnil sa",
                notImportant: "Na jeho hlasy sa neprihliada",
                voteCanOnly: "Hlasovať o tomto bode programu môže iba ten spoločník, ktorý sa zúčastnil na hlasovaní",
                forVote: 'Aby spoločník mohol hlasovať, musíte v úvodnom v bode 2. s názvom "Ktorí spoločníci sa zúčastnili na valnom zhromaždení?" napísať, koľko hlasov v % patrí tomuto spoločníkovi',
                forVoteInput: 'Aby spoločník mohol hlasovať, musíte v úvodnom v bode 2. s názvom "Ktorí spoločníci sa zúčastnili na valnom zhromaždení?" napísať, koľko hlasov v % patrí tomuto spoločníkovi',
                voting: "Hlasovanie",
                votingCount: "% hlasov.",
                accepted: "Uznesenie bolo prijaté",
                border: "Pre konanie VZ treba:",
                percents: "Pre schválenie treba:",
                herePeople: "% hlasov prítomných spoločníkov.",
                allPeople: "% hlasov všetkých spoločníkov.",
                noPeople: "Žiadny spoločníci",
                notRecognizable: "Valné zhromaždenie nie je uznášania schopné",
            },
            ZivnostNewBox: {
                remove: "Odobrať",
                enterRange: "Túto živnosť špecifikujte tak, ako je napísaná v doklade o odbornej kvalifikácií",
                range: "Prepíšte rozsah tejto živnosti z dokladu o odbornej kvalifikácií",
                activity: "Názov pridaného predmetu podnikania je",
                activityPlaceholder: "Napíšte presný názov",
                choose: "Chcem pridať živnosť",
                chooseButton: "Vyberte si zo zoznamu",
                orEnter: "Chcem pridať nie živnosť",
                add: "Napíšte predmet podnikania",
                chooseAcitivity: "Vyberte si zo zoznamu živnostenských oprávnení, ktoré eviduje živnostenský úrad",
            },

            CheckouFormBox: {
                invoiceDocs: "Fakturačné údaje",
                makeInvoice: "Chcem fakturovať na inú firmu",
                company: "Názov spoločnosti",
                companyAdress: "Adresa",
                dic: "DIČ",
                icdph: "IČ DPH",
                contactData: "Kontaktné údaje",
                nameSurname: "Meno a priezvisko",
                enterValidMail: 'Prosím zadajte platný email.',
                phone: "Telefónne číslo",
                enterValidPhone: "Prosím zadajte platné telefónne číslo.",
                docs: "Dokumentácia:",
                moenyFor: "Odmena za vyhotovenie dokumentácie",
                representing: "Zastupovanie:",
                moneyForRepresenting: "Odmena za zastupovanie",
                withoutWat: "Spolu bez DPH",
                with20Wat: "DPH 20%",
                fees: "Súdne a správne poplatky:",
                courtFee: "Súdny poplatok",
                sendingFee: "Dobropisovaná suma",
                finalAmount: "Konečná suma",
                discount: "Zľava pre partnera",
                acceptTips: "Akceptujem rady a odporúčania advokáta",
                iaccept: "Súhlasím so",
                universalRules: "všeobecnými obchodnými podmienkami",
                aboutContract: "zmluvou o poskytovaní služieb",
                campaignAgreement: "zasielaním marketingových ponúk",
                finishOrder: "Vygenerovať dokumenty",
                enterPassword: "Zadajte heslo pre registráciu",
                weakPassword: "Heslo musí obsahovať aspoň jedno velké a malé písmeno, aspoň jednu číslicu a aspoň jeden špeciálny znak: '!@#$%^&*)(+=._-'",
                cognitoErrorUserExists: 'Užívateľ s daným menom už existuje, použite iný email.'
            },
            LoadOrderModalBox: {
                password: "Heslo k formuláru",
                loadContent: "Načítať formulár",

            },
            LoginModalBox: {
                lostAuthAppAccess: "Ak nemáte prístup k aplikácií autentifikátor, kliknite nižšie pre odoslanie kódu cez sms",
                loading: "Načítavam ...",
                loginRegister: "Prihlásenie",
                login: "Prihlásiť",
                register: "Registrovať",
                forgot: "Zabudol som heslo",
                operator: "Portál prevádzkuje",
                youCanPayWith: "Na tejto stránke môžete bezpečne platiť cez:",
                rules: "Všeobecné obchodné podmienky",
                gdpr: "Ochrana osobných údajov",
                emailControll: "Váš email nebol overený, pre overenie zadajte kód ktorý ste obdržali emailom po registrácií.",
                controlCode: "Overovací kód",
                controll: "Overiť",
                resendEmail: "Pre opätovné odoslanie verifikačného emailu kliknite sem.",
                resend: "Znovu odoslať",
                forNewPass: "Pre obnovenie hesla zadajte email ktorý",
                youUsed: " ste použili pri registrácií",
                yourMail: "Váš e-mail",
                userName: 'Používateľské meno',
                sendCode: "Odoslať overovací kód",
                codeSms: "Pre overenie zadajte kód, ktorý ste obdržali cez SMS.",
                resendSms: "Pre opätovné odoslanie verifikačnej SMS kliknite sem.",
                reControl: "Znovu odoslať",
                reFallback: "Odoslať kód cez SMS",
                logOut: "Odhlásiť sa",
                myOrders: " Moje dokumenty",
                password: 'Heslo',
                codeAuth: "Zadajte kód z aplikácie autentifikátor"

            },
            OrderStatusFormBox: {
                orderStatus: "Stav dokumentu",
                dateOfOrder: "Dátum doručenia",
                smallCompany: "Živnosť",
                numberOf: "Číslo podania",
                recieveDate: "Dátum podania",
                lawBuilding: "Úrad",
                chooseOffice: "Vyberte živnostenský úrad",
                court: "Súd",
                chooseCourt: "Vyberte súd",
                dateOfUrgent: 'Dátum urgovania',
                dateOfWrite: 'Dátum zapísania',
                saveOrder: "Uložiť objednávku",
                addFiles: "Pridaj súbory",
                addDateOfWrite: "Dátum zápisu",

            },

            PasswordResetFormBox: {
                resetPassword: "Obnovenie hesla",
                authCode: "Overovací kód (z emailu alebo sms)",
                password: "Zadajte nové heslo",
                repeatPassword: "Zopakujte nové heslo",
                passwordArentSame: 'Heslá sa nezhodujú',

            },
            SaveOrderModalBox: {
                saveOrder: "Uložte si rozpracovaný dokument",
                pleaseSave: "Prosíme, zadajte e-mail, na ktorý zašleme odkaz na rozpracovaný dokument.",
                youCanComeBack: "Môžte sa k nemu kedykoľvek vrátit - ľahko a pohodlne.",
                yourMail: "Váš e-mail",
                yourSavedDoc: "Váš rozpracovaný formulár bol uložený v čase",
                updateDocs: "a bude aktualizovaný vždy, keď opustíte okno. Pre okamžité aktualizovanie údajov kliknite nižšie.",
                save: "Uložiť",
            },
            StepperBox: {
                flowOfMeet: "Názvy dokumentov",
                neededToBeFill: "Čo je potrebné vyplniť",
                viewOfDocs: "Náhľad pripravovaných dokumentov",
                firstStep: 'Vypĺňate údaje online',
                secondStep: 'Zaplatíte objednávku',
                thirdStep: 'Email s dokumentami',
                fourthStep: 'Podpíšte online'
            },
            UserProfileBox: {
                welcomeToProgram: "Ďakujeme, že ste s nami!",
                benefits: "Vitajte vo svojom profile",
                subtitle: "Registráciou a vytvorením profilu môžete čerpať výhody nášho Partnerského programu.",
                fastView: "vždy podrobný prehľad o uskutočnených objednávkach",
                getAcces: "ľahké vytvorenie novej objednávky",
                specialOffers: "špeciálne ponuky",
                accept: "Rozumiem",
                logOut: "Odhlásiť sa",
                settings: "Nastavenia profilu",
                showProfileTour: "Zobraziť sprievodcu profilom",
                changesInSro: "Zmluva o predaji motorového vozidla",
                homePage: "Hlavná stránka",
                actions: "Akcie a výhody",
                myOrders: "Moje dokumenty",
                signatureRequests: "Žiadosti o podpis",
                phoneSupport: "Telefonické právne poradenstvo",
                videos: "Videá a návody",
                progress: "Sledujte svoje dokumenty",
                signatureRequests: "Žiadosti o podpis",
                mySettingsTitle: "Upravte svoje nastavenia",
                mySettings: "Moje nastavenia",
                myPersons: "Moje šablóny osôb",
                actionsNow: "Akcie a výhody",
                useActions: "Využite naše špeciálne akcie",
                specialPrice: "ÚČTOVNÁ ZÁVIERKA TERAZ LEN ZA 14,90€",
                weCanHelp: "Pomôžeme Vám, aby ste mali schválenie účtovnej závierky čo najľahšie a boli v kľude, bez pokút",
                OrderDocs: "Stačí, ak si u nás objednáte dokumenty potrebné na schválenie účtovnej závierky  a do 5 minút máte všetko potrebné vo Vašom e-maile.",
                offerEnds: "Ponuka platí do 31. augusta 2023.",
                iWantDocs: "Chcem účtovnú závierku",
                videoBlog: "Videonávody",
                preparing: "Pripravujeme ... ",
                changesIsSro: "Zmena v s.r.o.",
                makeSro: "Založenie s.r.o.",
            },
            UserPersonsBox: {
                title: "Moje osoby",
                menuTitle: "Moje osoby",
            },
            UserProfileSettingsBox: {
                given_name: "Meno",
                given_name_input: "Zadajte meno",
                family_name: "Priezvisko",
                family_name_input: "Zadajte priezvisko",
                email: "Email",
                email_input: "Zadajte email",
                phone_number: "Telefónne číslo",
                phone_number_input: "Zadajte telefónne číslo",
                marketing_suhlas: "Súhlasím so zasielaním marketingových ponúk.",
                unknownUser: "Neznámy používateľ",
                modalTitleGetBenefits: "PO UDELENÍ SÚHLASU ČERPAJTE SVOJE VÝHODY",
                modalTitleGetBenefitsThanks: "Ďakujeme za udelenie súhlasu, ktorý môžete kedykoľvek zrušiť po 30 dňoch.",
                passwordChange: 'Zmena hesla',
                saveSettings: 'Uložiť nastavenia',
                verifyPhoneNumber: 'Overiť telefónne číslo',
                verifyPhoneNumberFirst: 'Najskôr overte telefónne číslo',
            },
            PersonList: {
                createNewPerson: "Vytvoriť šablónu",
                name: "Meno osoby",
                surname: "Priezvisko osoby",
                address: "Adresa",
                enterNameAndSurname: 'Zadajte meno alebo priezvisko',
                noteToPerson: "Poznámka k osobe"
            },
            PersonEditBox: {
                personEditModalTitle: "Osobné údaje fyzickej osoby, ktorú chcem uložiť do šablóny",
                titlesBefore: "Tituly pred menom osoby sú",
                titlesAfter: "Tituly za menom osoby sú",
                name: "Meno osoby je",
                surname: "Priezvisko osoby je",
                idType: "Typ dokladu",
                documentId: "Číslo dokladu",
                dateOfBirth: "Vyberte dátum narodenia",
                documentType: 'Má osoba rodné číslo?',
                idNumber: 'Rodné číslo / identifikátor osoby je',
                uploadFile: 'Nahrajte foto/scan OP alebo pasu a údaje sa automaticky vyplnia. ',
                note: "Poznámka"
            },

            UserRegistrationBox: {
                surname: "Priezvisko",
                telephone: "Telefónne číslo",
                pleaseEnterFullNumber: "Prosím zadajte platné telefónne číslo.",
                iAgree: "Súhlasím so zasielaním marketingových ponúk.",
                registerAsPartner: "Chcem sa zaregistrovať ako partner.",
                registerAsPartner2: "(Partnerom sa môže stať , len advokát, notár a účtovník.)",
                passwordTooWeak: 'veľmi slabé',
                passwordWeak: 'slabé',
                passwordGood: 'dobré',
                passwordStrong: 'silné',
                passwordTooStrong: 'veľmi silné',
                passwordShort: 'krátke',
                stepBack: 'Krok späť',
                cin: "IČO",
                noPartnerWarning: "Vybraný subjekt nie je advokát, notár ani účtovník vyberte iný subjekt."
            },
            UserOrderBox: {
                close: "Zatvoriť",
                open: "Otvoriť",
                download: "Stiahnuť",
                created: "Vytvorené",
                state: "Stav",
                numberInOffice: "Číslo podania úrad",
                dateInOffice: "Dátum podania úrad",
                office: "Úrad",
                addedToOffice: "Podatelňa úrad",
                dateOnCourt: "Dátum podania súd",
                court: "Súd",
                addedToCourt: "Podatelňa súd",
                urgentToOffice: "Urgované úrad",
                urgentToCourt: "Urgované súd",
                writtenToOffice: "Zapísané úrad",
                writtenToCourt: "Zapísané súd",

            },
            OrderListBox: {
                nameOfSro: "Názov obce",
                ico: "Kód obce",
                requester: "Žiadateľ",
                feedBack: 'Hodnotenie',
                identifier: "Identifikátor",
                stateOfOrder: "Stav dokumentu",
                stateOfPay: "Stav platby",
                priceOfOrder: "Celková suma objednávky",
                dateOfCreate: "Dátum vytvorenia",
            },
            UserListBox: {
                username: "Cognito meno",
                userstatus: "Stav užívateľa",
                given_name: "Krstné meno",
                family_name: "Priezvisko",
                email: "Email",
                email_verified: "Email overený",
                phone_number: "Telefónne číslo",
                phone_number_verified: "Telefónne číslo overené",
                custom_marketing_suhlas: "Marketingový súhlas",
                actions: "Operácie",
            },
            UserProcessForm: {
                username: "Cognito meno",
                userstatus: "Stav užívateľa",
                given_name: "Krstné meno",
                family_name: "Priezvisko",
                email: "Email",
                email_verified: "Email overený",
                phone_number: "Telefónne číslo",
                phone_number_verified: "Telefónne číslo overené",
                customer_role: "Typ užívateľa",
                custom_marketing_suhlas: "Marketingový súhlas",
                enterValue: "Zadajte hodnoty",
                enterPassword: "Prosím vypľnte heslo!",
                passwordValidation: "Heslo musí obsahovať aspoň jedno velké a malé písmeno, aspoň jednu číslicu a aspoň 8 znakov",
                passwordDoNotMatch: "Heslá sa nezhodujú!"
            },
            FeedbackListBox: {
                companyName: "Názov obce",
                contactName: "Kontakt meno",
                contactEmail: "Kontakt email",
                ratingValue: "Hodnotenie",
                ratingMessage: "Správa",
                ratingDate: "Dátum hodnotenia",
            },
            ErrorPageBox: {
                cantDo: "Ľutujeme, vybraný úkon nie je možné zrealizovať.",
                chooseAnotherCompany: "Vybrať inú firmu",
            },

            FrontOfficeBox: {
                cleanigDay: "bude prebiehať plánovaná údržba portálu. Ďakujeme za pochopenie | Tím ORSR.Help",
                day: "Dňa",

            },

            BaseInvestmentRaiseBox: {
                newInput: 'Záväzky na nové vklady prevezmú:',
                add: "Pridať",
                ico: 'IČO',
                birthID: 'Rodné číslo',
                idData: "Identifikačný údaj",
                enter: "Zadajte",
                owner: "spoločníka",
                birthIdOrId: 'Rodné číslo alebo Identifikačný údaj',
                icoOrId: 'IČO alebo Identifikačný údaj',
                chooseOwnerOne: 'Vyberte spoločníka',
                coOwner: "Spoločník",
                chooseBirthDate: "Vyberte dátum narodenia",
                chooseOwner: "Má spoločník",
                orData: "alebo iný Identifikačný údaj?",
                localCourt: "Okresný súd",
                birthDate: 'Dátum narodenia',
                enterLocalCourt: "Zadajte okresný súd",
                part: "Oddiel",
                insert: "Vložka",
                enterPart: "Zadajte oddiel",
                enterInsert: "Zadajte vložku",
                remove: "Odobrať",
                enterRepresentMan: "Pridať zastupujúcu osobu",
                enterAmount: 'Záväzok na nový vklad do ZI je vo výške',
                enterAmountEUR: 'Po navýšení bude vklad spoločníka do ZI vo výške €',
                represent: 'Splnomocnenec',
                enterFunc: 'Napíšte jeho funkciu',
                func: "Funkcia",
                enterName: 'Napíšte meno',
                nameTitleSurname: "Titul, meno, priezvisko"
            },
            BaseInvestmentRaiseFromProfitComponentBox: {
                add: "Pridať",
                partsOfOwners: 'Podiely spoločníkov na základnom imaní sa zmenia následovne:',
                chooseOwner: 'Vyberte spoločníka',
                owner: "Spoločník",
                ofOwner: "vlastníka",
                ico: 'IČO',
                birthIdOrId: 'Rodné číslo alebo identifikačný údaj',
                icoOrId: 'IČO alebo identifikačný údaj',
                newAmount: 'Zadajte novú výšku podielu v EUR',
                idData: "Identifikačný údaj",
                birthId: 'rodné číslo',
                enter: "Zadajte",
                chooseIfOwner: "Má spoločník",
                addRepresentMan: "Pridať zastupujúcu osobu",
                orData: "alebo iný identifikačný údaj?",
                dateOfBirth: 'Dátum narodenia',
                enterLocalCourt: "Zadajte okresný súd",
                localCourt: "Okresný súd",
                enterPart: "Zadajte oddiel",
                part: "Oddiel",
                enterInsert: "Zadajte vložku",
                insert: "Vložka",
                remove: "Odobrať",
                representMan: 'Splnomocnenec',
                enterFunc: 'Napíšte jeho funkciu',
                func: "Funkcia",
                enterName: 'Napíšte meno',
                nameTitle: "Titul, meno, priezvisko",
            },
            DepositAdministratorBox: {
                chooseAdmin: 'Kto je správcom vkladov?',
                chooseAdminTooltip: 'Vyberte spoločníka ktorý spravuje vklady spoločníkov do základného imania spoločnosti do zápisu spoločnosti do obchodného registra.',
                chooseOwner: "vyberte spoločníka",
                enteBusinessName: "Zadajte Obchodné meno",
                businessName: 'Obchodné meno',
                businessAdress: "Zadajte sídlo",
                streetPSC: 'ulica č., PSČ, mesto',
                chooseCountry: 'Vyberte krajinu',
                country: "Krajina",
                ico: 'IČO',
                representMan: 'Splnomocnenec',
                enterFunc: 'Napíšte jeho funkciu',
                func: "Funkcia",
                enterName: 'Napíšte meno',
                nameTitle: "Titul, meno, priezvisko",
                enterRepresent: "Pridať splnomocnenca",
            },
            DozornaRadaComponentBox: {
                titlesBefore: "Tituly pred menom člena dozornej rady sú",
                titlesAfter: "Tituly za menom člena dozornej rady sú",
                firstName: "Meno člena dozornej rady je",
                lastName: "Priezvisko člena dozornej rady je",
                birthId: "Má člen dozornej rady rodné číslo?",
                birthIdPlaceholder: "vyberte rodné číslo alebo iný identifikačný údaj",
                birthIdTooltip: "V niektorých krajinách neprideľujú osobám rodné číslo, ale osoby dostávajú iné identifikačné údaje. Je potrebné vybrať niektorú z možností.",
                enterBirthId: "Rodné číslo / identifikátor člena dozornej rady je",
                enterBirthIdPlaceholder: "napíšte rodné číslo alebo iný identifikačný udaj",
                enterBirthIdTooltip: "V niektorých krajinách neprideľujú osobám rodné číslo, ale osoby dostávajú iné identifikačné údaje. Je potrebné ich sem vyplniť.",
                member: "Členom dozornej rady je",
                memberTooltip: "Je potrebné správne napísať celé meno a priezvisko člena dozornej rady, a prípadne aj všetky jeho tituly",
                address: "Bydlisko člena dozornej rady je",
                addressTooltip: 'Je potrebné správne a dostatočne presne špecifikovať adresu bydliska člena dozornej rady',
                sepervisoryMember: "Má člen dozornej rady",
                orIdentity: "alebo iný identifikačný údaj?",
                enterIdentity: "Zadajte identifikačný údaj",
                enterBirth: "Vyberte dátum narodenia",
                birth: 'Dátum narodenia',
                enterFunc: 'Napíšte jeho funkciu',
                func: "Funkcia",
                addMember: "Pridať člena dozornej rady",
                sepervisoryExists: "Výber voliteľných orgánov spoločnosti",
                sepervisoryExistsTooltip: "Dozorná rada obvykle nie je v spoločnostiach s ručením obmedzeným zriaďovaná, ak je zriadená slúži na kontrolu konateľov a účtovníctva."
            },
            KonateliaEstablishmentBox: {
                titlesBefore: "Tituly pred menom konateľa sú",
                titlesAfter: "Tituly za menom konateľa sú",
                firstName: "Meno konateľa je",
                lastName: "Priezvisko konateľa je",
                konatel: "Konateľom je",
                konatelTooltip: "Je potrebné správne napísať celé meno a priezvisko konateľa, a prípadne aj všetky jeho tituly",
                enterAdress: 'Bydlisko konateľa je',
                enterAdressTooltip: 'Je potrebné správne a dostatočne presne špecifikovať adresu bydliska konateľa',
                chooseIf: "Má konateľ rodné číslo?",
                chooseIfPlaceholder: "vyberte rodné číslo alebo iný identifikačný údaj",
                chooseIfTooltip: "V niektorých krajinách neprideľujú osobám rodné číslo, ale osoby dostávajú iné identifikačné údaje. Je potrebné vybrať niektorú z možností.",
                enterBirthID: 'Rodné číslo / identifikátor konateľa je',
                enterBirthIDTooltip: 'V niektorých krajinách neprideľujú osobám rodné číslo, ale osoby dostávajú iné identifikačné údaje. Je potrebné ich sem vyplniť.',
                enterData: "Napíšte identifikačný údaj",
                enterICO: 'Zadajte IČO',
                ICO: 'IČO',
                birthID: 'Rodné číslo',
                identication: "napíšte obchodné meno konateľa",
                chooseBirthDate: "Vyberte dátum narodenia",
                birthDate: 'Dátum narodenia',
                addOwner: "Pridať konateľa",
                removeOwner: "Odobrať konateľa",
            },
            OwnersComponentBox: {
                allPeopleMeet: 'Na valnom zhromaždení sa zúčastnili všetci spoločníci',
                allPeopleVotedForOne: 'Všetci spoločníci hlasovali za schválenie všetkých bodov programu',
                partOwner: "Spoločník",
                present: 'Zúčastnil sa',
                choosePersonType: 'Je to človek?',
                personType: "Typ osoby",
                individual: "Človek",
                legalPerson: "Právnická osoba",
                enterVotes: 'Podiel hlasov v %',
                enterCompanyName: 'Obchodné meno spoločníka',
                enterName: 'Meno a priezvisko spoločníka',
                name: "Napíšte meno",
                enterAdress: 'Sídlo spoločníka',
                enterCompanyAdress: 'Bydlisko spoločníka',
                savedPersonsButton: 'Vybrať z uložených osôb',
                savePerson: "Uložiť osobu",
                personInformation: "Identifikačné údaje osoby",
                searchPerson: "Vyhľadať osobu",
                enterNameAndSurname: "Zadajte meno alebo identifikačný údaj",
                personName: "Meno",
                personSurname: "Priezvisko",
                id: "Identifikačný údaj",
                searchOrsr: "Vyhľadať",
                uploadImageForScanning: "Vyberte obrazok dokumentu"

            },
            BuyerSellerComponentBox: {
                allPeopleMeet: 'Na valnom zhromaždení sa zúčastnili všetci spoločníci',
                allPeopleVotedForOne: 'Všetci spoločníci hlasovali za schválenie všetkých bodov programu',
                partOwner: "Spoločník",
                present: 'Zúčastnil sa',
                choosePersonType: 'Je to človek?',
                personType: "Typ osoby",
                individual: "Človek",
                legalPerson: "SZČO/Právnická osoba",
                enterVotes: 'Podiel hlasov v %',
                enterCompanyName: 'Obchodné meno spoločníka',
                enterName: 'Meno a priezvisko spoločníka',
                name: "Napíšte meno",
                enterAdress: 'Sídlo spoločníka',
                enterCompanyAdress: 'Bydlisko spoločníka',
                savedPersonsButton: 'Vybrať z uložených osôb',
                savePerson: "Uložiť osobu",
                personInformation: "Identifikačné údaje osoby",
                searchPerson: "Vyhľadať osobu",
                enterNameAndSurname: "Zadajte meno alebo identifikačný údaj",
                personName: "Meno",
                personSurname: "Priezvisko",
                id: "Identifikačný údaj",
                searchOrsr: "Vyhľadať v ŽRSR alebo ORSR",
                uploadImageForScanning: "Vyberte obrazok dokumentu"

            },
            OwnersEstablishmentBox: {
                titlesBefore: "Tituly pred menom osoby sú",
                titlesAfter: "Tituly za menom osoby sú",
                firstName: "Meno osoby je",
                lastName: "Priezvisko osoby je",
                shareCapital: 'Aká bude hodnota základného imania v €',
                choosePersonType: 'Je to človek?',
                owner: "Názov",
                personType: "Typ osoby",
                individual: "Človek",
                legalPerson: "Právnická osoba",
                depositeEuro: 'Zadajte vklad v €',
                paidDepositeEuro: 'Zadajte splatený vklad v €',
                shareCompanyPart: 'Zadajte podiel na spoločnosti v %',
                shareProfit: 'Zadajte podiel na zisku v %',
                enterBusinessName: 'Ak je spoločnosť alebo osoba cudzokrajná, zadajte meno sem',
                enterTitleNameTooltip: 'Je potrebné správne napísať celé meno a priezvisko spoločníka, a prípadne aj všetky jeho tituly',
                enterTitleName: 'Napíšte jeho/jej titul, meno, priezvisko',
                businessName: 'Názov',
                titleName: 'Titul, meno a priezvisko',
                partnerForeginer: "Je spoločník zahraničnou osobou?",
                depositManager: "Je tento spoločník správcom vkladov?",
                partnerExecutive: "Je tento spoločník zároveň aj konateľom?",
                enterHomeLocation: 'Sídlo spoločníka je',
                enterHomeLocationTooltip: 'Je potrebné správne a dostatočne presne špecifikovať aktuálnu adresu sídla spoločníka',
                enterCompanyAdress: 'Bydlisko spoločníka je',
                enterCompanyAdressTooltip: 'Je potrebné správne a dostatočne presne špecifikovať adresu bydliska spoločníka',
                ownerHaveBirthId: "Má osoba rodné číslo?",
                ownerHaveOtherId: "Má osoba IČO?",
                ICO: 'vyberte IČO',
                ICOTooltip: 'V niektorých krajinách neprideľujú právnickým osobám IČO, ale osoby dostávajú iné identifikačné údaje. Je potrebné vybrať niektorú z možností.',
                birthID: 'vyberte rodné číslo',
                birthIDTooltip: 'V niektorých krajinách neprideľujú osobám rodné číslo, ale osoby dostávajú iné identifikačné údaje. Je potrebné vybrať niektorú z možností.',
                orID: "alebo iný identifikačný údaj?",
                dataID: 'Identifikačný údaj',
                enterICO: 'IČO / identifikátor spoločníka je',
                enterICOTooltip: 'V niektorých krajinách neprideľujú právnickým osobám IČO, ale osoby dostávajú iné identifikačné údaje. Je potrebné ich sem vyplniť.',
                enterICOPlaceholder: 'napíšte IČO alebo iný identifikačný udaj',
                enterBirthID: 'Rodné číslo / identifikátor osoby je',
                enterBirthIDTooltip: 'V niektorých krajinách neprideľujú osobám rodné číslo, ale osoby dostávajú iné identifikačné údaje. Je potrebné ich sem vyplniť.',
                enterBirthIDPlaceholder: 'napíšte rodné číslo alebo iný identifikačný udaj',
                enterID: 'Zadajte identifikačný údaj',
                chooseBirthDate: "Vyberte dátum narodenia",
                birthDate: 'Dátum narodenia',
                representMan: 'V mene spoločníka bude podpisovať:',
                enterFunc: 'Z akej pozície?',
                func: "napr. konateľ"
            },
            EstablishmentSinglePerson: {
                titlesBefore: "Tituly pred menom spoločníka sú",
                titlesAfter: "Tituly za menom spoločníka sú",
                firstName: "Meno spoločníka je",
                lastName: "Priezvisko spoločníka je",
                shareCapital: 'Aká bude hodnota základného imania v €',
                choosePersonType: 'Je to človek?',
                personType: "Typ osoby",
                individual: "Človek",
                legalPerson: "Právnická osoba",
                enterBusinessName: 'Spoločníkom je',
                enterBusinessNameTooltip: 'Je potrebné správne napísať celé aktuálne obchodné spoločníka',
                enterTitleName: 'Napíšte jeho/jej titul, meno, priezvisko',
                businessName: 'Obchodné meno',
                titleName: 'Titul, meno a priezvisko',
                partnerForeginer: "Je spoločník zahraničnou osobou?",
                depositManager: "Je tento spoločník zároveň aj správcom vkladov?",
                partnerExecutive: "Je tento spoločník zároveň aj konateľom?",
                enterHomeLocation: 'Bydlisko spoločníka je',
                enterCompanyAdress: 'Sídlo spoločníka je',
                enterCompanyAdressTooltip: 'Je potrebné správne a dostatočne presne špecifikovať aktuálnu adresu sídla spoločníka',
                enterHomeLocationTooltip: 'Je potrebné správne a dostatočne presne špecifikovať adresu bydliska spoločníka',
                ownerHaveBirthId: "Má spoločník rodné číslo?",
                ownerHaveOtherId: "Má spoločník IČO?",
                ICO: 'vyberte IČO',
                ICOTooltip: 'V niektorých krajinách neprideľujú právnickým osobám IČO, ale osoby dostávajú iné identifikačné údaje. Je potrebné vybrať niektorú z možností.',
                birthID: 'vyberte rodné číslo',
                birthIDTooltip: 'V niektorých krajinách neprideľujú osobám rodné číslo, ale osoby dostávajú iné identifikačné údaje. Je potrebné vybrať niektorú z možností.',
                orID: "alebo iný identifikačný údaj?",
                dataID: 'Identifikačný údaj',
                enterICO: 'IČO / identifikátor spoločníka je',
                enterICOPlaceholder: 'napíšte IČO alebo iný identifikačný udaj',
                enterICOTooltip: 'V niektorých krajinách neprideľujú právnickým osobám IČO, ale osoby dostávajú iné identifikačné údaje. Je potrebné ich sem vyplniť.',
                enterBirthID: 'Rodné číslo / identifikátor spoločníka je',
                enterBirthIDPlaceholder: 'napíšte rodné číslo alebo iný identifikačný udaj',
                enterBirthIDTooltip: 'V niektorých krajinách neprideľujú osobám rodné číslo, ale osoby dostávajú iné identifikačné údaje. Je potrebné ich sem vyplniť.',
                enterID: 'Zadajte identifikačný údaj',
                chooseBirthDate: "Vyberte dátum narodenia",
                birthDate: 'Dátum narodenia',
                representMan: 'V mene spoločníka bude podpisovať: ',
                enterFunc: 'Z akej pozície?',
                func: "napr. konateľ",
                addRepresent: "Pridať zastupujúcu osobu",
                owner: "Spoločníkom je",
                enterTitleNameTooltip: 'Je potrebné správne napísať celé meno a priezvisko spoločníka, a prípadne aj všetky jeho tituly',
            },
            SingleOwnerBox: {
                chooseOneType: 'Je jediný spoločník človek?',
                personType: "Typ osoby",
                individual: "Človek",
                legalPerson: "Právnická osoba",
                enterOneOwnerCompanyName: 'Zadajte obchodné meno jediného spoločníka',
                enterOwnerName: 'Zadajte meno jediného spoločníka',
                name: "Meno",
                enterOwnerLocation: 'Zadajte adresu sídlo jediného spoločníka',
                enterOwnerAdress: 'Zadajte adresu bydliska jediného spoločníka',
                ifOwnerHave: "Má spoločník",
                ICO: 'IČO',
                birthID: 'rodné číslo',
                orID: "alebo iný identifikačný údaj?",
                dataID: 'Identifikačný údaj',
                enterICO: 'Zadajte IČO',
                enterBirthID: 'Zadajte rodné číslo',
                enterID: 'Zadajte identifikačný údaj',
                chooseBirthDate: "Vyberte dátum narodenia",
                birthDate: 'Dátum narodenia',
                representMan: 'Splnomocnenec',
                enterFunc: 'Zadajte jeho funkciu',
                func: "Funkcia",
                addRepresent: "Pridať splnomocnenca",
                enter: "Zadajte",
                partner: "spoločníka",
                enterPartnerBirthDate: "Zadajte dátum narodenia spoločníka",
                enterTitleName: 'Zadajte titul, meno a priezvisko',
                titleName: 'Titul, meno a priezvisko',
            },
            RetributionBox: {
                withPayback: "Za kúpnu cenu",
                withoutPayback: "Bezodplatne",
                enterPaybackValue: "Výška kúpnej ceny je",
                paybackValue: "Výška kúpnej ceny",
                chooseExp: "Splatnosť kúpnej ceny je",
                exp: "Splatnosť",
                untilDate: "Do dátumu",
                withSign: "Pri podpise tejto zmluvy",
                chooseExpDate: "Vyberte dátum splatnosti",
                expDate: "Dátum splatnosti",
                choosePayment: "Vyberte spôsob platby",
                cash: "V hotovosti",
                transfer: "Prevodom na účet",
                didAccept: "Prevzal prevodca dojednanú odplatu?",
                iban: "IBAN"
            },
            PlnomocenstvoHeader: {
                enterFullOwner: "Zadajte splnomocňujúceho konateľa alebo prokuristu",
            },
            PresenceComponent: {
                personsHere: 'Prítomné osoby',
                personType: "Typ osoby"
            },
            PresenceKonatel: {
                chooseOwner: "Vyberte konatela",
                enterFunc: 'Zadajte jeho funkciu',
                func: "Funkcia",
                enterName: 'Zadajte meno',
                titleName: "titul, meno a priezvisko",
                enterAdress: 'Zadajte adresu',
                streetCity: "ulica č., PSČ, mesto",
                representMan: 'Zastupujúca osoba ',
            },
            PresenceOtherPerson: {
                enterFunc: 'Zadajte jeho funkciu',
                func: "Funkcia",
                enterName: 'Zadajte meno',
                titleName: "titul, meno a priezvisko",
                enterAdress: 'Zadajte adresu ',
                streetCity: "ulica č., PSČ, mesto",
                representMan: 'Zastupujúca osoba ',
            },
            PresenceProkurista: {
                chooseProxy: "Vyberte prokuristu",
                proxy: "Prokurista",
                enterFunc: 'Zadajte funkciu',
                func: "Funkcia",
                enterName: 'Zadajte meno',
                titleName: "titul, meno a priezvisko",
                enterAdress: 'Zadajte adresu',
                streetCity: "ulica č., PSČ, mesto",
                isHeRpresent: "Zastupuje ho niekto?",
                enterRepresent: "Pridať splnomocnenca",
                representMan: 'Splnomocnenec',
                enterCountry: 'Vyberte krajinu',
                country: "Krajina",
            },
            PresentOwnerPerson: {
                coOwner: 'Spoločník',
                enterFunc: 'Zadajte funkciu',
                func: "Funkcia",
                enterName: 'Zadajte meno',
                titleName: "titul, meno a priezvisko",
                enterAdress: 'Zadajte adresu',
                streetCity: "ulica č., PSČ, mesto",
                isHeRpresent: "Zastupovaný inou osobou/osobami?",
                enterRepresent: "Pridať zastupujúcu osobu",
                representMan: 'Zastupujúca osoba ',
            },
            LossComponent: {
                enterLoss: "Zadajte stratu",
                useToPay: "Použiť na úhradu straty zisk z predchádzajúcich rokov?",
                feeToPay: "Použiť na úhradu straty príspevok spoločníkov?",
                year: "Rok",
                enterValue: "Zadajte hodnotu",
                value: "Hodnota",
                coOwnerPay: "Príspevok spoločníka",
            },
            ProfitComponent: {
                enterProfit: "Zadajte zisk",
                profit: "Zisk",
                enterReserve: "Zadajte príspevok do rezervného fondu",
                resrveFoundation: "Príspevok do rezervného fondu",
                enterSocialReserve: "Zadajte príspevok do sociálneho fondu",
                socialFoundation: "Príspevok do sociálneho fondu",
                enterBaseReserve: "Zadajte zisk určený na zvýšenie základného imania",
                baseFoundation: "Zisk určený na zvýšenie základného imania",
                enterProfitToSplit: "Zadajte zisk určený na rozdelenie medzi spoločníkov",
                profitToSplit: "Rozdelenie zisku medzi spoločníkov",
                enterName: "Zadajte názov",
                name: "Názov",
                enterValue: "Zadajte hodnotu",
                value: "Hodnota",
            },
            PropertyOwner: {
                titlesBefore: "Tituly pred menom vlastníka sú",
                titlesAfter: "Tituly za menom vlastníka sú",
                firstName: "Meno vlastníka je",
                lastName: "Priezvisko vlastníka je",
                enterTypeOfOwner: 'Je vlastník človek?',
                enterICO: 'IČO / identifikátor vlastníka je',
                enterICOPlaceholder: 'napíšte IČO alebo iný identifikačný udaj',
                enterBirthID: 'Rodné číslo / identifikátor vlastníka je',
                enterBirthIDPlaceholder: 'napíšte rodné číslo alebo iný identifikačný udaj',
                typeOfOwner: "Typ osoby",
                individual: "Človek",
                legalPerson: "Právnická osoba",
                enterOwner: 'Vlastníkom je',
                enterOwnerTooltip: 'Je potrebné správne napísať celé meno a priezvisko vlastníka, a prípadne aj všetky jeho tituly',
                enterOwnerName: 'Vlastníkom je',
                enterOwnerNameTooltip: 'Je potrebné správne napísať celé aktuálne obchodné meno vlastníka',
                titleName: 'Titul, meno a priezvisko',
                businessName: 'Obchodné meno',
                streetCity: 'ulica č., PSČ, mesto',
                enterOwnerLocation: 'Bydlisko vlastníka je',
                enterAdress: 'Sídlo vlastníka je',
                enterOwnerBirth: "Dátum narodenia vlastníka",
                dateOfBirth: 'Dátum narodenia',
                chooseIfOwner: "Má vlastník",
                ico: 'IČO',
                hasIco: 'Má vlastník IČO?',
                hasIcoTooltip: 'V niektorých krajinách neprideľujú právnickým osobám IČO, ale osoby dostávajú iné identifikačné údaje. Je potrebné vybrať niektorú z možností.',
                IcoTooltip: "V niektorých krajinách neprideľujú právnickým osobám IČO, ale osoby dostávajú iné identifikačné údaje. Je potrebné ich sem vyplniť.",
                BirthIDTooltip: "V niektorých krajinách neprideľujú osobám rodné číslo, ale osoby dostávajú iné identifikačné údaje. Je potrebné ich sem vyplniť.",
                birthID: 'rodné číslo?',
                hasBirthID: 'Má vlastník rodné číslo',
                hasBirthIDTooltip: 'V niektorých krajinách neprideľujú osobám rodné číslo, ale osoby dostávajú iné identifikačné údaje. Je potrebné vybrať niektorú z možností.',
                orID: "alebo identifikačný údaj",
                id: 'Identifikačný údaj',
                enter: "Zadajte",
                owner: "vlastníka",
                birtIdOrId: 'Rodné číslo alebo identifikačný údaj',
                icoOrId: 'IČO alebo identifikačný údaj',
                enterRegistrationDisctrict: "Zadajte Okresný súd registrácie",
                registrationDistrict: "Okresný súd",
                enterPart: "Zadajte oddiel",
                part: "Oddiel",
                enterInsert: "Zadajte vložku",
                insert: "Vložka",
                addRepresentMan: "Pridať zastupujúcu osobu",
                representMan: 'V mene vlastníka bude podpisovať: ',
                enterFunc: "Z akej pozície?",
                func: "napr. konateľ",
                enterRepresentMan: "Napíšte jeho/jej titul, meno, priezvisko",
                tooltipPerson: "Je potrebné správne a dostatočne presne špecifikovať adresu bydliska vlastníka",
                tooltipCompany: "Je potrebné správne a dostatočne presne špecifikovať aktuálnu adresu sídla vlastníka"
            },
            PropertyType: {
                enterTypeOfProperty: 'Nehnuteľnosť v ktorej bude sídlo je',
                enterTypeOfPropertyPlaceholder: 'vyberte druh nehnuteľnosti',
                enterTypeOfPropertyTooltip: 'Ak nie je bytom alebo nebytovým priestorom, tak ide o stavbu, ktorá má súpisné číslo.',
                typeOfProperty: "Druh nehnuteľnosti",
                building: "Stavba",
                flat: "Byt",
                noResidentPlace: "Nebytový priestor",
                flatNumber: 'Zadajte číslo bytu',
                noResidentNumber: 'Zadajte číslo nebytového priestoru',
                enterEntrance: "Zadajte vchod",
                entrance: "Vchod",
                enterFloor: "Zadajte poschodie",
                floor: "Poschodie",
                enterInventoryNumber: "Zadajte súpisné číslo",
                inventoryNumber: "Súpisné číslo",
                enterDeedNumber: "Zadajte číslo listu vlastníctva",
                deedNumber: "Číslo listu vlastníctva",
                enterSizeOfShare: "Zadajte veľkosť podielu na spoločných priestoroch",
                sizeOfShare: "Veľkosť podielu na spoločných priestoroch",
                enterDistrictOffice: "Zadajte okresný úrad",
                districtOffice: "Okresný úrad",
                enterCadastralTerritory: "Zadajte katastrálne územie",
                cadastralTerritory: "Katastrálne územie",
                enterMunicipality: "Zadajte obec",
                municipality: "Obec",
                enterDistrict: "Zadajte okres",
                district: "Okres",
                chooseParcelType: "Vyberte druh registra parcely",
                parcelRegister: "Parcela registra",
                enterParcelNumber: "Zadajte parcelné číslo",
                parcelNumber: "Parcelné číslo",
                enterTypeOfLand: 'Zadajte druh pozemku',
                enterTypeOfBuilding: 'Zadajte druh stavby',
                typeOfLand: 'Druh pozemku',
                typeOfBuilding: 'Druh stavby',
                addParcel: "Pridať parcelu",
            },
            NewOwnerComponent: {
                willOwnerHaveThisPart: 'Ponechá si spoločník túto časť?',
                noResult: 'Žiadny výsledok',
                specifySearchingName: 'Ak ste nenašli Váš subjekt, upresnite názov alebo zadajte IČO',
                part: "Časť",
                enterShareEur: "Zadajte podiel v €",
                shareInEur: "najmenej 750 €",
                enterSharePercents: "Zadajte podiel v %",
                shareInPercents: "Podiel v %",
                chooseTypeOfPerson: 'Je kupujúci človek?',
                typeOfPerson: "Typ osoby",
                searchORSR: "Vyhľadáme ho v databáze Obchodného registra SR?",
                searchWithNameOrICO: 'napíšte IČO alebo obchodné meno',
                enterName: 'Napíšte titul, meno, priezvisko kupujúceho',
                enterBusinessName: 'Napíšte obchodné meno kupujúceho',
                titleName: "napíšte celé meno",
                addTitlesBeforeName: "Zadajte tituly pred menom",
                titlesBeforeName: "Tituly pred menom",
                addName: "Zadajte meno",
                addYourSurname: "Zadajte priezvisko",
                surname: "Priezvisko",
                addTitlesAfterName: "Zadajte tituly za menom",
                titlesAfterName: "Tituly za menom",
                enterHomeLocation: 'Napíšte sídlo kupujúceho',
                enterAdress: 'Napíšte adresu kupujúceho',
                chooseDateOfBirth: "Dátum narodenia kupujúceho",
                dateOfBirth: 'napíšte dátum narodenia',
                chooseIfNewOwner: "Má kupujúci",
                ico: 'IČO',
                birthID: 'rodné číslo',
                orID: "alebo má iný identifikačný údaj?",
                dataID: "Identifikačný údaj",
                enter: "Zadajte",
                newOwner: "kupujúceho",
                orDataId: "alebo identifikačný údaj",
            },
            OwnerComponent: {
                guideOp: 'Kto predáva obchodný podiel?',
                chooseDateOfBirth: "Dátum narodenia predávajúceho",
                dateOfBirth: 'napíšte dátum narodenia',
                chooseConverterHave: "Má predávajúci",
                ico: 'IČO',
                birthID: 'rodné číslo',
                orID: "alebo má iný identifikačný údaj?",
                dataID: "Identifikačný údaj",
                enter: "Rodné číslo / ",
                converter: "predávajúceho je",
                enterPart: "Výška vkladu predávajúceho v € je",
                part: "napíšte sumu",
                enterSharePercents: "Obchodný podiel predávajúceho je v %",
                shareInPercents: "Podiel v %",
                enterPartsToCut: "Na koľko častí chcete podiel rozdeliť?",
                partsToCut: "Napíšte číslo",
            },
            ShareDivision: {
                capital: 'Základné imanie v €',
                addRepresent: "Pridať ďalšieho podpisujúceho?",
                representMan: 'V mene kupujúceho bude podpisovať:',
                enterFunc: 'Z akej pozície?',
                func: "napr. konateľ",
                enterName: 'Napíšte jeho/jej titul, meno, priezvisko',
                titleName: "titul, meno, priezvisko",
                representManSeller: 'V mene predávajúceho bude podpisovať:'
            },
            ShareDivisionSinglePerson: {
                capital: 'Základné imanie v €',
                addRepresent: "Pridať ďalšieho podpisujúceho?",
                representMan: 'V mene kupujúceho bude podpisovať:',
                enterFunc: 'Z akej pozície?',
                func: "napr. konateľ",
                enterName: 'Napíšte jeho/jej titul, meno, priezvisko',
                titleName: "titul, meno, priezvisko",
                representManSeller: 'V mene predávajúceho bude podpisovať:'
            },
            SecondNewOwnerComponent: {
                enterNewOwner: "Kto kupuje obchodný podiel?",
                chooseTypeOfPerson: 'Je to človek?',
                typeOfPerson: "Typ osoby",
                individual: "Človek",
                legalPerson: "Právnická osoba",
                searchORSR: "Vyhľadáme ho v databáze Obchodného registra SR?",
                searchWithNameOrICO: 'napíšte IČO alebo obchodné meno',
                enterName: 'Napíšte titul, meno, priezvisko kupujúceho',
                enterBusinessName: 'Napíšte obchodné meno kupujúceho',
                titleName: "napíšte celé meno",
                enterHomeLocation: 'Napíšte sídlo kupujúceho',
                enterAdress: 'Napíšte adresu kupujúceho',
                name: "Meno",
                chooseIfNewOwner: "Má kupujúci",
                enter: "Zadajte",
                newOwner: "kupujúceho",
                ico: 'IČO',
                birthID: 'rodné číslo',
                orID: "alebo má iný identifikačný údaj",
                dataID: "Identifikačný údaj",
            },
            SecondOwnerComponent: {
                chooseConverter: 'Kto predáva obchodný podiel?',
                converter: "napíšte titul, meno, priezvisko predávajúceho",
                chooseDateOfBirth: "Dátum narodenia predávajúceho",
                dateOfBirth: 'napíšte dátum narodenia',
                chooseConverterHave: "Má predávajúci",
                ico: 'IČO',
                birthId: 'rodné číslo',
                orId: "alebo má iný identifikačný údaj?",
                enter: "Napíšte",
                dataId: "identifikačný údaj",
                converter: "predávajúceho",
                enterPart: "Výška vkladu predávajúceho v € je",
                part: "napíšte sumu",
                enterPartInPercent: "Obchodný podiel predávajúceho je v %",
                partInPercent: "Podiel v %",
            },
            ShareTransfer: {
                capital: 'Základné imanie v €',
                addRepresentMan: "Pridať ďalšieho podpisujúceho?",
                representMan: 'V mene kupujúceho bude podpisovať:',
                addFunc: 'Z akej pozície?',
                func: "napr. konateľ",
                addTitleName: 'Napíšte jeho/jej titul, meno, priezvisko',
                titleName: "Titul, meno, priezvisko",
                representManSeller: 'V mene predávajúceho bude podpisovať:',
            },
            ShareTransferSinglePerson: {
                capital: 'Základné imanie v €',
                addRepresentMan: "Pridať ďalšieho podpisujúceho?",
                representMan: 'V mene kupujúceho bude podpisovať:',
                addFunc: 'Z akej pozície?',
                func: "napr. konateľ",
                addTitleName: 'Napíšte jeho/jej titul, meno, priezvisko',
                titleName: "Titul, meno, priezvisko",
                representManSeller: 'V mene predávajúceho bude podpisovať:',
            },
            TransferAquirerStatement: {
                chooseSignatureDate: "Vyberte dátum podpisu",
                dateOfSignature: 'napíšte dátum',
                addPlaceOfSignature: "Kde sa podpisovalo",
                placeOfSignature: "Napíšte miesto",
            },
            TransferSellerStatement: {
                chooseSignatureDate: "Vyberte dátum podpisu",
                dateOfSignature: 'Napíšte dátum',
                addPlaceOfSignature: "Kde sa podpisovalo",
                placeOfSignature: "Napíšte miesto",
            },
            SignatureCanvasArtifact: {
                createSignature: "Vytvorte podpis",
                create: "Vytvoriť",
                loadFile: "alebo nahrajte súbor",
                signature: "Podpis",
                missingSignatures: 'Chýbajú podpisy prstom následujúcich osôb, po podpísaní KEP-om už nebude možné ich pridať.',
                save: "Použiť",
                clear: "Zmeniť",
                close: 'Zatvoriť',
                orderDetail: 'Detail objednávky'
            },
            DocumentsDownload: {
                makeAndChangeSro: "Zakladanie a zmeny v s.r.o.",
                enterOrderPassword: "Zadajte heslo objednávky",
                weAreCookingYourDocuments: "Usilovne pracujeme na vytvorení Vašich dokumentov.",
                itDoesntTakeMoreThan: "Nepotrvá to viac ako 5 minút.",
                registerToProgram: "Zaregistrujte sa do nášho ",
                partnersProgram: "Partnerského programu",
                allNews: "a vždy budete mať prehľad o svojich objednávkach aj špeciálnych ponukách.",
                itsDone: "A je to hotové!",
                docsAreReady: "Vaše dokumenty sú pripravené,",
                justDownload: "stačí ich len stiahnuť.",
                linkInEmail: "V e-maili Vás už čaká správa, kde nájdete link a heslo, vďaka čomu máte prístup k svojim dokumentom vždy, keď ich potrebujete.",
                thanks: "Ďakujeme, že ste s nami.",
                downloadAll: "Stiahnuť všetky dokumenty",
                kepSigning: "Elektronicky podpísať dokumenty",
                operator: "Portál prevádzkuje",
                vop: "Všeobecné obchodné podmienky",
                gdpr: "Ochrana osobných údajov",
            },
            DocumentOverview: {
                documentOverview: "Náhľad dokumentu",
                cancel: 'Zavrieť',
                preview: 'Náhľad'
            },
            ProcessDocuments: {
                uploadImageForScanning: "Nahrať/Odfotiť doklad",
                uploadImageForScanningTooltip: "Nahrajte foto/scan OP alebo pasu a údaje sa automaticky vyplnia."
            },
            ProcessDocumentsSeparatedName: {
                uploadImageForScanning: "Nahrať/Odfotiť doklad",
                uploadImageForScanningTooltip: "Nahrajte foto/scan OP alebo pasu a údaje sa automaticky vyplnia.",
                signIn: "Pre využitie tejto funkcionality sa prosím prihláste."
            }
        },
        screens: {
            ValidatorModal: {
                title: "Našli sme miesta kde niečo nesedí, chcete napriek tomu pokračovať?",
                step: "Tu niečo nesedí"
            },
            InviteModalBox: {
                enterAdresses: "Zadajte emailovú adresu osoby, ktorá má dokument podpísať",
                enterValidMail: 'Prosím zadajte platný email.',
                sendRequest: "Odoslať žiadosť o podpis",
                sendEmail: "Pridať email",
            },
            UserOrderList: {
                requestWasSent: "Žiadosť o podpis bol poslaný na emailovu adresu: ",
                requestFrom: "Žiadosť o podpis od: ",
                cancelRequestTooltip: "Zrušiť žiadosť o podpis",
                chooseDocument: "Požiadať o podpis inú osobu",
                download: "Stiahnuť",
                whoSign: "Dokument má podpísať:",
                otherDocuments: "Ostatná dokumentácia:",
                sign: "Podpísať elektronicky KEP-om",
                createSign: "Podpísať prstom",
                deleteSign: "Vymazať podpis prstom",
                signsRequestedFrom: "Podpisy vyžiadané od:",
                signedBy: "Dokument podpísal KEP-om:",
                signedByFinger: "Dokument podpísal prstom:",
                sendFeedback: "Odoslať hodnotenie",
                yourFeedback: "Vaše hodnotenie:",
                signingTitle: "Podpísanie"
            },
            DitecModalBox: {
                loadingWait: "Súbory sa nahrávajú, prosím čakajte",
                didntRecognise: "Nebolo rozoznané rozšírenie D.Bridge 2, nainštalujte ho kliknutím na tlačidlo a znovu načítajte stránku.",
                didntInstalledWell: "Nebolo rozoznaná aplikácia D.Launcher 2, nainštalujte ju kliknutím na tlačidlo. Po inštalácií reštartujte počítač.",
                restartPage: "Vyskytla sa neznáma chyba, obnovte stránku a skúste to znova",
                signInProgress: "Prebieha podpisovanie",
                errorInSign: "Vyskytla sa chyba",
                continueInApp: "Pokračujte v aplikácií dSigner",

            },
            UserSignatureRequestListBox: {
                close: "Zatvoriť",
                open: "Detail žiadosti",
                didntFill: 'Nevyplnené',
                sign: "Podpísať KEP-om",
                step: "Tu niečo nesedí, preverte si to",
                signingTitle: "Podpísanie"
            }
        },
        enums: {
            partnerType: {
                PARTNER_ACCOUNTANT: "Účtovník",
                PARTNER_LAWYER: "Advokát",
                PARTNER_NOTARY: "Notár"
            }
        }
    },
    en: {
        artifacts: {
            AdressAutocompleteInputsBox: {
                adress: "street and number, post code, city",
            },
            AddressAutoCompleteSeperatedInputBox: {
                street: "Street",
                streetSmall: "- street",
                streetSmallSecond: "Street",
                adressNumber: "Number",
                city: "City",
                poscode: "Postal code",
                chooseCountry: "Select a country",
                country: "Landscape",
            },
            BaseInvestmentBox: {
                baseFinance: "Share capital"
            },
            BusinessTransferBox: {
                companyPart: "part of the company",
                fullCompany: "the whole company",
                enterPartCompanyName: "Enter the name of the part of the business",
                partCompanyName: "Name of the part of the company",
            },
            CompanyAvailabilityInputBox: {
                forSure: "Your chosen business name is not yet used by any other company. To increase your legal certainty, we have currently checked this on",
                orsrsk: "orsr.sk website",
                unavailable: "The company name you chose is already being used by someone. Choose another",
                available: 'Dostupná'
            },
            ContractModalBox: {
                title: "Service agreement",
                parties: "The contracting parties",
                lawyersOffice: "law firm Matejka Friedmannová Ltd.",
                withCompanyAdress: " with registered office at Dunajská ul. 48, 811 08 Bratislava, ID No. 47 248 998, VAT ID No. SK 2023808765, a commercial company registered in the Commercial Register kept by the District Court Bratislava I, Section Sro, Insert No. 90113/B, on behalf of which acts JUDr. Ondrej Matejka, Executive Director authorized to act independently (hereinafter referred to as ",
                and: "and",
                lawyer: "Lawyer",
                orAnd: "“ and/or „",
                operator: "Operator",
                withAdress: ",with its registered office at adress: ",
                companyWrittenIn: ", commercial company registered in the commercial register kept by the district court: ",
                part: ", Section: ",
                insert: ", insert number : ",
                order: ", on whose behalf the person making the Order Completion is authorised to act independently and without any limitation (hereinafter referred to as „",
                client: "Client",
                user: "User",
                canActAlone: "on whose behalf the person making the Order Completion is authorised to act independently and without any limitation (hereinafter referred to as ",
                articleOne: "Article I",
                baseAgreement: "basic contractual arrangements",
                firstRule: "The Advocate agrees to provide the Application Services to the Client on the terms and conditions agreed in this Service Agreement and subject to the terms and conditions set out in the GTC",
                definatedDown: "term defined below in the text",
                secondRule: "The Client undertakes to specify the need for the provision of legal assistance to the Advocate solely by entering the User's requirements into the Application, and only the Completion of an Order shall be deemed to be a valid entry of the User's requirements for the provision of the Application Services",
                thirdRule: "Attorney shall commence providing the Application Services to Client at the time immediately following the Order Completion",
                fourthRule: "Attorney shall be responsible for the Legal Portion of the Documents created by Attorney during the term of this Services Agreement",
                fifthRule: "The Client undertakes to familiarize himself in detail with all available instructions of the Advocate in the Application, with the GTC ,",
                fifthRuleTwo: ", with the cookie policy, and with the personal data processing policy. ",
                sixsthRule: "The Advocate shall hand over the Application Services provided to the Client once the processing of the Client's entire request has been comprehensively completed,",
                seventhRule: "The Advocate shall only transfer the Application Services provided to the Client by way of delivery of the Generated Documents to the User",
                eightRule: "The Advocate shall make delivery of the Generated Documents to the User upon payment of the Price, generally within five minutes after payment of the Price; failure to comply with this time limit may be caused, in particular, by instability of the User's Internet connection and/or any other technical insufficiency of the User's Internet connection and/or any technical insufficiency of the User's Device and/or the Application",
                ninethRule: "The Client is entitled to complain about the Application Services provided by the Advocate in accordance with the relevant provisions of the Commercial Code.",
                articleTwo: "Article II ",
                lawyersPrize: "Advocate's fees and payment terms,",
                tenthRule: "The Client agrees to pay the Advocate the Price for the provision of the Application Services.",
                eleventhRule: "The price is negotiated as a flat fee and is governed by Decree of the Ministry of Justice of the Slovak Republic No. 655/2004 Coll. on the fees and compensation of attorneys for the provision of legal services and this Contract for the provision of services",
                twelveRule: "The Advocate shall invoice the Price for the Application Services provided whenever the processing of the Client's entire request has been comprehensively completed",
                thirtteenthRule: "The conditions for issuing an invoice, sending an invoice and delivering an invoice are contained in the GTC",
                articleThree: "Article III",
                termsAndConditions: "Relationship between the Service Contract and the General Terms and Conditions",
                fourthteenthRule: "  The Advocate has established General Terms and Conditions for the provision of",
                fourthteenthRuleSecond: "legal services using the Application and posted on the Website by the Attorney prior to the execution of this Service Agreement, as the GTC are in effect on the date of this Service Agreement (hereinafter referred to as the ",
                VOP: "GTC",
                fifthteenthRule: "The GTC are an integral part of this Service Contract",
                sixteenthRule: "In the event of a conflict/collision/inconsistency between the text of this Service Contract and the text of the GTC, the provisions contained in this Service Contract shall prevail.",
                articleFour: "Article IV",
                commonProvisions: "Common provisions",
                seventeenthRule: "This Service Contract is entered into electronically between the Parties using the Application",
                eighteenthRule: "The completion of the Order Completion shall be the moment when this Service Agreement is concluded between the Operator and the User",
                nineteenthRule: "This Service Contract shall come into force at the time of its conclusion",
                twentiethRule: "Unless otherwise specified, this Service Contract shall be legally effective upon its execution.",
                twentyFirstRule: "The Operator's obligation to deliver the Generated Documents to the User shall only arise upon payment of the Price. The User acknowledges and expressly agrees that the Operator shall not be obliged to deliver the Generated Documents to the User before the User has paid the Price to the Operator",
                twentySecondRule: "This Service Contract is concluded for an indefinite period of time",
                twentyThirdRule: "This Service Contract shall be valid and binding on the successors in title of the parties.",
                twentyFourRule: "When providing legal assistance and legal services, an advocate follows the applicable legal regulations governing the practice of advocacy and the binding regulations and guidelines of the Slovak Bar Association.",
                twentyFiveRule: "Client consents to Attorney's use of publicly available information about Client for purposes of Attorney's presentation, in particular, Client consents to Attorney's use of Client's trade name and trademark for purposes of Attorney's references",
                articleFive: "Article V",
                finalProvisions: "Final Provisions",
                twentySixRule: "The Parties consider the contents of this Service Contract to be confidential and undertake to protect them. The Parties agree that neither of them shall, without the prior consent of the other Party, inform third parties of the contents of this Service Contract or of the rights and obligations arising therefrom for the Parties; the foregoing shall not apply to cases of information and notification obligations arising for the Parties from specific legislation",
                twentySevenRule: "By its signature on this Service Agreement, the Client confirms that all conditions and prerequisites set out in its internal regulations and generally binding legal regulations have been met for the conclusion of this Service Agreement on behalf of the Client, and that this Service Agreement has been concluded on behalf of the Client's contracting party by a person authorised to do so without limitation and without reservation in accordance with generally binding legal regulations",
                twentyEightRule: "The Parties declare that they shall refrain from any action that would prevent or hinder the fulfilment of the object of this Service Contract",
                twentyNineRule: "The Client declares that it has carefully read this Service Contract, that it has a good understanding of its contents, that this Service Contract is the expression of its free, serious and certain will, that it enters into this Service Contract freely and not under duress and not on unfavourable terms, in token of which the Client has agreed to enter into this Service Contract",
                thirtyRule: "This contract shall be drawn up electronically",
            },
            EstablishmentPeriodBox: {
                companyIsMake: "The company is based on time",
                forever: "For an indefinite period of time",
                onDate: "To Date",
                chooseDate: "Select a date",
            },
            GuidanceModalBox: {
                lawyersInfo: "Advice and Recommendations of an Advocate",
                complexInfo: "We have summarised the important information for you. Thank you and continue",
                readInfo: "I have read the advocate's recommendation(s)",
                lawyerAdvice: "I have read the attorney's recommendation(s)",
            },
            NewCompanyAddressBox: {
                companyAdress: "Registered office,",
                cityAdress: "Street and No., Postcode, City,",
                tooltip: "It is necessary to spell correctly the entire address where the new registered office will be located so that you do not have problems with the delivery of documents"
            },
            NewCompanyNameBox: {
                companyName: "Company name"
            },
            numberOfPropertyOwners: {
                numberOfPropertyOwnersText: "How many people own the property where the registered office will be located?",
                numberOfOwnersTooltip: "If you do not wish to generate property owner consent, leave blank or select 0"
            },
            RecognizeAbilityBox: {
                recognizeAbilityText: "According to the Commercial Code, in order to hold a general meeting and to take decisions on individual agenda items, shareholders holding at least 50% of all votes must be present, but the articles of association may also specify a higher or lower number of votes required. How is it in this company? What % of the votes of all the members are required?",
                noLimits: " This company has no special conditions for the holding of general meetings and for voting other than those laid down by the Commercial Code"
            },
            RepresentationBox: {
                representation: "We'll file it for you.",
                wantRepresentation: "Shall we file it for you with the Commercial Register of the Slovak Republic?",
                freeService: "If you choose this service NOW, it's free,",
                moreInfo: "otherwise it will be charged according to ",
                priceList: "Price list.",
                yes: "Yes",
                no: "No",
                thankYou: "Thank you, we will be happy to represent you,",
                orsr: "on the Commercial Register?",

            },
            SelectBoardBox: {
                nameTitleSurname: "Title, first name, last name"
            },
            KonatelBox: {
                otherAdress: "Other address",
                otherPerson: "Other person",
                enterTitle: "Enter titles before name",
                enterTitleAfter: "Enter titles after name",
                titleAfter: "Titles after name",
                enterName: "Enter name",
                name: "Name",
                enterSurname: "Enter last name",
                surname: "Last name",
                beforeName: "Titles before name",
                selectAdress: "Enter address",
                enterName: "The name of the managing director is",
                nameTitleSurname: "Title, first name, last name"
            },
            LikvidatorBox: {
                otherPerson: "Other person",
                otherAdress: "Other address",
                selectAdress: "Enter address",
                enterName: "Liquidator's name is",
                nameTitleSurname: "Title, first name, last name",
                enterTitlesBeforeName: "Enter titles before name",
                titlesBefore: "Titles before name",
                enterTitlesAfter: "Enter titles after name",
                titlesAfter: "Titles after name",
                enterName: "Enter first name",
                enterSurname: "Enter last name",
                surname: " Last name ",
                name: "First name",
            },
            SelectPaymentBox: {
                choose: "- Select -",
                cash: "Cash",
                transfer: "By bank transfer",
                didRecieve: "Has the transferor received the agreed consideration?",
            },
            ProkuristaBox: {
                otherAdress: "Other address",
                otherPerson: "Other person",
                enterTitles: "Enter titles before name",
                titlesBefore: "Titles before name",
                enterTitlesAfter: "Enter titles after name",
                titlesAfter: "Titles after name",
                enterName: "Enter name",
                name: "Name",
                enterSurname: "Enter last name",
                surname: "Last name",
                selectAdress: "Enter address",
                nameTitleSurname: "Title, first name, last name"
            },
            SpolocnikBox: {
                otherPerson: "Other person",
                otherAdress: "Other address",
                selectAdress: "Enter address",
                nameTitleSurname: "Title, first name, last name",
                enterName: "Enter name",
            },
            TitleNameSurnameArtifact: {
                titlesBegoreName: "Titles before the name",
                titlesAfterName: "Titles after the name",
                name: "First name",
                surname: " Last name ",
            },

            VotingImputBox: {
                voteYes: "in favour",
                votingMaybe: "abstained",
                voteNo: "against",
                numberOfVotes: "Number of votes:",
                forChange: 'If this shareholder did not participate in the vote on this agenda item, first turn off the option "All shareholders were present at the general meeting", which you ticked in the introductory item 2. titled "Which shareholders attended the general meeting?',
                forChangeTwo: 'If this member did not vote in favour of adopting a resolution on this agenda item, first turn off the "All shareholders voted in favour of approving all agenda items" option in the introduction under item 2. titled "Which shareholders attended the general meeting?',
                here: "He participated in",
                notImportant: "His votes are not taken into account",
                voteCanOnly: "Only a member who participated in the vote may vote on this agenda item",
                forVote: 'In order for a member to be able to vote, you must state in the introductory paragraph 2. titled "Which shareholders attended the general meeting?" write how many votes in % belong to this shareholder',
                forVoteInput: 'In order for a member to be able to vote, you must state in the introductory paragraph 2. titled "Which shareholders attended the general meeting?" write how many votes in % belong to this shareholder',
                voting: "Vote",
                votingCount: "% of the votes.",
                accepted: "The resolution was adopted",
                border: "In order for a general meeting to be held",
                percents: "for approval are required ",
                herePeople: "% of the votes of the members present.",
                allPeople: "% of votes of all members",
                noPeople: "No members",
                notRecognizable: "The General meeting shall not have a quorum",
            },
            ZivnostNewBox: {
                remove: "Remove",
                enterRange: "Specify this trade as it appears on the professional qualification document",
                range: "Copy the scope of this trade from the proof of professional qualification",
                activity: "The name of the added business is",
                activityPlaceholder: "Write the exact title",
                choose: "I want to add a trade",
                chooseButton: "Select from the list",
                orEnter: "I want to add not a trade",
                add: "Write the subject of business",
                chooseAcitivity: "Choose from the list of trade licenses registered by the Trade Licensing Office",
            },

            CheckouFormBox: {
                invoiceDocs: "Billing information",
                makeInvoice: "I want to invoice another company",
                company: "Company name",
                companyAdress: "Registered office address",
                dic: "TAX IDENTIFICATION NUMBER",
                icdph: "VAT NUMBER",
                contactData: "Contact details",
                nameSurname: "Name and surname",
                enterValidMail: 'Please enter a valid email.',
                phone: 'Phone number',
                enterValidPhone: 'Please enter a valid telephone numbr',
                docs: "Documentation , moenyFor: remuneration for the preparation of the documentation",
                moenyFor: "Remuneration for the preparation of documentation",
                representing: 'Representation',
                moneyForRepresenting: "remuneration for representation",
                withoutWat: "Total excluding VAT",
                with20Wat: "VAT 20%",
                fees: 'Court and administrative fees',
                courtFee: "Court fee",
                sendingFee: "Amount credited",
                finalAmount: "Final amount",
                acceptTips: "I accept the advice and recommendations of counsel",
                iaccept: "I agree to",
                universalRules: "General Terms and Conditions",
                aboutContract: "Service Contract",
                campaignAgreement: "sending marketing offers",
                finishOrder: "Complete order with payment required",
                enterPassword: "Enter password to register or log in",
                weakPassword: "The password must contain at least one uppercase and lowercase letter, at least one digit, and at least one special character '!@#$%^&*)(+=._-'',",
                cognitoErrorUserExists: 'The user with that name already exists, use a different email.'
            },
            LoadOrderModalBox: {
                password: "Form password",
                loadContent: "Load form",
            },
            LoginModalBox: {
                loading: "Loading ...",
                loginRegister: "Profile login",
                intoPartnesProgram: "Affiliate Program",
                login: "Login",
                register: "Register",
                forgot: "I forgot my password",
                operator: "Portal operated by",
                youCanPayWith: "On this site you can pay securely via",
                Rules: "General Terms and Conditions",
                gdpr: "Privacy Policy",
                emailControll: "Your email has not been verified, to verify, enter the code you received by email after registration",
                controlCode: "Verification code",
                controll: "Verify",
                resendEmail: "Click here to resend the verification email",
                resend: "Resend",
                forNewPass: "To reset your password, enter the email that",
                youUsed: " you used to register",
                yourMail: "Your email",
                userName: 'Username',
                sendCode: 'Send authentication code',
                codeSms: "To authenticate, enter the code you received via SMS",
                resendSms: "To resend the verification SMS, click here",
                reControl: 'Resend',
                logOut: "Log out",
                myOrders: " My orders",
                password: 'Password'


            },
            OrderStatusFormBox: {
                orderStatus: "Order Status",
                dateOfOrder: "Date of delivery",
                smallCompany: "Trade",
                numberOf: "Submission number",
                recieveDate: "Date of Filing",
                lawBuilding: "Office",
                chooseOffice: "Select Trade Office",
                court: "Court",
                chooseCourt: "Select Court",
                dateOfUrgent: 'Date of urging',
                dateOfWrite: 'Date of registration',
                saveOrder: 'Save Order',
                addFiles: 'Add files',
                addDateOfWrite: 'Enrollment Date',

            },

            PasswordResetFormBox: {
                resetPassword: "Reset password",
                authCode: "Verification code (from email or sms)",
                password: "Enter new password",
                repeatPassword: "Repeat new password",
                passwordArentSame: 'Passwords do not match',

            },
            SaveOrderModalBox: {
                saveOrder: "Save document in progress",
                pleaseSave: "Please enter an e-mail address to which we will send a link to the work in progress",
                youCanComeBack: "You can come back to it at any time - easily and conveniently",
                yourMail: "Your email",
                yourSavedDoc: "Your work in progress has been saved in time",
                updateDocs: "and will be updated whenever you leave the window. Click below to update your details immediately",
                save: "Save",
            },
            StepperBox: {
                flowOfMeet: "Názvy dokumentov",
                neededToBeFill: "What needs to be filled in",
                viewOfDocs: "Preview of upcoming documents",
                firstStep: 'Vypĺňate údaje online',
                secondStep: 'Zaplatíte objednávku',
                thirdStep: 'Email s dokumentami',
                fourthStep: 'Podpíšte online'
            },
            UserProfileBox: {
                welcomeToProgram: "Thank you for being with us!",
                benefits: "Welcome to your profile",
                subtitle: "By registering and creating a profile, you can benefit from our Affiliate Program",
                fastView: "Always have a detailed overview of the orders placed",
                getAcces: "easy to create a new order",
                specialOffers: "special offers",
                accept: "I understand",
                logOut: "Unsubscribe",
                settings: "Profile settings",
                changesInSro: "Incorporation and changes in s.r.o.",
                homePage: "Homepage",
                actions: "Current Events",
                myOrders: "My orders",
                progress: "Track your orders",
                mySettingsTitle: "Edit your settings",
                mySettings: "My Settings",
                showProfileTour: "View profile tour",
                myPersons: "My people",
                actionsNow: "Current promotions",
                useActions: "Take advantage of our special promotions",
                specialPrice: "ACCOUNTS NOW FOR ONLY 14,90€",
                weCanHelp: "We will help you to have the approval of your financial statements as easy as possible and be at ease, without penalties",
                OrderDocs: "All you need to do is order the documents required for the approval of the financial statements from us and within 5 minutes you will have everything you need in your email",
                offerEnds: "The offer is valid until 31 August 2023",
                iWantDocs: "I want the financial statements",
                videoBlog: "Video instructions",
                preparing: "We are preparing ... ",

            },
            UserPersonsBox: {
                title: "My persons",
                menuTitle: "My persons."
            },
            UserProfileSettingsBox: {
                given_name: "Name",
                given_name_input: "Enter name",
                family_name: "Surname",
                family_name_input: "Enter last name",
                email: "Email",
                email_input: "Enter email",
                phone_number: "Phone number",
                phone_number_input: "Enter phone number",
                marketing_suhlas: "I agree to receive marketing offers",
                unknownUser: "Unknown User",
                modalTitleGetBenefits: "AFTER CONSENTING, REAP YOUR BENEFITS",
                modalTitleGetBenefitsThanks: "Thank you for giving your consent, which you can withdraw at any time after 30 days.",
                passwordChange: 'Password Change',
                saveSettings: 'Save settings',
                verifyPhoneNumber: 'Check phone number',
                verifyPhoneNumberFirst: 'Verify phone number first',
            },
            PersonList: {
                createNewPerson: "New Person",
                name: "The person's name",
                surname: "Person's surname",
                address: "Address",
                enterNameAndSurname: 'Enter first or last name'
            },
            PersonEditBox: {
                titlesBefore: "Titles before a person's name are",
                titlesAfter: "Titles after a person's name are",
                name: "A person's name is",
                surname: "A person's surname is",
                idType: "Document type",
                documentId: 'Document number',
                dateOfBirth: "Select date of birth",
                documentType: 'Does the person have a birth number?',
                idNumber: "Person's birth number/identifier is",
                personEditModalTitle: "Osobné údaje fyzickej osoby, ktorú chcem uložiť do šablóny",
                note: "Note"
            },

            UserRegistrationBox: {
                surname: "Last Name",
                telephone: "Phone number",
                pleaseEnterFullNumber: "Please enter a valid phone number.",
                iAgree: "I agree to receive marketing offers",
                passwordTooWeak: 'too weak',
                passwordWeak: 'weak',
                passwordGood: 'good',
                passwordStrong: 'strong',
                passwordTooStrong: 'too strong',
                passwordShort: 'short',
                stepBack: 'Step back',
                noPartnerWarning: "Vybraný subjekt nie je advokát, notár ani účtovník vyberte iný subjekt."
            },
            UserOrderBox: {
                close: "Close",
                open: "Open",
                download: "Download",
                created: "Created",
                state: "Status",
                numberInOffice: "Filing Office Number",
                dateInOffice: "Date of Office Filing",
                office: "Office",
                addedToOffice: 'Registry Office',
                dateOnCourt: "Date of filing with the court",
                court: "Court",
                addedToCourt: "Court Registry",
                urgentToOffice: "Urged Office",
                urgentToCourt: "Urgent court",
                writtenToOffice: "Entered Office",
                writtenToCourt: "Registered Court",

            },
            OrderListBox: {
                nameOfSro: "Title s.r.o.",
                ico: "ID NUMBER",
                feedBack: 'Rating',
                identifier: 'Identifier',
                stateOfOrder: 'Order Status',
                stateOfPay: 'Payment status',
                priceOfOrder: 'Order total',
                dateOfCreate: 'Date created',
            },
            UserListBox: {
                username: "Cognito meno",
                userstatus: "Stav užívateľa",
                given_name: "Krstné meno",
                family_name: "Priezvisko",
                email: "Email",
                email_verified: "Email overený",
                phone_number: "Telefónne číslo",
                phone_number_verified: "Telefónne číslo overené",
                custom_marketing_suhlas: "Marketingový súhlas",
                actions: "Operácie",
            },
            UserProcessForm: {
                username: "Cognito meno",
                userstatus: "Stav užívateľa",
                given_name: "Krstné meno",
                family_name: "Priezvisko",
                email: "Email",
                email_verified: "Email overený",
                phone_number: "Telefónne číslo",
                phone_number_verified: "Telefónne číslo overené",
                customer_role: "Typ užívateľa",
                custom_marketing_suhlas: "Marketingový súhlas",
                enterValue: "Zadajte hodnoty",
                enterPassword: "Prosím vypľnte heslo!",
                passwordValidation: "Heslo musí obsahovať aspoň jedno velké a malé písmeno, aspoň jednu číslicu a aspoň 8 znakov",
                passwordDoNotMatch: "Heslá sa nezhodujú!"
            },
            FeedbackListBox: {
                companyName: "Title s.r.o.",
                contactName: "Contact name",
                contactEmail: "Contact email",
                ratingValue: "Rating",
                ratingMessage: "Message",
                ratingDate: "Rating Date",
            },
            ErrorPageBox: {
                cantDo: "Sorry, the selected action cannot be performed",
                chooseAnotherCompany: "Choose another company",
            },

            FrontOfficeBox: {
                cleanigDay: "planned maintenance of the portal will take place. Thank you for your understanding | ORSR.Help Team",
                day: "On",

            },

            BaseInvestmentRaiseBox: {
                newInput: "Commitments for new deposits are assumed by",
                add: "Add",
                ico: "Identification number",
                birthID: "Personal No.",
                idData: " Identifier",
                enter: "Enter",
                owner: "Owner",
                birthIdOrId: "Personal No. or Identifier",
                icoOrId: "Identification number or Identifier",
                chooseOwnerOne: "Select a member",
                coOwner: "Member",
                chooseBirthDate: "Select the date of birth",
                chooseOwner: "The member has",
                orData: "or other identifier?",
                localCourt: "District court ",
                birthDate: "Date of birth",
                enterLocalCourt: "Enter the district court",
                part: "Section",
                insert: "Insert No.",
                enterPart: "Enther section",
                enterInsert: "Enter insert No.",
                remove: "Remove",
                enterRepresentMan: "Add a representative person",
                enterAmount: "The liability for the new contribution to the capital is in the amount of",
                enterAmountEUR: "After the increase, the member's contribution to the capital will be  €',",
                represent: "Proxy",
                enterFunc: "Write his function",
                func: "Function",
                enterName: "Write name",
                nameTitleSurname: "Title, first name, surname",
            },
            BaseInvestmentRaiseFromProfitComponentBox: {
                add: "Add",
                partsOfOwners: "The members' interests in the share capital will change as follows",
                chooseOwner: "Select a member",
                owner: "Member",
                ofOwner: "Owner",
                ico: "Identification number",
                birthIdOrId: "Personal No. or Identifier",
                icoOrId: "Identification number or Identifier",
                newAmount: "Enter new share amount in EUR",
                idData: "Identifier",
                birthId: "Personal No.",
                enter: "Enter",
                chooseIfOwner: "The member has",
                addRepresentMan: "Add a representative person",
                orData: "or other identifier?",
                dateOfBirth: "Date of birth",
                enterLocalCourt: "Enter the district court",
                localCourt: "District court",
                enterPart: "Enter section",
                part: "Section",
                enterInsert: "Enter insert No.",
                insert: "Insert No.",
                remove: "Remove",
                representMan: "Proxy",
                enterFunc: "Write his function",
                func: "Function",
                enterName: "Write name",
                nameTitle: "Title, first name, surname",
            },
            DepositAdministratorBox: {
                chooseAdmin: 'Select a deposit manager',
                chooseAdminTooltip: 'Vyberte spoločníka ktorý spravuje vklady spoločníkov do základného imania spoločnosti do zápisu spoločnosti do obchodného registra.',
                chooseOwner: "vyberte spoločníka",
                enteBusinessName: "Enter business name",
                businessName: 'Business name',
                businessAdress: "Enter registered seat:",
                streetPSC: 'street no., postcode, city',
                chooseCountry: 'Select a country',
                country: "Country",
                ico: 'Identification number',
                representMan: 'Proxy',
                enterFunc: 'Write his function',
                func: "Function",
                enterName: 'Write name',
                nameTitle: "Title, first name, surname",
                enterRepresent: "Add proxy",
            },
            DozornaRadaComponentBox: {
                titlesBefore: "Titles before the name of the member of the Supervisory Board are",
                titlesAfter: "Titles after the name of a member of the Supervisory Board are",
                firstName: "The name of the member of the Supervisory Board is",
                lastName: "The surname of the member of the Supervisory Board is",
                birthId: "Does the member of the Supervisory Board have a birth number?",
                birthIdPlaceholder: "Select a birth number or other identification",
                birthIdTooltip: "In some countries, they do not assign a birth number to persons, but persons are given other identifying information. It is necessary to choose one of the options,",
                enterBirthId: "The Supervisory Board member's birth number/identifier is",
                enterBirthIdPlaceholder: "write in the birth number or other identifier",
                enterBirthIdTooltip: "In some countries, they do not assign a birth number to persons, but persons are given other identifying information. They need to be filled in here",
                member: "A member of the Supervisory Board is",
                memberTooltip: "It is necessary to spell correctly the full name of the member of the Supervisory Board and, if applicable, all his titles.",
                address: "The residence of a member of the Supervisory Board is",
                addressTooltip: "It is necessary to specify correctly and with sufficient precision the address of the Supervisory Board member's residence",
                sepervisoryMember: 'Does the member of the Supervisory Board',
                orIdentity: "or other identifier?",
                enterIdentity: "Enter identifier",
                enterBirth: "Select a date of birth",
                birth: "Date of birth",
                enterFunc: "Write his function",
                func: "Function",
                addMember: "Add member of the Supervisory Board",
                sepervisoryExists: "Select the company's elective bodies",
                sepervisoryExistsTooltip: "A supervisory board is not usually established in limited liability companies; if it is established, it serves to control the directors and the accounts.",
            },
            KonateliaEstablishmentBox: {
                titlesBefore: "Titles before the name of the managing director are",
                titlesAfter: "Titles after the name of the managing director are",
                firstName: "The name of the managing director is",
                lastName: "The surname of the managing director is",
                konatel: "The managing director is",
                konatelTooltip: "It is necessary to spell the full name and surname of the managing director correctly and, if applicable, all his titles",
                enterAdress: 'The residence of the managing director is',
                enterAdressTooltip: 'The residential address of the managing director should be correctly and with sufficient precision',
                chooseIf: 'Does the managing director have a birth number?',
                chooseIfPlaceholder: 'Please select a birth number or other identifying information',
                chooseIfTooltip: 'In some countries they do not assign a birth number to persons, but persons are given other identifying information. You need to select one of the options',
                enterBirthID: "The managing director's birth number/identifier is",
                enterBirthIDTooltip: 'In some countries they do not assign a birth number to persons, but persons are given other identifying information. They need to be filled in here.',
                enterData: "Enter identifier",
                enterICO: "Enter identification number",
                ICO: "Identification number",
                birthID: "Personal No.",
                identication: "Identifier",
                chooseBirthDate: "Select a date of birth",
                birthDate: "Date of birth",
                addOwner: "Add a executive director",
                removeOwner: 'Remove Managing Director',
            },
            OwnersComponentBox: {
                allPeopleMeet: "The general meeting was attended by all partners",
                allPeopleVotedForOne: "All partners voted to approve all items on the agenda",
                partOwner: "Member",
                present: "Attended",
                choosePersonType: "Is it a human?",
                personType: "Type of Person",
                individual: "Human",
                legalPerson: "legal person",
                enterVotes: "Share of votes in %",
                enterCompanyName: "Business name of the member",
                enterName: "Name and surname of the member",
                name: "Write name",
                enterAdress: "address",
                enterCompanyAdress: "Write address",
                savedPersonsButton: 'Select from saved persons',
                savePerson: 'Save person',
                personInformation: "Person's identification data",
                searchPerson: 'Search person',
                enterNameAndSurname: 'Enter name or identification',
                personName: 'Name',
                personSurname: "Surname",
                id: "Identification",
                searchOrsr: "Search in ORSR",

            },
            OwnersEstablishmentBox: {
                titlesBefore: "Titles before a person's name are",
                titlesAfter: "Titles after a person's name are",
                firstName: "A person's name is",
                lastName: "A person's surname is",
                shareCapital: 'What will be the value of the share capital in €',
                choosePersonType: 'Is the person?',
                owner: 'The shareholder is',
                personType: 'Type of person',
                individual: 'Person',
                legalPerson: 'Legal person',
                depositeEuro: 'Enter deposit in €',
                paidDepositeEuro: 'Enter the deposit paid in €',
                shareCompanyPart: 'Enter % share in the company',
                shareProfit: 'Enter profit share in %',
                enterBusinessName: 'Need to spell out the full current business shareholder correctly',
                enterTitleNameTooltip: 'The full name of the shareholder and, if applicable, all titles of the shareholder must be spelled correctly',
                enterTitleName: 'Enter his/her title, first name, last name',
                businessName: 'Business name',
                titleName: 'Title, first and last name',
                partnerForeginer: "Is the partner a foreign person?",
                depositManager: "Is this partner a deposit manager?",
                partnerExecutive: "Is this partner also the managing director?",
                enterHomeLocation: 'The registered office of the shareholder is',
                enterHomeLocationTooltip: 'The current address of the registered office of the shareholder must be specified correctly and with sufficient precision',
                enterCompanyAdress: 'The domicile of the partner is',
                enterCompanyAdressTooltip: 'The current residential address of the partner must be specified correctly and with sufficient precision',
                ownerHaveBirthId: 'Does the person have a personal identification number?',
                ownerHaveOtherId: 'Does the associate have an ID number?',
                ICO: 'select the ID number',
                ICOTooltip: 'In some countries, legal persons are not assigned an ID number, but are given other identifying information. You need to choose one of the options.',
                birthID: 'select birth number',
                birthIDTooltip: 'In some countries, persons are not assigned a birth number, but are given other identifying information. You need to choose one of the options.',
                orID: "or other identifying information?",
                dataID: 'Identification',
                enterICO: "The shareholder's ID number/identifier",
                enterICOTooltip: 'In some countries, legal persons are not assigned an ID number, but are given other identifying information. They need to be filled in here',
                enterICOPlaceholder: 'fill in your registration number or other identification',
                enterBirthID: "The person's birth number/identifier is",
                enterBirthIDTooltip: 'In some countries, persons are not assigned a birth number, but are given other identifying information. They need to be filled in here',
                enterBirthIDPlaceholder: 'enter your birth number or other identification',
                enterID: 'Enter identification',
                chooseBirthDate: 'Select date of birth',
                birthDate: 'Date of birth',
                representMan: "Will sign on behalf of the partner",
                enterFunc: 'From what position?',
                func: 'e.g. managing director'
            },
            EstablishmentSinglePerson: {
                titlesBefore: "Titles before the name of the partner are",
                titlesAfter: "Titles after the name of the partner are",
                firstName: "The name of the companion is",
                lastName: "The surname of the partner is",
                shareCapital: "Write the capital in €",
                choosePersonType: "Is it a human?",
                personType: "Type of Person",
                individual: "Human",
                legalPerson: "legal person",
                enterBusinessName: "Enter business name",
                enterBusinessNameTooltip: 'Need to correctly spell the entire actual business of the partner',
                enterTitleName: 'Write his/her title, first name, last name',
                businessName: 'Business name',
                titleName: 'Title, first and last name',
                partnerForeginer: 'Is the partner a foreign person?',
                depositManager: "Is this partner also the custodian of the deposits?",
                partnerExecutive: "Is this partner also the managing director?",
                enterHomeLocation: 'The domicile of the partner is',
                enterCompanyAdress: 'The registered office of the partner is',
                enterCompanyAdressTooltip: 'The current address of the registered office of the shareholder must be correctly and sufficiently specified',
                enterHomeLocationTooltip: 'The current residential address of the partner should be specified correctly and with sufficient precision',
                ownerHaveBirthId: 'Does the companion have a birth number?',
                ownerHaveOtherId: 'Does the partner have a registration number?',
                ICO: 'select the registration number',
                ICOTooltip: 'In some countries, legal persons are not assigned an ID number, but are given other identifying information. You need to choose one of the options.',
                birthID: 'select a birth number',
                birthIDTooltip: 'Some countries do not assign a birth number to persons, but persons are given other identifying data. You need to select one of the options.',
                orID: 'or other identification data?',
                dataID: 'Identification',
                enterICO: "Shareholder's ID number/identifier",
                enterICOPlaceholder: 'enter the ID number or other identifier',
                enterICOTooltip: 'In some countries, they do not assign an ID number to legal entities, but persons are given other identifying information. They need to be filled in here',
                enterBirthID: "Shareholder's birth number/identifier is",
                enterBirthIDPlaceholder: 'fill in the birth number or other identifier',
                enterBirthIDTooltip: 'In some countries they do not assign a birth number to persons, but persons receive other identification data. They need to be filled in here.',
                enterID: "Identifier",
                chooseBirthDate: "Select the date of birth",
                birthDate: "Date of birth",
                representMan: "Proxy",
                enterFunc: "Enter his function",
                func: "Function",
                addRepresent: "Add proxy",
            },
            SingleOwnerBox: {
                chooseOneType: "Is the sole member a human?",
                personType: "Type of Person",
                individual: "Human",
                legalPerson: "Legal person",
                enterOneOwnerCompanyName: "Enter the business name of the sole member",
                enterOwnerName: "Enter the name of the sole member",
                name: "Name",
                enterOwnerLocation: "Enter the address of the registered office of the sole member",
                enterOwnerAdress: "Enter the residential address of the sole member",
                ifOwnerHave: "The member has",
                ICO: "Identification number",
                birthID: "Personal No.",
                orID: "or other identifier?",
                dataID: "Identifier",
                enterICO: "Enter identification number",
                enterBirthID: "Personal No.",
                enterID: "Identifier",
                chooseBirthDate: "Select the date of birth",
                birthDate: "Date of birth",
                representMan: "Proxy",
                enterFunc: "Enter his function",
                func: "Function",
                addRepresent: "Add proxy ",
                enter: "Enter",
                partner: "Member",
                enterPartnerBirthDate: "Enter the date of birth of member",
                enterTitleName: "Enter title, first name, surname",
                titleName: "Title, first name, surname",
            },
            RetributionBox: {
                withPayback: "For the purchase price",
                withoutPayback: "Free of charge",
                enterPaybackValue: "The purchase price amount is",
                paybackValue: "Purchase price amount",
                chooseExp: "the due of the purchase price is ",
                exp: "due",
                untilDate: "date ",
                withSign: "Upon signing this contract",
                chooseExpDate: "Select due date",
                expDate: "Due date",
                choosePayment: "Select payment method",
                cash: "In cash",
                transfer: "By bank transfer",
                didAccept: "Has the transferor accepted the agreed consideration?",
                iban: "IBAN"
            },
            PlnomocenstvoHeader: {
                enterFullOwner: "Enter the authorising executive director or procurator",
            },
            PresenceComponent: {
                personsHere: 'Present persons',
                personType: "Type of Person"
            },
            PresenceKonatel: {
                chooseOwner: "Select a executive director",
                enterFunc: 'Enter his function',
                func: "Function",
                enterName: 'Enter name',
                titleName: "Title, first name, surname",
                enterAdress: 'Enter address',
                streetCity: "street no., postcode, city",
                representMan: 'Representative',
            },
            PresenceOtherPerson: {
                enterFunc: 'Enter his function',
                func: "Function",
                enterName: 'Enter name',
                titleName: "Title, first name, surname",
                enterAdress: 'Enter address',
                streetCity: "street no., postcode, city",
                representMan: 'Representative',
            },
            PresenceProkurista: {
                chooseProxy: "Select a procurator",
                proxy: "Procurator",
                enterFunc: 'Enter function',
                func: "Function",
                enterName: 'Enter name',
                titleName: "Title, first name, surname",
                enterAdress: 'Enter address',
                streetCity: "street no., postcode, city",
                isHeRpresent: "Does anyone represent him?",
                enterRepresent: "Add proxy",
                representMan: 'Proxy',
                enterCountry: 'Select country',
                country: "Country",
            },
            PresentOwnerPerson: {
                coOwner: 'Member',
                enterFunc: 'Enter function',
                func: "Function",
                enterName: 'Enter name',
                titleName: "Title, first name, surname",
                enterAdress: 'Enter address',
                streetCity: "street no., postcode, city",
                isHeRpresent: "Represented by another person(s)?",
                enterRepresent: "Add a representative",
                representMan: 'Representative',
            },
            LossComponent: {
                enterLoss: "Enter the loss",
                useToPay: "Use profits from previous years to cover the loss?",
                feeToPay: "Use the members' contribution to cover the loss?",
                year: "Year",
                enterValue: "Enter the value",
                value: "Value",
                coOwnerPay: "Contribution of a member",
            },
            ProfitComponent: {
                enterProfit: "Enter profit",
                profit: "Profit",
                enterReserve: "Enter a contribution to the reserve fund",
                resrveFoundation: "Contribution to the reserve fund",
                enterSocialReserve: "Enter your contribution to the social fund",
                socialFoundation: "Contribution to the social fund",
                enterBaseReserve: "Enter the profit earmarked for the capital increase",
                baseFoundation: "Profit earmarked for capital increase",
                enterProfitToSplit: "Enter the profit to be distributed to the members",
                profitToSplit: "Distribution of profits among members",
                enterName: "Enter a title",
                name: "Title",
                enterValue: "Enter the value",
                value: "Value",
            },
            PropertyOwner: {
                titlesBefore: "Titles before the owner's name are",
                titlesAfter: "Titles after the owner's name are",
                firstName: "The owner's name is",
                lastName: "The owner's surname is",
                enterTypeOfOwner: 'Is the owner a man?',
                enterICO: "Owner's ID number/identifier is",
                enterICOPlaceholder: 'fill in the registration number or other identifier',
                enterBirthID: "Owner's birth number/identifier is",
                enterBirthIDPlaceholder: 'write the birth number or other identifier',
                typeOfOwner: "Type of Person",
                individual: "Natural person",
                legalPerson: "legal person",
                enterOwner: "Enter the owner",
                enterOwnerTooltip: 'The full name of the owner and, if applicable, any titles must be spelled correctly',
                enterOwnerName: "Enter business name",
                enterOwnerNameTooltip: "The owner's full current business name must be spelled correctly",
                titleName: "Title, first name, surname",
                businessName: "Business name",
                streetCity: "street no., postcode, city",
                enterOwnerLocation: "Enter the owner's place of residence",
                enterAdress: "Enter registered seat:",
                enterOwnerBirth: "Select the date of birth",
                dateOfBirth: "Date of birth",
                chooseIfOwner: "Select whether the owner has",
                ico: "Identification number",
                hasIco: 'Does the owner have an ID number?',
                hasIcoTooltip: 'In some countries, legal persons are not assigned an ID number, but are given other identifying information. You need to choose one of the options.',
                IcoTooltip: "In some countries, legal entities are not assigned an ID number, but persons are given other identification data. They need to be filled in here",
                BirthIDTooltip: "In some countries, they do not assign a birth number to persons, but persons are given other identifying information. They need to be filled in here",
                birthID: "'birth number'?",
                hasBirthID: 'Does the owner have a birth number',
                hasBirthIDTooltip: 'In some countries, persons are not assigned a birth number, but are given other identifying information. You need to choose one of the options.',
                orID: "Identifier",
                id: "Identifier",
                enter: "Enter",
                owner: "Owner",
                birtIdOrId: "Personal No.",
                icoOrId: "Identification number or identifier ",
                enterRegistrationDisctrict: "Enter District Court of Registration",
                registrationDistrict: "District court",
                enterPart: "Enter section",
                part: "Section",
                enterInsert: "Enter insert No.",
                insert: "Insert",
                addRepresentMan: "Add a representative",
                representMan: "Representative",
                enterFunc: "Enter function",
                func: "Function",
                enterRepresentMan: "Enter a representative",
                tooltipPerson: "It is necessary to specify the owner's home address correctly and with sufficient precision",
                tooltipCompany: "The current address of the owner's registered office must be correctly and sufficiently specified"
            },
            PropertyType: {
                enterTypeOfProperty: "Select the type of property",
                enterTypeOfPropertyPlaceholder: 'select property type',
                enterTypeOfPropertyTooltip: 'If it is not a dwelling or commercial premises, it is a building that has a registration number',
                typeOfProperty: "Type of property",
                building: "Building",
                flat: "Apartment",
                noResidentPlace: "Non-residential space",
                flatNumber: "Enter apartment number",
                noResidentNumber: "Enter the number of the non-residential space",
                enterEntrance: "Enter the entrance",
                entrance: "Entrance",
                enterFloor: "Enter floor",
                floor: "Floor",
                enterInventoryNumber: "Enter street number",
                inventoryNumber: "Street number",
                enterDeedNumber: "Enter the title deed number",
                deedNumber: "Title deed number",
                enterSizeOfShare: "Enter the size of the share in the common areas",
                sizeOfShare: "Size of the share in the common areas",
                enterDistrictOffice: "Enter the district office",
                districtOffice: "District office",
                enterCadastralTerritory: "Enter cadastral area",
                cadastralTerritory: "Cadastral area",
                enterMunicipality: "Enter the Village",
                municipality: "Village",
                enterDistrict: "Enter the district",
                district: "District",
                chooseParcelType: "Select parcel register type",
                parcelRegister: "Parcel of register E",
                enterParcelNumber: "Enter parcel number",
                parcelNumber: "Parcel number ",
                enterTypeOfLand: "Enter the type of land",
                enterTypeOfBuilding: "Enter the type of building",
                typeOfLand: "Type of land",
                typeOfBuilding: "building type",
                addParcel: "Add a parcel",

            },
            NewOwnerComponent: {
                willOwnerHaveThisPart: "Does the member keep this part?",
                noResult: 'No result found',
                specifySearchingName: 'If you did not find your company, specify the name or enter the ID number',
                part: "Part",
                enterShareEur: "Enter the share in €",
                shareInEur: "not less than €750",
                enterSharePercents: "Enter the share in %",
                shareInPercents: "Share in %",
                chooseTypeOfPerson: "Is the buyer human?",
                typeOfPerson: "Type of Person",
                searchORSR: "Can we find him in the database of the Commercial Register of the Slovak Republic?",
                searchWithNameOrICO: "Write identification number or business name",
                enterName: "Write the title, name, surname of the buyer",
                enterBusinessName: "Write the buyer's business name",
                titleName: "write full name",
                addTitlesBeforeName: 'Enter titles before name',
                titlesBeforeName: 'Titles before name',
                addName: 'Enter name',
                addYourSurname: 'Enter last name',
                surname: "Last name",
                addTitlesAfterName: "Enter titles after name",
                titlesAfterName: "Titles after name",
                enterHomeLocation: "Write the buyer's registered office",
                enterAdress: "Write the buyer's address",
                chooseDateOfBirth: "Date of birth",
                dateOfBirth: "Date of birth",
                chooseIfNewOwner: "Does the buyer have",
                ico: "Identification number",
                birthID: "Personal No.",
                orID: "or does he have a different Identifier?",
                dataID: "Identifier",
                enter: "Enter",
                newOwner: "Buyer",
            },
            OwnerComponent: {
                guideOp: "Who sells the business share?",
                chooseDateOfBirth: "Seller's date of birth",
                dateOfBirth: "Write date of birth",
                chooseConverterHave: "Does the seller have",
                ico: "Identification number",
                birthID: "Personal No.",
                orID: "or does he have a different Identifier?",
                dataID: "Identifier",
                enter: "Personal No.",
                converter: "of the seller is",
                enterPart: "The amount of the seller's deposit in € is",
                part: "write the amount",
                enterSharePercents: "Seller's business share is in %",
                shareInPercents: "Share in %",
                enterPartsToCut: "On how many parts do you want to divide the share into?",
                partsToCut: "Write a number",
            },
            ShareDivision: {
                capital: "Registered capital in € ",
                addRepresent: "Add another signer?",
                representMan: "On behalf of the buyer will sign",
                enterFunc: "From which position?",
                func: "e. g. executive director ",
                enterName: "Write his title, first name, surname",
                titleName: "Title, first name, surname",
                representManSeller: "He/she will sign on behalf of the seller",
            },
            ShareDivisionSinglePerson: {
                capital: "Registered capital in € ",
                addRepresent: "Add another signer?",
                representMan: "On behalf of the buyer will sign",
                enterFunc: "From which position?",
                func: "e. g. executive director ",
                enterName: "Write his title, first name, surname",
                titleName: "Title, first name, surname",
                representManSeller: "He/she will sign on behalf of the seller",
            },
            SecondNewOwnerComponent: {
                enterNewOwner: "Who buys the business share?",
                chooseTypeOfPerson: "Is it a human?",
                typeOfPerson: "Type of Person",
                individual: "Human",
                legalPerson: "legal person",
                searchORSR: "Can we find him in the database of the Commercial Register of the Slovak Republic?",
                searchWithNameOrICO: "Write identification number or business name",
                enterName: "Write the title, name, surname of the buyer",
                enterBusinessName: "Write the buyer's business name",
                titleName: "write full name",
                enterHomeLocation: "Write the buyer's registered office",
                enterAdress: "Write the buyer's address",
                name: "Name",
                chooseIfNewOwner: "Does the buyer have",
                enter: "Enter",
                newOwner: "Buyer",
                ico: "Identification number",
                birthID: "Personal No.",
                orID: "or does he have a different Identifier?",
                dataID: "Identifier",


            },
            SecondOwnerComponent: {
                chooseConverter: "Who sells the business share?",
                converter: "Write the title, name, surname of the seller",
                chooseDateOfBirth: "Seller's date of birth",
                dateOfBirth: "write date of birth",
                chooseConverterHave: "Does the seller have",
                ico: "Identification number",
                birthId: "Personal No.",
                orId: "or does he have a different Identifier?",
                enter: "Write",
                dataId: "Identifier",
                converter: "of the seller",
                enterPart: "The amount of the seller's deposit in € is",
                part: "write amount",
                enterPartInPercent: "Seller's share of the business is in %",
                partInPercent: "Share in %",
                chooseConverter: "Who sells the business share?",
            },
            ShareTransfer: {
                capital: "Registered capital in € ",
                addRepresentMan: "Add another signer?",
                representMan: "On behalf of the buyer will sign",
                addFunc: "From which position?",
                func: "e. g. executive director ",
                addTitleName: "Write his title, first name, surname",
                titleName: "Title, first name, surname",
                representManSeller: "He/she will sign on behalf of the seller",
            },
            ShareTransferSinglePerson: {
                capital: "Registered capital in € ",
                addRepresentMan: "Add another signer?",
                representMan: "On behalf of the buyer will sign",
                addFunc: "From which position?",
                func: "e. g. executive director ",
                addTitleName: "Write his title, first name, surname",
                titleName: "Title, first name, surname",
                representManSeller: "He/she will sign on behalf of the seller",
            },
            TransferAquirerStatement: {
                chooseSignatureDate: "Select the date of signature",
                dateOfSignature: "write the date",
                addPlaceOfSignature: "Where the signature was signed",
                placeOfSignature: "Write the location",
            },
            TransferSellerStatement: {
                chooseSignatureDate: "Select the date of signature",
                dateOfSignature: "write the date",
                addPlaceOfSignature: "Where the signature was signed",
                placeOfSignature: "Write the location",
            },
            SignatureCanvasArtifact: {
                createSignature: "Create a signature",
                create: "Create",
                loadFile: "or upload a file",
                signature: "Signature",
                missingSignatures: 'The finger signatures of the following persons are missing, it will no longer be possible to add them after signing by KEP.',
                save: "Apply",
                clear: "Change",
                close: 'Close',
                orderDetail: 'Order detail'
            },
            DocumentsDownload: {
                makeAndChangeSro: "Incorporation and changes in s.r.o.",
                enterOrderPassword: "Enter order password",
                weAreCookingYourDocuments: "We're working hard to create your documents",
                itDoesntTakeMoreThan: "It won't take more than 5 minutes",
                registerToProgram: "Sign up for our ",
                partnersProgram: "Affiliate Program",
                allNews: "and you'll always have an overview of your orders and special offers",
                itsDone: "And it's done!",
                docsAreReady: "Your documents are ready,",
                justDownload: "Just download them",
                linkInEmail: "A message is already waiting for you in your email, where you will find the link and password, so you can access your documents whenever you need them",
                thanks: "Thank you for being with us",
                downloadAll: "Download all documents",
                operator: "The portal is operational",
                vop: "General Terms and Conditions",
                gdpr: "Privacy Policy",
            },
            DocumentOverview: {
                documentOverview: 'Document preview',
                cancel: 'Close',
                preview: 'Preview'
            }
        },
        screens: {
            ValidatorModal: {
                title: "We've found places where something doesn't add up, do you still want to continue?",
                step: "Something is not right here",
            }
        }
    },
    de: {
        artifacts: {
            AdressAutocompleteInputsBox: {
                adress: "Straße und Hausnummer, Postleitzahl, Stadt",
            },
            AddressAutoCompleteSeperatedInputBox: {
                street: "Straße ",
                streetSmall: "Straße ",
                streetSmallSecond: "Straße ",
                adressNumber: "Nummer",
                adressNumberPlaceholder: "Geben Sie eine Zahl ein",
                city: "Stadt",
                cityPlaceholder: "Stadt betreten",
                poscodePlaceholder: "Geben Sie Ihre Postleitzahl ein",
                poscode: "Postleitzahl",
                chooseCountry: "ein Land wählen",
                country: "das Land",
            },
            BaseInvestmentBox: {
                baseFinance: "das Stammkapital",
            },
            BusinessTransferBox: {
                companyPart: "der Unternehmensteil",
                fullCompany: "das ganze Unternehmen",
                enterPartCompanyName: "geben Sie den Namen des Unternehmensteils ein",
                partCompanyName: "Name des Unternehmensteils",
            },
            CompanyAvailabilityInputBox: {
                forSure: "Der von Ihnen gewählte Firmenname wird noch von keinem anderen Unternehmen verwendet. Um Ihre Rechtssicherheit zu erhöhen, haben wir dies derzeit auf ",
                orsrsk: " der Website orsr.sk überprüft",
                unavailable: "Der von Ihnen gewählte Firmenname wird bereits von jemandem verwendet. Wählen Sie einen anderen",
                available: 'Dostupná'
            },
            ContractModalBox: {
                title: "Dienstleistungsvertrag",
                parties: "die Vertragsparteien",
                lawyersOffice: "Anwaltskanzlei Matejka Friedmannová s. r. o.",
                withCompanyAdress: " mit Sitz in Dunajská Str. 48, 811 08 Bratislava, ID-Nr. 47 248 998, USt.-ID-Nr. SK 2023808765, eine im Handelsregister des Bezirksgerichts Bratislava I, Abteilung Sro, Einlage-Nr. 90113/B eingetragene Handelsgesellschaft, für die JUDr. Ondrej Matejka, Geschäftsführer mit der Befugnis zum selbständigen Handeln, handelt (im Folgenden nur",
                and: "und",
                lawyer: "Anwalt",
                orAnd: "und/oder",
                operator: "Operator / Betreiber",
                withAdress: "mit eingetragenem Sitz an der Adresse",
                companyWrittenIn: "eine im Handelsregister des Amtsgerichts eingetrage Handelsgesellschaft",
                part: "Abteil",
                insert: "Einlage Nr.",
                order: "in deren Namen die Person, die die Bestellung fertigstellt, befugt ist, unabhängig und uneingeschränkt zu handeln (im Folgenden als",
                client: "der Kunde",
                user: "der Benutzer",
                canActAlone: "in deren Namen die Person, die die Bestellung fertigstellt, befugt ist, unabhängig und uneingeschränkt zu handeln (im Folgenden als",
                articleOne: "Artikel I",
                baseAgreement: "grundlegende vertragliche Vereinbarungen",
                firstRule: "Der Advokat verpflichtet sich, dem Kunden die Applikationsdienstleistungen zu den in diesem Dienstleistungsvertrag vereinbarten Bedingungen und vorbehaltlich der in den AGB festgelegten Bedingungen zu erbringen",
                definatedDown: " im Text weiter unten definierter Begriff",
                secondRule: "Der Kunde verpflichtet sich, den Bedarf an rechtlicher Unterstützung für den Anwalt ausschließlich durch die Eingabe der Anforderungen des Nutzers in die Applikation zu tätigen, und nur der Abschluss eine Bestellung gilt als gültige Eingabe der Anforderungen des Nutzers für die Erbringung der Applikationsdienstleistungen.",
                thirdRule: "Der Anwalt beginnt mit der Erbringung der Applikationsdienstleistungen für den Kunden zu dem Zeitpunkt, der unmittelbar auf den Abschluss der Bestellung folgt.",
                fourthRule: "Der Anwalt ist für den rechtlichen Teil der von ihm während der Laufzeit dieser Dienstleistungsvertrag erstellten Dokumente verantwortlich.",
                fifthRule: "Der Kunde verpflichtet sich, sich ausführlich mit allen verfügbaren Anweisungen des Anwalts in der Applikation, mit den AGB vertraut zu machen",
                fifthRuleTwo: "mit der Cookie-Politik und mit der Politik zur Verarbeitung personenbezogener Daten",
                sixsthRule: "Der Anwalt übergibt dem Kunden die erbrachten Applikationsdienstleistungen, wenn die Bearbeitung der gesamten Bestellungs des Kunden umfassend abgeschlossen ist.",
                seventhRule: "Der Anwalt überträgt die dem Kunden erbrachten Applikationsdienstleistungen nur im Wege der Lieferung der erstellten Nutzersdokumente.",
                eightRule: "Der Anwalt stellt dem Nutzer die erstellten Dokumente nach Zahlung des Preises zu, in der Regel innerhalb von fünf Minuten nach Zahlung des Preises; die Nichteinhaltung dieser Frist kann insbesondere durch eine instabile Internetverbindung des Nutzers und/oder andere technische Unzulänglichkeiten der Internetverbindung des Nutzers und/oder technische Unzulänglichkeiten des Geräts des Nutzers und/oder die Applikation verursacht werden.",
                ninethRule: "Der Kunde hat das Recht, die vom Anwalt erbrachten Anwendungsdienste gemäß den einschlägigen Bestimmungen des Handelsgesetzbuchs zu beanstanden.",
                articleTwo: "Artikel II",
                lawyersPrize: "Honorare und Zahlungsbedingungen des Anwalts,",
                tenthRule: "Der Kunde verpflichtet sich, dem Anwalt den Preis für die Erbringung der Appllikationsdienste zu zahlen,",
                eleventhRule: "Der Preis wird als Pauschalhonorar vereinbart und richtet sich nach der Verordnung des Justizministeriums der Slowakischen Republik Nr. 655/2004 Slg. über die Gebühren und die Entschädigung von Rechtsanwälten für die Erbringung von Rechtsdienstleistungen sowie nach diesem Dienstleistungsvertrag.",
                twelveRule: "Der Anwalt stellt den Preis für die erbrachten Applikationsdienstleistungen in Rechnung, wenn die Bearbeitung des gesamten Antrags des Kunden vollständig abgeschlossen ist,",
                thirtteenthRule: "Die Bedingungen für die Ausstellung einer Rechnung, den Versand einer Rechnung und die Zustellung einer Rechnung sind in den AGB enthalten",
                articleThree: "Artikel III",
                termsAndConditions: "Verhältnis zwischen dem Dienstleistungsvertrag und den Allgemeinen Geschäftsbedingungen,",
                fourthteenthRule: " Der Anwalt hat Allgemeine Geschäftsbedingungen für die Erbringung von Dienstleistungen aufgestellt,",
                fourthteenthRuleSecond: "Rechtsdienstleistungen, die durch Applikationsanwendung zustande kommen und die der Anwalt vor der Unterzeichnung dieser Dienstleistungsvertrag auf der Website veröffentlicht hat, in der zum Zeitpunkt des Abschlusses dieser Dienstleistungsvertrag geltenden Fassung (im Folgenden als 'AGB' bezeichnet)",
                VOP: "AGB,",
                fifthteenthRule: "Die AGB sind integraler Bestandteil dieses Dienstleistungsvertrags,",
                sixteenthRule: "Im Falle eines Konflikts/einer Kollision/einer Unstimmigkeit zwischen dem Text des vorliegenden Dienstleistungsvertrags und dem Text der AGB haben die in diesem Dienstleistungsvertrag enthaltenen Bestimmungen Vorrang.",
                articleFour: "Artikel IV",
                commonProvisions: "Gemeinsame Bestimmungen",
                seventeenthRule: "Dieser Dienstleistungsvertrag wird zwischen den Parteien auf elektronischem Wege unter Verwendung der Applikation abgeschlossen.",
                eighteenthRule: "Der Abschluss der Bestellung ist der Zeitpunkt, an dem dieser Dienstleistungsvertrag zwischen dem Betreiber und dem Kunden abgeschlossen wird.",
                nineteenthRule: "Der vorliegende Dienstleistungsvertrag tritt zum Zeitpunkt seines Abschlusses in Kraft.",
                twentiethRule: "Sofern nichts anderes bestimmt ist, wird dieser Dienstleistungsvertrag mit seiner Unterzeichnung rechtswirksam.",
                twentyFirstRule: "Die Verpflichtung des Betreibers, die erstellten Dokumente an den Nutzer zu liefern, entsteht erst mit der Zahlung des Preises. Der Nutzer erkennt an und erklärt sich ausdrücklich damit einverstanden, dass der Betreiber nicht verpflichtet ist, dem Nutzer die erstellten Dokumente zu liefern, bevor der Nutzer den Preis an den Betreiber gezahlt hat.",
                twentySecondRule: "Dieser Dienstleistungsvertrag wird auf unbestimmte Zeit geschlossen.",
                twentyThirdRule: "Dieser Dienstleistungsvertrag ist für die Rechtsnachfolger der Parteien gültig und verbindlich.",
                twentyFourRule: "Bei der Erbringung von Rechtshilfe und Rechtsdienstleistungen hält sich der Anwalt an die geltenden gesetzlichen Vorschriften über die Ausübung der Anwaltschaft und an die verbindlichen Vorschriften und Richtlinien der Slowakischen Rechtsanwaltskammer.",
                twentyFiveRule: "Der Kunde erklärt sich damit einverstanden, dass der Anwalt öffentlich zugängliche Informationen über den Kunden für die Zwecke der Präsentation des Anwalts verwendet, insbesondere erklärt sich der Kunde damit einverstanden, dass der Anwalt den Handelsnamen und die Marke des Kunden für die Zwecke der Referenzen des Anwalts verwendet.",
                articleFive: "Artikel V,",
                finalProvisions: "Schlussbestimmungen,",
                twentySixRule: "Die Parteien betrachten den Inhalt dieses Dienstleistungsvertrags als vertraulich und verpflichten sich, ihn zu schützen. Die Parteien vereinbaren, dass keine von ihnen ohne vorherige Zustimmung der anderen Partei Dritte über den Inhalt dieses Dienstleistungsvertrags oder über die sich daraus für die Parteien ergebenden Rechte und Pflichten informieren wird; dies gilt nicht für Fälle von Informations- und Mitteilungspflichten, die sich für die Parteien aus besonderen Rechtsvorschriften ergeben.",
                twentySevenRule: "Der Kunde bestätigt mit seiner Unterschrift auf dieser Dienstleistungsvertrag, dass alle in seinen internen Regelungen und allgemein verbindlichen Rechtsvorschriften festgelegten Bedingungen und Voraussetzungen für den Abschluss dieser Dienstleistungsvertrag im Namen des Kunden erfüllt sind und dass diese Dienstleistungsvertrag im Namen des Kunden als Vertragspartners von einer hierzu uneingeschränkt und vorbehaltlos bevollmächtigten Person in Übereinstimmung mit allgemein verbindlichen Rechtsvorschriften abgeschlossen worden ist.",
                twentyEightRule: "Die Parteien erklären, dass sie alles unterlassen werden, was die Erfüllung des Zwecks dieses Dienstleistungsvertrags verhindern oder erschweren könnte,",
                twentyNineRule: "Der Kunde erklärt, dass er diesen Dienstleistungsvertrag sorgfältig gelesen hat, dass er seinen Inhalt gut verstanden hat, dass dieser Dienstleistungsvertrag Ausdruck seines freien, ernsthaften und sicheren Willens ist, dass er diesen Dienstleistungsvertrag aus freien Stücken und nicht unter Zwang und nicht zu ungünstigen Bedingungen abschließt, und dass der Kunde als Zeichen dafür zugestimmt hat, diesen Dienstleistungsvertrag abzuschließen.",
                thirtyRule: "Dieser Vertrag wird auf elektronischem Wege erstellt,",
            },
            EstablishmentPeriodBox: {
                companyIsMake: "Ein Unternehmen wird auf Zeit gegründet",
                forever: "Auf unbestimmte Zeit",
                onDate: "Bis Datum",
                chooseDate: "Wählen Sie ein Datum",
            },
            GuidanceModalBox: {
                lawyersInfo: "Ratschläge und Empfehlungen eines Anwalts,",
                complexInfo: "Wir haben die wichtigsten Informationen für Sie zusammengefasst. Vielen Dank und wir machen weiter",
                readInfo: "Ich habe die Empfehlungen des Anwalts gelesen,",
                lawyerAdvice: "Ratschläge und Empfehlungen eines Anwalts,",
            },
            NewCompanyAddressBox: {
                companyAdress: "Eingetragener Sitz der Gesellschaft,",
                cityAdress: "Straße und Nr., Postleitzahl, Ort,",
                tooltip: "Je potrebné správne napísať celú adresu, na ktorej bude nové sídlo spoločnosti, aby ste nemali problémy s doručovaním písomností"
            },
            NewCompanyNameBox: {
                companyName: "Name der Gesellschaft",
            },
            numberOfPropertyOwners: {
                numberOfPropertyOwnersText: "Anzahl der Miteigentümer der Liegenschaft",
                numberOfOwnersTooltip: "Ak si neprajete vygenerovať súhlas vlastníka nehnuteľnosti, nechajte prázdne, alebo zvoľte 0"
            },
            RecognizeAbilityBox: {
                recognizeAbilityText: "Nach dem Handelsgesetzbuch ist für die Abhaltung einer Hauptversammlung und die Beschlussfassung über einzelne Tagesordnungspunkte die Anwesenheit von Gesellschafter erforderlich, die mindestens 50 % aller Stimmen auf sich vereinen; die Satzung kann jedoch auch eine höhere oder niedrigere Stimmenzahl vorsehen. Wie verhält es sich in dieser Gesellschaft? Wie viel % der Stimmen aller Gesellschafter sind erforderlich?",
                noLimits: " Für diese Gesellschaft gelten keine besonderen Bedingungen für die Abhaltung von Hauptversammlungen und für Abstimmungen, die über die im Handelsgesetzbuch festgelegten Bedingungen hinausgehen.",
            },
            RepresentationBox: {
                representation: "Wir werden es für Sie einreichen.",
                wantRepresentation: "Ich möchte, dass Sie eine Anmeldung beim Handelsregister für mich vornehmen.",
                freeService: "Wenn Sie die Dienstleistung jetzt in Anspruch nehmen, ist sie kostenlos",
                moreInfo: "Andernfalls werden die Kosten gemäß der ",
                priceList: "Preisliste",
                yes: "Ja.",
                no: "Nein",
                thankYou: "Danke, wir werden Sie gerne vertreten",
                orsr: "zum Handelsregister?"

            }, SelectBoardBox: {
                nameTitleSurname: "Titel, Vorname, Nachname",
            },
            KonatelBox: {
                otherAdress: "Andere Adresse",
                otherPerson: "Andere Person",
                enterTitle: "Titel vor dem Namen eingeben",
                enterTitleAfter: "Titel nach dem Namen eingeben",
                titleAfter: "Titel nach dem Namen",
                enterName: "Name eingeben",
                name: "Name",
                enterSurname: "Nachnamen eingeben",
                surname: "Nachname",
                beforeName: "Titel vor dem Namen",
                selectAdress: "Adresse eingeben",
                enterName: "Der Name des Geschäftsführers ist",
                nameTitleSurname: "Titel, Vorname, Nachname"
            },
            LikvidatorBox: {
                otherPerson: "Andere Person",
                otherAdress: "Andere Adresse",
                selectAdress: "Adresse eingeben",
                enterName: "Name des Verwalters ist",
                nameTitleSurname: "Titel, Vorname, Nachname",
                enterTitlesBeforeName: "Titel vor dem Namen eingeben",
                titlesBefore: "Titel vor dem Namen",
                enterTitlesAfter: "Titel nach dem Namen eingeben",
                titlesAfter: "Titel nach dem Namen",
                enterName: "Vornamen eingeben",
                enterSurname: "Nachnamen eingeben",
                surname: " Nachname",
                name: "Vorname",
            },
            SelectPaymentBox: {
                choose: "- Wählen Sie -",
                cash: "Bar",
                transfer: "Per Banküberweisung",
                didRecieve: "Hat der Übertragende die vereinbarte Gegenleistung erhalten?",
            },
            ProkuristaBox: {
                otherAdress: "Andere Adresse",
                otherPerson: "Andere Person",
                enterTitles: "Titel vor dem Namen eingeben",
                titlesBefore: "Titel vor dem Namen",
                enterTitlesAfter: "Titel nach dem Namen eingeben",
                titlesAfter: "Titel nach dem Namen",
                enterName: "Name eingeben",
                name: "Name",
                enterSurname: "Nachname eingeben",
                surname: "Nachname",
                selectAdress: "Adresse eingeben",
                nameTitleSurname: "Titel, Vorname, Nachname"
            },
            SpolocnikBox: {
                otherPerson: "Andere Person",
                otherAdress: "Andere Adresse",
                selectAdress: "Adresse eingeben",
                nameTitleSurname: "Titel, Vorname, Nachname",
                enterName: "Name eingeben",
            },
            TitleNameSurnameArtifact: {
                titlesBegoreName: "Titel vor dem Namen",
                titlesAfterName: "Titel nach dem Namen",
                name: "Vorname",
                surname: " Nachname",
            },
            VOPmodalBox: {
                vopIntro: "Advokátska kancelária Matejka Friedmannová s. r. o., so sídlom na adrese: Dunajská 48, 811 08 Bratislava – m. č. Staré Mesto, Slovenská republika, IČO: 47 248 998, zapísaná v Obchodnom registri vedenom Mestským súdom Bratislava III, oddiel Sro, vložka č. 90113/B, ustanovila pre používanie aplikácie ORSR.HELP a pre poskytovanie právnych služieb použitím aplikácie ORSR.HELP Všeobecné obchodné podmienky v následovnom znení (ďalej len „",
                vop: "VOP",
                articleOne: "Článok I",
                mainDefinitions: "Základné pojmy a definície",
                aplication: "„Aplikácia“ ",
                b2bApp: "znamená softvérová B2B [business-to-business] aplikácia s názvom ORSR.HELP vyvinutá Prevádzkovateľom a používaná Prevádzkovateľom ",
                contract: "uzatvorenie Zmluvy o poskytnutí služieb s Používateľom, ",
                using: "na poskytovanie Služieb Aplikácie a",
                payPrice: "na zabezpečenie zaplatenia Ceny za poskytnutie Služieb Aplikácie; súčasťou Aplikácie je internetová stránka dostupná z Internetovej adresy;",
                copyrightLaw: "„Autorský zákon“",
                copyMeans: "znamená zákon č. 185/2015 Z. z. autorský zákon v znení neskorších predpisov;",
                price: "„Cena“ ",
                definition: "znamená sumu elektronicky dohodnutú podľa Cenníka Služieb medzi Prevádzkovateľom a Používateľom za poskytnutie jednotlivých Služieb Aplikácie Používateľovi, ktorú sa Používateľ zaväzuje zaplatiť Prevádzkovateľovi použitím zabezpečenej platobnej brány Aplikácie. Cena je uvedená najmä v časti Aplikácie s názvom Košík, pričom výška Ceny sa odvíja od rozsahu Používateľovej objednávky Služieb Aplikácie. Cena neobsahuje žiadne skryté poplatky. Cena je konečná a je uvádzaná v členení na sumu základu dane z pridanej hodnoty a sumu dane z pridanej hodnoty. V Cene je zahrnutá aj náhrada hotových administratívnych výdavkov Prevádzkovateľa;",
                priceList: "„Cenník Služieb“ ",
                priceDefinition: "znamená cenník určený Prevádzkovateľom pre poskytovanie Služieb Aplikácie a aktuálny ku dňu poskytnutia Služieb Aplikácie Používateľovi. Cenník Služieb obsahuje najmä sumy cien a poplatkov a je zverejnený na Internetovej adrese;",
                civilOrder: "„Civilný mimosporový poriadok“",
                lawDefinition: "znamená zákon č. 161/2015 Z. z. Civilný mimosporový poriadok v znení neskorších predpisov;",
                completeOrder: "„Dokončenie objednávky“",
                itMeans: "znamená vyplnenie všetkých povinných polí objednávkového formulára Aplikácie Používateľom a potvrdenie objednávky Používateľa uskutočnením aktívneho kliknutia Používateľa na príslušné tlačidlo v Aplikácii ",
                finishOrder: " s nápisom „Dokončiť objednávku s povinnosťou platby“ alebo ",
                button: "na príslušné tlačidlo v Aplikácii s iným nápisom rovnakého obsahového významu;",
                document: "„Dokument“",
                partsOfDocument: "znamená ucelenú a vzájomne prepojenú časť Aplikácie, zobrazujúcu Používateľovi textové pole s obsahom súvisiacim od rozsahu Používateľovej objednávky Služieb Aplikácie; Dokument sa člení na",
                firstPartDocument: "Právnu časť Dokumentu a ",
                infoByUser: "Údaje vyplnené Používateľom. Dokument sa Používateľovi v Aplikácii zobrazuje najmä za tým účelom, aby Prevádzkovateľ poskytol Používateľovi online náhľad na konzistenciu a gramatickú súvsťažnosť Údajov vyplnených Používateľom s Právnou časťou Dokumentu. Zobrazovaním Dokumentu Aplikácia umožňuje Používateľovi online kontrolou zistiť chybu/nedostatok/nesprávnosť Údajov vyplnených Používateľom a umožňuje Používateľovi vykonať opravu/doplnenie Údajov vyplnených Používateľom v používateľskom rozhraní Aplikácie;",
                deliverDocuments: "„Doručenie Vygenerovaných Dokumentov“",
                means: "znamená",
                downloadDocuments: "sprístupnenie možnosti stiahnutia Vygenerovaných Dokumentov v Aplikácii do Zariadenia Používateľa a/alebo",
                hyperLink: "odoslanie hyperlinku pre možnosť stiahnutia Vygenerovaných Dokumentov na e-mailovú adresu zadanú Používateľom v objednávkovom formulári Aplikácie a odoslanie hesla na otvorenie zaslaného hyperlinku na mobilné telefónne číslo zadané Používateľom v objednávkovom formulári Aplikácie. Validné Doručenie Vygenerovaných Dokumentov Používateľovi nastane aj vtedy, ak ",
                downloadFromApp: "Používateľ nevyužije možnosť stiahnuť si z Aplikácie do Zariadenia Používateľa Vygenerované Dokumenty a/alebo ",
                badUserInfo: "ak Používateľ v objednávkovom formulári Aplikácie uvedie neexistujúcu/nefungujúcu/nesprávnu/neplatnú e-mailovú adresu a/alebo neexistujúce/nefungujúce/nesprávne/neplatné mobilné telefónne číslo;",
                internetAdress: "„Internetová adresa“",
                anyAdress: "znamená akúkoľvek internetovú adresu, z ktorej je dostupná Aplikácia, najmä internetová stránka www.orsr.help;",
                itAdmin: "„IT admin“",
                itAdminMeans: "znamená označenie pre každú fyzickú osobu vyslovene oprávnenú Prevádzkovateľom na vstup do admin rozhrania Aplikácie;",
                gdpr: "„Nariadenie GDPR“",
                gdprMeans: "znamená Nariadenie Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (všeobecné nariadenie o ochrane údajov);",
                register: "„Obchodný register SR“",
                registerMeans: "znamená verejný zoznam, do ktorého sa zapisujú zákonom stanovené údaje týkajúce sa podnikateľov, prípadne iných osôb, o ktorých to ustanovuje osobitný zákon;",
                commercialCode: "„Obchodný zákonník“",
                commercialMeans: "znamená zákon č. 513/1991 Zb. Obchodný zákonník v znení neskorších predpisov;",
                civilCode: "„Občiansky zákonník“",
                civilMeans: "znamená zákon č. 40/1964 Zb. Občiansky zákonník v znení neskorších predpisov;",
                personInfo: "„Osobné údaje“",
                personMeans: "znamená údaje týkajúce sa identifikovanej fyzickej osoby alebo identifikovateľnej fyzickej osoby, ktorú možno identifikovať priamo alebo nepriamo, najmä na základe všeobecne použiteľného identifikátora, iného identifikátora, ako je napríklad meno, priezvisko, identifikačné číslo, lokalizačné údaje, alebo online identifikátor, alebo na základe jednej alebo viacerých charakteristík alebo znakov, ktoré tvoria jej fyzickú identitu, fyziologickú identitu, genetickú identitu, psychickú identitu, mentálnu identitu, ekonomickú identitu, kultúrnu identitu alebo sociálnu identitu.",
                user: "„Používateľ“",
                userMeans: "znamená obchodnú spoločnosť zapísanú v Obchodnom registri SR; alebo obchodnú spoločnosť, ktorá bude Službou Aplikácie založená; ktorá používa Aplikáciu za účelom kúpenia Služieb Aplikácie. Používateľ nie je fyzická osoba. Používateľ nie je spotrebiteľ. Používateľ je povinný na vlastnú zodpovednosť zabezpečiť, že Aplikáciu používajú v jeho mene osoby na to oprávnené v zmysle ustanovení § 20 Občianskeho zákonníka;",
                lawDocument: "„Právna časť Dokumentu“",
                lawDocumentMeans: "znamená textové pole Dokumentu vytvorené Prevádzkovateľom a obsahujúce úplný text a/alebo časť textácie o.i. zápisnice z valného zhromaždenia, zmluvy, dohody, vyhlásenia, alebo v Aplikácii inak označeného Dokumentu právne relevantného pre konanie vo veciach obchodného registra podľa Civilného mimosporového poriadku;",
                operator: "„Prevádzkovateľ“",
                operatorMeans: "znamená obchodnú spoločnosť, advokátsku kanceláriu Matejka Friedmannová s. r. o., so sídlom na adrese: Dunajská 48, 811 08 Bratislava – m. č. Staré Mesto, Slovenská republika, IČO: 47 248 998, email: info@legaltechfactory.eu, zapísanú v Obchodnom registri vedenom Mestským súdom Bratislava III, oddiel Sro, vložka č. 90113/B, ktorá je vlastníkom a administrátorom Aplikácie. Prevádzkovateľ v zmysle Nariadenia GDPR a Zákona o ochrane osobných údajov vymedzil účel a prostriedky spracúvania Osobných údajov a spracúva Osobné údaje vo vlastnom mene;",
                appServices: "„Služby Aplikácie“",
                appServicesMeans: "znamená spoločné označenie pre jednorazové poskytnutie právnej služby Používateľovi, ktorú Prevádzkovateľ poskytuje Používateľovi podľa Zákona o advokácii, za podmienok dojednaných v Zmluve o poskytnutí služieb a výlučne len použitím Aplikácie, a obsahom ktorej [právnej služby] je ",
                appServicesMeansOne: "spísanie listín na zmenu údajov zapísaných Používateľovi v Obchodnom registri SR podľa požiadaviek Používateľa zadaných v Aplikácii Dokončením objednávky a/alebo spísanie listín na založenie Používateľa podľa požiadaviek Používateľa zadaných v Aplikácii Dokončením objednávky, a",
                appServicesMeansTwo: "vyhotovenie Dokumentov, a",
                documentGeneration: "Vygenerovanie Dokumentov, a",
                deliverGeneratedDocuments: "Doručenie Vygenerovaných Dokumentov Používateľovi;",
                consumer: "„Spotrebiteľ“ ",
                consumerMeans: "znamená fyzická osoba, ktorá pri používaní Aplikácie nekoná v rámci predmetu svojej obchodnej činnosti alebo inej podnikateľskej činnosti.",
                dataByUser: "„Údaje vyplnené Používateľom“",
                dataByUserMeans: "znamená textové pole Dokumentu vytvorené Používateľom v používateľskom rozhraní Aplikácie;",
                generatedDocument: "„Vygenerovaný Dokument“",
                generatedDocumentsMeans: "znamená needitovateľný súbor formátu „pdf“, „docx“, „odt“, „rtf“ a/alebo iného bežne používaného formátu s obsahom textovo totožným obsahu Dokumentu v okamihu Dokončenia objednávky;",
                advocacyLaw: "„Zákon o advokácii“",
                advocacyLawMeans: "znamená zákon č. 586/2003 Z. z. o advokácii a o zmene a doplnení zákona č. 455/1991 Zb. o živnostenskom podnikaní (živnostenský zákon) v znení neskorších predpisov;",
                eGoverment: "„Zákon o e-Governmente“",
                eGovermentMeans: "znamená zákon č. 305/2013 Z. z. o elektronickej podobe výkonu pôsobnosti orgánov verejnej moci a o zmene a doplnení niektorých zákonov (zákon o e-Governmente) v znení neskorších predpisov;",
                userDataLaw: "„Zákon o ochrane osobných údajov“",
                userDataLawMeans: "znamená zákon č, 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov;",
                userDevice: "„Zariadenie Používateľa“",
                userDeviceMeans: "znamená akékoľvek zariadenie, najmä desktop PC, laptop, notebook, smart-phone a iné zariadenie, použitím ktorého Používateľ používa Aplikáciu;",
                userRepresentation: "„Zastupovanie Používateľa“",
                userRepresentationOne: "znamená právne zastupovanie Používateľa podľa príslušných ustanovení Civilného mimosporového poriadku, podľa Zákona o advokácii a podľa ostatných príslušných právnych predpisov právne záväzných v Slovenskej republike, na základe osobitného písomného plnomocenstva udeleného Prevádzkovateľovi, ",
                userRepresentationTwo: "v konaní o zápis zmeny/zmien údajov zapísaných o Používateľovi v Obchodnom registri SR, výlučne však len v rozsahu Služieb Aplikácie poskytnutých Používateľovi spôsobom Doručenia Vygenerovaných Dokumentov a/alebo ",
                userRepresentationThree: "v konaní o prvozápis Používateľa do Obchodného registra SR, výlučne však len v rozsahu Služieb Aplikácie poskytnutých Používateľovi spôsobom Doručenia Vygenerovaných Dokumentov. Prevádzkovateľ je povinný uskutočniť Zastupovanie Používateľa len ak sú kumulatívne splnené všetky následovné podmienky:",
                printDocuments: "Používateľ vytlačil všetky Vygenerované Dokumenty a nespravil do ich textu žiaden zásah a zabezpečil na nich podpisy konajúcich osôb tak, ako to vyplýva z Vygenerovaných Dokumentov,",
                allDocuments: "Používateľ všetky dokumenty podľa bodu 26.1. týchto VOP do 25 dní od Doručenia Vygenerovaných Dokumentov doručil Prevádzkovateľovi, a to ",
                allDocumentsOne: "formou originálu jedného rovnopisu každého Vygenerovaného Dokumentu v listinnej podobe a doručeného Prevádzkovateľovi na adresu sídla Prevádzkovateľa, alebo",
                allDocumentsTwo: "formou elektronického dokumentu podpísaného kvalifikovaným elektronickým podpisom [KEP] a doručeného do elektronickej schránky Prevádzkovateľa zriadenej na Ústrednom portáli verejnej správy na adrese www.slovensko.sk podľa Zákona o e-Governmente.",
                finishOrderLaw: "Používateľ Dokončením objednávky udeľuje Prevádzkovateľovi výslovný, neodvolateľný, nepodmienený, nemenný, časovo ani inak neobmedzený pokyn, aby Prevádzkovateľ pri Zastupovaní Používateľa neuskutočnil žiadnu kontrolu správnosti/úplnosti/pravdivosti Údajov vyplnených Používateľom a aby Prevádzkovateľ neuskutočnil žiadnu kontrolu úplnosti dokumentov požadovaných právnymi predpismi na Zastupovanie Používateľa.",
                userPresentation: "Prevádzkovateľ uskutoční Zastupovanie Používateľa ",
                recordChanges: "konaní o zápis zmeny/zmien údajov zapísaných o Používateľovi v Obchodnom registri SR podaním elektronického návrhu na príslušný registrový súd alebo ",
                recordChangesOne: " v konaní o ohlásenie živnostenských oprávnení a zápis zmien údajov zapísaných o Používateľovi v Obchodnom registri SR na základe ustanovení Smernice 2006/123/ES o službách na vnútornom trhu podaním elektronického návrhu na elektronické jednotné kontaktné miesto v Slovenskej republike alebo ",
                recordChangesTwo: "v konaní o ohlásenie živnostenských oprávnení a prvozápis Používateľa v Obchodnom registri SR na základe ustanovení Smernice 2006/123/ES o službách na vnútornom trhu podaním elektronického návrhu na elektronické jednotné kontaktné miesto v Slovenskej republike, a ",
                recordChangesThree: "doručením rozhodnutia vydaného v konaní podľa predchádzajúcich písm. (a), (b) alebo (c) tejto vety na e-mailovú adresu zadanú Používateľom v objednávkovom formulári Aplikácie;",
                changesContract: "„Zmluva o poskytnutí služieb“ ",
                changesContractMeans: "znamená písomnú zmluvu elektronicky uzatvorenú v Aplikácii medzi Používateľom a Prevádzkovateľom, na základe ktorej sa Prevádzkovateľ zaväzuje poskytnúť Používateľovi Služby Aplikácie a Používateľ sa zaväzuje zaplatiť Prevádzkovateľovi Cenu. Zmluva o poskytnutí služieb upravuje práva a povinností Prevádzkovateľa a Používateľa týkajúce sa poskytnutia Služieb Aplikácie;",
                articleNumberTwo: "Článok II.",
                rulesOfApp: "Pravidlá a podmienky používania Aplikácie",
                thisVopEdits: "Tieto VOP upravujú ",
                rulesOfUse: "podmienky a pravidlá používania Aplikácie a ",
                lawBetween: " vzájomné práva a povinnosti medzi Prevádzkovateľom a Používateľom založené Zmluvou o poskytnutí služieb.",
                appNotFor: "Aplikácia nie je určená pre Spotrebiteľa. Spotrebiteľovi sa zakazuje používať Aplikáciu.",
                flawless: "Prevádzkovateľ nikomu negarantuje nepretržitú funkčnosť Aplikácie a ani bezchybnú činnosť Aplikácie.",
                operatorIsNot: "Prevádzkovateľ nie je povinný poskytovať Služby Aplikácie okamžite, nepretržite alebo bez výpadkov.",
                everybodyUse: "Každý, kto používa Aplikáciu, má povinnosti Používateľa vyplývajúce z týchto VOP a z právnych predpisov právne záväzných v Slovenskej republike.",
                everybodyHaveTo: "Každý je povinný pri používaní Aplikácie dodržiavať povinnosti Používateľa vyplývajúce z týchto VOP a z právnych predpisov právne záväzných v Slovenskej republike.",
                everybodyCould: "Každý je oprávnený používať Aplikáciu výlučne len v používateľskom rozhraní Aplikácie.",
                onlyAdmin: "Prevádzkovateľ zabezpečuje admin rozhranie Aplikácie proti náhodnému vstupu/preniknutiu tretích osôb; do admin rozhrania Aplikácie má prístup len IT admin.",
                nobodyBesidesAdmin: "Všetkým sa zakazuje vstupovať do admin rozhrania Aplikácie.",
                appPurpose: "Účelom Aplikácie je výlučne len uzatvorenie Zmluvy o poskytnutí služieb, poskytovanie Služieb Aplikácie a zabezpečenie zaplatenia Ceny za poskytnutie Služieb Aplikácie.",
                appOnlyForSale: "Používateľ je povinný používať Aplikáciu len za účelom kúpenia Služieb Aplikácie a zaplatenia Ceny za poskytnutie Služieb Aplikácie.",
                userUsingApp: "Používateľ a osoba konajúca v mene Používateľa pri používaní Aplikácie, sú v plnom rozsahu a bez akýchkoľvek obmedzení, spoločne a nerozdielne zodpovední Prevádzkovateľovi za škodu spôsobenú porušením povinností Používateľa vyplývajúcich z týchto VOP.",
                userAccept: "Používateľ používaním Aplikácie tieto VOP akceptuje, zaväzuje sa nimi riadiť a zaväzuje sa korektne dodržiavať všetky povinnosti vyplývajúce Používateľovi z týchto VOP.",
                usingAppToBuy: "Používanie Aplikácie, kúpenie Služieb Aplikácie a zaplatenie Ceny za poskytnutie Služieb Aplikácie, sú podmienené prístupom na internet. Prístup na internet nie je súčasťou Aplikácie ani súčasťou Služieb Aplikácie.",
                operatorCan: "Prevádzkovateľ nie je zodpovedný za žiadne obmedzenia používania Aplikácie, za žiadne vady Služieb Aplikácie a za žiadne vady platenia Ceny za poskytnutie Služieb Aplikácie, ktoré sú spôsobené nestálosťou internetového pripojenia Používateľa a/alebo akoukoľvek inou technickou nedostatočnosťou internetového pripojenia Používateľa a/alebo akoukoľvek technickou nedostatočnosťou Zariadenia Používateľa a/alebo Aplikáciou.",
                operatorDont: "Prevádzkovateľ nie je zodpovedný za žiadne škody spôsobené Používateľovi v súvislosti s používaním Aplikácie ani v súvislosti s poskytovaním Služieb Aplikácie ani v súvislosti s platením Ceny za poskytnutie Služieb Aplikácie, ktoré boli Používateľovi spôsobené nestálosťou internetového pripojenia Používateľa a/alebo akoukoľvek inou technickou nedostatočnosťou internetového pripojenia Používateľa a/alebo akoukoľvek technickou nedostatočnosťou Zariadenia Používateľa a/alebo Aplikáciou.",
                operatorAndRisk: "Prevádzkovateľ nikomu nezodpovedá za žiadnu nepriamu, náhodnú, následnú, mimoriadnu, trestnú a/alebo podobnú škodu, vrátane [najmä, nie však výlučne] zodpovednosti za stratu príjmu, stratu zisku, stratu dát alebo prevádzkové poruchy (prerušenie prevádzky) alebo za iné nehmotné straty (i keď takéto straty sú kvalifikované), ktoré vzniknú z/alebo akokoľvek súvisia s ",
                withThisVOP: "týmito VOP a/alebo",
                appService: "Službami Aplikácie a/alebo",
                appForContract: "Aplikáciou, či už na základe Zmluvy o poskytnutí služieb, úmyselného porušenia práva alebo na základe iného právneho titulu a bez ohľadu na to, či Prevádzkovateľ upozornil Používateľa [resp. či Používateľ bol upozornený] na možnosť takejto škody.",
                appPrice: "Používateľ používaním Aplikácie konkludentne ubezpečuje Prevádzkovateľa, že Používateľovi nevznikajú žiadne náklady vyvolané používaním Aplikácie, najmä žiadne osobitné náklady za použitie komunikačných prostriedkov na diaľku.",
                userRespects: "Používateľ je pri používaní Aplikácie povinný rešpektovať práva a oprávnené záujmy Prevádzkovateľa, najmä pri nakladaní s autorskými dielami a inými predmetmi práva duševného vlastníctva.",
                userCant: "Používateľovi sa zakazuje používať Aplikáciu takým spôsobom, ktorý spôsobí porušenie Aplikácie a/alebo obmedzenie prevádzky Aplikácie a/alebo obmedzenie funkčnosti Aplikácie.",
                userCantOne: "Používateľovi sa zakazuje akýmkoľvek spôsobom zasahovať do Aplikácie alebo jej častí, meniť, kopírovať a/alebo rozširovať vzhľad, grafické prvky, rozhranie, technický charakter a ďalšie vlastnosti Aplikácie.",
                userCantTwo: "Používateľovi sa zakazuje sprístupňovať Aplikáciu tretím osobám.",
                userCantThree: "Používateľovi sa zakazuje prostredníctvom Aplikácie poskytovať služby tretím osobám.",
                userHaveTo: "Používateľ je povinný zabezpečiť, aby v prípade zneužívania alebo podozrenia zo zneužívania Aplikácie boli vykonané všetky potrebné úkony smerujúce k upusteniu od takéhoto stavu alebo od činností porušujúcich právne predpisy alebo zmluvné dojednania medzi ním a Prevádzkovateľom, a tiež bezodkladne odstrániť všetky následky takýchto činností a zásahov do oprávnených záujmov Prevádzkovateľa; tým nie je dotknutá zodpovednosť Používateľa za škodu spôsobenú Prevádzkovateľovi.",
                userCould: "Používateľ je oprávnený objednať si u Prevádzkovateľa Služby Aplikácie uzatvorením Zmluvy o poskytovaní služieb.",
                isForbidden: "Používateľovi je pri používaní Aplikácie zakázané:",
                useProfanity: "používať vulgarizmy, slovné spojenia a/alebo slovné alebo znakové výrazy, ktorých priamy alebo nepriamy význam je v rozpore so všeobecne akceptovateľnou spoločenskou morálkou a dobrými mravmi,",
                useReservations: "používať vyhrážky a osobné útoky voči Prevádzkovateľovi, iným Používateľom alebo akýmkoľvek tretím osobám,",
                useLies: "uvádzať nepravdivé, neoverené, zavádzajúce, urážlivé alebo klamlivé informácie o inej osobe,",
                useViolence: "propagovať násilie a otvorene alebo skrytou formou podnecovať k nenávisti na základe pohlavia, rasy, farby pleti, jazyka, viery a náboženstva, politického či iného zmýšľania, národného alebo sociálneho pôvodu, príslušnosti k národnosti alebo k etnickej skupine alebo na základe akéhokoľvek iného dôvodu,",
                useWar: "propagovať vojnu alebo opisovať kruté alebo inak neľudské konanie spôsobom, ktorý je ich nevhodným zľahčovaním, ospravedlňovaním alebo schvaľovaním,",
                useAlcohol: "otvorene alebo skrytou formou propagovať alkohol, alkoholizmus, fajčenie, užívanie omamných látok, jedov a prekurzorov alebo zľahčovať následky užívania uvedených látok,",
                useFear: "ohrozovať fyzický, psychický alebo morálny vývin maloletých alebo narúšať ich duševné zdravie a emocionálny stav,",
                useChildPornography: "propagovať detskú pornografiu,",
                useBadTexts: "zverejňovať nezmyselné alebo nezrozumiteľné texty, ako aj vykonávať iné aktivity, ktoré môžu čo i len potenciálne viesť k obmedzeniu alebo zníženiu kvality Aplikácie a/alebo k obmedzeniu alebo zníženiu kvality poskytovaných Služieb Aplikácie,",
                usePrivateInfo: "zverejňovať osobné údaje o iných osobách, najmä ich meno, priezvisko, adresu alebo telefónne číslo, pokiaľ k tomu uvedená osoba neudelila súhlas,",
                shareReservations: "šíriť obscénne, vulgárne a urážlivé materiály a protizákonné materiály, pričom zakázané je aj zverejňovať odkaz na akékoľvek webové stránky s takýmto obsahom,",
                takeUserInfo: "získavať prihlasovacie mená a/alebo heslá iných Používateľov,",
                editApp: "zneužívať, blokovať, modifikovať alebo inak meniť, upravovať akúkoľvek súčasť Aplikácie, alebo sa čo len pokúsiť narušiť stabilitu, integritu, chod alebo dáta Aplikácie,",
                hackApp: "používať mechanizmy, programové vybavenie alebo iné postupy, ktoré by mohli mať negatívny vplyv na prevádzku Aplikácie,",
                shareCompetition: "zverejňovať akékoľvek informácie, ktoré svojím obsahom môžu naplniť znaky nekalej súťaže podľa ustanovenia § 44 – 51 zákona č. 513/1991 Zb. Obchodný zákonník v znení neskorších predpisov,",
                shareCommercial: "zverejňovať akúkoľvek reklamu alebo propagáciu výrobkov, tovarov alebo služieb.",
                articleThree: "Článok III.",
                contractOfProvision: "Zmluva o poskytnutí služieb",
                finishOrderMoment: "Dokončením objednávky nastáva okamih, kedy je medzi Prevádzkovateľom a Používateľom uzatvorená Zmluva o poskytnutí služieb.",
                acceptVOP: "Používateľ vyhlasuje, že sa pred uzatvorením Zmluvy o poskytnutí služieb dôkladne a detailne oboznámil so znením Zmluvy o poskytnutí služieb a so znením týchto VOP.",
                userAcceptContract: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje, že ustanovenia Zmluvy o poskytnutí služieb a ustanovenia VOP sú mu zrozumiteľné a jasné.",
                partOfContractIsVOP: "Neoddeliteľnou súčasťou Zmluvy o poskytnutí služieb sú tieto VOP.",
                contractIsActive: "Zmluva o poskytnutí služieb je platná a záväzná aj pre právnych nástupcov Prevádzkovateľa a pre právnych nástupcov Používateľa.",
                userAcceptActiveContract: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje, že Prevádzkovateľ nie je povinný preveriť ani žiadnym spôsobom skontrolovať údaje uvádzané Používateľom v objednávkovom formulári Aplikácie, najmä Prevádzkovateľ nie je povinný skontrolovať či sa nejedná o neexistujúcu/nefungujúcu/nesprávnu/neplatnú e-mailovú adresu osoby konajúcej v mene Používateľa a/alebo či sa nejedná o neexistujúce/nefungujúce/nesprávne/neplatné mobilné telefónne číslo osoby konajúcej v mene Používateľa.",
                userCloseContract: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje, že Prevádzkovateľ nie je povinný preveriť ani žiadnym spôsobom skontrolovať Údaje vyplnené Používateľom.",
                operatorAccept: "Prevádzkovateľ nikomu negarantuje, že Údaje vyplnené Používateľom je možné zapísať do Obchodného registra SR.",
                operatorInform: "Prevádzkovateľ oznamuje Používateľovi a Používateľ berie na vedomie skutočnosť, že uzatvorením Zmluvy o poskytnutí služieb Používateľ záväzne žiada Prevádzkovateľa o bezodkladné začatie poskytovania Služieb Aplikácie.",
                userAcceptService: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje, že pred uzavretím Zmluvy o poskytnutí služieb ho Prevádzkovateľ prostredníctvom Aplikácie riadne a v dostatočnom rozsahu oboznámil s predpokladaným rozsahom právnych úkonov, ktoré je potrebné uskutočniť na poskytnutie Služieb Aplikácie v rozsahu Používateľovej objednávky.",
                operatorAutomaticaly: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje skutočnosť, že Prevádzkovateľ automaticky začína poskytovať Používateľovi Služby Aplikácie okamihom uzatvorenia Zmluvy o poskytnutí služieb, ešte pred zaplatením Ceny.",
                operatorOnline: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje, že Používateľ využil možnosť online zistiť chybu/nedostatok/nesprávnosť Údajov vyplnených Používateľom ",
                controllData: "prednostne preverením správnosti a úplnosti údajov vyplnených Používateľom v Aplikácii a",
                controllText: "kontrolou textu Dokumentu v používateľskom rozhraní Aplikácie.",
                onlyInApp: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje, že dopĺňanie a/alebo opravovanie chýb/nedostatkov/nesprávností Údajov vyplnených Používateľom, je možné vykonať výlučne len v používateľskom rozhraní Aplikácie pred Dokončením objednávky.",
                editDocument: "Používateľ uzatvorením Zmluvy o poskytnutí služieb konkludentne potvrdzuje, že Vygenerované Dokumenty je z technických dôvodov objektívne nemožné editovať [t.j. najmä meniť, dopĺňať, vymazávať a pod.].",
                incidentsFromContract: "Povinnosť Prevádzkovateľa Doručiť Vygenerované Dokumenty Používateľovi zakotvená v Zmluve o poskytnutí služieb, vyvoláva právne účinky až okamihom zaplatenia Ceny. Používateľ berie na vedomie a výslovne súhlasí s tým, že Prevádzkovateľ nie je povinný Používateľovi Doručiť Vygenerované Dokumenty skôr, než Používateľ zaplatí Prevádzkovateľovi Cenu.",
                goodPrice: "Používateľ potvrdzuje, že Cenu považuje za zodpovedajúcu poskytovaným Službám Aplikácie a že takto dohodnutú Cenu nepovažuje za odporujúcu dobrým mravom.",
                afterOrderFinish: "Po Dokončení objednávky je Používateľ Aplikáciou presmerovaný na zabezpečenú platobnú bránu Aplikácie za účelom zaplatenia Ceny.",
                userAcceptPay: "Používateľ je povinný zaplatiť Prevádzkovateľovi Cenu výlučne len použitím zabezpečenej platobnej brány Aplikácie; použitie iných spôsobov platby nemá za následok splnenie povinnosti Používateľa zaplatiť Prevádzkovateľovi Cenu.",
                threeTimes: "Na zaplatenie Ceny použitím zabezpečenej platobnej brány Aplikácie má Používateľ tri [3] pokusy.",
                afterPay: "Proti pohľadávke Prevádzkovateľa na zaplatenie Ceny nie je možné započítať žiadnu pohľadávku Používateľa voči Prevádzkovateľovi ani žiadnu pohľadávku tretej osoby voči Prevádzkovateľovi.",
                paymentDocument: "Prevádzkovateľ vystaví Používateľovi riadnu faktúru podľa rozsahu poskytnutých Služieb Aplikácie. Súčasťou faktúry je špecifikácia poskytnutých Služieb Aplikácie.",
                serviceTax: "Používateľ týmto vyslovuje v zmysle § 71 ods. 1 písm. b) zákona č. 222/2004 Z. z. o dani z pridanej hodnoty súhlas s vydaním elektronickej faktúry. Faktúra sa doručuje Používateľovi výlučne len elektronicky zaslaním na e-mailovú adresu zadanú Používateľom do objednávkového formulára Aplikácie pri Dokončení objednávky. Prevádzkovateľ odošle Používateľovi faktúru bezodkladne po zaplatení Ceny. Faktúra sa považuje za doručenú Používateľovi okamihom odoslania faktúry na e-mailovú adresu zadanú Používateľom do objednávkového formulára Aplikácie pri Dokončení objednávky. Validné Doručenie faktúry Používateľovi nastane aj vtedy, ak Používateľ v objednávkovom formulári Aplikácie uvedie neexistujúcu/nefungujúcu/nesprávnu/neplatnú e-mailovú adresu.",
                noInterest: "Prevádzkovateľ a Používateľ sa dohodli, že Prevádzkovateľ nie je povinný po skončení poskytovania Služieb Aplikácie odovzdať Používateľovi protokolárne, osobne ani nijak inak spisovú agendu, nakoľko Používateľ má záujem len o Doručenie Vygenerovaných Dokumentov a okrem toho Používateľ nemá žiaden záujem o spisovú agendu.",
                operatorAcceptLaw: "Prevádzkovateľ sa pri poskytovaní Služieb Aplikácie riadi právnymi predpismi právne záväznými v Slovenskej republike a záväznými predpismi a usmerneniami Slovenskej advokátskej komory.",
                contractCancel: "Zmluva o poskytnutí služieb sa automaticky zrušuje márnym uplynutím tretieho pokusu Používateľa o zaplatenie Ceny použitím zabezpečenej platobnej brány Aplikácie; takéto zrušenie Zmluvy o poskytnutí služieb má za následok",
                contractCancelOne: " stratu všetkých údajov vyplnených Používateľom v Aplikácii a ",
                contractCancelTwo: "presmerovanie Používateľa na domovskú stránku Aplikácie.",
                operatorAfterContract: "Nároky Prevádzkovateľa vyplývajúce zo Zmluvy o poskytnutí služieb nezanikajú ani po zániku Zmluvy o poskytnutí služieb.",
                articleNumberFour: "Článok IV.",
                responsibility: "Zodpovednosť za obsah Vygenerovaných Dokumentov",
                makeLawPart: "Prevádzkovateľ je povinný vytvoriť Právnu časť Dokumentu tak, aby v rozsahu Používateľom zvolenej objednávky Služieb Aplikácie, Vygenerované Dokumenty obsahovali všetky podstatné náležitosti právne relevantné pre konanie vo veciach obchodného registra podľa Civilného mimosporového poriadku.",
                deleteOne: "Prevádzkovateľ si vyhradzuje právo kedykoľvek zmazať obsah Údajov vyplnených Požívateľom,",
                deleteTwo: "ktorý porušuje ustanovenia Článku II. bod 30 týchto VOP alebo",
                deleteThree: "pri ktorom je podozrenie, že by čo i len potenciálne mohol porušovať tieto VOP.",
                operatorTakes: "Prevádzkovateľ si vyhradzuje právo:",
                partTime: "dočasne alebo natrvalo, v celom rozsahu alebo čo i len čiastočne prerušiť možnosť zadania Údajov vyplnených Používateľom;",
                anyTime: "kedykoľvek vykonať technickú odstávku Aplikácie, a to aj bez akéhokoľvek predchádzajúceho upozornenia,",
                without: "bez obmedzení vykonávať akékoľvek práva, ktoré Prevádzkovateľovi vyplývajú z týchto VOP alebo z právnych predpisov právne záväzných v Slovenskej republike.",
                userDontTake: "Prevádzkovateľ nezodpovedá za správnosť/pravdivosť/úplnosť Údajov vyplnených Používateľom.",
                userAcceptResponsibility: "Používateľ prijíma výhradnú plnú zodpovednosť za ním uskutočnené konanie pri používaní Aplikácie, najmä pri zvolení, používaní, nastavení a zadaní Údajov vyplnených Používateľom.",
                content: "Zadanie obsahu Údajov vyplnených Používateľom neznamená, že sa Prevádzkovateľ stotožňuje s ich obsahom, správnosťou, pravdivosťou a/alebo úplnosťou.",
                artivleNumberFive: "Článok V.",
                lawOfAuthor: "Právo duševného vlastníctva",
                author: "Aplikácia je autorským dielom, ku ktorému majetkové práva vykonáva Prevádzkovateľ a je chránená predovšetkým Autorským zákonom a ďalšími príslušnými právnymi predpismi právne záväznými v Slovenskej republike.",
                operatorProperty: "Prevádzkovateľ má všetky majetkové práva k Aplikácii a výlučne Prevádzkovateľ je oprávnený poskytnúť súhlas/licenciu na používanie Aplikácie. Podmienky používania Aplikácie určuje, vydáva a mení výhradne Prevádzkovateľ.",
                operatorCanUse: "Prevádzkovateľ umožňuje Používateľovi používanie Aplikácie výlučne len pre osobné a nekomerčné potreby Používateľa. Akceptáciou týchto VOP Používateľ súhlasí, že nebude používať žiadne Služby Aplikácie za iným účelom ako je uvedený v predchádzajúcej vete. Doručením Vygenerovaných Dokumentov končí oprávnenie Používateľa na používanie Aplikácie.",
                cantAnalyse: "Používateľ nie je oprávnený Aplikáciu analyzovať, meniť, upravovať a dopĺňať, rozmnožovať, spracovávať, upravovať, distribuovať, vyhotovovať preklad Aplikácie zo strojového kódu do zdrojového jazyka, ani voľne modifikovať, ani adaptovať Aplikáciu podľa svojej potreby, a to ani prostredníctvom tretích osôb.",
                noCopy: "Používateľ nie je oprávnený vyhotoviť záložnú rozmnoženinu Aplikácie, a to ani prostredníctvom tretích osôb, nakoľko takáto rozmnoženina Aplikácie nie je potrebná pre fungovanie Aplikácie ani pre používanie Aplikácie.",
                cantTest: "Používateľ nie je oprávnený preskúmať ani testovať funkčnosť Aplikácie za účelom zistenia princípov, na ktorých bola Aplikácia založená a vytvorená, ani za účelom zistenia princípov, na ktorých bol založený a vytvorený ktorýkoľvek prvok Aplikácie. Porušenie VOP podľa tohto bodu Používateľom je dôvodom na okamžité zablokovanie Používateľa v Aplikácii, s čím Používateľ vyjadruje svoj výslovný a bezvýhradný súhlas. Nárok Prevádzkovateľa na náhradu škody voči Používateľovi nie je týmto dotknutý.",
                cantOwn: "Používateľ využívaním Aplikácie nenadobúda vlastnícke právo, ani žiadne majetkové ani osobnostné práva k Aplikácii, softvéru, ani k žiadnej ich súčasti.",
                userAndOperator: "Používateľ a Prevádzkovateľ sa týmto dohodli,",
                operatorOwn: "že akékoľvek údaje vložené Používateľom do databázy obsiahnutej v Aplikácii sú časťou databázy zhromaždenej Prevádzkovateľom a",
                databaseOwner: "že zriaďovateľom databázy je výhradne Prevádzkovateľ. Používateľovi nevzniká žiadne právo k databáze obsiahnutej v Aplikácii.",
                articleNumber: "Článok VI.",
                finalDecision: "Záverečné ustanovenia",
                userAgree: "Používateľ súhlasí ",
                userAgreeOne: " so spracovaním osobných údajov v rozsahu podľa Súhlasu so spracovaním osobných údajov a ",
                userAgreeTwo: "so spracovaním súborov cookies v rozsahu podľa Súhlasu so spracovaním súborov cookies.",
                lawOfState: "Tieto VOP sa riadia právnym poriadkom Slovenskej republiky. Právny vzťah medzi Prevádzkovateľom a Používateľom sa riadi právom Slovenskej republiky, predovšetkým Obchodným zákonníkom. Prípadné spory medzi Prevádzkovateľom a Používateľom budú rozhodované vecne a miestne príslušným súdom podľa sídla Prevádzkovateľa.",
                changeVOP: "Prevádzkovateľ si vyhradzuje právo na zmenu týchto VOP. Zmena VOP je platná a účinná dňom uvedeným vo VOP. Prevádzkovateľ zverejní nové VOP umiestnením na Internetovej stránke.",
                userAndOperatorAccept: "Prevádzkovateľ a používateľ sa dohodli, že plne uznávajú elektronickú formu komunikácie prostredníctvom elektronickej pošty a internetovej siete ako platnú a záväznú pre obe zmluvné strany."
            },
            VotingImputBox: {
                voteYes: "Für",
                votingMaybe: "Enthalten",
                voteNo: "Dagegen",
                numberOfVotes: "Die Anzahl der Stimmen ",
                forChange: "Wenn dieser Aktionär nicht an der Abstimmung zu diesem Tagesordnungspunkt teilgenommen hat, deaktivieren Sie zunächst die Option 'Alle Aktionäre waren bei der Hauptversammlung anwesend', die Sie im einleitenden zweiten Abschnitt mit dem Titel 'Welche Aktionäre haben an der Hauptversammlung teilgenommen?'",
                forChangeTwo: "Wenn dieser Aktionär nicht für die Beschlussfassung zu diesem Tagesordnungspunkt gestimmt hat, deaktivieren Sie zunächst die Option 'Alle Aktionäre haben für die Zustimmung zu allen Tagesordnungspunkten gestimmt' in der Einleitung unter dem zweiten Abschnitt 'Welche Aktionäre haben an der Hauptversammlung teilgenommen?'",
                here: "Anwesend",
                notImportant: "Seine Stimmen werden nicht berücksichtigt",
                voteCanOnly: "Nur der Aktionär, der an der Abstimmung teilgenommen hat, kann über diesen Tagesordnungspunkt abstimmen",
                forVote: "Damit ein Aktionär abstimmen kann, müssen Sie im einleitenden zweiten Abschnitt mit der Überschrift 'Welche Aktionäre haben an der Versammlung teilgenommen?' angeben, wie viele Stimmen in % auf diesen Aktionär entfallen",
                forVoteInput: "Damit ein Aktionär abstimmen kann, müssen Sie im einleitenden 2. Abschnitt mit der Überschrift 'Welche Aktionäre haben an der Hauptversammlung teilgenommen?' angeben, wie viele Stimmen in % auf diesen Aktionär entfallen",
                voting: "Abstimmen",
                votingCount: "% der Stimmen.",
                accepted: "Der Beschluss wurde angenommen",
                border: "Um eine Hauptversammlung abzuhalten, benötigen Sie ,Prozente: für die Annahme benötigen Sie ,hierPersonen: % der Stimmen der anwesenden Mitglieder.",
                percents: "Für die Genehmigung benötigen Sie:",
                herePeople: "% der Stimmen der anwesenden Partner.",
                allPeople: "% der Stimmen aller Aktionäre",
                noPeople: "Keine Gesellschafter",
                notRecognizable: "Die Generalversammlung ist nicht beschlussfähig",
            },
            ZivnostNewBox: {
                remove: "Odobrať",
                enterRange: "Geben Sie dieses Gewerbe so an, wie es in dem  Nachweis der beruflichen Qualifikation angegeben ist,",
                range: "Überschreiben Sie den Umfang dieses Gewerbes aus dem Nachweis der beruflichen Qualifikation,",
                activity: "Der Name des hinzugefügten Unternehmensgegenstands ist,",
                activityPlaceholder: "Schreiben Sie den genauen Namen,",
                choose: "Ich möchte ein Gewerbe hinzufügen,",
                chooseButton: "Wählen Sie aus der Liste,",
                orEnter: "Ich möchte kein Gewerbe hinzufügen,",
                add: "Schreiben Sie den Unternehmensgegenstand,",
                chooseAcitivity: "Wählen Sie aus der Liste der vom Gewerbeamt registrierten Gewerbescheine,",
            },

            CheckouFormBox: {
                invoiceDocs: "Informationen zur Rechnungsstellung",
                makeInvoice: "Ich möchte einem anderen Unternehmen eine Rechnung stellen",
                company: "Firmenname",
                companyAdress: "Adresse des eingetragenen Firmensitzes",
                dic: "STEUERIDENTIFIKATIONSNUMMER",
                icdph: "UMSATZSTEUER-IDENTIFIKATIONSNUMMER",
                contactData: "Kontaktangaben",
                nameSurname: "Vor- und Nachname",
                enterValidMail: 'Bitte geben Sie eine gültige E-Mail ein.',
                phone: 'Telefonnummer',
                enterValidPhone: 'Bitte geben Sie eine gültige Telefonnummer ein',
                docs: "Dokumentation , moenyFor: Vergütung für die Erstellung der Dokumentation",
                moenyFor: "Vergütung für die Erstellung der Dokumentation",
                representing: "Repräsentation",
                moneyForRepresenting: "Vergütung für Repräsentation",
                withoutWat: "Gesamtbetrag ohne Mehrwertsteuer",
                with20Wat: "MEHRWERTSTEUER 20%",
                fees: "Gerichts- und Verwaltungsgebühren",
                courtFee: "Gerichtsgebühr",
                sendingFee: "Gutgeschriebener Betrag",
                finalAmount: "Endbetrag",
                acceptTips: "Ich akzeptiere den Rat und die Empfehlungen des Anwalts",
                iaccept: "Ich stimme zu",
                universalRules: "Allgemeine Geschäftsbedingungen",
                aboutContract: "Dienstleistungsvertrag",
                campaignAgreement: "Versenden von Marketingangeboten",
                finishOrder: "Vollständige Bestellung mit Zahlung erforderlich",
                enterPassword: "Passwort eingeben, um sich zu registrieren oder anzumelden",
                weakPassword: "Das Passwort muss mindestens einen Groß- und Kleinbuchstaben, mindestens eine Ziffer und mindestens ein Sonderzeichen '!@#$%^&*)(+=._-'', enthalten.",
                cognitoErrorUserExists: " Der Benutzer mit diesem Namen existiert bereits, verwenden Sie eine andere E-Mail.",
            },
            LoadOrderModalBox: {
                password: "Formular-Passwort",
                loadContent: "Formular laden",

            },
            LoginModalBox: {
                loading: "Laden ...",
                loginRegister: "Profil anmelden",
                intoPartnesProgram: "Partnerprogramm",
                login: "Anmelden",
                register: "Registrieren",
                forgot: "Ich habe mein Passwort vergessen",
                operator: "Portal betrieben von",
                youCanPayWith: "Auf dieser Seite können Sie sicher über , Regeln bezahlen: Allgemeine Geschäftsbedingungen",
                rules: "Allgemeine Geschäftsbedingungen",
                gdpr: "Datenschutzbestimmungen",
                emailControll: "Ihre E-Mail wurde nicht verifiziert, geben Sie zur Verifizierung den Code ein, den Sie nach der Registrierung per E-Mail erhalten haben",
                controlCode: "Verifizierungscode",
                controll: "Überprüfen",
                resendEmail: "Klicken Sie hier, um die Verifizierungs-E-Mail erneut zu senden",
                resend: "Erneut senden",
                forNewPass: "Um Ihr Passwort zurückzusetzen, geben Sie die E-Mail ein, die",
                youUsed: "die Sie bei der Registrierung verwendet haben",
                yourMail: "Ihre E-Mail",
                userName: 'Benutzername',
                sendCode: 'Authentifizierungscode senden',
                codeSms: "Um sich zu authentifizieren, geben Sie den Code ein, den Sie per SMS erhalten haben",
                resendSms: "Um die Bestätigungs-SMS erneut zu senden, klicken Sie hier",
                reControl: "Erneut senden",
                logOut: "Abmelden",
                myOrders: " Meine Bestellungen",
                password: 'Passwort'


            },
            OrderStatusFormBox: {
                orderStatus: "Status der Bestellung",
                dateOfOrder: "Datum der Lieferung",
                smallCompany: "Handel",
                numberOf: "Einreichungsnummer",
                recieveDate: "Datum der Einreichung",
                lawBuilding: "Amt",
                chooseOffice: "Handelsamt auswählen",
                court: "Gericht",
                chooseCourt: "Gericht auswählen",
                dateOfUrgent: "Datum des Drängens",
                dateOfWrite: "Datum der Eintragung",
                saveOrder: 'Bestellung speichern',
                addFiles: 'Dateien hinzufügen',
                addDateOfWrite: 'Eintragungsdatum',

            },

            PasswordResetFormBox: {
                resetPassword: "Passwort zurücksetzen",
                authCode: "Bestätigungscode (aus E-Mail oder SMS)",
                password: "Neues Passwort eingeben",
                repeatPassword: "Neues Kennwort wiederholen",
                passwordArentSame: "Passwörter stimmen nicht überein",

            },
            SaveOrderModalBox: {
                saveOrder: "Dokument in Bearbeitung speichern",
                pleaseSave: "Bitte geben Sie eine E-Mail-Adresse an, an die wir einen Link zu Ihrer aktuellen Arbeit senden",
                youCanComeBack: "Sie können jederzeit darauf zurückkommen - einfach und bequem",
                yourMail: "Ihre E-Mail",
                yourSavedDoc: "Ihre laufende Arbeit ist rechtzeitig gespeichert worden",
                updateDocs: "und wird aktualisiert, sobald Sie das Fenster verlassen. Klicken Sie unten, um Ihre Daten sofort zu aktualisieren",
                save: "Speichern",
            },
            StepperBox: {
                flowOfMeet: "Názvy dokumentov",
                neededToBeFill: "Was noch ausgefüllt werden muss",
                viewOfDocs: "Vorschau auf kommende Dokumente",
                firstStep: 'Vypĺňate údaje online',
                secondStep: 'Zaplatíte objednávku',
                thirdStep: 'Email s dokumentami',
                fourthStep: 'Podpíšte online'
            },
            UserProfileBox: {
                welcomeToProgram: "Danke, dass Sie bei uns sind!",
                benefits: "Willkommen in Ihrem Profil",
                subtitle: "Wenn Sie sich registrieren und ein Profil erstellen, können Sie von unserem Partnerprogramm profitieren",
                fastView: "Sie haben immer einen detaillierten Überblick über die getätigten Bestellungen",
                getAcces: "einfach eine neue Bestellung erstellen",
                specialOffers: "Sonderangebote",
                accept: "Ich verstehe",
                logOut: "Abbestellen",
                settings: "Profil-Einstellungen",
                changesInSro: "Gründung und Änderungen der s.r.o.",
                homePage: "Startseite",
                actions: "Aktuelle Ereignisse",
                myOrders: "Meine Bestellungen",
                progress: "Verfolgen Sie Ihre Bestellungen",
                mySettingsTitle: "Ihre Einstellungen bearbeiten",
                mySettings: "Meine Einstellungen",
                showProfileTour: "Profiltour ansehen",
                myPersons: "Meine Leute",
                actionsNow: "Aktuelle Aktionen",
                useActions: "Profitieren Sie von unseren Sonderangeboten",
                specialPrice: "KONTEN JETZT FÜR NUR 14,90€",
                weCanHelp: "Wir helfen Ihnen, die Genehmigung Ihres Jahresabschlusses so einfach wie möglich zu gestalten, ohne dass Sie Strafen befürchten müssen",
                OrderDocs: "Alles, was Sie tun müssen, ist, die für die Genehmigung des Jahresabschlusses erforderlichen Dokumente bei uns zu bestellen, und innerhalb von 5 Minuten haben Sie alles, was Sie brauchen, in Ihrer E-Mail",
                offerEnds: "Das Angebot ist bis zum 31. August 2023 gültig",
                iWantDocs: "Ich möchte die Jahresabschlüsse",
                videoBlog: "Video-Anleitung",
                preparing: "Wir bereiten vor ... ",

            },
            UserPersonsBox: {
                title: "Meine Personen.",
                menuTitle: "Meine Personen",
            },
            UserProfileSettingsBox: {
                given_name: "Name",
                given_name_input: "Name eingeben",
                family_name: "Nachname",
                family_name_input: "Nachname eingeben",
                email: "E-Mail",
                email_input: "E-Mail eingeben",
                phone_number: "Telefonnummer",
                phone_number_input: "Telefonnummer eingeben",
                marketing_suhlas: "Ich bin damit einverstanden, Marketingangebote zu erhalten",
                unknownUser: "Unbekannter Benutzer",
                modalTitleGetBenefits: "NACH DER ZUSTIMMUNG, PROFITIEREN SIE VON DEN VORTEILEN",
                modalTitleGetBenefitsThanks: "Vielen Dank für Ihre Zustimmung, die Sie nach 30 Tagen jederzeit widerrufen können.",
                passwordChange: 'Passwortänderung',
                saveSettings: 'Einstellungen speichern',
                verifyPhoneNumber: 'Überprüfen Sie die Telefonnummer',
                verifyPhoneNumberFirst: 'Überprüfen Sie zuerst die Telefonnummer',
            },
            PersonList: {
                createNewPerson: "Neue Person",
                name: "Der Name der Person",
                surname: "Der Nachname der Person",
                address: "Adresse",
                enterNameAndSurname: 'Vor- oder Nachname eingeben'
            },
            PersonEditBox: {
                personEditModalTitle: "Osobné údaje fyzickej osoby, ktorú chcem uložiť do šablóny",
                titlesBefore: "Titel vor dem Namen einer Person sind",
                titlesAfter: "Titel nach dem Namen einer Person sind",
                name: "Der Name einer Person ist",
                surname: "Der Nachname einer Person ist",
                idType: "Dokumenttyp",
                documentId: "Dokumentennummer",
                dateOfBirth: "Geburtsdatum auswählen",
                documentType: "Hat die Person eine Geburtsnummer?",
                idNumber: 'Geburtsnummer/Kennzeichen der Person ist',
                note: "Notiz"
            },

            UserRegistrationBox: {
                surname: "Nachname",
                telephone: "Telefonnummer",
                pleaseEnterFullNumber: "Bitte geben Sie eine gültige Telefonnummer ein.",
                iAgree: "Ich bin damit einverstanden, Marketingangebote zu erhalten",
                passwordTooWeak: 'zu schwach',
                passwordWeak: 'schwach',
                passwordGood: 'gut',
                passwordStrong: 'stark',
                passwordTooStrong: 'zu stark',
                passwordShort: 'kurz',
                stepBack: 'Zurücktreten',
                noPartnerWarning: "Vybraný subjekt nie je advokát, notár ani účtovník vyberte iný subjekt."
            },
            UserOrderBox: {
                close: "Close",
                open: "Open",
                download: "Download",
                created: "Created",
                state: "Status",
                numberInOffice: "Filing Office Number",
                dateInOffice: "Date of Office Filing",
                office: "Office",
                addedToOffice: 'Registry Office',
                dateOnCourt: "Date of filing with the court",
                court: "Court",
                addedToCourt: "Court Registry",
                urgentToOffice: "Urged Office",
                urgentToCourt: "Urgent court",
                writtenToOffice: "Entered Office",
                writtenToCourt: "Registered Court",

            },
            OrderListBox: {
                nameOfSro: "Titel s.r.o.",
                ico: "ID NUMBER",
                feedBack: 'Bewertung',
                identifier: 'Kennung',
                stateOfOrder: 'Auftragsstatus',
                stateOfPay: 'Zahlungsstatus',
                priceOfOrder: 'Bestellsumme',
                dateOfCreate: 'Erstellungsdatum',
            },
            UserListBox: {
                username: "Cognito meno",
                userstatus: "Stav užívateľa",
                given_name: "Krstné meno",
                family_name: "Priezvisko",
                email: "Email",
                email_verified: "Email overený",
                phone_number: "Telefónne číslo",
                phone_number_verified: "Telefónne číslo overené",
                custom_marketing_suhlas: "Marketingový súhlas",
                actions: "Operácie",
            },
            FeedbackListBox: {
                companyName: "Názov obce",
                contactName: "Kontakt meno",
                contactEmail: "Kontakt email",
                ratingValue: "Hodnotenie",
                ratingMessage: "Správa",
                ratingDate: "Dátum hodnotenia",
            },
            FeedbackListBox: {
                companyName: "Title s.r.o.",
                contactName: "Contact name",
                contactEmail: "Contact email",
                ratingValue: "Rating",
                ratingMessage: "Message",
                ratingDate: "Rating Date",
            },
            ErrorPageBox: {
                cantDo: "Die ausgewählte Aktion kann leider nicht durchgeführt werden",
                chooseAnotherCompany: "Wählen Sie ein anderes Unternehmen",
            },

            FrontOfficeBox: {
                cleanigDay: "wird eine geplante Wartung des Portals stattfinden. Vielen Dank für Ihr Verständnis | ORSR.Help Team",
                day: "Auf",

            },

            BaseInvestmentRaiseBox: {
                newInput: "Die Zusagen für neue Einlagen werden übernommen,",
                add: "hinzufügen",
                ico: "Firmen-Identifikationsnummer",
                birthID: "Geburtsnummer,",
                idData: "Identifizierungsangabe,",
                enter: "Geben Sie an",
                owner: "Eigentümer",
                birthIdOrId: "Geburtsnummer oder Identifizierungsangabe,",
                icoOrId: "Firmen-Identifikationsnummer oder Identifizierungsangabe,",
                chooseOwnerOne: "Wählen Sie einen Gesellschafter,",
                coOwner: "Gesellschafter,",
                chooseBirthDate: "Wählen Sie das Geburtsdatum,",
                chooseOwner: "Das Gesellschafter hat",
                orData: "oder andere Identifizierungsangabe?,",
                localCourt: "Bezirksgericht,",
                birthDate: "Geburtsdatum,",
                enterLocalCourt: "Geben Sie das Bezirksgericht an,",
                part: "Abteil",
                insert: "Einlage,",
                enterPart: "Abteil angeben,",
                enterInsert: "Einlage angeben,",
                remove: "entfernen,",
                enterRepresentMan: "einen Vertreter hinzufügen,",
                enterAmount: "Die Verbindlichkeit für die neue Einlage in das Stammkapital beläuft sich auf",
                enterAmountEUR: "Nach der Erhöhung beträgt der Beitrag des Gesellschafters zum Stammkapital €,",
                represent: "Bevollmächtigter,",
                enterFunc: "schreiben Sie seine Funktion,",
                func: "Funktion,",
                enterName: "schreiben Sie den Namen,",
                nameTitleSurname: "Titel, Vorname, Nachname",
            },
            BaseInvestmentRaiseFromProfitComponentBox: {
                add: "Hinzufügen,",
                partsOfOwners: "Die Anteile der Gesellschaftere am Stammkapital werden wie folgt geändert,",
                chooseOwner: "Wählen Sie einen Gesellschafter,",
                owner: "Gesellschafter,",
                ofOwner: "Eigentümer,",
                ico: "Firmen-Identifikationsnummer",
                birthIdOrId: "Geburtsnummer oder Identifizierungsangabe,",
                icoOrId: "Firmen-Identifikationsnummer oder Identifizierungsangabe,",
                newAmount: "Neue Anteilshöhe in EUR eingeben,",
                idData: "Identifizierungsangabe,",
                birthId: "Geburtsnummer,",
                enter: "Geben Sie an",
                chooseIfOwner: "Das Gesellschafter hat",
                addRepresentMan: "einen Vertreter hinzufügen,",
                orData: "oder andere Identifizierungsangabe?,",
                dateOfBirth: "Geburtsdatum,",
                enterLocalCourt: "Geben Sie das Bezirksgericht an,",
                localCourt: "Bezirksgericht,",
                enterPart: "Abteil angeben",
                part: "Abteil ",
                enterInsert: "Einlage angeben",
                insert: "Einlage",
                remove: "Entfernen,",
                representMan: "Bevollmächtigter",
                enterFunc: "schreiben Sie seine Funktion,",
                func: "Funktion,",
                enterName: "schreiben Sie den Namen,",
                nameTitle: "Titel, Vorname, Nachname,",
            },
            DepositAdministratorBox: {
                chooseAdmin: "Wer ist der Verwahrer der Einlagen?",
                chooseAdminTooltip: "Wählen Sie den Gesellschafter, der die Einlagen der Gesellschafter auf das Stammkapital der Gesellschaft verwaltet, bis die Gesellschaft in das Handelsregister eingetragen ist.",
                chooseOwner: "wählen Sie einen Gesellschafter",
                enteBusinessName: "Firmenname eingeben",
                businessName: "Firmenname",
                businessAdress: "Sitz eingeben,",
                streetPSC: "Straße Nr., Postleitzahl, Ort",
                chooseCountry: "Wählen Sie ein Land",
                country: "Land",
                ico: "Firmen-Identifikationsnummer",
                representMan: "Bevollmächtigter",
                enterFunc: "schreiben Sie seine Funktion,",
                func: "Funktion,",
                enterName: "schreiben Sie den Namen,",
                nameTitle: "Titel, Vorname, Nachname,",
                enterRepresent: "einen Bevollmächtigten hinzufügen,",
            },
            DozornaRadaComponentBox: {
                titlesBefore: "Titel vor dem Namen des Aufsichtsratsmitglieds sind",
                titlesAfter: "Die Bezeichnungen nach dem Namen eines Aufsichtsratsmitglieds lauten",
                firstName: "Der Name des Aufsichtsratsmitglieds lautet",
                lastName: "Der Nachname des Aufsichtsratsmitglieds lautet",
                birthId: "Hat das Mitglied des Aufsichtsrats eine Geburtsnummer?",
                birthIdPlaceholder: "Wählen Sie eine Geburtsnummer oder eine andere Identifikation",
                birthIdTooltip: "In einigen Ländern wird den Personen keine Geburtsnummer zugewiesen, sondern es werden ihnen andere identifizierende Informationen gegeben. Sie müssen sich für eine der beiden Möglichkeiten entscheiden",
                enterBirthId: "Die Geburtsnummer/Kennziffer des Aufsichtsratsmitglieds lautet",
                enterBirthIdPlaceholder: "Geben Sie die Geburtsnummer oder eine andere Kennung ein",
                enterBirthIdTooltip: "In einigen Ländern wird den Personen keine Geburtsnummer zugewiesen, sondern es werden andere Angaben zur Identifizierung gemacht. Diese müssen hier ausgefüllt werden",
                member: "Ein Mitglied des Aufsichtsrates ist",
                memberTooltip: "Es ist erforderlich, den vollständigen Namen des Aufsichtsratsmitglieds und gegebenenfalls alle seine Titel korrekt zu schreiben.",
                address: "Der Wohnsitz eines Mitglieds des Aufsichtsrates ist",
                addressTooltip: "Es ist erforderlich, die Anschrift des Wohnsitzes des Aufsichtsratsmitglieds korrekt und hinreichend genau anzugeben",
                sepervisoryMember: "Hat das Mitglied des Aufsichtsrates",
                orIdentity: 'oder andere identifizierende Informationen?',
                enterIdentity: 'Bitte Identifikation eingeben',
                enterBirth: 'Wählen Sie das Geburtsdatum',
                birth: 'Geburtsdatum',
                enterFunc: 'Geben Sie seine/ihre Position ein',
                func: 'Funktion',
                addMember: 'Aufsichtsratsmitglied hinzufügen',
                sepervisoryExists: 'Wählen Sie die Wahlorgane des Unternehmens',
                sepervisoryExistsTooltip: "Ein Aufsichtsrat wird in der Regel nicht in Gesellschaften mit beschränkter Haftung eingerichtet; wenn er eingerichtet wird, dient er der Kontrolle der Geschäftsführer und der Buchführung.",
            },
            KonateliaEstablishmentBox: {
                titlesBefore: "Titel vor dem Namen des Geschäftsführers sind",
                titlesAfter: "Die Titel nach dem Namen des Geschäftsführers sind",
                firstName: "Der Name des Geschäftsführers ist",
                lastName: "Der Nachname des Geschäftsführers ist",
                konatel: "Der Geschäftsführer ist",
                konatelTooltip: "Es ist erforderlich, den Vor- und Nachnamen des Geschäftsführers und gegebenenfalls alle seine Titel richtig zu schreiben",
                enterAdress: "Der Wohnsitz des Geschäftsführers ist",
                enterAdressTooltip: "Die Wohnanschrift des Geschäftsführers sollte korrekt und mit ausreichender Genauigkeit angegeben werden",
                chooseIf: "Hat der Geschäftsführer eine Geburtsnummer?",
                chooseIfPlaceholder: "Bitte wählen Sie eine Geburtsnummer oder andere identifizierende Informationen",
                chooseIfTooltip: "In einigen Ländern wird Personen keine Geburtsnummer zugewiesen, sondern es werden ihnen andere identifizierende Informationen gegeben. Sie müssen eine der Optionen auswählen",
                enterBirthID: "Die Geburtsnummer/Identifikationsnummer des Geschäftsführers lautet",
                enterBirthIDTooltip: "In einigen Ländern wird den Personen keine Geburtsnummer zugewiesen, sondern es werden andere Informationen zur Identifizierung der Personen angegeben. Diese müssen hier ausgefüllt werden",
                enterData: "Identifikation schreiben",
                enterICO: 'ID-Nummer eingeben',
                ICO: 'ID NUMBER',
                birthID: 'Geburtsnummer',
                identication: 'Geschäftsbezeichnung des Geschäftsführers eingeben',
                chooseBirthDate: 'Geburtsdatum auswählen',
                birthDate: 'Geburtsdatum',
                addOwner: 'Geschäftsführer hinzufügen',
                removeOwner: 'Geschäftsführer entfernen',
            },
            OwnersComponentBox: {
                allPeopleMeet: "Alle Gesellschaftere nahmen an der Hauptversammlung teil,",
                allPeopleVotedForOne: "Alle Gesellschafter stimmten für die Annahme aller Tagesordnungspunkte,",
                partOwner: "Gesellschafter",
                present: "Er nahm teil,",
                choosePersonType: "Ist es ein Mensch?",
                personType: "Art der Person,",
                individual: "Mensch,",
                legalPerson: "Juristische Person,",
                enterVotes: "Anteil der Stimmen in %",
                enterCompanyName: "Firmenname des Gesellschafters",
                enterName: "Name und Nachname des Gesellschafters",
                name: "Schreiben Sie den Namen",
                enterAdress: "Adresse",
                enterCompanyAdress: "Schreiben Sie die Adresse",
                savedPersonsButton: 'Aus gespeicherten Personen auswählen',
                savePerson: 'Person speichern',
                personInformation: 'Identifikationsdaten der Person',
                searchPerson: 'Person suchen',
                enterNameAndSurname: 'Name oder Identifikation eingeben',
                personName: 'Name',
                personSurname: "Nachname",
                id: "Identifizierung",
                searchOrsr: "Suche im ORSR",

            },
            OwnersEstablishmentBox: {
                titlesBefore: "Titel vor dem Namen einer Person sind",
                titlesAfter: "Titel nach dem Namen einer Person sind",
                firstName: "Der Name einer Person ist",
                lastName: "Der Nachname einer Person ist",
                shareCapital: "Wie hoch ist der Wert des Aktienkapitals in €",
                choosePersonType: 'Ist die Person?',
                owner: 'Der Aktionär ist',
                personType: 'Art der Person',
                individual: 'Person',
                legalPerson: 'juristische Person',
                depositeEuro: 'Einzahlung in € eingeben',
                paidDepositeEuro: 'Geleistete Einlage in € eingeben',
                shareCompanyPart: 'Anteil am Unternehmen in % eingeben',
                shareProfit: 'Gewinnanteil in % eingeben',
                enterBusinessName: "Der derzeitige Aktionär muss vollständig und korrekt angegeben werden",
                enterTitleNameTooltip: "Der vollständige Name des Aktionärs und gegebenenfalls alle Titel des Aktionärs müssen richtig geschrieben sein",
                enterTitleName: "Titel, Vorname, Nachname eingeben",
                businessName: 'Geschäftsname',
                titleName: "Titel, Vor- und Nachname",
                partnerForeginer: "Ist der Partner eine ausländische Person?",
                depositManager: "Ist dieser Partner ein Einlagenverwalter?",
                partnerExecutive: "Ist dieser Gesellschafter auch der Geschäftsführer?",
                enterHomeLocation: "Der Sitz des Gesellschafters ist",
                enterHomeLocationTooltip: "Die aktuelle Anschrift des eingetragenen Sitzes des Aktionärs muss korrekt und hinreichend genau angegeben werden",
                enterCompanyAdress: "Der Wohnsitz des Partners ist",
                enterCompanyAdressTooltip: "Die aktuelle Wohnanschrift des Partners muss korrekt und hinreichend genau angegeben werden",
                ownerHaveBirthId: "Besitzt die Person eine persönliche Identifikationsnummer?",
                ownerHaveOtherId: 'Hat der Mitarbeiter eine ID-Nummer?',
                ICO: 'Wählen Sie die ID-Nummer aus',
                ICOTooltip: "In einigen Ländern wird juristischen Personen keine ID-Nummer zugewiesen, sondern es werden andere Identifizierungsinformationen angegeben. Sie müssen eine der Optionen wählen.",
                birthID: "Geburtsnummer auswählen",
                birthIDTooltip: "In einigen Ländern wird Personen keine Geburtsnummer zugewiesen, sondern sie erhalten andere Identifizierungsdaten. Sie müssen eine der Optionen wählen.",
                orID: "oder andere identifizierende Informationen?",
                dataID: 'Identifizierung',
                enterICO: 'Kennnummer/Kennzeichen des Aktionärs',
                enterICOTooltip: "In einigen Ländern wird juristischen Personen keine ID-Nummer zugewiesen, sondern es werden andere Identifizierungsdaten angegeben. Diese müssen hier ausgefüllt werden",
                enterICOPlaceholder: 'Geben Sie Ihre Registrierungsnummer oder eine andere Kennung ein',
                enterBirthID: "Die Geburtsnummer/Kennnummer der Person ist",
                enterBirthIDTooltip: "In einigen Ländern wird den Personen keine Geburtsnummer zugewiesen, sondern es werden andere Angaben zur Identifizierung gemacht. Diese müssen hier ausgefüllt werden",
                enterBirthIDPlaceholder: 'Geben Sie Ihre Geburtsnummer oder einen anderen Ausweis ein',
                enterID: 'Identifikation eingeben',
                chooseBirthDate: 'Geburtsdatum auswählen',
                birthDate: 'Geburtsdatum',
                representMan: 'Unterzeichnet im Namen des Partners',
                enterFunc: "Von welcher Position aus?",
                func: 'z.B. Geschäftsführer',
            },
            EstablishmentSinglePerson: {
                titlesBefore: "Titel vor dem Namen des Partners sind",
                titlesAfter: "Titel nach dem Namen des Partners sind",
                firstName: "Der Name des Gesellschafters ist",
                lastName: "Der Nachname des Gesellschafters ist",
                shareCapital: 'Wie hoch ist der Wert des Stammkapitals in €',
                choosePersonType: "Ist es eine Person?",
                personType: 'Art der Person',
                individual: 'Mensch',
                legalPerson: 'Juristische Person',
                enterBusinessName: 'Der Gesellschafter ist',
                enterBusinessNameTooltip: 'Sie müssen die gesamte tatsächliche Tätigkeit des Gesellschafters richtig schreiben',
                enterTitleName: "Schreiben Sie seinen/ihren Titel, Vornamen, Nachnamen",
                businessName: 'Geschäftsname',
                titleName: "Titel, Vor- und Nachname",
                partnerForeginer: "Ist der Partner eine ausländische Person?",
                depositManager: "Ist dieser Gesellschafter auch der Verwahrer der Einlagen?",
                partnerExecutive: "Ist dieser Gesellschafter auch Geschäftsführer?",
                enterHomeLocation: "Der Wohnsitz des Gesellschafters ist",
                enterCompanyAdress: "Der eingetragene Sitz des Gesellschafters ist",
                enterCompanyAdressTooltip: "Die derzeitige Anschrift des eingetragenen Sitzes des Aktionärs muss korrekt und ausreichend angegeben werden",
                enterHomeLocationTooltip: "Die aktuelle Wohnanschrift des Partners sollte korrekt und mit ausreichender Genauigkeit angegeben werden",
                ownerHaveBirthId: "Hat der Partner eine Geburtsnummer?",
                ownerHaveOtherId: 'Hat der Partner eine Registriernummer?',
                ICO: "Wählen Sie die Registriernummer",
                ICOTooltip: "In einigen Ländern wird juristischen Personen keine ID-Nummer zugewiesen, sondern es werden andere Identifizierungsinformationen angegeben. Sie müssen eine der Optionen wählen.",
                birthID: "eine Geburtsnummer auswählen",
                birthIDTooltip: "Einige Länder weisen Personen keine Geburtsnummer zu, sondern geben ihnen andere Identifikationsdaten. Sie müssen eine der Optionen auswählen.",
                orID: 'oder andere Identifikationsdaten?',
                dataID: 'Identifikation',
                enterICO: 'Kennnummer/Kennzeichen des Aktionärs',
                enterICOPlaceholder: 'Geben Sie die ID-Nummer oder eine andere Kennung ein',
                enterICOTooltip: "In einigen Ländern wird juristischen Personen keine ID-Nummer zugewiesen, aber Personen erhalten andere Identifikationsdaten. Diese müssen hier ausgefüllt werden",
                enterBirthID: "Geburtsnummer/Kennzeichen des Aktionärs ist",
                enterBirthIDPlaceholder: "Geben Sie die Geburtsnummer oder eine andere Kennung ein",
                enterBirthIDTooltip: "In einigen Ländern wird den Personen keine Geburtsnummer zugewiesen, sondern die Personen erhalten andere Identifikationsdaten. Diese müssen hier ausgefüllt werden.",
                enterID: "Identifikation eingeben",
                chooseBirthDate: 'Geburtsdatum auswählen',
                birthDate: 'Geburtsdatum',
                representMan: "Die folgenden Personen werden im Namen des Partners unterschreiben",
                enterFunc: 'Von welcher Position aus?',
                func: 'z.B. Geschäftsführer',
                addRepresent: 'Eine repräsentative Person hinzufügen',
                owner: 'Der Partner ist',
                enterTitleNameTooltip: "Der vollständige Vor- und Nachname des Gesellschafters und ggf. die Titel müssen korrekt geschrieben werden",
            },
            SingleOwnerBox: {
                chooseOneType: "Ist der einzige Gesellschafter ein Mensch?",
                personType: "Art der Person",
                individual: "Mensch",
                legalPerson: "Juristische Person",
                enterOneOwnerCompanyName: "Geben Sie den Firmennamen des Alleingesellschafters an,",
                enterOwnerName: "Geben Sie den Namen des Alleingesellschafters an,",
                name: "Name,",
                enterOwnerLocation: "Geben Sie die Anschrift des eingetragenen Sitzes des Alleingesellschafters an,",
                enterOwnerAdress: "Geben Sie die Wohnanschrift des Alleingesellschafters an,",
                ifOwnerHave: "der Gesellschafter hat",
                ICO: "Firmen-Identifikationsnummer",
                birthID: "Geburtsnummer,",
                orID: "oder andere Identifizierungsangabe?,",
                dataID: "Identifizierungsangabe,",
                enterICO: "Firmen-Identifikationsnummer eingeben",
                enterBirthID: "Geburtsnummer eingeben,",
                enterID: "Identifikationsangabe eingeben,",
                chooseBirthDate: "Wählen Sie das Geburtsdatum,",
                birthDate: "Geburtsdatum,",
                representMan: "Bevollmächtigter",
                enterFunc: "geben Sie seine Funktion an,",
                func: "Funktion",
                addRepresent: "einen Bevollmächtigten hinzufügen",
                enter: "geben Sie an",
                partner: "Gesellschafter,",
                enterPartnerBirthDate: "Geben Sie das Geburtsdatum des Gesellschafters ein,",
                enterTitleName: "Titel, Vor- und Nachname eingeben,",
                titleName: "Titel, Vor- und Nachname,",
            },
            RetributionBox: {
                withPayback: "Für den Kaufpreis,",
                withoutPayback: "Unentgeltlich,",
                enterPaybackValue: "Die Höhe des Kaufpreises beträgt,",
                paybackValue: "Höhe des Kaufpreises,",
                chooseExp: "Der Kaufpreis ist fällig,",
                exp: "Fälligkeit,",
                untilDate: "Bis dem Datum,",
                withSign: "Bei der Unterzeichnung dieses Vertrags,",
                chooseExpDate: "Fälligkeitsdatum auswählen,",
                expDate: "Fälligkeitsdatum,",
                choosePayment: "Zahlungsmethode auswählen,",
                cash: "Bargeld,",
                transfer: "Per Banküberweisung,",
                didAccept: "Hat der Veräußerer die vereinbarte Gegenleistung erhalten?,",
                iban: "IBAN",
            },
            PlnomocenstvoHeader: {
                enterFullOwner: "Geben Sie den bevollmächtigten Geschäftsführer oder Prokurist an,",
            },
            PresenceComponent: {
                personsHere: "Anwesende Personen,",
                personType: "Art der Person",
            },
            PresenceKonatel: {
                chooseOwner: "Geschäftsführer auswählen,",
                enterFunc: "seine Funktion eingeben,",
                func: "Funktion,",
                enterName: "Name eingeben,",
                titleName: "Titel, Vor- und Nachname,",
                enterAdress: "Adresse eingeben,",
                streetCity: "Straße, Postleitzahl, Ort,",
                representMan: "vertretende Person,",
            },
            PresenceOtherPerson: {
                enterFunc: "seine Funktion eingeben,",
                func: "Funktion,",
                enterName: "Name eingeben,",
                titleName: "Titel, Vor- und Nachname,",
                enterAdress: "Adresse eingeben,",
                streetCity: "Straße, Postleitzahl, Ort,",
                representMan: "vertretende Person,",
            },
            PresenceProkurista: {
                chooseProxy: "Wählen Sie einen Prokuristen,",
                proxy: "Prokurist.",
                enterFunc: "Funktion eingeben,",
                func: "Funktion,",
                enterName: "Name eingeben,",
                titleName: "Titel, Vor- und Nachname,",
                enterAdress: "Adresse eingeben,",
                streetCity: "Straße, Postleitzahl, Ort,",
                isHeRpresent: "Vertritt ihn jemand?",
                enterRepresent: "einen Bevollmächtigten hinzufügen,",
                representMan: "Bevollmächtigter,",
                enterCountry: "Wählen Sie ein Land,",
                country: "Land,",
            },
            PresentOwnerPerson: {
                coOwner: "Gesellschafter,",
                enterFunc: "Funktion eingeben,",
                func: "Funktion,",
                enterName: "Name eingeben,",
                titleName: "Titel, Vor- und Nachname,",
                enterAdress: "Adresse eingeben,",
                streetCity: "Straße, Postleitzahl, Ort,",
                isHeRpresent: "Vertreten durch eine andere Person(en)?,",
                enterRepresent: "Einen Vertreter hinzufügen,",
                representMan: "vertretende Person,",
            },
            LossComponent: {
                enterLoss: "Den Verlust eintragen,",
                useToPay: "Gewinne aus den Vorjahren zur Deckung von Verlusten verwenden?,",
                feeToPay: "Den Verlust mit dem Beitrag der Gesellschaftere decken?,",
                year: "Jahr.",
                enterValue: "Wert eingeben,",
                value: "Wert,",
                coOwnerPay: "Beitrag eines Gesellschafters,",
            },
            ProfitComponent: {
                enterProfit: "Gewinn eingeben,",
                profit: "Gewinn.",
                enterReserve: "geben Sie einen Beitrag zum Reservefonds an,",
                resrveFoundation: "Beitrag zum Reservefonds,",
                enterSocialReserve: "Beitrag zum Sozialfonds eingeben,",
                socialFoundation: "Beitrag zum Sozialfonds,",
                enterBaseReserve: "Geben Sie den Gewinn an, der zur Erhöhung des Stammkapitals verwendet werden soll,",
                baseFoundation: "Gewinn, der für Stammkapitalerhöhung vorgesehen ist,",
                enterProfitToSplit: "Geben Sie den an die Gesellschaftere auszuschüttenden Gewinn an,",
                profitToSplit: "Verteilung der Gewinne auf die Gesellschafter,",
                enterName: "Geben Sie einen Namen ein,",
                name: "Name,",
                enterValue: "Wert eingeben,",
                value: "Wert",
            },
            PropertyOwner: {
                titlesBefore: "Titel vor dem Namen des Eigentümers sind",
                titlesAfter: "Die Titel nach dem Namen des Eigentümers sind",
                firstName: "Der Name des Eigentümers ist",
                lastName: "Der Nachname des Besitzers ist",
                enterTypeOfOwner: "Ist der Eigentümer ein Mann?",
                enterICO: "ID-Nummer/Kennzeichen des Eigentümers ist",
                enterICOPlaceholder: "Geben Sie die Registrierungsnummer oder eine andere Kennung ein",
                enterBirthID: 'Geburtsnummer/Kennzeichen des Eigentümers ist',
                enterBirthIDPlaceholder: 'geben Sie die Geburtsnummer oder eine andere Kennung ein',
                typeOfOwner: "Personentyp",
                individual: 'Person',
                legalPerson: 'Juristische Person',
                enterOwner: 'Eigentümer ist',
                enterOwnerTooltip: 'Der vollständige Name des Eigentümers und ggf. Titel müssen richtig geschrieben werden',
                enterOwnerName: 'Eigentümer ist',
                enterOwnerNameTooltip: "Der vollständige aktuelle Firmenname des Eigentümers muss richtig geschrieben sein",
                titleName: "Titel, Vor- und Nachname",
                businessName: "Firmenname",
                streetCity: "Straße Nr., Postleitzahl, Ort",
                enterOwnerLocation: "Wohnsitz des Eigentümers ist",
                enterAdress: "Wohnsitz des Inhabers ist",
                enterOwnerBirth: "Geburtsdatum des Eigentümers",
                dateOfBirth: 'Geburtsdatum',
                chooseIfOwner: 'Hat Eigentümer',
                ico: 'AUSWEIS',
                hasIco: 'Hat der Eigentümer eine ID-Nummer?',
                hasIcoTooltip: "In einigen Ländern wird juristischen Personen keine ID-Nummer zugewiesen, sondern es werden andere Identifizierungsinformationen angegeben. Sie müssen eine der Optionen wählen.",
                IcoTooltip: "In einigen Ländern wird juristischen Personen keine ID-Nummer zugewiesen, aber Personen erhalten andere Identifikationsdaten. Diese müssen hier ausgefüllt werden",
                BirthIDTooltip: "In einigen Ländern wird den Personen keine Geburtsnummer zugewiesen, sondern es werden andere Angaben zur Identifizierung gemacht. Diese müssen hier ausgefüllt werden",
                birthID: 'Geburtsnummer',
                hasBirthID: "Hat der Eigentümer eine Geburtsnummer",
                hasBirthIDTooltip: "In einigen Ländern wird Personen keine Geburtsnummer zugewiesen, sondern sie erhalten andere Identifizierungsdaten. Sie müssen eine der Optionen wählen.",
                orID: "oder identifizierende Informationen",
                id: "Identifizierung",
                enter: 'Eingabe',
                owner: "Eigentümer",
                birtIdOrId: 'Geburtsnummer oder identifizierende Informationen',
                icoOrId: 'Ausweisnummer oder Identifizierung',
                enterRegistrationDisctrict: 'Registergericht eingeben',
                registrationDistrict: "Bezirksgericht",
                enterPart: "Abschnitt eingeben",
                part: "Abschnitt",
                enterInsert: "Einsatz eingeben",
                insert: "Einfügen",
                addRepresentMan: "Ersatzperson hinzufügen",
                representMan: "Der folgende wird im Namen des Eigentümers unterschreibe",
                enterFunc: 'Von welcher Position aus',
                func: 'z.B. Geschäftsführer',
                enterRepresentMan: 'Schreiben Sie Titel, Vorname, Nachname',
                tooltipPerson: "Die Wohnanschrift des Eigentümers muss korrekt und hinreichend genau angegeben werden",
                tooltipCompany: "Die aktuelle Anschrift des eingetragenen Sitzes des Eigentümers muss korrekt und ausreichend genau angegeben werden",
            },
            PropertyType: {
                enterTypeOfProperty: "Die Immobilie, in der sich der Wohnsitz befinden wird, ist",
                enterTypeOfPropertyPlaceholder: 'Immobilienart auswählen',
                enterTypeOfPropertyTooltip: "Wenn es sich nicht um eine Wohnung oder ein Geschäftslokal handelt, handelt es sich um ein Gebäude, das eine Registriernummer hat",
                typeOfProperty: 'Grundstückstyp',
                building: 'Gebäude',
                flat: 'Wohnung',
                noResidentPlace: 'Nicht-Wohngebäude',
                flatNumber: 'Wohnungsnummer eingeben',
                noResidentNumber: 'Nummer des Nichtwohnraums eingeben',
                enterEntrance: "Eingang eingeben",
                entrance: 'Eingang',
                enterFloor: "Etage eingeben",
                floor: 'Stockwerk',
                enterInventoryNumber: 'Wohnungsnummer eingeben',
                inventoryNumber: "Aufzählungsnummer",
                enterDeedNumber: "Geben Sie die Nummer der Eigentumsurkunde ein",
                deedNumber: "Nummer der Eigentumsurkunde",
                enterSizeOfShare: "Geben Sie die Größe des Anteils an den Gemeinschaftsflächen an",
                sizeOfShare: "Größe des Anteils an den Gemeinschaftsflächen",
                enterDistrictOffice: "Bezirksamt eingeben",
                districtOffice: "Bezirksamt",
                enterCadastralTerritory: "Katasterfläche eingeben",
                cadastralTerritory: "Katasterfläche",
                enterMunicipality: "Gemeinde eingeben",
                municipality: "Gemeinde",
                enterDistrict: "Bezirk eingeben",
                district: "Gemarkung",
                chooseParcelType: "Art des Flurstückskatasters wählen",
                parcelRegister: "Flurstückskataster",
                enterParcelNumber: "Flurstücksnummer eingeben",
                parcelNumber: "Flurstücksnummer",
                enterTypeOfLand: "Flurstücksart eingeben",
                enterTypeOfBuilding: 'Gebäudetyp eingeben',
                typeOfLand: 'Grundstücksart',
                typeOfBuilding: 'Gebäudetyp',
                addParcel: "Flurstück hinzufügen",
            },
            NewOwnerComponent: {
                willOwnerHaveThisPart: "Wird der Gesellschafter diesen Teil behalten?",
                noResult: 'Keine Ergebnisse gefunden',
                specifySearchingName: 'Wenn Sie Ihr Unternehmen nicht gefunden haben, geben Sie den Namen oder die ID-Nummer an',
                part: "Teil.",
                enterShareEur: "geben Sie den Anteil in € an,",
                shareInEur: "mindestens 750 €,",
                enterSharePercents: "geben Sie den Anteil in % an,",
                shareInPercents: "Anteil in %,",
                chooseTypeOfPerson: "Ist der Käufer ein Mensch?",
                typeOfPerson: "Art der Person,",
                searchORSR: "Sollen wir in der Datenbank des Handelsregisters der Slowakischen Republik nachsehen?,",
                searchWithNameOrICO: "schreiben Sie Firmen-Identifikationsnummer oder Firmenname ",
                enterName: "Schreiben Sie den Titel, den Vornamen und den Nachnamen des Käufers,",
                enterBusinessName: "Schreiben Sie den Firmennamen des Käufers,",
                titleName: 'Vollständigen Namen eintragen',
                addTitlesBeforeName: 'Titel vor dem Namen eingeben',
                titlesBeforeName: 'Titel vor Name',
                addName: 'Name eingeben',
                addYourSurname: 'Nachnamen eingeben',
                surname: "Nachname",
                addTitlesAfterName: "Titel nach dem Namen eingeben",
                enterHomeLocation: "Schreiben Sie den Sitz des Käufers",
                enterAdress: "Schreiben Sie die Adresse des Käufers,",
                chooseDateOfBirth: "Geburtsdatum des Käufers,",
                dateOfBirth: "geben Sie das Geburtsdatum an,",
                chooseIfNewOwner: "der Käufer hat",
                ico: "Firmen-Identifikationsnummer",
                birthID: "Geburtsnummer,",
                orID: "oder hat eine andere Identifizierungsangabe?,",
                dataID: "Identifizierungsangabe,",
                enter: "geben Sie an",
                newOwner: "Käufer,",
                orDataId: "alebo identifikačný údaj",
            },
            OwnerComponent: {
                guideOp: "Wer verkauft den Geschäftsanteil?",
                chooseDateOfBirth: "Geburtsdatum des Verkäufers,",
                dateOfBirth: "geben Sie das Geburtsdatum an,",
                chooseConverterHave: "der Verkäufer hat",
                ico: "Firmen-Identifikationsnummer",
                birthID: "Geburtsnummer,",
                orID: "oder hat eine andere Identifizierungsangabe?,",
                dataID: "Identifizierungsangabe,",
                enter: "Geburtsnummer / ,",
                converter: "des Verkäufers ist",
                enterPart: "Der Gesellschaftsbeitrag des Verkäufers in € beträgt,",
                part: "Schreiben Sie den Betrag,",
                enterSharePercents: "Das Geschäftsbeitrag des Verkäufers ist in %,",
                shareInPercents: "Anteil in %,",
                enterPartsToCut: "In wie viele Teile wollen Sie den Geschäftsanteil aufteilen?,",
                partsToCut: "Schreiben Sie die Nummer.",

            },
            ShareDivision: {
                capital: "Stammkapital in €,",
                addRepresent: "Einen weiteren Unterzeichner hinzufügen?,",
                representMan: "Im Namen des Käufers wird unterschreiben.",
                enterFunc: "Von welcher Position aus?",
                func: "z.B. Geschäftsführer,",
                enterName: "Schreiben Sie seinen Titel, seinen Vornamen und seinen Nachnamen,",
                titleName: "Titel, Vorname, Nachname,",
            },
            ShareDivisionSinglePerson: {
                capital: "Grundkapital in €",
                addRepresent: "Einen weiteren Unterzeichner hinzufügen?",
                representMan: "Der folgende wird im Namen des Käufers unterschreiben",
                enterFunc: 'In welcher Position?',
                func: 'z.B. Geschäftsführer',
                enterName: "Schreiben Sie seinen/ihren Titel, Vornamen, Nachnamen",
                titleName: 'Titel, Vorname, Nachname',
                representManSeller: 'Er/sie wird im Namen des Verkäufers unterschreiben',
            },
            SecondNewOwnerComponent: {
                enterNewOwner: "Wer kauft den Geschäftsanteil?,",
                chooseTypeOfPerson: "Ist es ein Mensch?",
                typeOfPerson: "Art der Person",
                individual: "Mensch,",
                legalPerson: "Juristische Person,",
                searchORSR: "Sollen wir in der Datenbank des Handelsregisters der Slowakischen Republik nachsehen?,",
                searchWithNameOrICO: "schreiben Sie Firmen-Identifikationsnummer oder Firmenname ",
                enterName: "schreiben Sie den Titel, den Vornamen und den Nachnamen des Käufers,",
                enterBusinessName: "schreiben Sie den Firmennamen des Käufers,",
                titleName: "Schreiben Sie den vollständigen Namen,",
                enterHomeLocation: "Schreiben Sie den Sitz des Käufers",
                enterAdress: "Schreiben Sie die Adresse des Käufers,",
                name: "Name,",
                chooseIfNewOwner: "der Käufer hat,",
                enter: "geben Sie an.",
                newOwner: "Käufer,",
                ico: "Firmen-Identifikationsnummer",
                birthID: "Geburtsnummer,",
                orID: "oder hat eine andere Identifizierungsangabe,",
                dataID: "Identifizierungsangabe,",
            },
            SecondOwnerComponent: {
                chooseConverter: "Wer verkauft den Geschäftsanteil?",
                converter: "Schreiben Sie den Titel, den Namen und den Nachnamen des Verkäufers,",
                chooseDateOfBirth: "Geburtsdatum des Verkäufers,",
                dateOfBirth: "geben Sie das Geburtsdatum an,",
                chooseConverterHave: "der Verkäufer hat",
                ico: "Firmen-Identifikationsnummer",
                birthId: "Geburtsnummer,",
                orId: "oder hat eine andere Identifizierungsangabe?,",
                enter: "schreiben Sie.",
                dataId: "Identifikationsangabe,",
                converter: "Verkäufer,",
                enterPart: "die Höhe des Gesellschaftsbeitrags des Verkäufers in € beträgt,",
                part: "Schreiben Sie den Betrag,",
                enterPartInPercent: "der Gesellschaftsanteil des Verkäufers ist in %,",
                partInPercent: "Anteil in %,",
            },
            ShareTransfer: {
                capital: "Stammkapital in €,",
                addRepresentMan: "Einen weiteren Unterzeichner hinzufügen?,",
                representMan: "Im Namen des Käufers unterschreiben,",
                addFunc: "Von welcher Position aus?",
                func: "z.B. Geschäftsführer,",
                addTitleName: "Schreiben Sie seinen Titel, Vornamen und Nachnamen,",
                titleName: "Titel, Vorname, Nachname",
                representManSeller: 'Er/sie wird im Namen des Verkäufers unterschreiben',

            },
            ShareTransferSinglePerson: {
                capital: "Stammkapital in €,",
                addRepresentMan: "Einen weiteren Unterzeichner hinzufügen?,",
                representMan: "Im Namen des Käufers unterschreiben,",
                addFunc: "Von welcher Position aus?",
                func: "z.B. Geschäftsführer,",
                addTitleName: "Schreiben Sie seinen Titel, Vornamen und Nachnamen,",
                titleName: "Titel, Vorname, Nachname",
                representManSeller: 'Er/sie wird im Namen des Verkäufers unterschreiben',

            },
            TransferAquirerStatement: {
                chooseSignatureDate: "Wählen Sie das Datum der Unterschrift,",
                dateOfSignature: "schreiben Sie das Datum,",
                addPlaceOfSignature: "Wo er unterzeichnet wurde,",
                placeOfSignature: "Schreiben Sie den Ort,",
            },
            TransferSellerStatement: {
                chooseSignatureDate: "Wählen Sie das Datum der Unterschrift,",
                dateOfSignature: "Schreiben Sie das Datum,",
                addPlaceOfSignature: "Wo er unterzeichnet wurde,",
                placeOfSignature: "Schreiben Sie den Ort,",
            },
            SignatureCanvasArtifact: {
                createSignature: "Erstellen Sie eine Signatur",
                create: "Erstellen",
                loadFile: "oder eine Datei hochladen",
                signature: "Unterschrift",
                missingSignatures: 'Die Fingerunterschriften der folgenden Personen fehlen, eine Ergänzung ist nach der Unterschrift durch KEP nicht mehr möglich.',
                save: "Anwenden",
                clear: "Ändern",
                close: 'Schließen',
                orderDetail: 'Bestelldetails'
            },
            DocumentsDownload: {
                makeAndChangeSro: "Gründung und Änderungen von s.r.o.",
                enterOrderPassword: "Bestellpasswort eingeben",
                weAreCookingYourDocuments: "Wir arbeiten hart daran, Ihre Dokumente zu erstellen",
                itDoesntTakeMoreThan: "Es wird nicht länger als 5 Minuten dauern",
                registerToProgram: "Melden Sie sich für unser ",
                partnersProgram: "Partnerprogramm",
                allNews: "und Sie haben immer einen Überblick über Ihre Bestellungen und Sonderangebote",
                itsDone: "Und schon ist es geschafft!",
                docsAreReady: "Ihre Dokumente sind fertig",
                justDownload: "Laden Sie sie einfach herunter",
                linkInEmail: "In Ihrer E-Mail wartet bereits eine Nachricht auf Sie, in der Sie den Link und das Passwort finden, damit Sie jederzeit auf Ihre Dokumente zugreifen können",
                thanks: "Danke, dass Sie bei uns waren",
                downloadAll: "Alle Dokumente herunterladen",
                operator: "Das Portal ist betriebsbereit",
                vop: "Allgemeine Geschäftsbedingungen",
                gdpr: "Datenschutz"
            },
            DocumentOverview: {
                documentOverview: 'Dokumentvorschau',
                cancel: 'Schließen',
                preview: 'Vorschau'
            }
        },
        screens: {
            ValidatorModal: {
                title: "Wir haben Stellen gefunden, an denen etwas nicht stimmt. Willst du trotzdem weitermachen?",
                step: "Irgendetwas stimmt hier nicht"
            }
        }
    }
})