
export interface Filter {
    name: string,
    type: FilterType,
    translation: string,
    value: any,
    values?: any[],
    selected: boolean
}

export enum FilterType {
    DATE_TIME = "DATE_TIME",
    STRING = "STRING",
    SELECT = "SELECT"
}

export enum UploadsStatus {
    CONFIRMED = "CONFIRMED",
    REJECTED = "REJECTED",
    NULL = "NULL"
}

// do not forget to updte OrderStateSteper and OrderStateTextSteper
export enum OrderState {
    VYTVORENE = "VYTVORENE",
    DORUCENE = "DORUCENE",
    PODANE_URAD = "PODANE_URAD",
    URGOVANE_URAD = "URGOVANE_URAD",
    PODANE_SUD = "PODANE_SUD",
    URGOVANE_SUD = "URGOVANE_SUD",
    ZAPISANE_URAD = "ZAPISANE_URAD",
    ZAPISANE_SUD = "ZAPISANE_SUD"
}

// here we get index to stepper text for given OrderState
export enum OrderStateSteper {
    VYTVORENE = "0",
    DORUCENE = "1",
    PODANE_URAD = "2",
    URGOVANE_URAD = "2",
    PODANE_SUD = "4",
    URGOVANE_SUD = "4",
    ZAPISANE_URAD = "3",
    ZAPISANE_SUD = "5"
}
export const OrderStateText = {
    VYTVORENE: "Vytvorená",
    DORUCENE: "Doručená",
    PODANE_URAD: "Podaná úrad",
    URGOVANE_URAD: "Urgovaná úrad",
    PODANE_SUD: "Podaná súd",
    URGOVANE_SUD: "Urgovaná súd",
    ZAPISANE_URAD: "Zapísaná úrad",
    ZAPISANE_SUD: "Zapísaná súd"
}
export const OrderStateTextSteper = [
    "Vytvorená",
    "Doručená",
    "Podaná úrad",
    "Zapísaná úrad",
    "Podaná súd",
    "Zapísaná súd"
]

export enum RegistroveSudy {
    BANSKA_BYSTRICA = "BANSKA_BYSTRICA",
    BRATISLAVA_I = "BRATISLAVA_I",
    KOSICE_I = "KOSICE_I",
    NITRA = "NITRA",
    PRESOV = "PRESOV",
    TRENCIN = "TRENCIN",
    TRNAVA = "TRNAVA",
    ZILINA = "ZILINA"
}

export const RegistroveSudyText = {
    BANSKA_BYSTRICA: "Okresný súd Banská Bystrica",
    BRATISLAVA_I: "Mestský súd Bratislava III",
    KOSICE_I: "Mestský súd Košice",
    NITRA: "Okresný súd Nitra",
    PRESOV: "Okresný súd Prešov",
    TRENCIN: "Okresný súd Trenčín",
    TRNAVA: "Okresný súd Trnava",
    ZILINA: "Okresný súd Žilina"
}

export enum PodatelneSudy {
    PODATELNA_BANSKA_BYSTRICA = "podatelnaOSBB@justice.sk1",
    PODATELNA_BRATISLAVA_I = "podatelnaosba1@justice.sk1",
    PODATELNA_KOSICE_I = "podatelnaOSKE1@justice.sk1",
    PODATELNA_NITRA = "podatelnaosnr@justice.sk1",
    PODATELNA_PRESOV = "podatelnaospo@justice.sk1",
    PODATELNA_TRENCIN = "PodatelnaOSTN@justice.sk1",
    PODATELNA_TRNAVA = "podatelnaOSTT@justice.sk1",
    PODATELNA_ZILINA = "podatelnaOSZA@justice.sk1"
}

export enum FakePodatelneSudy {
    PODATELNA_BANSKA_BYSTRICA = "orsr-test@maildrop.cc",
    PODATELNA_BRATISLAVA_I = "orsr-test@maildrop.cc",
    PODATELNA_KOSICE_I = "orsr-test@maildrop.cc",
    PODATELNA_NITRA = "orsr-test@maildrop.cc",
    PODATELNA_PRESOV = "orsr-test@maildrop.cc",
    PODATELNA_TRENCIN = "orsr-test@maildrop.cc",
    PODATELNA_TRNAVA = "orsr-test@maildrop.cc",
    PODATELNA_ZILINA = "orsr-test@maildrop.cc"
}

export const prepareSud2Podatelna = () => {
    const tmpMap = new Map();
    tmpMap.set(RegistroveSudy.BANSKA_BYSTRICA, PodatelneSudy.PODATELNA_BANSKA_BYSTRICA);
    tmpMap.set(RegistroveSudy.BRATISLAVA_I, PodatelneSudy.PODATELNA_BRATISLAVA_I);
    tmpMap.set(RegistroveSudy.KOSICE_I, PodatelneSudy.PODATELNA_KOSICE_I);
    tmpMap.set(RegistroveSudy.NITRA, PodatelneSudy.PODATELNA_NITRA);
    tmpMap.set(RegistroveSudy.PRESOV, PodatelneSudy.PODATELNA_PRESOV);
    tmpMap.set(RegistroveSudy.TRENCIN, PodatelneSudy.PODATELNA_TRENCIN);
    tmpMap.set(RegistroveSudy.TRNAVA, PodatelneSudy.PODATELNA_TRNAVA);
    tmpMap.set(RegistroveSudy.ZILINA, PodatelneSudy.PODATELNA_ZILINA);
    return tmpMap;
}

export const prepareFakeSud2Podatelna = () => {
    const tmpMap = new Map();
    tmpMap.set(RegistroveSudy.BANSKA_BYSTRICA, FakePodatelneSudy.PODATELNA_BANSKA_BYSTRICA);
    tmpMap.set(RegistroveSudy.BRATISLAVA_I, FakePodatelneSudy.PODATELNA_BRATISLAVA_I);
    tmpMap.set(RegistroveSudy.KOSICE_I, FakePodatelneSudy.PODATELNA_KOSICE_I);
    tmpMap.set(RegistroveSudy.NITRA, FakePodatelneSudy.PODATELNA_NITRA);
    tmpMap.set(RegistroveSudy.PRESOV, FakePodatelneSudy.PODATELNA_PRESOV);
    tmpMap.set(RegistroveSudy.TRENCIN, FakePodatelneSudy.PODATELNA_TRENCIN);
    tmpMap.set(RegistroveSudy.TRNAVA, FakePodatelneSudy.PODATELNA_TRNAVA);
    tmpMap.set(RegistroveSudy.ZILINA, FakePodatelneSudy.PODATELNA_ZILINA);
    return tmpMap;
}

export enum ZivnostenskeUrady {
    BRATISLAVA_BRATISLAVA = "BRATISLAVA_BRATISLAVA",
    BRATISLAVA_MALACKY = "BRATISLAVA_MALACKY",
    BRATISLAVA_PEZINOK = "BRATISLAVA_PEZINOK",
    BRATISLAVA_SENEC = "BRATISLAVA_SENEC",
    TRNAVA_DUNAJSKA_STREDA = "TRNAVA_DUNAJSKA_STREDA",
    TRNAVA_GALANTA = "TRNAVA_GALANTA",
    TRNAVA_PIESTANY = "TRNAVA_PIESTANY",
    TRNAVA_SENICA = "TRNAVA_SENICA",
    TRNAVA_TRNAVA = "TRNAVA_TRNAVA",
    TRENCIN_BANOVCE_NAD_BEBRAVOU = "TRENCIN_BANOVCE_NAD_BEBRAVOU",
    TRENCIN_NOVE_MESTO_NAD_VAHOM = "TRENCIN_NOVE_MESTO_NAD_VAHOM",
    TRENCIN_POVAZSKA_BYSTRICA = "TRENCIN_POVAZSKA_BYSTRICA",
    TRENCIN_PRIEVIDZA = "TRENCIN_PRIEVIDZA",
    TRENCIN_TRENCIN = "TRENCIN_TRENCIN",
    NITRA_KOMARNO = "NITRA_KOMARNO",
    NITRA_LEVICE = "NITRA_LEVICE",
    NITRA_NITRA = "NITRA_NITRA",
    NITRA_NOVE_ZAMKY = "NITRA_NOVE_ZAMKY",
    NITRA_SALA = "NITRA_SALA",
    NITRA_TOPOLCANY = "NITRA_TOPOLCANY",
    ZILINA_CADCA = "ZILINA_CADCA",
    ZILINA_DOLNY_KUBIN = "ZILINA_DOLNY_KUBIN",
    ZILINA_LIPTOVSKY_MIKULAS = "ZILINA_LIPTOVSKY_MIKULAS",
    ZILINA_MARTIN = "ZILINA_MARTIN",
    ZILINA_NAMESTOVO = "ZILINA_NAMESTOVO",
    ZILINA_RUZOMBEROK = "ZILINA_RUZOMBEROK",
    ZILINA_ZILINA = "ZILINA_ZILINA",
    BANSKA_BYSTRICA_BANSKA_BYSTRICA = "BANSKA_BYSTRICA_BANSKA_BYSTRICA",
    BANSKA_BYSTRICA_BREZNO = "BANSKA_BYSTRICA_BREZNO",
    BANSKA_BYSTRICA_LUCENEC = "BANSKA_BYSTRICA_LUCENEC",
    BANSKA_BYSTRICA_RIMAVSKA_SOBOTA = "BANSKA_BYSTRICA_RIMAVSKA_SOBOTA",
    BANSKA_BYSTRICA_VELKY_KRTIS = "BANSKA_BYSTRICA_VELKY_KRTIS",
    BANSKA_BYSTRICA_ZVOLEN = "BANSKA_BYSTRICA_ZVOLEN",
    BANSKA_BYSTRICA_ZIAR_NAD_HRONOM = "BANSKA_BYSTRICA_ZIAR_NAD_HRONOM",
    PRESOV_BARDEJOV = "PRESOV_BARDEJOV",
    PRESOV_HUMENNE = "PRESOV_HUMENNE",
    PRESOV_KEZMAROK = "PRESOV_KEZMAROK",
    PRESOV_POPRAD = "PRESOV_POPRAD",
    PRESOV_PRESOV = "PRESOV_PRESOV",
    PRESOV_STARA_LUBOVNA = "PRESOV_STARA_LUBOVNA",
    PRESOV_STROPKOV = "PRESOV_STROPKOV",
    PRESOV_SVIDNIK = "PRESOV_SVIDNIK",
    PRESOV_VRANOV_NAD_TOPLOU = "PRESOV_VRANOV_NAD_TOPLOU",
    KOSICE_KOSICE = "KOSICE_KOSICE",
    KOSICE_KOSICE_OKOLIE = "KOSICE_KOSICE_OKOLIE",
    KOSICE_MICHALOVCE = "KOSICE_MICHALOVCE",
    KOSICE_ROZNAVA = "KOSICE_ROZNAVA",
    KOSICE_SPISSKA_NOVA_VES = "KOSICE_SPISSKA_NOVA_VES",
    KOSICE_TREBISOV = "KOSICE_TREBISOV"
}

export const ZivnostenskeUradyText = {
    BRATISLAVA_BRATISLAVA: "Okresný úrad Bratislava, odbor živnostenského podnikania",
    BRATISLAVA_MALACKY: "Okresný úrad Malacky, odbor živnostenského podnikania",
    BRATISLAVA_PEZINOK: "Okresný úrad Pezinok, odbor živnostenského podnikania",
    BRATISLAVA_SENEC: "Okresný úrad Senec, odbor živnostenského podnikania",
    TRNAVA_DUNAJSKA_STREDA: "Okresný úrad Dunajská Streda, odbor živnostenského podnikania",
    TRNAVA_GALANTA: "Okresný úrad Galanta, odbor živnostenského podnikania",
    TRNAVA_PIESTANY: "Okresný úrad Piešťany, odbor živnostenského podnikania",
    TRNAVA_SENICA: "Okresný úrad Senica, odbor živnostenského podnikania",
    TRNAVA_TRNAVA: "Okresný úrad Trnava, odbor živnostenského podnikania",
    TRENCIN_BANOVCE_NAD_BEBRAVOU: "Okresný úrad Bánovce nad Bebravou, odbor živnostenského podnikania",
    TRENCIN_NOVE_MESTO_NAD_VAHOM: "Okresný úrad Nové Mesto nad Váhom, odbor živnostenského podnikania",
    TRENCIN_POVAZSKA_BYSTRICA: "Okresný úrad Považská Bystrica, odbor živnostenského podnikania",
    TRENCIN_PRIEVIDZA: "Okresný úrad Prievidza, odbor živnostenského podnikania",
    TRENCIN_TRENCIN: "Okresný úrad Trenčín, odbor živnostenského podnikania",
    NITRA_KOMARNO: "Okresný úrad Komárno, odbor živnostenského podnikania",
    NITRA_LEVICE: "Okresný úrad Levice, odbor živnostenského podnikania",
    NITRA_NITRA: "Okresný úrad Nitra, odbor živnostenského podnikania",
    NITRA_NOVE_ZAMKY: "Okresný úrad Nové Zámky, odbor živnostenského podnikania",
    NITRA_SALA: "Okresný úrad Šaľa, odbor živnostenského podnikania",
    NITRA_TOPOLCANY: "Okresný úrad Topoľčany, odbor živnostenského podnikania",
    ZILINA_CADCA: "Okresný úrad Čadca, odbor živnostenského podnikania",
    ZILINA_DOLNY_KUBIN: "Okresný úrad Dolný Kubín, odbor živnostenského podnikania",
    ZILINA_LIPTOVSKY_MIKULAS: "Okresný úrad Liptovský Mikuláš, odbor živnostenského podnikania",
    ZILINA_MARTIN: "Okresný úrad Martin, odbor živnostenského podnikania",
    ZILINA_NAMESTOVO: "Okresný úrad Námestovo, odbor živnostenského podnikania",
    ZILINA_RUZOMBEROK: "Okresný úrad Ružomberok, odbor živnostenského podnikania",
    ZILINA_ZILINA: "Okresný úrad Žilina, odbor živnostenského podnikania",
    BANSKA_BYSTRICA_BANSKA_BYSTRICA: "Okresný úrad Banská Bystrica, odbor živnostenského podnikania",
    BANSKA_BYSTRICA_BREZNO: "Okresný úrad Brezno, odbor živnostenského podnikania",
    BANSKA_BYSTRICA_LUCENEC: "Okresný úrad Lučenec, odbor živnostenského podnikania",
    BANSKA_BYSTRICA_RIMAVSKA_SOBOTA: "Okresný úrad Rimavská Sobota, odbor živnostenského podnikania",
    BANSKA_BYSTRICA_VELKY_KRTIS: "Okresný úrad Veľký Krtíš, odbor živnostenského podnikania",
    BANSKA_BYSTRICA_ZVOLEN: "Okresný úrad Zvolen, odbor živnostenského podnikania",
    BANSKA_BYSTRICA_ZIAR_NAD_HRONOM: "Okresný úrad Žiar nad Hronom, odbor živnostenského podnikania",
    PRESOV_BARDEJOV: "Okresný úrad Bardejov, odbor živnostenského podnikania",
    PRESOV_HUMENNE: "Okresný úrad Humenné, odbor živnostenského podnikania",
    PRESOV_KEZMAROK: "Okresný úrad Kežmarok, odbor živnostenského podnikania",
    PRESOV_POPRAD: "Okresný úrad Poprad, odbor živnostenského podnikania",
    PRESOV_PRESOV: "Okresný úrad Prešov, odbor živnostenského podnikania",
    PRESOV_STARA_LUBOVNA: "Okresný úrad Stará Ľubovňa, odbor živnostenského podnikania",
    PRESOV_STROPKOV: "Okresný úrad Stropkov, odbor živnostenského podnikania",
    PRESOV_SVIDNIK: "Okresný úrad Svidník, odbor živnostenského podnikania",
    PRESOV_VRANOV_NAD_TOPLOU: "Okresný úrad Vranov nad Topľou, odbor živnostenského podnikania",
    KOSICE_KOSICE: "Okresný úrad Košice, odbor živnostenského podnikania",
    KOSICE_KOSICE_OKOLIE: "Okresný úrad Košice-okolie, odbor živnostenského podnikania",
    KOSICE_MICHALOVCE: "Okresný úrad Michalovce, odbor živnostenského podnikania",
    KOSICE_ROZNAVA: "Okresný úrad Rožňava, odbor živnostenského podnikania",
    KOSICE_SPISSKA_NOVA_VES: "Okresný úrad Spišská Nová Ves, odbor živnostenského podnikania",
    KOSICE_TREBISOV: "Okresný úrad Trebišov, odbor živnostenského podnikania"
}


export enum Codes {
    Stat = "Stat",
    PravnaForma = "PravnaForma",
    FunkciaClenaStatutarnehoOrganu = "FunkciaClenaStatutarnehoOrganu",
    TypInyIdentifikator = "TypInyIdentifikator",
    Mena = "Mena",
    TypVkladu = "TypVkladu",
    Obec = "Obec",
    ObchodnyRegister = "ObchodnyRegister"
}

export const CodesText = {
    Stat: "Stat",
    PravnaForma: "Pravna forma",
    FunkciaClenaStatutarnehoOrganu: "FunkciaClenaStatutarnehoOrganu",
    TypInyIdentifikator: "TypInyIdentifikator",
    Mena: "Mena",
    TypVkladu: "TypVkladu",
    Obec: "Obec",
    ObchodnyRegister: "ObchodnyRegister"
}

export interface StatCode {
    codeId: number;
    value: string;
}

export interface PravnaFormaCode {
    codeId: number;
    value: string;
}

export interface FunkciaClenaStatutarnehoOrganuCode {
    codeId: number;
    value: string;
}

export interface TypInyIdentifikatorCode {
    codeId: number;
    value: string;
    znacka: string;
}

export interface MenaCode {
    codeId: number;
    value: string;
    znacka: string;
}

export interface TypVkladuCode {
    codeId: number;
    value: string;
}

export interface ObecCode {
    codeId: number;
    value: string;
    statId: number;
    obce: string[];

}

export interface ObchodnyRegisterCode {
    // codeId is id in FUZS xml
    codeId: number;
    okresnySud: string;
    ulica: string;
    cislo: string;
    obec: string;
    psc: string;
    kod: string;
}

export enum UserRoles {
    ADMIN = 'admin',
    USER = 'user',
    EDITOR = 'editor'
}

export enum LoginFormState {
    LOGIN,
    REGISTER,
    USER_NOT_VERIFIED,
    SOFTWARE_TOKEN_MFA,
    SMS_MFA,
    LOGGED_IN,
    FORGOT_PASSWORD,
    PASSWORD_RESET,
    NEW_PASSWORD_REQUIRED
}

export enum PartnerType {
    PARTNER_LAWYER = "PARTNER_LAWYER",
    PARTNER_ACCOUNTANT = "PARTNER_ACCOUNTANT",
    PARTNER_NOTARY = "PARTNER_NOTARY"
}

// person name

export type SeparatedName = {
    titlesBefore: string,
    name: string,
    surname: string
    titlesAfter: string
}


export enum TypInyIdentifikatorEnum {
    RODNE_CISLO = "rodné číslo",
    PREUKAZ_TOTOZNOSTI = "preukaz totožnosti",
    CESTOVNY_DOKLAD = "cestovný doklad",
    DOKLAD_O_POBYTE_CUDZINCA = "doklad o pobyte cudzinca",
    INY_IDENTIFIKACNY_UDAJ = "iný identifikačný údaj"
}

export enum TypInyIdentifikatorTabsEnum {
    RODNE_CISLO = "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;",
    PREUKAZ_TOTOZNOSTI = "&#9;&#9;&#9;&#9;&#9;",
    CESTOVNY_DOKLAD = "&#9;&#9;&#9;&#9;&#9;&#9;",
    DOKLAD_O_POBYTE_CUDZINCA = "&#9;&#9;",
    INY_IDENTIFIKACNY_UDAJ = "&#9;&#9;&#9;&#9;"
}

export enum TypInyIdentifikatorEstablishmentTabsEnum {
    RODNE_CISLO = "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;",
    PREUKAZ_TOTOZNOSTI = "&#9;&#9;&#9;&#9;&#9;",
    CESTOVNY_DOKLAD = "&#9;&#9;&#9;&#9;&#9;&#9;",
    DOKLAD_O_POBYTE_CUDZINCA = "&#9;&#9;",
    INY_IDENTIFIKACNY_UDAJ = "&#9;&#9;&#9;&#9;"
}



export enum PaidStatus {
    'paid' = 'Vygenerované',
    'unpaid' = 'Rozpracovaná',
}

// while updating this enum, update also ORSRFilePrefixes in generation api
export enum ORSRFileTypes {
    FUZS = 'fuzs',
    FUPS = 'fups',

    // default value
    ORSR = 'orsr'
}


// Cognito user types
export enum UserType {
    'uctovnik' = 'UCTOVNIK',
    'advokat' = 'ADVOKAT',
    'notar' = 'NOTAR',
}

