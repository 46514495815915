
function MunicipalitiesRegionHTMLRenderer(values) {
    let returnValue = ""
    if (values.municipalities[0].regionId.name) {
        returnValue = values.municipalities[0].regionId.name
    }
    return returnValue
}


// eslint-disable-next-line import/no-anonymous-default-export
 export default {
    HTMLRenderer: MunicipalitiesRegionHTMLRenderer
};