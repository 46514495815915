
import { Input, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Box } from '../../../../../components/Box';
import { translations } from '../../../../../utils/LocalizedStrings';

const _ = require('lodash');

export function RegistrationCertificateDataMutator({ name, values, handleChange, disabled = false, tooltip, label, onFocus, onBlur }) {

    const [, updateState] = React.useState({});

    const changeValue = (e) => {
        values[e.target.name] = e.target.value
        handleChange(values)
    }

    return (
        <form className="fullGridRow">
            <form id={'registrationCertificate'} className="fullGridRow">
                <div className="addressAuto">

                    <Box>
                        <p className='inputLabelTooltip'>
                            <h4>{label}</h4>
                            {tooltip && (
                                <div style={{ marginBottom: 20 }} className="icon-position">
                                    <Tooltip title={tooltip}>
                                        <span className="icon-info">
                                            <Icon name="info circle" />
                                        </span>
                                    </Tooltip>
                                </div>
                            )}
                        </p>


                        <span className="gridRow" style={{ marginTop: 10 }}>
                            <Box flex={1}>
                                <Text strong>{translations.artifacts.RegistrationCertificateDataBox.registrationNumber}</Text>
                                <Input
                                    disabled={disabled}
                                    className="ant-input"
                                    autoComplete="chrome-off"
                                    name={"cisloTechnickehoPreukazu"}
                                    placeholder={translations.artifacts.RegistrationCertificateDataBox.registrationNumberId}
                                    value={values.cisloTechnickehoPreukazu ? values.cisloTechnickehoPreukazu : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={changeValue}
                                />
                            </Box>
                            <Box flex={1}>
                                <Text strong>{translations.artifacts.RegistrationCertificateDataBox.licensePlate}</Text>
                                <Input
                                    disabled={disabled}
                                    name={'evidencneCislo'}
                                    placeholder={translations.artifacts.RegistrationCertificateDataBox.licensePlateId}
                                    value={values.evidencneCislo ? values.evidencneCislo : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={changeValue}
                                />
                            </Box>
                        </span>


                        <span className="gridRow" style={{ marginTop: 10 }}>
                            <Box flex={1}>
                                <Text strong>{translations.artifacts.RegistrationCertificateDataBox.vin}</Text>
                                <Input
                                    disabled={disabled}
                                    className="ant-input"
                                    autoComplete="chrome-off"
                                    name={"vin"}
                                    placeholder={translations.artifacts.RegistrationCertificateDataBox.vinId}
                                    value={values.vin ? values.vin : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={changeValue}
                                />
                            </Box>
                            <Box flex={1}>
                                <Text strong>{translations.artifacts.RegistrationCertificateDataBox.yearOfProduction}</Text>
                                <Input
                                    disabled={disabled}
                                    name={'rokVyrobyVozidla'}
                                    placeholder={translations.artifacts.RegistrationCertificateDataBox.yearOfProductionId}
                                    value={values.rokVyrobyVozidla ? values.rokVyrobyVozidla : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={changeValue}
                                />
                            </Box>
                        </span>

                        <span className="gridRow" style={{ marginTop: 10 }}>
                            <Box flex={1}>
                                <Text strong>{translations.artifacts.RegistrationCertificateDataBox.brand}</Text>
                                <Input
                                    disabled={disabled}
                                    name={'znacka'}
                                    placeholder={translations.artifacts.RegistrationCertificateDataBox.brandId}
                                    value={values.znacka ? values.znacka : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={changeValue}
                                />
                            </Box>
                            <Box flex={1}>
                                <Text strong>{translations.artifacts.RegistrationCertificateDataBox.model}</Text>
                                <Input
                                    disabled={disabled}
                                    className="ant-input"
                                    autoComplete="chrome-off"
                                    name={"druh"}
                                    placeholder={translations.artifacts.RegistrationCertificateDataBox.modelId}
                                    value={values.druh ? values.druh : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={changeValue}
                                />
                            </Box>

                        </span>
                        <span className="gridRow" style={{ marginTop: 10 }}>
                            <Box flex={1}>
                                <Text strong>{translations.artifacts.RegistrationCertificateDataBox.type}</Text>
                                <Input
                                    disabled={disabled}
                                    placeholder={translations.artifacts.RegistrationCertificateDataBox.typeId}
                                    name="typ"
                                    value={values.typ ? values.typ : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={changeValue}
                                />
                            </Box>
                            <Box flex={1}>
                                <Text strong>{translations.artifacts.RegistrationCertificateDataBox.color}</Text>
                                <Input
                                    disabled={disabled}
                                    placeholder={translations.artifacts.RegistrationCertificateDataBox.colorId}
                                    name="farba"
                                    value={values.farba ? values.farba : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={changeValue}
                                />
                            </Box>
                        </span>
                    </Box>
                </div>
            </form>
        </form>

    );
}

export function RegistrationCertificateDataRenderer(values) {

    let returnValue = ""
    if (values) {
        returnValue += 'VIN: '
        returnValue += values.vin ? values.vin : "[Doplňte]"
        returnValue += '<br />'
        returnValue += 'Evidencne cislo: '
        returnValue += values.evidencneCislo ? values.evidencneCislo : "[Doplňte]"
        returnValue += '<br />'
        returnValue += 'Cislo technickeho preukazu: '
        returnValue += values.cisloTechnickehoPreukazu ? values.cisloTechnickehoPreukazu : "[Doplňte]"
        returnValue += '<br />'
        returnValue += 'Rok vyroby: '
        returnValue += values.rokVyrobyVozidla ? values.rokVyrobyVozidla : "[Doplňte]"
        returnValue += '<br />'
        returnValue += 'Druh: '
        returnValue += values.druh ? values.druh : "[Doplňte]"
        returnValue += '<br />'
        returnValue += 'Znacka: '
        returnValue += values.znacka ? values.znacka : "[Doplňte]"
        returnValue += '<br />'
        returnValue += 'Typ: '
        returnValue += values.typ ? values.typ : "[Doplňte]"
        returnValue += '<br />'
        returnValue += 'Farba: '
        returnValue += values.farba ? values.farba : "[Doplňte]"
        returnValue += '<br />'
        return returnValue
    }

}

export function validateRegistrationCertificateData(values) {
    return values !== undefined
}


