import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import { DeleteUserAction, GetUsersListAction, StoreAction } from '../../../app/ActionsImpl';
import { isAdmin, isAuthorized } from '../../../utils/AuthorizationValidation';
import { copyCognito2User } from '../../../utils/string';
import { getAllUrlParams } from '../../../utils/url';
import LoginModal from '../../frontoffice/components/LoginModal';
import UsersListTemplate from '../templates/UsersList.template';
import { AccessDeniedPage } from './AccessDenied.page';
import { Filter, FilterType, UserRoles } from './Interfaces';

const filtersConfig: Filter[] = [
  {
    name: "given_name",
    translation: "Krstné meno",
    value: "",
    type: FilterType.STRING,
    selected: false
  },
  {
    name: "family_name",
    translation: "Priezvisko",
    value: "",
    type: FilterType.STRING,
    selected: false
  },
]

export interface Props extends StateProps, DispatchProps { }

const UsersListPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [filters, setFiltersValues] = useState(JSON.parse(JSON.stringify(filtersConfig)))
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])

  const [items, setItems] = useState({ token: '', limit: 10 });
  const [cogUsers, setCogUsers] = useState<any[]>([])
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);

  const [initialLoad, setInitialLoad] = useState(true)
  const [isFilterLoading, setIsFilterLoading] = useState(false)
  const history = useHistory()

  const [loadNext, setLoadNext] = useState(false)
 

  const createUserDTO = () => {
    return {
      username: undefined,
      userstatus: undefined,
      given_name: undefined,
      family_name: undefined,
      email: undefined,
      email_verified: undefined,
      phone_number: undefined,
      phone_number_verified: undefined,
      custom_marketing_suhlas: undefined
    }
  }

  useEffect(() => {
    if (props.usersList) {

      
      // let tmpDocs = items.docs
      let tmpItems = { token: '', limit: 10 }
      
      // tmpItems.docs = tmpDocs.concat(tmpItems.docs)
      tmpItems.token = props.usersList.token
      tmpItems.limit = props.usersList.limit
      // setShouldLoad(false)
      // if(props.usersList.token) {
        //    setShouldLoad(shouldLoad +1)
        // } else {
          //   setShouldLoad(0)
          // }
          setItems(JSON.parse(JSON.stringify(tmpItems)))
          
          const newUsers = props.usersList.docs.map((user: any) => {
            let userDto = createUserDTO()
            copyCognito2User(user, userDto)
            return userDto
          })
          
          if (cogUsers.length === 0 || tmpItems.token ) {
            setCogUsers([...cogUsers, ...newUsers])
          } else {
            setCogUsers(newUsers)
          }
          
          setIsLoading(false)
          handleResize()
    }
  }, [props.usersList])

  const handleResize = async () => {
    const contentElement = document.getElementById('userListItems'); // Replace 'content' with the actual ID or class of your content element
    const viewportHeight = window.innerHeight;
    if (contentElement) {
      const contentHeight = contentElement.scrollHeight;
      setIsContentOverflowing(contentHeight > viewportHeight);
      // if (!(contentHeight > viewportHeight)) {
      //   setIsLoading(true)
      //   await getUserList();
      // }
    }
  };

  useEffect(() => {

    handleResize();

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    };

  }, []);


  React.useEffect(() => {
    if (props.user) {
      let search = window.location.search
      let params: any = getAllUrlParams()
      if (params.adm === undefined && isAdmin()) {
        if (search.indexOf("?") !== -1) {
          search += '&adm=01'
        } else {
          search += '?adm=01'
        }
        if (history) {
          history.push({
            pathname: window.location.pathname,
            search: search
          })
        }
      }
      getUserList()
    }
  }, [props.user])


  async function getUserList() {
    if (initialLoad || items.token || isFilterLoading) {
      await props.getUsersList(items.token, perPage, parseFilters())
      setInitialLoad(false)
      setIsLoading(false)
    }
  }

  const setFilters = (filterName: string) => {
    let selectedFiltersCopy: string[] = [] //selectedFilters
    for (let filter of filters) {
      filter.value = ""
    }
    selectedFiltersCopy.push(filterName)
    setIsLoading(true)
    setItems({ token: '', limit: 10 })
    setCogUsers([])
    setSelectedFilters(selectedFiltersCopy)
    setInitialLoad(true)
    getUserList()
  }

  const filtersCount = () => {
    let count = 0
    for (let filter of filters) {
      if (filter.value !== "") {
        count += 1
      }
    }
    return count
  }

  const parseFilters = () => {
    let filterUri = ""
    let index = 0
    for (let filter of filters) {
      if (filter.value !== "") {
        filterUri += filter.name + "=" + filter.value
        index += 1
        if (index !== filtersCount()) {
          filterUri += "&"
        }
      }
    }
    return filterUri
  }

  useEffect(() => {
    if(loadNext) {
      nextData()
      setLoadNext(false)
    }
  },[loadNext])

  async function nextData() {
    setIsLoading(true);
    getUserList();
  }

  async function resetData() {
    setItems({ token: '', limit: 10 })
    setCogUsers([])
    setIsLoading(true);
    setIsFilterLoading(true)
  }

  useEffect(() => {
    const func = async () => {
      if (isFilterLoading) {
        await getUserList()
        setIsFilterLoading(false)
      }
    }
    func()
  }, [isFilterLoading])

  const onDocumentChange = (visible: boolean, theLastOne: boolean) => {
      console.log("VISIBILITY SENSOR, visible, token, last id, current id: ",visible,items?.token, theLastOne)
    if (visible && theLastOne &&  items?.token ) {
      setLoadNext(true)
      // nextData()
    }
  }

  return props.user ?
    isAuthorized(UserRoles.ADMIN) ?
      <>
        {cogUsers &&
          <div id="userListItems">
            <UsersListTemplate
              selectedFilters={selectedFilters}
              setFilters={setFilters}
              filters={filters}
              documents={cogUsers} isLoading={isLoading}
              deleteUser={props.deleteUser}
              resetData={resetData}
              onDocumentChange={onDocumentChange}
              
            />
          </div>
        }
        {/* <Spin spinning={isLoading} size="large" /> */}
      </>
      :
      <AccessDeniedPage />
    :
    <LoginModal visible={true} handleClose={() => { }} isPartnerRegistration={false}/>
};

const mapStateToProps = ({ appState }: any) => ({
  usersList: appState.usersList,
  user: appState.cognitoUser
});

interface DispatchProps {
  getUsersList: (token: string, size: number, filters: string) => void;
  deleteUser: (username: string) => void;

}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    getUsersList: (token: string, size: number, filters: string) => dispatch(GetUsersListAction(token, size, filters)),
    deleteUser: (username: string) => dispatch(DeleteUserAction(username))
  }
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UsersListPage)

