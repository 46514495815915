import { StoreAction } from './ActionsImpl';
import * as Constants from './ActionConstants';
import { AppState } from './AppState';
import { notification } from 'antd';
import { message } from 'antd';

const initialState: AppState = { representationActive: true, allowInvoiceOtherCompany: false, language: "sk" };

export function appReducer(state = initialState, action: StoreAction): AppState {
  switch (action.type) {
    case Constants.SET_USER: {
      let user = action.response
      let roles = []
      if (user && user.signInUserSession && user.signInUserSession.accessToken && user.signInUserSession.accessToken.payload && user.signInUserSession.accessToken.payload['cognito:groups']) {
        roles = user.signInUserSession.accessToken.payload['cognito:groups']
      }
      return {
        ...state,
        cognitoUser: action.response,
        roles: roles
      };
    }
    case Constants.GET_USER_PROFILE_DATA: {
      return {
        ...state,
        userProfileData: action.response
      };
    }
    case Constants.SAVE_USER_PROFILE_DATA: {
      notification.success({ message: 'Profil bol ulozeny' });
      return {
        ...state
      };
    }
    case Constants.CREATE_USER_PROFILE_DATA: {
      message.info("Registrácia bola úspešná.", 20)
      return {
        ...state
      };
    }
    //FLOWS
    case Constants.SET_LANGUAGE: {
      return {
        ...state,
        language: action.response,
      };
    }
    case Constants.SET_SWITCH_LOGIN_MODAL: {
      return {
        ...state,
        switchLoadModal: action.response,
      };
    }
    case Constants.GET_FLOW: {
      return {
        ...state,
        flow: action.response.document,
      };
    }
    case Constants.SET_ACTIVE_ARTIFACT: {
      return {
        ...state,
        activeArtifact: action.response,
      };
    }
    case Constants.UPDATE_DOCUMENT: {
      notification.success({ message: 'Dokument bol uložený' });
      return {
        ...state,
        newDocument:
          state.newDocument && state.newDocument.id === action.response
            ? undefined
            : state.newDocument,
      };
    }
    case Constants.UPDATE_METADATA: {
      notification.success({ message: 'Všeobecné informácie boli uložené' });
      return {
        ...state,
      };
    }
    case Constants.UPDATE_DOCUMENT_INDEXES: {
      notification.success({ message: 'Poradie dokumentov bolo uložené' });
      return {
        ...state,
      };
    }
    case Constants.SAVE_ARTIFACT: {
      notification.success({ message: 'Artefakt bol uložený' });
      return {
        ...state,
      };
    }
    case Constants.DELETE_ARTIFACT: {
      notification.success({ message: 'Artefakt bol vymazaný' });
      return {
        ...state,
      };
    }
    case Constants.SET_FLOW_ID: {
      return {
        ...state,
        flowId: action.response,
      };
    }
    case Constants.DELETE_DOCUMENT: {
      notification.success({ message: 'Dokument bol vymazaný' });
      return {
        ...state,
      };
    }
    case Constants.DELETE_FILE_SIGN_REQUEST: {
      notification.success({ message: 'Žiadosť bola zrušená' });
      return {
        ...state,
      };
    }
    case Constants.SET_NEW_DOCUMENT: {
      return {
        ...state,
        newDocument: action.response,
      };
    }
    case Constants.GET_ORDERS_LIST: {
      return {
        ...state,
        ordersList: action.response,
      };
    }
    case Constants.GET_SIGNATURE_REQUESTS: {
      return {
        ...state,
        signatureRequestsList: action.response,
      };
    }
    case Constants.GET_FEEDBACKS_LIST: {
      return {
        ...state,
        feedbacksList: action.response,
      };
    }
    case Constants.GET_USER_ORDERS_LIST: {
      return {
        ...state,
        ordersList: action.response,
      };
    }
    case Constants.ALLOW_INVOICE_OTHER_COMPANY: {
      return {
        ...state,
        allowInvoiceOtherCompany: action.response,
      };
    }
    //VOPS
    case Constants.GET_PUBLIC_VOPS: {
      return {
        ...state,
        publicVops: action.response,
      };
    }
    case Constants.GET_VOPS: {
      return {
        ...state,
        vops: action.response,
      };
    }
    case Constants.DELETE_VOPS: {
      notification.success({ message: 'VOP boli vymazané' });
      return {
        ...state
      };
    }
    case Constants.SAVE_VOPS: {
      if (action.response) {
        notification.success({ message: 'VOP boli uložené' });
      }
      return {
        ...state,
        newVop: action.response
      };
    }
    //PHONE SERVICE CONTRACTS
    case Constants.GET_PHONE_SERVICE_CONTRACTS: {
      return {
        ...state,
        phoneServiceContracts: action.response,
      };
    }
    case Constants.DELETE_PHONE_SERVICE_CONTRACTS: {
      notification.success({ message: 'Zmluvy boli vymazané' });
      return {
        ...state
      };
    }
    case Constants.SAVE_PHONE_SERVICE_CONTRACTS: {
      if (action.response) {
        notification.success({ message: 'Zmluvy boli uložené' });
      }
      return {
        ...state,
        newPhoneServiceContract: action.response
      };
    }
    //PACKAGES
    case Constants.GET_PACKAGES: {
      return {
        ...state,
        packages: action.response,
      };
    }
    case Constants.SET_OPENED_PACKAGE: {
      return {
        ...state,
        openedPackage: action.response,
      };
    }
    case Constants.SAVE_PACKAGE: {
      notification.success({ message: 'Balíček bol uložený' });
      return {
        ...state,
        openedPackage: action.response,
      };
    }
    case Constants.UPDATE_NOTE: {
      notification.success({ message: 'Poznámka bola uložená' });
      return {
        ...state
      };
    }
    case Constants.UPDATE_ORDER_STATUS: {
      notification.success({ message: 'Stav objednávky bol uložený' });
      return {
        ...state
      };
    }
    case Constants.UPDATE_PACKAGES: {
      notification.success({ message: 'Poradie balíčkov bolo uložené' });
      return {
        ...state,
      };
    }
    case Constants.DELETE_PACKAGE: {
      notification.success({ message: 'Balíček bol vymazaný' });
      return {
        ...state,
      };
    }
    case Constants.SET_ERROR: {
      notification.error({ message: action.response.message });
      return {
        ...state,
      };
    }
    case Constants.GET_BLOG_CATEGORIES: {
      return {
        ...state,
        blogCategories: action.response,
      };
    }
    case Constants.GET_BLOG_TITLES: {
      return {
        ...state,
        blogTitles: action.response,
      };
    }
    case Constants.SAVE_BLOG_CATEGORY: {
      notification.success({ message: 'Kategória bola uložená' });
      return {
        ...state,
      };
    }
    case Constants.SAVE_BLOG: {
      notification.success({ message: 'Blog bol uložený' });
      return {
        ...state,
      };
    }
    case Constants.UPLOAD_NEW_DOCUMENT: {
      notification.success({ message: 'Dokument bol uložený' });
      return {
        ...state,
      };
    }
    case Constants.UPLOAD_FILE_FOR_DOCUMENT: {
      notification.success({ message: 'Dokument bol uložený' });
      return {
        ...state,
      };
    }
    case Constants.UPLOAD_SIGNED_FILE_ADMIN: {
      notification.success({ message: 'Dokument bol uložený' });
      return {
        ...state,
      };
    }
    case Constants.UPLOAD_SIGNED_FILE_CUSTOMER: {
      notification.success({ message: 'Dokument bol uložený' });
      return {
        ...state,
      };
    }
    case Constants.UPLOAD_SIGNATURE_IMAGE_CUSTOMER: {
      notification.success({ message: 'Podpis bol uložený' });
      return {
        ...state,
      };
    }
    case Constants.DELETE_SIGNED_FILE_ADMIN: {
      notification.success({ message: 'Podpis bol resetovaný' });
      return {
        ...state,
      };
    }
    case Constants.DELETE_SIGNATURE_IMAGE_CUSTOMER: {
      notification.success({ message: 'Podpis bol vymazaný' });
      return {
        ...state,
      };
    }
    case Constants.DELETE_FILE_FOR_DOCUMENT: {
      notification.success({ message: 'Dokument bol vymazaný' });
      return {
        ...state,
      };
    }
    case Constants.DELETE_FILE_DOCUMENT: {
      notification.success({ message: 'Dokument bol vymazaný' });
      return {
        ...state,
      };
    }
    case Constants.CREATE_FILE_SIGN_REQUEST: {
      notification.success({ message: 'Žiadosť bola odoslaná' });
      return {
        ...state,
      };
    }
    case Constants.GET_BLOG: {
      return {
        ...state,
        blog: action.response,
      };
    }
    case Constants.GET_BLOG_BY_LINK: {
      return {
        ...state,
        blogLink: action.response,
      };
    }
    case Constants.CLEAR_BLOG_LINK: {
      return {
        ...state,
        blogLink: undefined,
      };
    }
    case Constants.CLEAR_BLOG: {
      return {
        ...state,
        blog: undefined,
      };
    }
    case Constants.CREATE_BLOG: {
      return {
        ...state,
        blog: action.blog,
      };
    }
    case Constants.SET_REPRESENTATION_ACTIVE: {
      return {
        ...state,
        representationActive: action.response,
      };
    }
    case Constants.SET_CHOSEN_COMPANY: {
      return {
        ...state,
        company: action.response,
      };
    }
    case Constants.SAVE_USER_LOGIN_COOKIES: {
      return {
        ...state,
        cookieShareJWTToken: action.response,
      };
    }
    case Constants.LOAD_USER_LOGIN_COOKIES: {
      return {
        ...state,
        userLoginData: JSON.parse(action.response.data),
      };
    }
    case Constants.GET_CRAFTS: {
      return {
        ...state,
        zivnosti: action.response,
      };
    }
    case Constants.SET_CRAFTS: {
      notification.success({ message: 'Živnosti bol uložené' });
      return {
        ...state
      };
    }
    //orders
    case Constants.SAVE_ORDER: {
      if (action.response.token) {
        notification.success({ message: 'Formulár bol uložený' });
      }
      return {
        ...state,
        token: action.response.token,
        templateShortId: action.response.shortId,
        templateSaved: action.response.updated_at
      };
    }
    case Constants.UPDATE_ORDER: {
      notification.success({ message: 'Formulár bol uložený' });
      return {
        ...state,
        templateShortId: action.response.shortId,
        templateSaved: action.response.updated_at
      };
    }
    case Constants.SET_SETTINGS: {
      notification.success({ message: 'Nastavenia boli uložené' });
      return {
        ...state,
        settings: action.response
      };
    }
    case Constants.GET_SETTINGS: {
      return {
        ...state,
        settings: action.response
      };
    }
    case Constants.LAMBDA_USAGE: {
      return {
        ...state,
        lambdaUsage: action.response
      };
    }
    case Constants.GET_TEMPLATE: {
      return {
        ...state,
        template: JSON.parse(action.response.template.template),
        templateShortId: action.response.template.shortId,
        token: action.response.token,
        userEmail: action.response.template.email,
        templateSaved: action.response.updated_at
      };
    }
    case Constants.GET_TEMPLATES: {
      return {
        ...state,
        formsList: action.response,
      };
    }
    case Constants.GET_USER_ORDERS_AMOUNT: {
      return {
        ...state,
        userOrdersAmount: action.response,
      };
    }
    case Constants.GET_TEMPLATE_ADMIN: {
      return {
        ...state,
        orderToEdit: action.response
      };
    }
    case Constants.UPDATE_FORMSTATE: {
      if (action.response) {
        notification.success({ message: 'Údaje boli zmenené' });
      }
      return {
        ...state
      }
    }
    case Constants.GENERATE_REFUND: {
      if (action.response) {
        notification.success({ message: 'Dobropis bol vygenerovaný' });
      }
      return {
        ...state
      }
    }
    case Constants.SAVE_COGNITO_USER_PROPERTIES: {
      if (action.response) {
        notification.success({ message: 'Údaje boli zmenené' });
      }
      return {
        ...state
      }
    }
    case Constants.ACCEPT_PHONE_SERVICE_CONTRACT: {
      if (action.response) {
        notification.success({ message: 'Súhlas bol uložený' });
      }
      return {
        ...state
      }
    }
    case Constants.SET_USER_PERSONS: {
      return {
        ...state,
        userPersons: action.response
      }
    }
    case Constants.RECENTLY_CONFIRMED: {
      return {
        ...state,
        recentlyConfirmed: action.response
      }
    }
    case Constants.GET_USER: {
      return {
        ...state,
        cognitoUserToEdit: action.response
      }
    }
    case Constants.UPDATE_USER: {
      if (action.response) {
        notification.success({ message: 'Údaje o užívateľovi boli uložené.' });
      }
      return {
        ...state
      }
    }

    case Constants.GET_USERS_LIST: {
      return {
        ...state,
        usersList: action.response,
      };
    }
    default:
      return state;
  }
}
