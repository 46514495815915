import { renderSeparatedAddress } from "../../../../utils/string";
import NestedArtifactKeys from "./NestedArtifactKeys";

function ServiceContractClient(values, formData, user, userProfileData) {

  console.log(formData)

  let returnValue = "<div>"
  if (user?.attributes && user?.attributes["custom:customer_role"]) {
    if (userProfileData?.partnerData?.legalForm?.code === "112") {
      returnValue += `<b>${userProfileData?.partnerData?.business_name},</b> IČO: ${userProfileData?.partnerData?.cin}, so sídlom: ${renderSeparatedAddress(userProfileData?.partnerData?.address)}, konajúca osoba splnomocnenca: ${values.plnomocenstvo_header_custom_partner ? values.plnomocenstvo_header_custom_partner : "[Doplňte]"}(ďalej len <b>„Splnomocnený“</b>)`
    } else {
      returnValue += `<b>${userProfileData?.partnerData?.business_name},</b> IČO: ${userProfileData?.partnerData?.cin}, so sídlom: ${renderSeparatedAddress(userProfileData?.partnerData?.address)}</br>(ďalej len <b>„Splnomocnený“</b>)`
    }
  } else if (formData.personType) {
    if (formData.personType === "ownerCompany") {
      returnValue += '<b>'
      returnValue += formData.spolocnost ? formData.spolocnost : "[Doplňte]"
      returnValue += '</b>'
      returnValue += ` so  sídlom na adrese: ${formData.adresa ? formData.adresa : "[Doplňte]"}`
      returnValue += `, IČO: ${formData.ico}`
      // returnValue += `, obchodná spoločnosť zapísaná v Obchodnom registri ${NestedArtifactKeys.HTMLRenderer('company.data.oddiel', values)}`
      // returnValue += `, oddiel: ${NestedArtifactKeys.HTMLRenderer('company.data.oddiel', values)}`
      // returnValue += `, vložka č.: ${NestedArtifactKeys.HTMLRenderer('company.data.vlozka', values)}`
      if (formData.legalForm?.code === "112") {
        returnValue += ', v mene ktorej je oprávnená samostatne a bez akýchkoľvek obmedzení konať osoba uskutočňujúca Dokončenie objednávky'
      }
      returnValue += " (ďalej v texte len <b>„Klient“</b> a/alebo <b>„Používateľ“</b>)"
    } else {
      returnValue += '<b>'
      returnValue += formData.name ? formData.name : "[Doplňte]"
      returnValue += '</b>,'
      returnValue += ` bydlisko: ${formData.adresa ? formData.adresa : "[Doplňte]"}`
      returnValue += ' (ďalej v texte len <b>„Klient“</b> a/alebo <b>„Používateľ“</b>)'
    }
  }
  returnValue += "</div>"

  if (returnValue !== '') {
    return returnValue
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: ServiceContractClient,
};
