import React, { useEffect } from 'react';
import { Input, Checkbox, Button, Divider } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { countries } from '../../../../editor/countries/countries';
import { Option, Select, SelectWithAddItemOption } from '../../../../../components/Input';
import { ConfigProvider, Form } from 'antd';
import moment from 'moment';
import sk_SK from 'antd/lib/locale/sk_SK';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { transformLikvidatorValue, transformProkuristaValue } from '../../../transformers';
import { dots } from '../../../../../utils/constants';
import { RenderSeparatedName, renderSeparatedAddress } from '../../../../../utils/string';
import { TypInyIdentifikatorEnum } from '../../../../admin/pages/Interfaces';
import { TypInyIdentifikatorEstablishmentTabsEnum } from '../../../../admin/pages/Interfaces';

function PlnomocenstvoHeaderComponent({
  documentId,
  name,
  label = 'Select',
  ...rest
}) {
  const valuesPartner = useFormDataContext(({ data, setField }) => ({
    konatelia: data['konatelia_establishment_custom'],
    custom: [],
    value: data["plnomocenstvo_establishment_header_custom"] || [],
    setField,
  }));

  const [fieldValuesPartner, setFieldValuesPartner] = React.useState(valuesPartner);
  const [value, setValue] = React.useState("");
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);


  const handleChangePartner = () => {
    valuesPartner.setField({
      target: {
        name: "plnomocenstvo_header_custom_partner",
        value: fieldValuesPartner.value
      },
    });
    forceUpdate()
  }


  return (
    <>
      {rest.user?.attributes && rest.user?.attributes["custom:customer_role"] &&
        rest.userProfileData?.partnerData?.legalForm?.code === "112" &&
        <Box flex={1}>
          <p className='inputLabelTooltip'>
            <Text strong>
              Konajúca osoba splnomocnenca
            </Text>
          </p>
          <Input
            name="represetingPartner"
            onBlur={handleChangePartner}
            placeholder={"Konajúca osoba splnomocnenca"}
            onChange={(e) => {
              let fieldValuesPartnerCopy = JSON.parse(JSON.stringify(fieldValuesPartner))
              fieldValuesPartnerCopy.value = e.target.value
              setFieldValuesPartner(fieldValuesPartnerCopy)
            }}
            value={fieldValuesPartner.value}
          />
        </Box>
      }
    </>
  );
}

function PlnomocenstvoHeaderHTMLRenderer(values, user, userProfileData) {
  let returnValue = '';
  if (
    values && values['konatelia_establishment_custom'] && values['konatelia_establishment_custom'].konatelia
  ) {
    console.log(values)
    returnValue += `Dolu podpísaný klient: `
    let index = 0;
    for (let representingPerson of values['konatelia_establishment_custom'].konatelia) {
      let person = representingPerson
      let tabLength = "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;"
      returnValue += `${index === 0 ? '&#9;' : tabLength}<b>Meno a priezvisko:</b>&#9;&#9;&#9;&#9;&#9;${person.separatedName ? RenderSeparatedName(person.separatedName) : "[DOPLŇTE]"}<br>`;
      let addressTabLength = "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;"
      returnValue += `${tabLength}<b>bydlisko:</b>${addressTabLength}`
      returnValue += person.address.street ? person.address.street : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.number ? person.address.number : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.zip ? person.address.zip : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.city ? person.address.city : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.country ? countries.sk[person.address.country] : "[Doplňte]"
      returnValue += `<br>`;
      let dobTabLength = "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;"
      returnValue += `${tabLength}<b>narodený:</b>${dobTabLength}${person.dateOfBirth ? person.dateOfBirth : "[Doplňte]"}`

      let idTabLength = person.idType ? TypInyIdentifikatorEstablishmentTabsEnum[person.idType] : "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;"
      returnValue += `<b><br>${tabLength}${person.idType ? TypInyIdentifikatorEnum[person.idType] : "[Doplňte]"}:</b>${idTabLength}${person.idNumber ? person.idNumber : "[Doplňte]"}`

      returnValue += "<br><br>"
      index += 1
    }
    returnValue += `(ďalej len <b>„Spoločnosť“</b>)</br></br>ako prvý konateľ spoločnosti ${values.companyName}, so sídlom spoločnosti ${values.companyAddress?.address ? renderSeparatedAddress(values.companyAddress?.address) : "[Doplňte]"} (ďalej len „Spoločnosť“), spoločnosť založená Spoločenskou zmluvou zo dňa ${values.dateOfMeeting} týmto udeľujem plnomocenstvo splnomocnencovi:</br>`
    if (user?.attributes && user?.attributes["custom:customer_role"]) {
      if (userProfileData?.partnerData?.legalForm?.code === "112") {
        returnValue += `</br><b>${userProfileData?.partnerData?.business_name},</b> IČO: ${userProfileData?.partnerData?.cin}, so sídlom: ${renderSeparatedAddress(userProfileData?.partnerData?.address)}, konajúca osoba splnomocnenca: ${values.plnomocenstvo_header_custom_partner ? values.plnomocenstvo_header_custom_partner : "[Doplňte]"}</br>(ďalej len <b>„Splnomocnený“</b>)`
      } else {
        returnValue += `</br><b>${userProfileData?.partnerData?.business_name},</b> IČO: ${userProfileData?.partnerData?.cin}, so sídlom: ${renderSeparatedAddress(userProfileData?.partnerData?.address)}</br>(ďalej len <b>„Splnomocnený“</b>)`
      }
    } else {
      returnValue += `</br><b>Matejka Friedmannová s. r. o.,</b> IČO: 47 248 998, so sídlom: Dunajská 48, 811 08 Bratislava – mestská časť Staré Mesto, Slovenská republika, zapísaná v Obchodnom registri Mestského súdu Bratislava III, Oddiel: Sro, Vložka číslo: 90113/B, konajúca: JUDr. Ondrejom Matejkom, konateľ</br>(ďalej len <b>„Advokátska kancelária“</b>)`
    }
  }

  if (returnValue !== '') {
    return returnValue
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: PlnomocenstvoHeaderComponent,
  HTMLRenderer: PlnomocenstvoHeaderHTMLRenderer,
};
