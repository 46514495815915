// src/components/MapComponent.jsx
import { Button, notification } from 'antd';
import domtoimage from 'dom-to-image';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Map, Polygon, TileLayer } from 'react-leaflet';
import { prepareLayerPokryvUzemiSvazku } from '../../../../utils/GeometryUtils';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import Legend from '../Legend';
import { captureMapImage, fetchMapLayer, setupLeafletDefaults } from '../../../../utils/MapaUtils';

setupLeafletDefaults()

const MapaKrajinnyPokryvUzemiSvazkuMutator = ({ name }) => {
    const title = "Mapa Krajiny pokrytí území svazky";
    const mapUniqueId = "mapaKrajinnyPokryvUzemiSvazku" + name ? name : "";

    const values = useFormDataContext(({ data, setField }) => ({
        value: data[name] || { position: [49.94297310, 17.03212700], zoom: 13 },
        municipality: data.municipalities[0],
        setField
    }));
    const [fieldValues, setFieldValues] = useState(values)
    const [isActiveMap, setIsActiveMap] = useState(false);
    const [opacity, setOpacity] = useState(0.75);
    const mapRef = useRef();
    const containerRef = useRef();

    const handleChange = () => {
        values.setField({
            target: {
                name,
                value: fieldValues.value
            },
        });
    }

    const onMoveEnd = () => {
        const map = mapRef.current.leafletElement;
        const newCenter = map.getCenter();
        let tmpFieldValues = { ...fieldValues };
        tmpFieldValues.value.position = [newCenter.lat, newCenter.lng];
        tmpFieldValues.value.zoom = map.getZoom();
        captureMapImage(containerRef, setFieldValues, fieldValues, values, name, false);
    };

    useEffect(() => {
        if (values.municipality.geoBoundingBox && mapRef.current) {
            const map = mapRef.current.leafletElement;
            map.fitBounds(values.municipality.geoBoundingBox);
        }
    }, []);

    useEffect(() => {
        const mapInstance = mapRef.current.leafletElement;
        if (mapInstance && !isActiveMap) {
            mapInstance.dragging.disable();
            mapInstance.touchZoom.disable();
            mapInstance.doubleClickZoom.disable();
            mapInstance.scrollWheelZoom.disable();
            mapInstance.boxZoom.disable();
            mapInstance.keyboard.disable();
        }
    }, [isActiveMap]);

    const enableMapInteractions = () => {
        const mapInstance = mapRef.current.leafletElement;
        if (!isActiveMap && mapInstance) {
            setIsActiveMap(true);
            mapInstance.dragging.enable();
            mapInstance.touchZoom.enable();
            mapInstance.doubleClickZoom.enable();
            mapInstance.scrollWheelZoom.enable();
            mapInstance.boxZoom.enable();
            mapInstance.keyboard.enable();
        }
    };

    const disableMapInteractions = (event) => {
        const mapElement = document.getElementById(mapUniqueId);
        if (!mapElement.contains(event.target)) {
            const mapInstance = mapRef.current.leafletElement;
            if (isActiveMap && mapInstance) {
                setIsActiveMap(false);
                mapInstance.dragging.disable();
                mapInstance.touchZoom.disable();
                mapInstance.doubleClickZoom.disable();
                mapInstance.scrollWheelZoom.disable();
                mapInstance.boxZoom.disable();
                mapInstance.keyboard.disable();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', disableMapInteractions);

        return () => {
            document.removeEventListener('click', disableMapInteractions);
        };
    }, [isActiveMap]);

    const handleOpacityChange = (event) => {
        setOpacity(event.target.value);
    };

    return (
        <div className='open-street-map'>
            <Button
                className='addButton'
                style={{ marginRight: 20 }}
                onClick={() => {
                    captureMapImage(containerRef, setFieldValues, fieldValues, values, name, true)
                }}>Vložiť do náhľadu</Button>
            <Button
                className='addButton'
                onClick={() => {
                    fetchMapLayer(prepareLayerPokryvUzemiSvazku, values.municipality.geoJson, setFieldValues, fieldValues, containerRef, values, name)
                }}>Stiahnuť údaje</Button>
            <div ref={containerRef} className={isActiveMap ? 'open-street-map is-active-map' : 'open-street-map'}>
                <Map onblur={handleChange}
                    center={values?.municipality?.geoCenter}
                    zoom={fieldValues.value.zoom}
                    ref={mapRef}
                    onMoveEnd={onMoveEnd}
                    onZoomEnd={onMoveEnd}
                    id={mapUniqueId}
                    onClick={enableMapInteractions}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    >
                    </TileLayer>
                    <Polygon key={"524862865862466984"} positions={values?.municipality?.geoJson} opacity={opacity} />
                    {fieldValues?.value?.geoLayer &&
                        <Polygon color='#8B4513' key={"524862865862466985"} positions={[fieldValues?.value?.geoLayer]} />
                    }
                </Map>
                <Legend
                    title={title}
                    url={fieldValues?.value?.geoServer?.geoUrl + "/legend"}
                    layerId={fieldValues?.value?.geoServer?.layerId}
                    opacity={opacity}
                />
            </div>
            <div className='opacity-slider-map'>
                <label>Viditeľnosť vrstiev</label>
                <input
                    type="range"
                    id="opacity"
                    name="opacity"
                    min="0"
                    max="1"
                    step="0.02"
                    value={opacity}
                    onChange={handleOpacityChange}
                />
            </div>
            <span><strong>Zdroj:</strong> AOPK-vlastní zpracování</span>
        </div>
    );
};


function MapaKrajinnyPokryvUzemiSvazkuRenderer(values, key) {
    let returnValue = ""
    if (values[key]) {
        returnValue += `<img src="${values[key].image}" alt="Map view" style="height: 100%; width: 100%; object-fit: cover; margin-top: 3.5rem; border-radius: 4px;" />`;
    }

    return returnValue
}

function MapaKrajinnyPokryvUzemiSvazkuValidator() {
    return true;
}

export default {
    Mutator: MapaKrajinnyPokryvUzemiSvazkuMutator,
    HTMLRenderer: MapaKrajinnyPokryvUzemiSvazkuRenderer,
    Validator: MapaKrajinnyPokryvUzemiSvazkuValidator
};
