function ContractPointRenderer(values) {
  return (values && values['generate-protocol-custom'] && values['generate-protocol-custom'].amount === 1) ?
    "1. Zmluvné strany sa dohodli na tom, že Predávajúci odovzdá Kupujúcemu motorové vozidlo vrátane príslušenstva do užívania Kupujúcemu Protokolom o  odovzdaní a prevzatí motorového vozidla a to najneskôr 10 dní od úplnej úhrady kúpnej ceny podľa článku III. tejto Zmluvy. Predávajúci spolu s motorovým vozidlom odovzdá Kupujúcemu príslušenstvo motorového vozidla, kľúče a všetky doklady, ktoré sa vzťahujú k Predmetu kúpy."
    :
    "1. Zmluvné strany sa dohodli na tom, že Predávajúci odovzdá Kupujúcemu motorové vozidlo vrátane príslušenstva do užívania kupujúcemu pri podpise tejto Zmluvy. Predávajúci spolu s motorovým vozidlom odovzdá kupujúcemu príslušenstvo motorového vozidla, kľúče a všetky doklady, ktoré sa vzťahujú k Predmetu kúpy."
}

export default {
  HTMLRenderer: ContractPointRenderer
};
