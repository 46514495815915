import { Button } from 'antd';
import { Box } from '../Box';
import Text from '../Text';
import { Icon } from 'semantic-ui-react'

type Props = {
  label: string;
  onClick?: () => void;
  background?: string;
  disableMarginBottom?: boolean;
  active: boolean;
  button?: any;
  onButtonClick: (key: string) => void
  disabled?: Boolean
};

export const SidebarSectionLabel: React.FC<Props> = ({
  label,
  disableMarginBottom,
  onClick,
  background = '#0b1e4e',
  active,
  button,
  onButtonClick,
  disabled
}) => {

  let labelID = label.substring(label.indexOf(".") + 1).trim()

  return (
    <Box
      style={{ cursor: "pointer", background: active ? "#a8c230" : 'transparent', borderRadius: 4, }}
      onClick={onClick}
      id={active ? 'sidebar-label-active' : ''}
      flex={1}
      display="flex"
      background={background}
      padding="0.8rem 1.5rem"
      flexDirection="row"
      alignItems="center"
      className={active ? 'sidebar--label active ' + labelID : 'sidebar--label ' + labelID}
      marginBottom={!disableMarginBottom ? '0.5rem' : undefined}>
      <div className='leftNavItem' style={{ width: button ? "80%" : "100%" }}>
        <Icon name='tasks' />
        <Text style={{ fontWeight: 500 }}>{label}</Text>
      </div>
      <div style={{ width: button ? "20%" : "0%" }}>
        {button && <Button onClick={() => onButtonClick("NEW")} shape="circle">{button}</Button>}
      </div>
    </Box>
  );
};
