import { StarTwoTone } from '@ant-design/icons';
import { Button, Divider, Form, Input, Modal, Spin, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import mainLogo from '../../images/blueLogo.webp';
import download from '../../images/mainBack.webp';
import logo from '../../images/matejkaLogo.webp';
import eco from '../../images/ecofriendly.webp';
import Stepper from '../frontoffice/components/Stepper';

import { Dispatch } from 'redux';
import { Icon } from 'semantic-ui-react';
import { SaveFeedbackAction, StoreAction } from '../../app/ActionsImpl';
import { translations } from '../../utils/LocalizedStrings';
import {
  StyledContainer,
  StyledDocumentCard
} from './DocumentDownload.styled';
import { DocumentDownloadOverview } from './DocumentDownloadOverview';
import TextAreaWithDynamicPlaceholder from './TextAreaWithDynamicPlaceholder';
import { LANDING_PAGE_URI } from '../../constants';

interface Props extends DispatchProps {
  isAuthenticated: boolean;
  documents: any;
  company: any;
  email?: string;
  marketingId: string;
  isLoading: Boolean;
  isRedirecting: Boolean;
  errorMessage: string;
  onEnterPassword: (password: string) => void;
  triggerDownload: (documentItem: any) => void;
  triggerDownloadAll: () => void;
  token: any;
  orderId: any
}

const { Paragraph, Text } = Typography;

const DocumentDownloadPage: FC<Props> = ({
  company,
  isAuthenticated,
  documents,
  email,
  marketingId,
  isLoading,
  isRedirecting,
  errorMessage,
  onEnterPassword,
  triggerDownload,
  triggerDownloadAll,
  saveFeedback,
  token,
  orderId
}) => {
  const [enterPass, setEnterPass] = useState('');
  const [isOverviewVisible, setOverviewVisible] = useState<any[]>([]);

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  let stepsArray = [
    translations.artifacts.StepperBox.firstStep,
    translations.artifacts.StepperBox.thirdStep,
    translations.artifacts.StepperBox.fourthStep
  ];

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [rating, setRating] = useState(5);
  const [hover, setHover] = useState(0);



  const handleChangeFeedback = (e: any) => {
    setFeedbackMessage(e.target.value);
  };

  const sendFeedback = () => {
    saveFeedback(marketingId, rating, feedbackMessage)
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (!isAuthenticated && !isLoading && !isRedirecting) {
    return (
      <>
        <div className='documentDownloadPage'>
          <span className="logoContainer">
            <a onClick={() => window.location.href = LANDING_PAGE_URI ? LANDING_PAGE_URI : ''}>
              <img className="orsrLogo" src={mainLogo} alt="logo" />
            </a>
          </span>
          <StyledContainer>
            <img className="downloadPicture" src={download} alt="picture" />
            <div className="downloadPictureBackground"></div>
            {!errorMessage &&
              <>
                <Title className="downloadTitle">{translations.artifacts.DocumentsDownload?.enterOrderPassword}</Title>
                <Form className="downloadFormFailed">
                  <Input.Group compact>
                    <Input
                      style={{
                        height: '4rem',
                        fontSize: 24,
                      }}
                      type="password"
                      value={enterPass}
                      onKeyDown={(e) => e.key === 'Enter' && onEnterPassword(enterPass)}
                      onChange={(e) => setEnterPass(e.target.value.toUpperCase())}
                    />
                    <Button
                      className='downloadConfirmButton'
                      htmlType="submit"
                      type="primary"
                      onClick={() => onEnterPassword(enterPass)}>
                      Potvrdiť
                    </Button>
                  </Input.Group>
                </Form>
              </>}
            {errorMessage && (
              <div className='downloadDocumentsReadyContainer downloadErrorMessage'>
                <h4 className='downloadTitleReady' style={{ textAlign: 'center' }}>{errorMessage}</h4>
              </div>
            )}
          </StyledContainer>
        </div>
      </>
    );
  }
  if (isLoading) {
    return (
      <StyledContainer>
        <div className="downloadLoader" />
        <img className="downloadPicture" src={download} alt="picture" />
        <div className="downloadPictureBackground"></div>
        <Title className="downloadTitle2">
          {translations.artifacts.DocumentsDownload?.weAreCookingYourDocuments}
          <br /> {translations.artifacts.DocumentsDownload?.itDoesntTakeMoreThan}
          <Spin className='spinLoading' />
        </Title>
      </StyledContainer>
    );
  }
  if (isRedirecting) {
    return (
      <StyledContainer>
        <div className="downloadLoader" />
        <img className="downloadPicture" src={download} alt="picture" />
        <div className="downloadPictureBackground"></div>
        <Spin className='spinLoading' />
      </StyledContainer>
    );
  }
  return (
    <>
      <div className="document-download">
        {/* {isModalOpen && <Modal className='feedbackModal' onCancel={handleCancel} title="Poviete nám, ako ste s nami spokojní?" visible={isModalOpen} footer={[
          <Button type="primary" onClick={sendFeedback}>Odoslať hodnotenie</Button>
        ]}>
          <div className='ratingStarsContainer'>
            {[...Array(5)].map((star, i) => {
              const currentRating = i + 1
              return (
                <label>
                  <input type="radio" name="rating" value={currentRating} onClick={() => setRating(currentRating)} />
                  <StarTwoTone
                    height="40rem"
                    className='star'
                    twoToneColor={currentRating <= (hover || rating) ? "#a8c230" : "#e4e5e9"}
                    onMouseEnter={() => setHover(currentRating)}
                    onMouseLeave={() => setHover(0)}
                  />
                </label>
              )
            })}
          </div>
          <div className='textareaContainer'>
            <span>Vaše hodnotenie:</span>
            <TextAreaWithDynamicPlaceholder
              id='feedbackTextarea'
              className='feedbackTextarea'
              value={feedbackMessage}
              onChange={handleChangeFeedback}
            ></TextAreaWithDynamicPlaceholder>
          </div>
        </Modal>
        } */}
        <span className="logoContainer">
          <a onClick={() => window.location.href = LANDING_PAGE_URI ? LANDING_PAGE_URI : ''}>
            <img className="orsrLogo" src={mainLogo} alt="logo" />
          </a>
        </span>
        <span className="orderBasketIcon user" onClick={() => (window.location.href = '/user-profile')}>
          <Icon name="user" />
          <strong className='iconUserName'>Môj profil</strong>
        </span>
        <StyledContainer className="downloadForm">
          <Stepper steps={stepsArray} currentStepNumber={3} />
          <img
            className="downloadPicture"
            src={download}
            alt="picture"
          />
          <div className="downloadPictureBackground"></div>
          <div className='downloadDocumentsReadyContainer'>
            <Title className="downloadTitleReady">
              {translations.artifacts.DocumentsDownload?.itsDone}
              <br /> {translations.artifacts.DocumentsDownload?.docsAreReady}
              <br /> {translations.artifacts.DocumentsDownload?.justDownload}
            </Title>
            <div className="downloadDescription">
              {translations.artifacts.DocumentsDownload?.linkInEmail}
              <span className='downloadEmail'>{translations.artifacts.DocumentsDownload?.thanks}</span>
            </div>
          </div>
        </StyledContainer>
        <StyledDocumentCard className='documentDownloadContainer'>
          {documents?.map((documentItem: any) => {
            if (!documentItem) {
              return null;
            }
            const { name, link } = documentItem;
            return (
              <>
                <div className='documentDownloadRow' key={`doc-downloadn-${name}`}>
                  <b>{name}</b>
                  {/* <a href={link} target="_blank" rel="noopener noreferrer"> */}
                  <span>
                    <DocumentDownloadOverview token={token} documentItem={documentItem} orderId={orderId} />
                    <Button
                      size="large"
                      type="primary"
                      onClick={() => triggerDownload(documentItem)}>
                      Stiahnuť
                    </Button>
                  </span>
                  {/* </a> */}
                </div>
              </>
            );
          })}
          <Divider />
          <div className='documentDownloadButtonContainer'>
            <Button size="large" type="primary" onClick={() => (window.location.href = '/user-profile')}>
              {translations.artifacts.DocumentsDownload?.kepSigning} <Icon name="leaf" />
            </Button>
            <Button size="large" type="primary" onClick={() => triggerDownloadAll()}>
              {translations.artifacts.DocumentsDownload?.downloadAll}
            </Button>
          </div>
          <Divider className='ecoFriendlyDivider' />
          <div className='ecoFriendlyFooter'>
            <img src={eco} alt='eco friendly' />
            <div>
              <h3>Podpíšte dokumenty ekologicky</h3>
              <p>Elektronickým podpísaním dokumentov prispievate k zníženiu uhlíkovej stopy aj ochrane lesov. Za rok vieme takto spoločne znížiť <strong>uhlíkovú stopu o cca 75 ton CO<span style={{ fontSize: 10 }}>2</span></strong> a ušetriť <strong>vyrúbanie 1000 stromov.</strong></p>
            </div>
          </div>
        </StyledDocumentCard>
        <footer className="footer">
          <div>
            <p>&copy; SMS AI s.r.o. {new Date().getFullYear()}</p>
          </div>
          <nav>
            <ul>
              <li>
                <a
                  href="https://www.matejka.company/docs/kodex%20GDPR%20sk_en.pdf.pdf"
                  target="_blank">
                  {translations.artifacts.DocumentsDownload?.gdpr}
                </a>{' '}
              </li>
            </ul>
          </nav>
        </footer>
      </div>
    </>
  );
};

const mapStateToProps = ({ appState }: any) => ({
  company: appState.company
});


interface DispatchProps {
  saveFeedback: (marketingId: string, rating: number, feedbackMessage: string) => void;
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    saveFeedback: (marketingId: string, rating: number, feedbackMessage: string) => dispatch(SaveFeedbackAction(marketingId, rating, feedbackMessage))

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDownloadPage);
