import { Button, Card, Checkbox, Form, Input, Radio } from 'antd';
import { useState } from 'react';
import { translations } from '../../../utils/LocalizedStrings';
import { validatePassword } from '../../../utils/string';
import Navigation from '../templates/Navigation';
import { useHistory } from 'react-router';

export function UsersProcessForm(
  { form,
    onFinish,
    onFinishFailed,
    mode }:
    {
      form: any,
      onFinish: any,
      onFinishFailed: any,
      mode: string
    }) {

  const [hasChanged, setHasChanged] = useState(false)


  const onValuesChange = (changedValues: any, allValues: any) => {
    setHasChanged(true)
  };

  const history = useHistory()

  const stepBack = () => {
    history.push("/back-office/users")
  }

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ remember: true }}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <Button onClick={() => stepBack()} type="primary">
        Krok spat
      </Button>
      <Form.Item
        label={translations.artifacts.UserProcessForm?.username}
        name="username"
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label={translations.artifacts.UserProcessForm?.userstatus}
        name="userstatus"
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label={translations.artifacts.UserProcessForm?.given_name}
        name="given_name"
        rules={[{ required: true, message: translations.artifacts.UserProcessForm?.enterValue }]}      >
        <Input />
      </Form.Item>

      <Form.Item
        label={translations.artifacts.UserProcessForm?.family_name}
        name="family_name"
        rules={[{ required: true, message: translations.artifacts.UserProcessForm?.enterValue }]}      >
        <Input />
      </Form.Item>
      <Form.Item
        label={translations.artifacts.UserProcessForm?.email}
        name="email"
        rules={[{ required: true, message: translations.artifacts.UserProcessForm?.enterValue }]}      >
        <Input />
      </Form.Item>
      <Form.Item name="email_verified" valuePropName="checked">
        <Checkbox disabled>{translations.artifacts.UserProcessForm?.email_verified}</Checkbox>
      </Form.Item>
      <Form.Item
        label={translations.artifacts.UserProcessForm?.phone_number}
        name="phone_number"
        rules={[
          {
            required: true,
            message: 'Please input your phone number!',
          },

        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="phone_verified"
        valuePropName="checked"
        label={translations.artifacts.UserProcessForm?.phone_number_verified}>
        <Checkbox disabled></Checkbox>
      </Form.Item>

      {(mode === 'create' &&
        <>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: translations.artifacts.UserProcessForm?.enterPassword,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    if (validatePassword(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error(translations.artifacts.UserProcessForm?.passwordValidation));
                    }
                  } else {
                    return Promise.reject(new Error(translations.artifacts.UserProcessForm?.passwordDoNotMatch));
                  }
                }
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </>)}

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button disabled={!hasChanged} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>

  )

};

export function UserProcessHeader(
  {
    mode
  }: {
    mode: string
  }) {

  return (
    <>
      <Navigation />
      <Card style={{ background: '#f0f0f0', padding: "4% 15% 0 15%" }}>
        <div>
          <div style={{ display: 'flex' }}>
            <h3
              style={{
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '1.375rem',
                margin: 0
              }}>
              Cognito užívateľ {mode === 'create' ? 'vytvorenie' : 'úprava'}
            </h3>
            <div>

            </div>

          </div>

        </div>
      </Card>
    </>
  );
}

// const mapStateToProps = ({ appState }: any) => ({
// });

// interface DispatchProps {
//   getUser: (username: string) => {};
//   updateUser: (username: {}) => void;

// }

// function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
//   return {
//     getUser: (username: string) => dispatch(GetUserAction(username)),
//     updateUser: (user: {}) => dispatch(UpdateUserAction(user))
//   }
// };

// type StateProps = ReturnType<typeof mapStateToProps>;

// export default connect(mapStateToProps, mapDispatchToProps)(UsersProcessPage)

