import { useEffect, useRef, useState } from 'react';
import { Grid, Divider, Button, Form, Card, CardContent, Search } from 'semantic-ui-react';
import { Checkbox } from 'antd';
import Stepper from './Stepper';
import VopModal from '../components/inputs/VopModal';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getAllUrlParams } from '../../../utils/url';
import NewCompanyAddress from './inputs/NewCompanyAddress';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { RenderSeparatedName, isEmailValid, renderAddress, renderSeparatedAddress, validatePassword } from '../../../utils/string';
import ContractModal from './inputs/ContractModal';
import moment from 'moment';
import _ from 'lodash';
import { COMPANY_LOOKUP_HOST } from '../../../constants';
import { translations } from '../../../utils/LocalizedStrings';
import PasswordStrengthBar from 'react-password-strength-bar';
import { IsUserAction } from '../../../app/ActionsImpl';

const CheckoutForm = ({
  onSuccess,
  clearSteps,
  documents,
  values,
  documentsMap,
  user,
  userOrdersCount,
  returnLessons,
  templateToken,
  handleLoginForExistingUser,
  isUserAction
}) => {
  const editorState = useSelector((state) => state.editor.editorState);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});
  const [invoicePerson, setInvoicePerson] = useState("buyer");

  const [price, setPrice] = useState(0);
  const [count, setCount] = useState(0);
  const [selectedSteps] = useState(getAllUrlParams().steps?.split(','));
  const [validEmail, validateEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [validationPassMessages, setValidationPassMessages] = useState('');
  const [checkedConfirmCheckbox, setCheckedConfirmCheckbox] = useState(true);
  const [vopModalVisible, setVopModalVisible] = useState(false);
  const [contractModalVisible, setContractModalVisible] = useState(false);
  const [isPhoneNumberValid, setPhoneNumberValid] = useState(true);
  const [companyName, setCompanyName] = useState(true);
  const [shouldLogin, setShouldLogin] = useState(false);

  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [selectedCompany, setSelectedCompany] = useState({});
  const [results, setResults] = useState([]);
  const promises = useRef();

  const handleField = (e) => {
    if (e.persist) {
      e.persist();
    }
    setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const personTypeOption = [
    { key: 'p', text: 'Fyzická osoba', value: 'ownerPerson' },
    { key: 'c', text: 'Podnikateľ, právnická osoba', value: 'ownerCompany' }
  ]


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!user && isUserAction(formData.kontakt_email)) {
      setShouldLogin(true)
      return
    }

    const orderKey = `${formData.kontakt_email}_${Date.now()}`;
    let finalPrice = parseFloat(price);
    onSuccess({
      ...formData,
      orderKey,
      price:
        finalPrice * 1.2,
      priceWithoutVat: finalPrice,
      templateToken: templateToken,
      campaignsAcceppted: Date.now()
    });
  };

  useEffect(() => {
    let priceParsed = JSON.parse(JSON.stringify(editorState.metadata.prices));
    let priceToRefund = 0
    priceParsed =
      priceParsed
        .sort((a, b) =>
          parseInt(a.count) > parseInt(b.count)
            ? 1
            : parseInt(b.count) > parseInt(a.count)
              ? -1
              : 0,
        )
        .find((price) => parseInt(price.count) >= clearSteps.filter(step => !documentsMap[step].value.price).length) ||
      editorState.metadata.prices[editorState.metadata.prices.length - 1];
    for (let step of clearSteps) {
      if (documentsMap[step] && documentsMap[step].value.price) {
        priceParsed.price = parseFloat(priceParsed.price) + parseFloat(documentsMap[step].value.price)
      }
    }
    setPrice(parseFloat(priceParsed.price).toFixed(2));
    setCount(parseInt(priceParsed.count));
  }, [userOrdersCount, documents]);

  let stepsArray = [
    translations.artifacts.StepperBox.firstStep,
    translations.artifacts.StepperBox.thirdStep,
    translations.artifacts.StepperBox.fourthStep
  ];

  useEffect(() => {
    if (user && user.attributes) {
      handleField({ target: { name: "kontakt_meno", value: `${user.attributes.given_name} ${user.attributes.family_name}` } })
      handleField({ target: { name: "kontakt_email", value: user.attributes.email } })
      if (user.attributes.phone_number) {
        handleField({ target: { name: "kontakt_phone", value: user.attributes.phone_number } })
      }
    }
  }, [user])

  useEffect(() => {
    if (formData.kontakt_phone) {
      setPhoneNumberValid(isValidPhoneNumber(formData.kontakt_phone))
    } else {
      setPhoneNumberValid(true)
    }
    setValidPassword(doValidatePasswords(formData.pass1, formData.pass2))
  }, [formData])

  const allLessonsAccepted = () => {
    let lessonsCount = Object.entries(documentsMap).filter(
      ([key, document]) =>
        document.value.requireLesson
    ).length
    let acceptedLessonsCount = values.lessons ? Object.entries(values.lessons).filter(([key, lesson]) => lesson.accepted === true).length : 0
    return lessonsCount === acceptedLessonsCount
  }

  const acceptAllLessons = () => {
    let lessonsToAccept = Object.entries(documentsMap).filter(
      ([key, document]) =>
        document.value.requireLesson
    )
    let newValues = {};

    for (let doc of lessonsToAccept) {
      newValues[doc[1].id.split("-")[0]] = {
        accepted: true,
        at: moment().format()
      }
    }
    returnLessons(newValues)
  }

  const promiseCompanyLookup = (token, isCreateMode, type = '') => {
    const controller = new AbortController();
    const signal = controller.signal;
    let cPromise = new Promise((resolve, reject) => {
      fetch(
        `${COMPANY_LOOKUP_HOST}/company/lookup-by-name${isCreateMode ? '-available' : ''
        }`,
        {
          body: JSON.stringify({ token, oddiel: type }),
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          signal,
        },
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res.data);
        });
    });
    cPromise.cancel = () => controller.abort();
    return cPromise;
  };

  const handleSearchChange = async (e, data, isCreateMode) => {
    // setResults([]);
    // setFreeCompanyName([]);
    setLoading(true);
    // setNewCompanyName(data.value);
    setSelectedCompany({});
    promises.current = promises.current || [];
    promises.current.forEach((p) => p.cancel());
    promises.current = [];
    const responseData = promiseCompanyLookup(data.value, isCreateMode, 'sro');
    promises.current.push(responseData);
    responseData
      .then((responseData) => {
        if (responseData) {
          setResults(
            responseData.map((item) => ({
              title: item.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value,
              description: `IČO: ${item.identifiers.filter(i => moment(i.validFrom).isSameOrBefore(moment()) && !i.validTo)[0].value}`,
              full_data: item,
            })),
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const debounceHandleSearchChange = useRef(
    _.debounce(handleSearchChange, 500, {
      loading: true,
    }),
  );

  const onSearchChange = (e, data) => {
    setLoading(true);
    let newSearch = data.value;
    if (/^[0-9]+\s+$/.test(newSearch)) {
      newSearch = newSearch.replace(/\s/g, '');
    }

    setSearchString(newSearch);
    debounceHandleSearchChange.current(e, data, false);
  };

  const doValidatePasswords = (pass1, pass2) => {

    let messages = '';

    if (user === undefined) {
      if (!validatePassword(pass1)) {
        messages += translations.artifacts.CheckouFormBox.weakPassword
      }
    }

    if (messages.length === 0) {
      return true
    } else {
      setValidationPassMessages(messages)
      return false
    }

  }

  useEffect(() => {
    if (selectedCompany && selectedCompany.fullNames && selectedCompany.identifiers) {
      setFormData((old) => ({ ...old, spolocnost: selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value }));
      setSearchString(selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value);
      setFormData((old) => ({ ...old, ico: selectedCompany.identifiers.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value }));
      setFormData((old) => ({ ...old, legalForm: selectedCompany.legalForms.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value }));
      setFormData((old) => ({ ...old, dic: selectedCompany.TIN?.DIC }));
      setFormData((old) => ({ ...old, icdph: selectedCompany.VAT?.IC_DPH }));
      if (selectedCompany && selectedCompany.addresses) {
        setFormData((old) => ({ ...old, adresa: renderAddress(selectedCompany.addresses.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0]) }));
      }
    }
  }, [selectedCompany])

  useEffect(() => {
    const formDataCopy = JSON.parse(JSON.stringify(formData))
    switch (invoicePerson) {
      case "buyer": {
        if (values && values.buyer_custom) {
          let buyer = values.buyer_custom
          if (buyer.type === "ownerPerson") {
            formDataCopy.personType = buyer.type
            formDataCopy.name = RenderSeparatedName(buyer.separatedName)
            formDataCopy.adresa = renderSeparatedAddress(buyer.address)

          } else if (buyer.type === "ownerCompany") {
            formDataCopy.spolocnost = buyer.name
            setSearchString(buyer.name)
            formDataCopy.personType = buyer.type
            formDataCopy.ico = buyer.idNumber
            formDataCopy.dic = buyer.tin
            formDataCopy.icdph = buyer.vat
            formDataCopy.adresa = renderSeparatedAddress(buyer.address)
          } else {
            formDataCopy.spolocnost = ""
            setSearchString("")
            formDataCopy.personType = ""
            formDataCopy.ico = ""
            formDataCopy.dic = ""
            formDataCopy.icdph = ""
            formDataCopy.adresa = ""
          }
        }
        else {
          formDataCopy.spolocnost = ""
          setSearchString("")
          formDataCopy.personType = ""
          formDataCopy.ico = ""
          formDataCopy.dic = ""
          formDataCopy.icdph = ""
          formDataCopy.adresa = ""
        }
        break
      }
      case "seller": {
        if (values && values.seller_custom) {
          let seller = values.seller_custom
          if (seller.type === "ownerPerson") {
            formDataCopy.personType = seller.type
            formDataCopy.name = RenderSeparatedName(seller.separatedName)
            formDataCopy.adresa = renderSeparatedAddress(seller.address)
          } else if (seller.type === "ownerCompany") {
            formDataCopy.spolocnost = seller.name
            setSearchString(seller.name)
            formDataCopy.personType = seller.type
            formDataCopy.ico = seller.idNumber
            formDataCopy.dic = seller.tin
            formDataCopy.icdph = seller.vat
            formDataCopy.adresa = renderSeparatedAddress(seller.address)
          } else {
            console.log("dsfkmsdfjkn")
            formDataCopy.spolocnost = ""
            setSearchString("")
            formDataCopy.personType = ""
            formDataCopy.ico = ""
            formDataCopy.dic = ""
            formDataCopy.icdph = ""
            formDataCopy.adresa = ""
          }
        } else {
          formDataCopy.spolocnost = ""
          setSearchString("")
          formDataCopy.personType = ""
          formDataCopy.ico = ""
          formDataCopy.dic = ""
          formDataCopy.icdph = ""
          formDataCopy.adresa = ""
        }
        break
      }
      default: {
        formDataCopy.personType = "ownerPerson"
        formDataCopy.name = ""
        formDataCopy.adresa = ""
      }
    }
    setFormData(formDataCopy)
  }, [invoicePerson, values])

  useEffect(() => {
    const formDataCopy = JSON.parse(JSON.stringify(formData))
    if (user && user.attributes) {
      formDataCopy.kontakt_meno = user.attributes.given_name + " " + user.attributes.family_name
      formDataCopy.kontakt_phone = user.attributes.phone_number
      formDataCopy.kontakt_email = user.attributes.email
    }
    setFormData(formDataCopy)
  }, [user])

  return (
    <div className="orderForm">
      <Form onSubmit={handleSubmit} className="orderUiForm">
        <Stepper steps={stepsArray} currentStepNumber={2} />
        <Grid container centered style={{ marginTop: 10 }}>
          <Grid.Column mobile={15} widescreen={15}>
            <h3 className="orderHeading">{translations.artifacts.CheckouFormBox.contactData}</h3>
            <Card className="orderCard">
              <CardContent>
                <Form className="orderUiForm">
                  <Form.Field className="contactField nameSurmanePhone">
                    <Form.Input
                      label={translations.artifacts.CheckouFormBox.nameSurname}
                      name="kontakt_meno"
                      value={formData.kontakt_meno}
                      onChange={handleField}
                      required
                    />
                    <Form.Input
                      label="E-mail"
                      id={'emailInputCheckoutForm'}
                      name="kontakt_email"
                      className={validEmail ? '' : "invalid"}
                      value={formData.kontakt_email}
                      onChange={(e) => {
                        handleField(e);
                        validateEmail(isEmailValid(e.target.value));
                      }}
                      error={
                        validEmail
                          ? false
                          : {
                            content: translations.artifacts.CheckouFormBox.enterValidMail,
                          }
                      }
                      required
                    />
                    <div className="field">
                      <div className={formData.kontakt_phone &&
                        !isValidPhoneNumber(formData.kontakt_phone) ? 'phoneInputLabel invalid' : 'phoneInputLabel'}>{translations.artifacts.CheckouFormBox.phone}</div>
                      <PhoneInput
                        labels={'test'}
                        id={'phoneNumberInputCheckoutForm'}
                        name="kontakt_phone"
                        value={formData.kontakt_phone}
                        defaultCountry="SK"
                        withCountryCallingCode={true}
                        useNationalFormatForDefaultCountryValue={true}
                        className={(isPhoneNumberValid) ? 'valid' : 'invalid'}
                        onChange={(value) => {
                          setFormData((old) =>
                            JSON.parse(
                              JSON.stringify({ ...old, ['kontakt_phone']: value }),
                            ),
                          );
                        }}
                        error={
                          validEmail
                            ? false
                            : {
                              content: translations.artifacts.CheckouFormBox.enterValidMail,
                            }
                        }
                        required
                      />
                    </div>
                  </Form.Field>
                  {user === undefined &&
                    <>
                      <Form.Field className="contactField nameSurmanePhone" id="passwordStrengthBarContainer">
                        {formData.pass1 && formData.pass1.length > 0 &&
                          < PasswordStrengthBar scoreWords={['veľmi slabé', 'slabé', 'dobré', 'silné', 'veľmi silné']} shortScoreWord="krátke" className='passwordStrengthBar' password={formData.pass1} />
                        }
                        <Form.Input
                          label={translations.artifacts.CheckouFormBox.enterPassword}
                          name="pass1"
                          value={formData.pass1}
                          onChange={(e) => {
                            handleField(e);
                          }}
                          error={
                            validPassword
                              ? false
                              : {
                                content: validationPassMessages,
                              }
                          }
                          required
                          type='password'
                        />
                        <Form.Input
                          className='repeatPassword'
                          label={translations.artifacts.PasswordResetFormBox.repeatPassword}
                          name="pass2"
                          value={formData.pass2}
                          onChange={handleField}
                          required
                          type='password'
                          error={
                            formData.pass1 === formData.pass2
                              ? false
                              : {
                                content: translations.artifacts.PasswordResetFormBox.passwordArentSame,
                              }
                          }
                        />
                      </Form.Field>
                    </>
                  }
                </Form>
                <Divider />
                <div className="orderCheckbox">
                  <Checkbox
                    style={{ margin: '15px 20px' }}
                    onChange={acceptAllLessons}
                    className="orderFormCheckbox acceptGuidanceCheckbox"
                    disabled={allLessonsAccepted()}
                    checked={allLessonsAccepted()}
                  ></Checkbox>
                  <label>
                    {translations.artifacts.CheckouFormBox.acceptTips}
                  </label>
                </div>
                <div className="orderCheckbox">
                  <VopModal
                    visible={vopModalVisible}
                    handleClose={() => setVopModalVisible(false)}
                  />
                  <ContractModal
                    visible={contractModalVisible}
                    formData={formData}
                    handleClose={() => setContractModalVisible(false)}
                    values={values}
                    documents={documents}
                  />
                  <Checkbox
                    style={{ margin: '15px 20px' }}
                    className="orderFormCheckbox"
                    checked={checkedConfirmCheckbox}
                    onChange={(e) =>
                      setCheckedConfirmCheckbox(e.target.checked)
                    }></Checkbox>
                  <label>
                    {translations.artifacts.CheckouFormBox.iaccept}{' '}
                    <span
                      className="vopCheckbox"
                      onClick={() => setVopModalVisible(true)}>
                      {translations.artifacts.CheckouFormBox.universalRules}
                    </span>
                    <span>
                      ,&nbsp;
                    </span>
                    <span
                      className="vopCheckbox"
                      onClick={() => setContractModalVisible(true)}>
                      {translations.artifacts.CheckouFormBox.aboutContract}
                    </span>
                    <span>
                      &nbsp;a&nbsp;
                    </span>
                    {translations.artifacts.CheckouFormBox.campaignAgreement}
                  </label>
                </div>

                {shouldLogin && !user &&
                  <Button id="shouldLoginButton" onClick={() => handleLoginForExistingUser()}>Účet s uvedeným emailom je už vytvorený, prosím prihláste sa</Button>
                }
                <Button
                  className="ui primary big button confirmOrderButton"
                  type="submit"
                  disabled={
                    !formData.kontakt_meno ||
                    formData.kontakt_meno === '' ||
                    !isEmailValid(formData.kontakt_email) ||
                    (formData.kontakt_phone &&
                      !isValidPhoneNumber(formData.kontakt_phone)) ||
                    !allLessonsAccepted()
                  }>
                  {translations.artifacts.CheckouFormBox.finishOrder}
                </Button>
              </CardContent>
            </Card>
          </Grid.Column>
        </Grid>
      </Form>
    </div >
  );
};

const mapStateToProps = ({ appState }) => ({
  company: appState.company,
  allowInvoiceOtherCompany: appState.allowInvoiceOtherCompany,
  user: appState.cognitoUser,
  userOrdersCount: appState.userOrdersAmount,
  templateToken: appState.token
});

const mapDispatchToProps = {
  isUserAction: IsUserAction
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
