import { UnorderedListOutlined } from '@ant-design/icons';
import { Modal, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Form, Icon, Loader, Popup } from 'semantic-ui-react';
import { SetRecentlyConfirmed } from '../../../app/ActionsImpl';
import { LANDING_PAGE_URI } from '../../../constants';
import avatar from '../../../images/avatar.svg';
import logo from '../../../images/logoWhiteText.webp';
import { translations } from '../../../utils/LocalizedStrings';
import { getAllUrlParams } from '../../../utils/url';
import { LoginFormState } from '../../admin/pages/Interfaces';
import UserRegistrationForm from '../../frontoffice/components/UserRegistrationForm';
import NewPasswordForm from './NewPasswordForm';
import PasswordResetForm from './PasswordResetForm';
import ReactPlayer from 'react-player/lazy'
import backgroundVideo from '../../../images/background.mp4'

const LoginModal = ({
  visible,
  handleClose,
  user,
  setRecentlyConfirmed,
  isPartnerRegistration
}) => {
  const [formState, setFormState] = useState(LoginFormState.LOGIN)
  const [email, setEmail] = useState('');
  const [emailFromLink, setEmailFromLink] = useState();
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState(false);
  const [verificationMethod, setVerificationMethod] = useState("SMS_MFA");
  const [forgotPasswordChallenge, setForgotPasswordChallenge] = useState(false);
  const [returnedUser, setReturnedUser] = useState();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setEmail("")
    setPassword("")
    setVerificationCode("")
    setReturnedUser()
    if (isPartnerRegistration) {
      setFormState(LoginFormState.REGISTER)
    } else {
      setFormState(LoginFormState.LOGIN)
    }
  }, [visible, isPartnerRegistration])

  useEffect(() => {
    if (getAllUrlParams().email !== undefined) {
      setEmail(getAllUrlParams().email)
      setEmailFromLink(getAllUrlParams().email)
    }
  }, [])

  async function signIn() {
    setIsLoading(true)
    try {
      let shouldClose = true;
      const user = await Auth.signIn(email, password);
      console.log(user)
      if (user.challengeName === "SMS_MFA") {
        setFormState(LoginFormState.SMS_MFA)
        setReturnedUser(user)
        shouldClose = false;
        if (returnedUser) {
          message.info("Overovacia SMS bola odoslaná")
          shouldClose = false;
        }
      }
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setReturnedUser(user)
        setFormState(LoginFormState.NEW_PASSWORD_REQUIRED)
      }
      if (user.challengeName === "SOFTWARE_TOKEN_MFA" || user.challengeName === "SELECT_MFA_TYPE") {
        setFormState(LoginFormState.SOFTWARE_TOKEN_MFA)
        setReturnedUser(user)
        let mfaType = "SOFTWARE_TOKEN_MFA"
        setVerificationMethod(mfaType)
        user.sendMFASelectionAnswer(mfaType, {
          onFailure: (err) => {
            console.error(err);
          },
          mfaRequired: (challengeName, parameters) => {
            console.log(challengeName)
          },
          totpRequired: (challengeName, parameters) => {
            console.log(challengeName)
          },
        });
        shouldClose = false;
      }
      if (shouldClose && handleClose) {
        // handleClose();
      }
    } catch (error) {
      console.log(error)
      if (error.code === "UserNotConfirmedException") {
        setFormState(LoginFormState.USER_NOT_VERIFIED);
      } else {
        setFormState(LoginFormState.LOGIN);
        message.error("Prosíme, zadajte správne údaje.")
      }
    }
    setIsLoading(false)
  }

  async function signOut() {
    setIsLoading(true)
    try {
      const user = await Auth.signOut();
      setFormState(LoginFormState.LOGIN)
    } catch (error) {
      message.error(error)
    }
    setIsLoading(false)
  }

  async function confirmSignup() {
    setIsLoading(true)
    try {
      await Auth.confirmSignUp(email, verificationCode);
      setRecentlyConfirmed(true)
      if (!forgotPasswordChallenge) {
        await signIn()
        setForgotPasswordChallenge(false)
      } else {
        setFormState(LoginFormState.FORGOT_PASSWORD)
        setForgotPasswordChallenge(false)
      }
    } catch (error) {
      console.log(error)
      if (error.code === "CodeMismatchException") {
        message.error('Nesprávny overovací kód')
      }
    }
    setIsLoading(false)
  }

  async function confirmSignIn() {
    setIsLoading(true)
    try {
      await Auth.confirmSignIn(returnedUser, verificationCode, verificationMethod);
      if (handleClose) {
        handleClose();
      }
      setFormState(LoginFormState.LOGGED_IN);
    } catch (error) {
      message.error('Zadali ste nesprávny kód.');
    }
    setIsLoading(false)
  }

  async function forgotPassword() {
    setIsLoading(true)
    try {
      await Auth.forgotPassword(email)
      setFormState(LoginFormState.PASSWORD_RESET);
    } catch (error) {
      if (error.message.includes('verified')) {
        setFormState(LoginFormState.USER_NOT_VERIFIED);
        setForgotPasswordChallenge(true)
        message.error('Prosím, najprv overte emailovú adresu');
      } else if (error.code === "LimitExceededException") {
        {
          message.error('Prekročili ste limit, skúste neskôr');
        }
      } else {
        message.error('Zadané údaje nie sú správne');
      }
    }
    setIsLoading(false)
  }

  async function resendConfirmationCode() {
    setIsLoading(true)
    try {
      await Auth.resendSignUp(email);
      message.info("Overovací kód bol odoslaný.")
    } catch (err) {
      message.error(err.message)
    }
    setIsLoading(false)
  }

  return (
    <Modal
      centered
      visible={visible}
      onCancel={() => {
        if (handleClose) {
          handleClose()
        }
      }}
      closable={!!handleClose}
      onOk={() => {
        if (handleClose) {
          handleClose();
        }
      }}
      className={!user ? 'loginModal' : 'userStatusModal'}
      maskStyle={{ padding: 0 }}
      style={{ margin: 0 }}
      footer={null}>
      {
        isLoading && <Dimmer active>
          <Loader size='large' inverted>{translations.artifacts.LoginModalBox.loading}</Loader>
        </Dimmer>
      }
      <div className='introVideo'>
        <ReactPlayer
          url={backgroundVideo}
          muted
          playsinline
          loop={true}
          playing={true}
        />
      </div>
      <span className="logoContainer">
        <a onClick={() => { window.location = LANDING_PAGE_URI }}>
          <img className="orsrLogo" src={logo} alt="logo" />
        </a>
      </span>
      {!user &&
        <>
          {formState === LoginFormState.LOGIN &&
            <>
              <main id="login">
                <div id='mainWrapper'>
                  <div className='main-login-container'>
                    <div className="loginBox">
                      <header className='loginHeader'>Web aplikácia umelej inteligencie Sdružení místních samospráv České republiky</header>
                      <div>
                        <p>Pre viac informácií nás kontaktujte e-mailom:</p>
                        <p>info@legaltechfactory.eu</p>
                      </div>
                    </div>
                    <div className="loginBox">
                      <header className='loginHeader'>{translations.artifacts.LoginModalBox.loginRegister}</header>
                      <Form>
                        <Form.Field className="contactField">
                          <div className='loginInputField'>
                            <Icon name="user" />
                            <Form.Input
                              id={'emailInputCheckoutForm'}
                              label={translations.artifacts.LoginModalBox.yourMail}
                              name="kontakt_email"
                              value={email}
                              disabled={emailFromLink}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          </div>
                          <div className='loginInputField'>
                            <Icon name="lock" />
                            <Form.Input
                              id={'emailInputCheckoutForm'}
                              label={translations.artifacts.LoginModalBox.password}
                              type='password'
                              name="password"
                              value={password}
                              onKeyDown={(e) => e.keyCode === 13 && signIn(e)}
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                            />
                          </div>
                        </Form.Field>
                      </Form>
                      <Form onSubmit={signIn} className="orderUiForm signInButton">
                        <Button
                          disabled={!email || !password}
                          className="ui primary big button"
                          type="submit">
                          {translations.artifacts.LoginModalBox.login}
                        </Button>
                      </Form>
                      {/* <Form onSubmit={() => { setFormState(LoginFormState.REGISTER) }} className="orderUiForm register">
                    <Button
                      className="ui primary big button"
                      type="submit">
                      {translations.artifacts.LoginModalBox.register}
                    </Button>
                  </Form> */}
                      <Form onSubmit={() => { setFormState(LoginFormState.FORGOT_PASSWORD) }} className="orderUiForm register">
                        <Button
                          className="ui primary big button"
                          type="submit">
                          {translations.artifacts.LoginModalBox.forgot}
                        </Button>
                      </Form>
                    </div>
                  </div>
                </div>
              </main>
              <footer className='landing-page-footer'>
                <article className="footer redirectLinks">
                  <p>&copy; SMS AI s.r.o. {new Date().getFullYear()}</p>
                  <nav>
                    <ul>
                      <li>
                        <a href="https://www.matejka.company/docs/kodex%20GDPR%20sk_en.pdf.pdf" target="_blank">{translations.artifacts.LoginModalBox.gdpr}</a>
                      </li>
                    </ul>
                  </nav>
                </article>
              </footer>
            </>
          }
          {formState === LoginFormState.REGISTER &&
            <UserRegistrationForm
              email={emailFromLink}
              setFormState={setFormState}
              setIsLoading={setIsLoading}
              isPartnerRegistration={isPartnerRegistration} />
          }
          {formState === LoginFormState.PASSWORD_RESET &&
            <PasswordResetForm
              setFormState={setFormState}
              setIsLoading={setIsLoading}
              email={email}
            />
          }
          {formState === LoginFormState.USER_NOT_VERIFIED &&
            <div id='mainWrapper'>
              <div className='main-login-container'>
                <div className='loginModalSMS'>
                  <h3>
                    {translations.artifacts.LoginModalBox.emailControll}
                  </h3>
                  <Form.Field className="contactField">
                    <Form.Input
                      id={'emailInputCheckoutForm'}
                      label={translations.artifacts.LoginModalBox.controlCode}
                      name="verification-code"
                      value={verificationCode}
                      onChange={(e) => {
                        setVerificationCode(e.target.value);
                      }}
                    />
                  </Form.Field>
                  <Form onSubmit={confirmSignup} className="orderUiForm">
                    <Button
                      disabled={!email || !verificationCode}
                      className="ui primary big button"
                      type="submit">
                      {translations.artifacts.LoginModalBox.controll}
                    </Button>
                  </Form>
                  <h4>
                    {translations.artifacts.LoginModalBox.resendEmail}
                  </h4>
                  <Form onSubmit={resendConfirmationCode} className="orderUiForm">
                    <Button
                      className="ui primary big button"
                      type="submit">
                      {translations.artifacts.LoginModalBox.resend}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          }
          {
            formState === LoginFormState.NEW_PASSWORD_REQUIRED &&
            <NewPasswordForm setFormState={setFormState} setIsLoading={setIsLoading} email={email} returnedUser={returnedUser} setReturnedUser={setReturnedUser} handleClose={handleClose} setVerificationMethod={setVerificationMethod} />
          }
          {formState === LoginFormState.FORGOT_PASSWORD &&
            <main id="login">
              <div id='mainWrapper'>
                <div className='main-login-container password-reset'>
                  <span className='stepBackArrow' onClick={() => setFormState(LoginFormState.LOGIN)}>
                    <Popup className='stepBackArrowPopup' content='Krok späť' trigger={<Icon name="arrow left" />} />
                  </span>
                  <header className='loginHeader'>{translations.artifacts.LoginModalBox.forNewPass} <br />{translations.artifacts.LoginModalBox.youUsed}</header>
                  <Form className='forgotPassword'>
                    <Form.Field className="contactField">
                      <div className='loginInputField'>
                        <Form.Input
                          id={'emailInputCheckoutForm'}
                          label={translations.artifacts.LoginModalBox.yourMail}
                          name="kontakt_email"
                          placeholder={translations.artifacts.LoginModalBox.userName}
                          required
                          value={email}
                          onKeyDown={(e) => e.keyCode === 13 && forgotPassword(e)}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Field>
                  </Form>
                  <Form onSubmit={forgotPassword} className="orderUiForm">
                    <Button
                      disabled={!email}
                      className="ui primary big button"
                      type="submit">
                      {translations.artifacts.LoginModalBox.sendCode}
                    </Button>
                  </Form>
                </div>
              </div>

            </main>
          }
          {(formState === LoginFormState.SMS_MFA || formState === LoginFormState.SOFTWARE_TOKEN_MFA) &&
            <div className='landingPage loginModalSMS'>
              <div className='container'>
                <div className='main-login-container'>
                  <span className='stepBackArrow' onClick={() => setFormState(LoginFormState.LOGIN)}>
                    <Popup className='stepBackArrowPopup' content='Krok späť' trigger={<Icon name="arrow left" />} />
                  </span>
                  <h3>{formState === LoginFormState.SMS_MFA ? translations.artifacts.LoginModalBox.codeSms : translations.artifacts.LoginModalBox.codeAuth}</h3>
                  <Form.Field className="contactField">
                    <Form.Input
                      id={'emailInputCheckoutForm'}
                      label={translations.artifacts.LoginModalBox.controlCode}
                      name="verification-code"
                      value={verificationCode}
                      onKeyDown={(e) => e.keyCode === 13 && confirmSignIn(e)}
                      onChange={(e) => {
                        setVerificationCode(e.target.value);
                      }}
                    />
                  </Form.Field>
                  <Form onSubmit={confirmSignIn} className="orderUiForm">
                    <Button
                      disabled={!email || !verificationCode}
                      className="ui primary big button"
                      type="submit">
                      {translations.artifacts.LoginModalBox.controll}
                    </Button>
                  </Form>
                  <h4>
                    {formState === LoginFormState.SMS_MFA ? translations.artifacts.LoginModalBox.resendSms : translations.artifacts.LoginModalBox.lostAuthAppAccess}
                  </h4>
                  {formState === LoginFormState.SMS_MFA &&
                    <Form onSubmit={signIn} className="orderUiForm">
                      <Button
                        className="ui primary big button"
                        type="submit">
                        {translations.artifacts.LoginModalBox.reControl}
                      </Button>
                    </Form>
                  }
                  {formState === LoginFormState.SOFTWARE_TOKEN_MFA &&
                    <Form onSubmit={async () => {
                      let mfaType = "SMS_MFA" // let's say selected this MFA method   
                      setVerificationMethod(mfaType)
                      returnedUser.sendMFASelectionAnswer(mfaType, {
                        onFailure: (err) => {
                          message.error("SMS sa nepodarilo odoslať")
                          console.error(err);
                        },
                        mfaRequired: (challengeName, parameters) => {
                          console.log(challengeName)
                        },
                        totpRequired: (challengeName, parameters) => {
                          console.log(challengeName)
                        },
                      });
                      message.info("SMS bola odoslaná")
                    }} className="orderUiForm">
                      <Button
                        className="ui primary big button"
                        type="submit">
                        {translations.artifacts.LoginModalBox.reFallback}
                      </Button>
                    </Form>
                  }
                </div>
              </div>
            </div>
          }
        </>
      }
      {user &&
        <div>
          <Form className="orderUiForm userStatusModalWindow">
            <div className='userStatusRow'>
              <div><img class="avatarLogo" src={avatar} alt="user avatar" width="100" /></div>
              <div>
                <div className='userName'>{user.attributes.given_name} {user.attributes.family_name}</div>
                <div className='userEmail'>{user.attributes.email}</div>
              </div>
            </div>
            <Button className='myOrders' onClick={() => (window.location = '/user-profile')}>
              <UnorderedListOutlined />{translations.artifacts.LoginModalBox.myOrders}</Button>
          </Form>
          <Form onSubmit={signOut} className="orderUiForm userStatusModalWindow">
            <Button
              className="logoutButton"
              type="submit">
              <Icon name="log out" />
              {translations.artifacts.LoginModalBox.logOut}
            </Button>
          </Form>
        </div>
      }
    </Modal >
  );
};

const mapStateToProps = ({ appState }) => ({
  user: appState.cognitoUser,
  recentlyConfirmed: appState.recentlyConfirmed
});

const mapDispatchToProps = {
  setRecentlyConfirmed: SetRecentlyConfirmed
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginModal)