import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { LoadUserLoginCookiesAction, SaveFeedbackAction, StoreAction } from '../../../app/ActionsImpl';
import { useEffect, useState } from 'react';
import { COOKIE_STORAGE, __DEV__ } from '../../../constants';



export interface Props extends StateProps, DispatchProps { }

function ResolveLogin(
    props: Props
) {

    const trustedOrigins = ['https://*.orsr.help', 'https://*.amplifyapp.com', 'http://localhost:3000', 'localhost:3000', 'localhost'];

    const [userLoginCookies, setUserLoginCookies] = useState(undefined)

    useEffect(() => {
        const messageHandler = (event: any) => {
            const isTrustedOrigin = trustedOrigins.some((origin) => {
                if (origin.includes('*')) {
                    // Create a regex pattern to match subdomains
                    const pattern = origin.replace(/\./g, '\\.').replace('*', '(.+)');
                    const regex = new RegExp(`^${pattern}$`);
                    return regex.test(event.data.origin);
                }
                return origin === event.data.origin;
            });
            if (isTrustedOrigin && event.data && event.data.action === "resolve-login") {
                const cookies = JSON.parse(event.data.cookies)
                setUserLoginCookies(cookies)
            }

            if (isTrustedOrigin && event.data && event.data.action === "resolve-logout") {
                deleteCookies()
            }
        };

        window.addEventListener('message', messageHandler);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('message', messageHandler);
        };
    }, []);

    useEffect(() => {
        if (userLoginCookies) {
            deleteAndReplaceCookies(userLoginCookies)
            setUserLoginCookies(undefined)
        }
    }, [userLoginCookies])

    const deleteAndReplaceCookies = (newCookies: any, domain = __DEV__ ? window.document.location.hostname : COOKIE_STORAGE) => {
        const cookies = document.cookie.split('; ');
        console.log(window.document.location.hostname)
        // Delete all cookies with names starting with 'CognitoIdentityServiceProvider'
        for (const cookie of cookies) {
            const [name] = cookie.split('=');
            if (name.startsWith('CognitoIdentityServiceProvider')) {
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${domain}; SameSite=None; path=/; Secure`;

            }
        }

        // Add new cookies from the object
        for (const [name, value] of Object.entries(newCookies)) {
            if (name.startsWith('CognitoIdentityServiceProvider')) {
                document.cookie = `${name}=${value}; domain=${domain}; SameSite=None; path=/; Secure`;
                console.log(name, value)
            }
        }
        window.parent.postMessage({ action: "LOGIN_FINISHED", origin: COOKIE_STORAGE }, '*');
    };

    const deleteCookies = (domain = __DEV__ ? window.document.location.hostname : COOKIE_STORAGE) => {
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
            const [name] = cookie.split('=');
            if (name.startsWith('CognitoIdentityServiceProvider')) {
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${domain}; SameSite=None; path=/; Secure`;

            }
        }
        window.parent.postMessage({ action: "LOGIN_FINISHED", origin: COOKIE_STORAGE }, '*');
    };

    return (
        <></>
    )
};

const mapStateToProps = ({ appState }: any) => ({
    user: appState.cognitoUser
});

type StateProps = ReturnType<typeof mapStateToProps>;

export interface DispatchProps {

}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResolveLogin);