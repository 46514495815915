import { useEffect, useState } from 'react';

const Legend = ({ url, layerId, title, opacity }) => {
  const [legendData, setLegendData] = useState([]);
  const [openLegend, setOpenLegend] = useState(false);

  const toogleLegend = () => {
    setOpenLegend(!openLegend);
  };

  useEffect(() => {
    if (url) {
      // Fetch the legend data from the ArcGIS REST service
      fetch(`${url}?f=json`)
        .then(response => response.json())
        .then(data => {
          setLegendData(data.layers);
        })
        .catch(error => console.error('Error fetching the legend data:', error));
    }
  }, [url]);

  return (legendData && (
    <div className="legend-container">
      <div className='title-icon-container'>
        <h3 className="legend-title">{title}</h3>
        {legendData && legendData.length > 0 &&
          <span onClick={toogleLegend}>Legenda</span>
        }
      </div>

      {openLegend &&
        <>
          {
            legendData.filter(layer => layer.layerId === layerId).map((layer, index) => (
              <div key={layer.layerId} className="legend-layer">
                <div className="legend-layer-title">{layer.layerName}</div>
                {layer.legend.map((item, index) => (
                  <div key={index} className="legend-item">
                    <img
                      className="legend-swatch"
                      src={`data:image/png;base64,${item.imageData}`}
                      alt={item.label}
                      style={{opacity: opacity }}
                    />
                    <div className="legend-label">{item.label}</div>
                  </div>
                ))}
              </div>
            ))
          }
        </>
      }
    </div>
  ))
}

export default Legend;