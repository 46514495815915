import { nestedArtifactKeys } from "../../../../frontoffice/components/Input";

export const hashValues = [
  { id: 'snippet-start', value: 'Náhľad Štart' },
  { id: 'snippet-stop', value: 'Náhľad Stop' },
  { id: 'MUNICIPALITY-NAMES-DATA-OBJECT', value: 'Názvy obcí' },
  { id: 'MUNICIPALITY-NAMES-REGION', value: 'Kraj obce' },
  { id: 'MUNICIPALITY-NAMES-DISTRICT', value: 'Okres obce' },
  {id: 'MUNICIPALITY-POPULATION', value: 'Pocet obyvatelov'}
];

export const templateStringDenotationChar = '#';
export const artifactStringDenotationChar = ':';

export const mentionModule = (artifactValues) => ({
  allowedChars: /^[a-zA-Z0-9\-_\s]*$/,
  mentionDenotationChars: [
    templateStringDenotationChar,
    artifactStringDenotationChar
  ],
  spaceAfterInsert: false,
  isolateCharacter: true,
  onSelect: function (item, insertItem) {
    let itemParts = item.value.split('_');
    item.value = itemParts[itemParts.length - 1];
    insertItem(item);
  },
  source: function (searchTerm, renderList, mentionChar) {
    let values;

    if (mentionChar === artifactStringDenotationChar) {
      values = artifactValues;
    } else {
      values = hashValues;
    }

    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches = [];
      for (let i = 0; i < values.length; i++) {
        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
          matches.push(values[i]);
        }
      }
      renderList(matches, searchTerm);
    }
  },
});


export function makeArtifactSuggestions(artifacts) {
  const suggestions = [];

  Object.entries(artifacts).forEach(([key, value]) => {
    suggestions.push({
      id: key,
      value: value.name,
    });

    if (value.type === 'person-select') {
      for (const [nestedKey, nestedName] of Object.entries(nestedArtifactKeys.person)) {
        suggestions.push({
          id: key + '.' + nestedKey,
          value:
            value.name +
            '-' +
            nestedName,
        });
      };
    }
    if (value.type === 'company') {
      for (const [nestedKey, nestedName] of Object.entries(nestedArtifactKeys.company)) {
        suggestions.push({
          id: key + '.' + nestedKey,
          value:
            value.name +
            '-' +
            nestedName,
        });
      };
    }
  });
  return mentionModule(suggestions);
}