import logo from '../../../images/blueLogo.webp';
import { LANDING_PAGE_URI } from '../../../constants';

export const PageNotExist = () => {
    return (
        <article id='pageNotExist' className='maintenancePage'>
            <div className='newLogo'>
            <a onClick={() =>  window.location.href = LANDING_PAGE_URI ? LANDING_PAGE_URI : '' }>
                    <img src={logo} alt="logo" className='logo' />
                </a>
            </div>
            <div className='container'>
                <h1>Stránka neexistuje</h1>
                <a onClick={() =>  window.location.href = LANDING_PAGE_URI ? LANDING_PAGE_URI : '' }
                className='returnHome'>Návrat domov</a>
            </div>
        </article>
    )
};