
import { Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';

import { RegistrationCertificateDataMutator, RegistrationCertificateDataRenderer } from './RegistrationCertificateDataArtifact';
import { COMPANY_LOOKUP_HOST } from '../../../../../constants';
import { translations } from '../../../../../utils/LocalizedStrings';
import { isoCountryCodes } from '../../../../editor/countries/countries';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import ProcessDocuments from '../ProcessDocuments';

const _ = require('lodash');

function RegistrationCertificateMutator({ documentId, name, disabled, binding, label = 'Select', ...rest }) {

    const [, updateState] = React.useState({})
    const forceUpdate = () => { }
    const [searchStringOwner, setSearchStringOwner] = React.useState('')
    const [searchStringHolder, setSearchStringHolder] = React.useState('')
    const promisesOwner = React.useRef()
    const promisesHolder = React.useRef()
    const [resultsOwner, setResultsOwner] = React.useState([])
    const [resultsHolder, setResultsHolder] = React.useState([])


    const prepareEmptyRegistrationCertificateData = () => {
        return {
            data: {
                vin: '',
                evidencneCislo: '',
                cisloTechnickehoPreukazu: '',
                rokVyrobyVozidla: '',
                druh: '',
                znacka: '',
                typ: '',
                farba: ''
            }
        }
    }

    const values = useFormDataContext(({ data, setField }) => ({
        value: data[name] || prepareEmptyRegistrationCertificateData(),
        userPersons: data.userPersons || [],
        setField
    }));

    const [fieldValues, setFieldValues] = useState(JSON.parse(JSON.stringify(values)))

    useEffect(() => {
        if (JSON.stringify(values.value) !== JSON.stringify(fieldValues.value)) {
            console.log(JSON.stringify(values.value), JSON.stringify(fieldValues.value))
            let tmpValues = JSON.parse(JSON.stringify(fieldValues))
            tmpValues.value.data = values.value.data
            setFieldValues(tmpValues)
        }
    }, [values?.value?.data?.vin, values?.value?.data?.evidencneCislo, values?.value?.data?.cisloTechnickehoPreukazu, values?.value?.data?.rokVyrobyVozidla, values?.value?.data?.druh, values?.value?.data?.znacka, values?.value?.data?.typ, values?.value?.data?.farba])

    const handleChange = () => {
        values.setField({
            target: {
                name,
                value: fieldValues.value
            },
        });
    }

    const handleChangeData = (valuesData) => {
        handleChangePart(valuesData, 'data')
    }

    const handleChangePart = (valuesToChange, person) => {
        console.log(valuesToChange)
        let fieldValuesCopy = JSON.parse(JSON.stringify(fieldValues))
        let tmpPerson = fieldValuesCopy.value[person]
        const tmpMerge = _.merge(tmpPerson, valuesToChange);
        let tmpValues = fieldValuesCopy.value
        tmpValues[person] = tmpMerge
        setFieldValues(fieldValuesCopy)
    }

    const onFocus = () => {
        document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
            el.classList.add('mention--focus');
        });
    };

    const onBlur = () => {
        document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
            el.classList.remove('mention--focus');
        });
        handleChange();
    };

    const promiseCompanyLookup = (token, isCreateMode, type = '112') => {
        const controller = new AbortController();
        const signal = controller.signal;
        let cPromise = new Promise((resolve, reject) => {
            fetch(
                `${COMPANY_LOOKUP_HOST}/company/lookup-by-name${isCreateMode ? '-available' : ''
                }`,
                {
                    body: JSON.stringify({ token, code: type }),
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    signal,
                },
            )
                .then((res) => res.json())
                .then((res) => {
                    resolve(res.data);
                });
        });
        cPromise.cancel = () => controller.abort();
        return cPromise;
    };

    const handleSearchChange = async (data, isCreateMode, promises, setResults) => {
        promises.current = promises.current || [];
        promises.current.forEach((p) => p.cancel());
        promises.current = [];
        const responseData = promiseCompanyLookup(data.value, isCreateMode, '112');
        promises.current.push(responseData);
        responseData
            .then((responseData) => {
                if (responseData) {
                    setResults(
                        responseData.map((item) => ({
                            title: item.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value,
                            description: `IČO: ${item.identifiers.filter(i => moment(i.validFrom).isSameOrBefore(moment()) && !i.validTo)[0].value}`,
                            full_data: item,
                        })),
                    );
                }
            })
            .catch(() => {
            });
    };

    const setScannedDataRegistrationCertificate = (value) => {
        console.log("REGISTRATION Certificate ARTIFACT", value);
        const tmp = _.merge(fieldValues.value, value)
        fieldValues.value = tmp
        setFieldValues(fieldValues);

        if (value?.owner?.type === 'ownerCompany') {
            setSearchStringOwner(value.owner.name)
            if (value.owner.idNumber) { // 35704713
                handleSearchChange({ value: value.owner.idNumber }, false, promisesOwner, setResultsOwner)
            }
        }

        if (value.differentHolder && value.holder?.type === 'ownerCompany') {
            setSearchStringHolder(value.holder.name)
            if (value.holder.idNumber) { // 52104966
                handleSearchChange({ value: value.holder.idNumber }, false, promisesHolder, setResultsHolder)
            }
        }

        forceUpdate()
        handleChange()
    }

    useEffect(() => {
        if (resultsOwner && resultsOwner.length > 0) {
            let tmpValues = fieldValues
            const tmpMoment = moment()

            tmpValues.value.owner.name = resultsOwner[0].full_data.fullNames.filter(n => (moment(n.validFrom) < tmpMoment) && !n.validTo)[0].value;
            setSearchStringOwner(tmpValues.value.owner.name)
            let tmpAddress = resultsOwner[0].full_data.addresses.filter(n => (moment(n.validFrom) < tmpMoment) && !n.validTo)[0]

            tmpValues.value.owner.address.city = tmpAddress.municipality.value
            tmpValues.value.owner.address.street = tmpAddress.street
            tmpValues.value.owner.address.number = tmpAddress.buildingNumber
            tmpValues.value.owner.address.zip = tmpAddress.postalCodes[0]
            tmpValues.value.owner.address.country = isoCountryCodes[tmpAddress.country.code]
            setFieldValues(tmpValues)
            forceUpdate()
            handleChange()
        }
    }, [resultsOwner])

    useEffect(() => {
        if (resultsHolder && resultsHolder.length > 0) {
            let tmpValues = fieldValues
            const tmpMoment = moment()

            tmpValues.value.holder.name = resultsHolder[0].full_data.fullNames.filter(n => (moment(n.validFrom) < tmpMoment) && !n.validTo)[0].value;
            setSearchStringHolder(tmpValues.value.holder.name)
            let tmpAddress = resultsHolder[0].full_data.addresses.filter(n => (moment(n.validFrom) < tmpMoment) && !n.validTo)[0]

            tmpValues.value.holder.address.city = tmpAddress.municipality.value
            tmpValues.value.holder.address.street = tmpAddress.street
            tmpValues.value.holder.address.number = tmpAddress.buildingNumber
            tmpValues.value.holder.address.zip = tmpAddress.postalCodes[0]
            tmpValues.value.holder.address.country = isoCountryCodes[tmpAddress.country.code]
            setFieldValues(tmpValues)
            forceUpdate()
            handleChange()
        }
    }, [resultsHolder])


    return (
        <div className='registerCertificateArtifactContainer'>
            <form className="fullGridRow registerCertificateArtifact newPersonForm">
                <div className='gridRow' id='scannedDataContainer'>
                    <p className='inputLabelTooltip'>
                        <h4>{label}</h4>
                        {rest.tooltip && (
                            <div style={{ marginBottom: 20 }} className="icon-position">
                                <Tooltip title={rest.tooltip}>
                                    <span className="icon-info">
                                        <Icon name="info circle" />
                                    </span>
                                </Tooltip>
                            </div>
                        )}
                    </p>
                    <ProcessDocuments
                        parentId={name}
                        setScannedData={setScannedDataRegistrationCertificate}
                        processLabel={translations.artifacts.RegistrationCertificateArtifact.uploadImageForScanning}
                        processLabelTooltip={translations.artifacts.RegistrationCertificateArtifact.uploadImageForScanningTooltip}
                    />
                </div>
                <RegistrationCertificateDataMutator
                    name={name}
                    values={fieldValues.value.data}
                    handleChange={handleChangeData}
                    // setEdited={setEditedOwner}
                    disabled={false}
                    tooltip={translations.artifacts.RegistrationCertificateArtifact.tooltipDataText}
                    label={translations.artifacts.RegistrationCertificateArtifact.labelData}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            </form>
        </div>
    );
}

function RegistrationCertificateRenderer(values, id) {

    let returnValue = ""
    if (
        values &&
        values[id]
    ) {
        if (values[id].data)
            returnValue += RegistrationCertificateDataRenderer(values[id].data)

        return returnValue
    }

}

function validateRegistrationCertificate(values) {
    return values !== undefined

}

export default {
    Mutator: RegistrationCertificateMutator,
    HTMLRenderer: RegistrationCertificateRenderer,
    Validator: validateRegistrationCertificate
};
