import domtoimage from 'dom-to-image';
import L from 'leaflet';
import { convertCoordinates2Krovak } from './GeometryUtils';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export const setupLeafletDefaults = () => {
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
};

export const captureMapImage = (containerRef, setFieldValues, fieldValues, values, name, save) => {
  console.log('CAPTURE IMAGE', containerRef, setFieldValues, fieldValues, values, name, save)
    if (containerRef.current) {
        domtoimage.toPng(containerRef.current)
            .then((dataUrl) => {
                setFieldValues(prev => ({
                    ...prev,
                    value: {
                        ...prev.value,
                        image: dataUrl
                    }
                }));
                if (save) {
                    values.setField({
                        target: {
                            name,
                            value: {
                                ...fieldValues.value,
                                image: dataUrl
                            }
                        },
                    });
                }
            })
            .catch((error) => {
                console.error('Error capturing map image:', error);
            });
    }
};

export const getMapBounds = (mapRef) => {
    const map = mapRef?.current?.leafletElement;
    if (map) {
        const bounds = map.getBounds();
        const southWest = bounds.getSouthWest();
        const northEast = bounds.getNorthEast();
        return [southWest.lng, southWest.lat, northEast.lng, northEast.lat];
    }
    return null;
};

export const getImageUrl = (geoUrl, layerId, bbox) => {
    if (bbox) {
        return `${geoUrl}/export?dpi=96&transparent=true&format=png32&layers=show%3A${layerId}&bbox=${bbox.join('%2C')}&bboxSR=4326&imageSR=4326&size=1024%2C1024&f=image`;
    }
    return null;
};

export const getRestepImagePotencialPlochyStriechUrl = (geoUrl, bbox) => {
  if (bbox) {
    const coord1 = convertCoordinates2Krovak({x: bbox[0], y: bbox[1]});
    const coord2 = convertCoordinates2Krovak({x: bbox[2], y: bbox[3]});
    const krovakBBox = [coord1.x, coord1.y, coord2.x, coord2.y];
    return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fslunce_strechy.map&layers=slunce_strechy&mode=map&map_imagetype=png&mapext=${krovakBBox.join('+')}&imgext=${krovakBBox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
  }
  return null;
};

export const getRestepImagePotencialVeternejEnergieUrl = (geoUrl, bbox) => {
    if (bbox) {
        const coord1 = convertCoordinates2Krovak({x: bbox[0],y: bbox[1]})
        const coord2 = convertCoordinates2Krovak({x: bbox[2],y: bbox[3]})
        const krovakBBox = [coord1.x,coord1.y,coord2.x,coord2.y]
        return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fvitr_potencial.map&layers=vitr_potencial&mode=map&map_imagetype=png&mapext=${krovakBBox.join('+')}&imgext=${krovakBBox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
    }
    return null;
};

export const getRestepImagePotencialVynosovTpp0kgUrl = (geoUrl, bbox) => {
    if (bbox) {
      const coord1 = convertCoordinates2Krovak({x: bbox[0], y: bbox[1]});
      const coord2 = convertCoordinates2Krovak({x: bbox[2], y: bbox[3]});
      const krovakBBox = [coord1.x, coord1.y, coord2.x, coord2.y];
      return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_ttp0.map&layers=biomasa_ttp0&mode=map&map_imagetype=png&mapext=${krovakBBox.join('+')}&imgext=${krovakBBox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
    }
    return null;
  };
  
  export const getRestepImagePotencialVynosovTpp120kgUrl = (geoUrl, bbox) => {
    if (bbox) {
      const coord1 = convertCoordinates2Krovak({x: bbox[0], y: bbox[1]});
      const coord2 = convertCoordinates2Krovak({x: bbox[2], y: bbox[3]});
      const krovakBBox = [coord1.x, coord1.y, coord2.x, coord2.y];
      return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_ttp120.map&layers=biomasa_ttp120&&mode=map&map_imagetype=png&mapext=${krovakBBox.join('+')}&imgext=${krovakBBox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
    }
    return null;
  };
  
  export const getRestepImagePotencialLesneTazobneZvyskyUrl = (geoUrl, bbox) => {
    if (bbox) {
      const coord1 = convertCoordinates2Krovak({x: bbox[0], y: bbox[1]});
      const coord2 = convertCoordinates2Krovak({x: bbox[2], y: bbox[3]});
      const krovakBBox = [coord1.x, coord1.y, coord2.x, coord2.y];
      return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_ltz_vyhrevnost.map&layers=biomasa_ltz_vyhrevnost&mode=map&map_imagetype=png&mapext=${krovakBBox.join('+')}&imgext=${krovakBBox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
    }
    return null;
  };
  
  export const getRestepImagePotencialKukuricaSilazUrl = (geoUrl, bbox) => {
    if (bbox) {
      const coord1 = convertCoordinates2Krovak({x: bbox[0], y: bbox[1]});
      const coord2 = convertCoordinates2Krovak({x: bbox[2], y: bbox[3]});
      const krovakBBox = [coord1.x, coord1.y, coord2.x, coord2.y];
      return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_kukurice_s.map&layers=biomasa_kukurice_s&mode=map&map_imagetype=png&mapext=${krovakBBox.join('+')}&imgext=${krovakBBox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
    }
    return null;
  };
  
  export const getRestepImagePotencialSlnecnicaTopinamburUrl = (geoUrl, bbox) => {
    if (bbox) {
      const coord1 = convertCoordinates2Krovak({x: bbox[0], y: bbox[1]});
      const coord2 = convertCoordinates2Krovak({x: bbox[2], y: bbox[3]});
      const krovakBBox = [coord1.x, coord1.y, coord2.x, coord2.y];
      return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_topinambur.map&layers=biomasa_topinambur&mode=map&map_imagetype=png&mapext=${krovakBBox.join('+')}&imgext=${krovakBBox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
    }
    return null;
  };
  
  export const getRestepImagePotencialRepkaOzimnaUrl = (geoUrl, bbox) => {
    if (bbox) {
      const coord1 = convertCoordinates2Krovak({x: bbox[0], y: bbox[1]});
      const coord2 = convertCoordinates2Krovak({x: bbox[2], y: bbox[3]});
      const krovakBBox = [coord1.x, coord1.y, coord2.x, coord2.y];
      return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_repka_o.map&layers=biomasa_repka_o&mode=map&map_imagetype=png&mapext=${krovakBBox.join('+')}&imgext=${krovakBBox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
    }
    return null;
  };
  
  export const getRestepImagePotencialKonopeTechnickeUrl = (geoUrl, bbox) => {
    if (bbox) {
      const coord1 = convertCoordinates2Krovak({x: bbox[0], y: bbox[1]});
      const coord2 = convertCoordinates2Krovak({x: bbox[2], y: bbox[3]});
      const krovakBBox = [coord1.x, coord1.y, coord2.x, coord2.y];
      return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_konopi.map&layers=biomasa_konopi&mode=map&map_imagetype=png&mapext=${krovakBBox.join('+')}&imgext=${krovakBBox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
    }
    return null;
  };
  
  export const getRestepImagePotencialRychloRastuceDrevinyUrl = (geoUrl, bbox) => {
    if (bbox) {
      const coord1 = convertCoordinates2Krovak({x: bbox[0], y: bbox[1]});
      const coord2 = convertCoordinates2Krovak({x: bbox[2], y: bbox[3]});
      const krovakBBox = [coord1.x, coord1.y, coord2.x, coord2.y];
      return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_rrd.map&layers=biomasa_rrd&mode=map&map_imagetype=png&mapext=${krovakBBox.join('+')}&imgext=${krovakBBox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
    }
    return null;
  };
  
  export const getRestepImageSpotrebaElektrickejEnergieUrl = (geoUrl, bbox) => {
    if (bbox) {
      const coord1 = convertCoordinates2Krovak({x: bbox[0], y: bbox[1]});
      const coord2 = convertCoordinates2Krovak({x: bbox[2], y: bbox[3]});
      const krovakBBox = [coord1.x, coord1.y, coord2.x, coord2.y];
      return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fspotreba_elektrina.map&layers=spotreba_elektrina&mode=map&map_imagetype=png&mapext=${krovakBBox.join('+')}&imgext=${krovakBBox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
    }
    return null;
  };