import { Button } from 'antd';
import logo from '../../../images/logo.webp';
import { Icon } from 'semantic-ui-react';
import { LANDING_PAGE_URI } from '../../../constants';

const BottomFloatingPanel = ({
  handleDone,
  buttonDisabled,
  buttonTitle,
  showIcon,
  iconClassName,
}) => {
  return (
    <div>
      <span className="logoContainer">
        <a onClick={() => { window.location = LANDING_PAGE_URI }}>
          <img className="orsrLogo" src={logo} alt="logo"/>
        </a>
      </span>
      <Button
        disabled={buttonDisabled}
        className="nextBottomButton"
        onClick={handleDone}>
        {buttonTitle}
        <Icon name={showIcon} className={iconClassName} />
      </Button>
    </div>
  );
};

export default BottomFloatingPanel;
