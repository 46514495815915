
function MunicipalitiesNamesHTMLRenderer(values) {
    let returnValue = ""
    if (values.municipalities) {
        const municipalitiesLength = values.municipalities.length
        let index = 0
        for (let municipality of values.municipalities) {
            returnValue += municipality.municipality
            if (index < municipalitiesLength - 1) {
                returnValue += ", "
            }
            index += 1
        }
    }
    return returnValue
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    HTMLRenderer: MunicipalitiesNamesHTMLRenderer
};