import axios from 'axios';
import { StyledDocumentItem } from '../../../document-download/DocumentDownload.styled';

import {
  CreateFileSignRequestAction,
  DeleteFileSignRequestAction,
  DeleteSignatureImageCustomerAction,
  GenerateORSRFileAction,
  SaveFeedbackAction,
  StoreAction,
  UpdateNoteAction,
  UploadSignatureImageCustomerAction,
  UploadSignedFileCustomerAction
} from '../../../../app/ActionsImpl';

import { DeleteOutlined, EditOutlined, StarFilled, StarTwoTone } from '@ant-design/icons';
import { Button, Card, Checkbox, Divider, Modal, Row, Spin, Steps, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { useEffect, useRef, useState } from 'react';
import { isChrome, isFirefox, isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import ReactSignatureCanvas from 'react-signature-canvas';
import { Dispatch } from 'redux';
import { Dropdown, Icon, Segment, Table } from 'semantic-ui-react';
import { getFlow } from '../../../../api/admin.api';
import { AppState } from '../../../../app/AppState';
import { COMPANY_LOOKUP_HOST } from '../../../../constants';
import ecofriendly from '../../../../images/ecofriendly.webp';
import DitecSigner from '../../../../utils/DitecSigner';
import { translations } from '../../../../utils/LocalizedStrings';
import { getAllUrlParams } from '../../../../utils/url';
import { placeholderList } from '../../../admin/pages/Feedback.page';
import { ORSRFileTypes, OrderState, OrderStateSteper, OrderStateText, OrderStateTextSteper, PaidStatus, RegistroveSudy, RegistroveSudyText, ZivnostenskeUrady, ZivnostenskeUradyText } from '../../../admin/pages/Interfaces';
import { DitecModal } from '../../components/DitecModal';
import { InviteModal } from '../../components/InviteModal';
import { DocumentDownloadOverview } from './UserOrderListDocumentOverview';

export interface Props extends StateProps, DispatchProps {
  data: { id: string } & any;
  token: any;
  openedOrder: any;
  refresh: () => void;
}

const { Step } = Steps;

function UserOrderItem({
  data,
  openedOrder,
  uploadSignedFile,
  refresh,
  saveFeedback,
  createFileSignRequest,
  deleteFileSignRequest,
  uploadSignatureImage,
  deleteSignatureImage,
  generateORSRFile,
  token,
  user
}: Props,) {
  const [downloadingFiles, setDownloadingFiles] = useState<any>([]);


  let feedback = placeholderList[Math.floor(Math.random() * placeholderList.length)];

  const [showDetail, setShowDetail] = useState(false);
  const [showDocuments, setShowData] = useState(true);
  const [showStatusHistory, setShowStatusHistory] = useState(false);
  const [showXMLFormulars, setShowXMLFormulars] = useState(false);
  const [formState, setFormState] = useState<any>({});
  const [signing, setSigning] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorCode, setErrorCode] = useState()
  const [errorSubCode, setErrorSubCode] = useState()
  const [invited, setInvited] = useState([""])
  const [inviteModalVisible, setInviteModalVisible] = useState(false)
  const [signatureModalVisible, setSignatureModalVisible] = useState<any>({})
  const [missingSignatures, setMissingSignatures] = useState<any[]>([])

  const [historyActivePage, setHistoryActivePage] = useState(1);
  const [historyRecordsPerPage, setHistoryRecordsPerPage] = useState(5);
  const [stepperIndex, setStepperIndex] = useState(0)

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState(feedback);
  const [rating, setRating] = useState(5);
  const [hover, setHover] = useState(0);
  const [filesToSign, setFilesToSign] = useState<any>([]);
  const [showSignatures, setShowSignatures] = useState<any[]>([])
  const [noneSignedDocuments, setNoneSignedDocuments] = useState<any[]>([])
  const [documentsToSign, setDocumentToSign] = useState<{ [Key: string]: any[] }>({});
  const [modalButtonDisabled, setModalButtonDisabled] = useState(false)

  const [isCreate, setIsCreate] = useState(false)
  const [isChange, setIsChange] = useState(false)

  let sigPad = useRef<ReactSignatureCanvas | null | undefined>()

  useEffect(() => {
    if (openedOrder === data._id) {
      setShowDetail(true)
    }

    let noneSignedDocuments = []
    let tmpdoc: { [Key: string]: any[] } = {}
    if (data && data.downloads) {
      for (let download of data.downloads) {
        if (!download.requiredSignatures || download.requiredSignatures.length === 0) {
          noneSignedDocuments.push(download)
        } else {
          for (let signature of download.requiredSignatures) {
            if (tmpdoc[signature]) {
              tmpdoc[signature].push(download)
            } else {
              tmpdoc[signature] = [download]
            }
          }
        }
      }
    }
    setNoneSignedDocuments(noneSignedDocuments)
    setDocumentToSign(tmpdoc)
    getFlow(data.flow_id, onFlow)
  }, [data])

  useEffect(() => {
    let params: any = getAllUrlParams()
    if (params.orderid === data._id) {
      setShowDetail(true)
    }
  }, [])

  const onFlow = (flow: any) => {
    if (flow.document.metadata.title.indexOf('Založenie') > -1) {
      setIsCreate(true)
    }
    if (flow.document.metadata.title.indexOf('Zmeny') > -1) {
      setIsChange(true)
    }
  }

  useEffect(() => {
    if (data.formState) {
      setFormState(JSON.parse(data.formState))
    } else if (data.template) {
      setFormState(JSON.parse(data.template).template)
    }
  }, [data])

  useEffect(() => {
    let found = false;

    if (data.status === 'paid') {
      if (data.orderStatus &&
        data.orderStatus.history &&
        OrderState[data.orderStatus.history[data.orderStatus.history.length - 1].state as keyof typeof OrderState]) {
        Object.keys(OrderState).forEach((k, index) => {

          if (k === data.orderStatus.history[data.orderStatus.history.length - 1].state) {
            found = true;
            setStepperIndex(Number(OrderStateSteper[k as keyof typeof OrderState]));
          }
        })
      }
    }
    if (!found) {
      setStepperIndex(0)
    }
  }, [showDetail]);

  const handleTriggerDownload = async (documentItem: any, id: any, extension: any) => {
    const filename = documentItem.name;
    await axios
      .get(
        `${COMPANY_LOOKUP_HOST}/userorder/download-file/${id}/${documentItem.id}`,
        {
          responseType: 'blob',
        },
      ).then((res) => {
        saveAs(res.data, filename + extension);
      });
  };

  const handleTriggerDownloadAll = async (documentList: any) => {
    let zip = new JSZip();
    Promise.all(
      documentList.map(async (documentItem: any) => {
        if (!documentItem) {
          return null;
        }
        const { name } = documentItem;
        await axios.get(`${COMPANY_LOOKUP_HOST}/userorder/download-file/${data._id}/${documentItem.id}`, {
          responseType: 'blob',
        },
        ).then((res) => {
          zip.file(name + '.pdf', res.data, { binary: true });
        });
      }),
    ).then(() =>
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, 'orsrhelp dokumenty');
      }),
    );
  };

  const textarea = Array.from(document.querySelectorAll('textarea'));
  if (textarea) {
    textarea.map((area) => {
      area.addEventListener('focus', (e: any) => {
        area.style.height = '65px';
        let scHeight = e.target.scrollHeight;
        area.style.height = `${scHeight}px`;
      });
      area.addEventListener('blur', (e: any) => {
        area.style.height = '63px';
      });
    });
  }

  const handleModalClose = () => {
    setErrorCode(undefined)
    setErrorSubCode(undefined)
    setSigning(false)
    setLoading(false)
  }

  const handlePaginationChange = (e: any, pageIdx: any) => {
    setHistoryActivePage(pageIdx.activePage);
  };

  const errorFunction = (error: any) => {
    if (error) {
      if (error.code !== 1) {
        setErrorCode(error.code)
        if (error.cause) {
          setErrorSubCode(error.cause.code)
        }
      }
    }
    setSigning(false)
  }

  const singaturePad = () => {
    return (
      <ReactSignatureCanvas
        canvasProps={{
          className: "signature"
        }}
        ref={(ref) => { sigPad.current = ref }} />
    )
  }

  const callbackFunction = async (response: any) => {
    setLoading(true)
    await uploadSignedFile(response.orderId, response.fileId, response)
    await refresh()
    setSigning(false)
    setLoading(false)
  }
  useEffect(() => {
  }, [downloadingFiles])

  const signFile = async (documentItem: any, id: any, signature: any) => {
    const filename = documentItem.name;
    let tmpMissingSignatures = []
    if (documentItem.requiredSignatureImages) {
      if (documentItem.imageSignatures) {
        for (let signature of documentItem.requiredSignatureImages) {
          if (documentItem.imageSignatures.indexOf(signature) === -1) {
            tmpMissingSignatures.push(signature)
          }
        }
        if (tmpMissingSignatures.length > 0) {
          setMissingSignatures(tmpMissingSignatures)
          return
        }
      } else {
        for (let signature of documentItem.requiredSignatureImages) {
          if (!documentItem.imageSignatures || documentItem.imageSignatures.indexOf(signature) === -1) {
            tmpMissingSignatures.push(signature)
          }
        }
        if (tmpMissingSignatures.length > 0) {
          setMissingSignatures(tmpMissingSignatures)
          return
        }
      }
    }
    let newDownloadingFiles = [...downloadingFiles]
    newDownloadingFiles.push(documentItem.id)
    setDownloadingFiles(newDownloadingFiles)
    if (documentItem.signedFile && documentItem.signedFile.key) {
      await axios
        .get(
          `${COMPANY_LOOKUP_HOST}/userorder/download-file/${id}/${documentItem.signedFile.id}`,
          {
            responseType: 'arraybuffer',
          },
        )
        .then((res) => {
          let base64data = Buffer.from(res.data, 'binary').toString('base64');
          let dsigner = new DitecSigner(callbackFunction, errorFunction, signature);
          dsigner.asicAddSignature(base64data, filename, id, documentItem.id);
        })
        .catch((error) => {
          console.error(error);
        });
      newDownloadingFiles = [...newDownloadingFiles]
      newDownloadingFiles.splice(newDownloadingFiles.indexOf(documentItem.id), 1)
      setDownloadingFiles(newDownloadingFiles)
    } else {
      await axios
        .get(
          `${COMPANY_LOOKUP_HOST}/userorder/download-file/${id}/${documentItem.id}`,
          {
            responseType: 'arraybuffer',
          },
        )
        .then((res) => {
          let base64data = Buffer.from(res.data, 'binary').toString('base64');
          let dsigner = new DitecSigner(callbackFunction, errorFunction, signature);
          dsigner.signFile(base64data, filename, id, documentItem.id);
        })
        .catch((error) => {
          console.error(error);
        });
      newDownloadingFiles = [...newDownloadingFiles]
      newDownloadingFiles.splice(newDownloadingFiles.indexOf(documentItem.id), 1)
      setDownloadingFiles(newDownloadingFiles)
    }
    setSigning(true)
  }
  const handleChangeFeedback = (e: any) => {
    setFeedbackMessage(e.target.value);
  };

  const sendFeedback = () => {
    setIsFeedbackModalOpen(false)
    saveFeedback(data.marketingId, rating, feedbackMessage)
  };

  const resolveFilesToSign = (documentId: any, signature: any) => {
    setFilesToSign([{ documentId, signature }])
  }

  const sendInvitation = async () => {
    let fileSignRequest = {
      recipients: invited,
      orderId: data._id,
      filesToSign: filesToSign
    }

    await createFileSignRequest(fileSignRequest)
    setInviteModalVisible(false)
    await refresh()
    await setInvited([''])
  }

  const handleInviteModalClose = () => {
    setInviteModalVisible(false)
  }

  const handleShowSignature = (id: any) => {
    let newShowSignatures = JSON.parse(JSON.stringify(showSignatures))
    if (newShowSignatures.indexOf(id) === -1) {
      newShowSignatures.push(id)
    } else {
      newShowSignatures.splice(newShowSignatures.indexOf(id), 1)
    }
    setShowSignatures(newShowSignatures)
  }
  const handleCancel = () => {
    setIsFeedbackModalOpen(false);
  };

  const clear = () => {
    sigPad?.current?.clear()
  }

  const trim = async (documentItemId: any, signature: any) => {
    let value = sigPad?.current?.getTrimmedCanvas().toDataURL('image/png')
    setModalButtonDisabled(true)
    await uploadSignatureImage(data._id, documentItemId, signature, value)
    setModalButtonDisabled(false)
    await setSignatureModalVisible({ ...signatureModalVisible, [signature]: undefined })
    await clear()
    await refresh()
  }

  const renderDocumentItem = (documentItem: any, signature: any) => {
    const { name, uploads, uploadsStatus } = documentItem;
    console.log("Podpis rukou", documentItem.requiredKepSignatures)
    // console.log(documentItem.requiredKepSignatures.indexOf(signature))
    console.log(documentItem.requiredSignatureImages)
    // console.log(documentItem.requiredSignatureImages.indexOf(signature))
    console.log(documentItem.signedFile)
    return (
      <>
        <Spin spinning={downloadingFiles.indexOf(documentItem.id) !== -1} className='userOrderList'>
          <StyledDocumentItem key={`doc-downloadn-${name}`}>
            <div className="ordersDatabaseRow">
              <Row onClick={() => handleShowSignature(documentItem.id)}>
                <span className='ordersDatabaseRowCheckbox'>
                  <div>
                    <strong>{name}</strong>
                    {documentItem.signedFile &&
                      <div className='requestSignatureEmail'>
                        <span className='requestSignatureTitle' style={{ paddingRight: 10 }}>{translations.screens.UserOrderList?.signedBy}</span>
                        {documentItem.signedFile.signatures.map((signature: any) => {
                          return (
                            <div className='documentSignedTag'>
                              <span>{signature.CN}</span>
                              <span>R.č.: {signature.SERIALNUMBER.split('-')[1]}</span>
                            </div>
                          )
                        })}
                      </div>
                    }
                    {documentItem.imageSignatures && documentItem.imageSignatures.length > 0 &&
                      <div className='requestSignatureEmail'>
                        <span className='requestSignatureTitle' style={{ paddingRight: 10 }}>{translations.screens.UserOrderList?.signedByFinger}</span>
                        {documentItem.imageSignatures?.map((signature: any) => {
                          return (
                            <div className='documentSignedTag'>
                              <span>{signature}</span>
                            </div>
                          )
                        })}
                      </div>
                    }
                    {data.signatureRequests && data.signatureRequests[documentItem.id] &&
                      <div className='requestSignatureEmail'>
                        <span className='requestSignatureTitle' style={{ paddingRight: 10 }}>{translations.screens.UserOrderList?.requestFrom}</span>
                        {data.signatureRequests[documentItem.id].map((signature: any) => {
                          return (
                            <div className='requestSignatureRow'>
                              <Tooltip title={[translations.screens.UserOrderList?.cancelRequestTooltip]} >
                                <Button
                                  onClick={async (e) => {
                                    e.stopPropagation()
                                    await deleteFileSignRequest({ orderId: data._id, recipient: signature, file: documentItem.id })
                                    refresh()
                                  }}
                                  size="small"
                                  type="text"
                                  className='cancelRequestSignatureTag'>
                                  {signature} <DeleteOutlined />
                                </Button>
                              </Tooltip>
                            </div>
                          )
                        })}
                      </div>
                    }
                  </div>
                </span>
                <span className='ordersDatabaseRowButtons'>
                  <DocumentDownloadOverview documentItem={documentItem} token={token} orderId={data._id} />

                  {signature &&
                    <Dropdown floating icon='signup' text={translations.screens.UserOrderList?.signingTitle} className='userProfileOrdersDropdown'>
                      <Dropdown.Menu>
                        <React.Fragment key={signature}>
                          {!isMobile && (isFirefox || isChrome) &&
                            <>
                              {documentItem.requiredKepSignatures && documentItem.requiredKepSignatures.indexOf(signature) !== -1 &&
                                <Dropdown.Item>
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      signFile(documentItem, data._id, signature)
                                    }}
                                    size="small"
                                    type="primary"
                                    className=''>
                                    {translations.screens.UserOrderList?.sign}
                                  </Button>
                                </Dropdown.Item>
                              }

                              {
                                ((documentItem.imageSignatures && documentItem.imageSignatures.indexOf(signature) !== -1)) &&
                                <Dropdown.Item>
                                  <Button
                                    onClick={async (e) => {
                                      e.stopPropagation()
                                      await deleteSignatureImage(data._id, documentItem.id, signature)
                                      await refresh()
                                    }}
                                    size="small"
                                    type="primary"
                                    className='requestSignatureBtn handSignature'>
                                    {translations.screens.UserOrderList?.deleteSign}
                                  </Button>
                                </Dropdown.Item>
                              }



                            </>
                          }

                          <Dropdown.Item>
                            <span className='chooseDocumentToSignCheckboxContainer'>
                              <Checkbox
                                onClick={(e) => {
                                  e.stopPropagation()
                                  resolveFilesToSign(documentItem.id, signature)
                                  setInviteModalVisible(true)
                                }}
                                className='inviteModalVisibleCheckbox'
                                checked={filesToSign.indexOf(documentItem.id) !== -1}>
                                {translations.screens.UserOrderList?.chooseDocument}
                              </Checkbox>
                            </span>
                          </Dropdown.Item>
                          <Modal
                            key={"modal_img" + signature}
                            className="vopModal signatureModal"
                            title={translations.artifacts.SignatureCanvasArtifact.signature}
                            visible={signatureModalVisible[signature] === documentItem.id}
                            onCancel={() => { setSignatureModalVisible({ ...signatureModalVisible, [signature]: undefined }) }}
                            footer={null}>
                            <div className='signature-wrapper'>
                              {singaturePad()}
                            </div>
                            <div className='buttonsContainer'>
                              {signature}
                              <Button
                                type="primary"
                                onClick={clear}
                                className="addPersonButton clearBtn">
                                {translations.artifacts.SignatureCanvasArtifact.clear}
                              </Button>
                              <Button
                                type="primary"
                                disabled={modalButtonDisabled}
                                onClick={() => trim(documentItem.id, signature)}
                                className="addPersonButton saveBtn">
                                {translations.artifacts.SignatureCanvasArtifact.save}
                              </Button>
                            </div>
                          </Modal>
                          {
                            ((!documentItem.requiredKepSignatures || documentItem.requiredKepSignatures.indexOf(signature) === -1 || (documentItem.requiredSignatureImages && documentItem.requiredSignatureImages.indexOf(signature) !== -1)) && !documentItem.signedFile) &&
                            <Dropdown.Item>
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setSignatureModalVisible({ ...signatureModalVisible, [signature]: documentItem.id })
                                }}
                                size="small"
                                type="primary"
                                className=''>
                                {translations.screens.UserOrderList?.createSign}
                              </Button>
                            </Dropdown.Item>
                          }
                        </React.Fragment>
                      </Dropdown.Menu>
                    </Dropdown>
                  }

                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      handleTriggerDownload(documentItem, data._id, ".pdf")
                    }}
                    size="small"
                    type="primary"
                    className='pdf-download-Btn'>
                    <Icon name='file pdf outline' />
                    {translations.screens.UserOrderList?.download} PDF
                  </Button>
                </span>
              </Row>
            </div>
          </StyledDocumentItem>
        </Spin >
      </>
    )
  }

  const generateORSRFileComponent = async (orderId: any, fileType: ORSRFileTypes) => {
    generateORSRFile(orderId, fileType);
  }

  const downloadORSRFile = async (orderId: any, fileType: ORSRFileTypes) => {
    await axios
      .get(
        `${COMPANY_LOOKUP_HOST}/order/download-file-orsr/${fileType}/${orderId}`,
        {
          responseType: 'blob',
        },
      ).then((res) => {
        saveAs(res.data, fileType + '-generovane.xml');
      });
  }



  return (
    <>
      <Modal
        className="missingSignaturesModal vopModal"
        title={translations.artifacts.SignatureCanvasArtifact.signature}
        visible={missingSignatures && missingSignatures.length > 0}
        onCancel={() => { setMissingSignatures([]) }}
        footer={null}>
        <div>
          {translations.artifacts.SignatureCanvasArtifact.missingSignatures}
          < br />
          {missingSignatures.map(signature => {
            return (
              <h4>
                {signature}
                <br />
              </h4>
            )
          })}
        </div>
        <div className='buttonsContainer'>
          <Button
            type="primary"
            onClick={() => { setMissingSignatures([]) }}
            className="addPersonButton clearBtn">
            OK
          </Button>
        </div>
      </Modal>
      <InviteModal visible={inviteModalVisible} invited={invited} setInvited={setInvited} sendInvitation={sendInvitation} handleClose={handleInviteModalClose} />
      <DitecModal signing={signing} loading={loading} errorCode={errorCode} errorSubCode={errorSubCode} handleClose={handleModalClose} />
      {data.template === undefined &&
        <Table.Row >
          <Table.Cell className='orderDetailsChevronContainer' onClick={() => setShowDetail(!showDetail)}>
            {showDetail ? <strong><span className='noMobileView2'>{translations.artifacts.SignatureCanvasArtifact.close}</span> <Icon className='orderDetailsChevron' name='angle up' /></strong> : <strong><span className='noMobileView2'>{translations.artifacts.SignatureCanvasArtifact.orderDetail}</span> <Icon className='orderDetailsChevron' name='angle down' /></strong>}
          </Table.Cell>
          <Table.Cell>
            <strong>
              {formState?.municipalities ? formState.municipalities[0].municipality : ''}
            </strong>
          </Table.Cell>
          <Table.Cell className='noMobileView'>
            {formState?.municipalities ? formState.municipalities[0].municipalityID : 'Nevyplnené'}
          </Table.Cell>
          {/* <Table.Cell>
            {data.status === 'paid' &&
              <Button onClick={() => { setIsFeedbackModalOpen(true) }}>
                {translations.artifacts.OrderListBox.feedBack}
              </Button>
            }
            {formState?.fieldValues?.company?.ico?.value ? formState.fieldValues.company.ico.value : ''}
          </Table.Cell> */}
          <Table.Cell className='noMobileView'></Table.Cell>
          <Table.Cell className='noMobileView'>
            {data.orderStatus?.actualStatus?.state
              ? OrderStateText[data.orderStatus.actualStatus.state as OrderState]
              : OrderStateText[OrderState.VYTVORENE]}
          </Table.Cell>
          {/* <Table.Cell className='noMobileView'>
            <span className='statePayment'><strong style={{ color: data.status === 'unpaid' ? '#f00' : '#0077b6' }}>{PaidStatus[data.status as keyof typeof PaidStatus]}</strong></span>
          </Table.Cell> */}
          {/* <Table.Cell className='noMobileView'>{data.formData.price ? data.formData.price.toLocaleString('sk-SK', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) + ' €' : 'Nevyplnené'}</Table.Cell> */}
          <Table.Cell>
            <span>{new Date(data.updated_at).toLocaleString()}</span>
          </Table.Cell>
        </Table.Row>
      }
      {data.template && formState &&
        <Table.Row onClick={() => {
          window.open(
            `/flow/${formState.flowId}?steps=${formState.selectedSteps.join(",")}&order=${data._id.toString()}`
            , "_blank")
          setShowDetail(!showDetail)
        }}>
          <Table.Cell>
            <strong>Editovať <EditOutlined /></strong>
          </Table.Cell>
          <Table.Cell>
            {formState?.fieldValues?.municipalities ? formState.fieldValues.municipalities[0].municipality : ''}
          </Table.Cell>
          <Table.Cell className='noMobileView'>
            {formState?.fieldValues?.company?.ico?.value ? formState.fieldValues.company.ico.value : ''}
          </Table.Cell>
          <Table.Cell className='noMobileView'>
            {!data.shortId ? 'Nevyplnené' : data.shortId}
          </Table.Cell>
          <Table.Cell className='noMobileView'>
            Rozpracovaná
          </Table.Cell>
          <Table.Cell>
            {<span>{new Date(data.updated_at).toLocaleString()}</span>}
          </Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>
      }
      {showDetail &&
        data.template === undefined &&
        (
          <Table.Row className='userProfileTableRow'>
            <Table.Cell colspan="9">
              <Segment>
                {/* <Steps className='userProfileStepper' current={stepperIndex}>
                  {OrderStateTextSteper.map((step, index) => {
                    return (
                      <Step title={step} key={index} />
                    )
                  })}
                </Steps> */}
                <div className="ordersButtonGroup">
                  <Button
                    className={showDocuments ? 'tabDocument active' : 'tabDocument'}
                    onClick={() => { setShowStatusHistory(false); setShowData(true); setShowXMLFormulars(false) }}>
                    Dokumenty
                  </Button>
                  <Button
                    className={showStatusHistory ? 'tabHistory active' : 'tabHistory'}
                    onClick={() => { setShowStatusHistory(true); setShowData(false); setShowXMLFormulars(false) }}
                    disabled={!(data.orderStatus && data.orderStatus.history)}>
                    História
                  </Button>
                  {user.attributes && user.attributes['custom:customer_role'] &&
                    <Button
                      className={showXMLFormulars ? 'tabXMLFormulars active' : 'tabXMLFormulars'}
                      onClick={() => { setShowStatusHistory(false); setShowData(false); setShowXMLFormulars(true) }}>
                      Formuláre FUZS/FUPS
                    </Button>
                  }
                </div>
                <Table.Row className='userOrderTableListMobileInfo'>
                  <Table.Cell className='userOrderListRatingMobile'>
                    {/* <span>{translations.artifacts.OrderListBox.ico}:</span> {!data.formData.ico ? 'Nevyplnené' : data.formData.ico} */}
                  </Table.Cell>
                  <Table.Cell className='userOrderListRatingMobile'><span>{translations.artifacts.OrderListBox.stateOfOrder}: </span>
                    {data.orderStatus?.actualStatus?.state
                      ? OrderStateText[data.orderStatus.actualStatus.state as OrderState]
                      : OrderStateText[OrderState.VYTVORENE]}
                  </Table.Cell>
                  <Table.Cell className='userOrderListRatingMobile'>
                    <span className='statePayment'>{translations.artifacts.OrderListBox.stateOfPay}:<strong style={{ color: data.status === 'unpaid' ? '#f00' : '#0077b6' }}>{PaidStatus[data.status as keyof typeof PaidStatus]}</strong></span>
                  </Table.Cell>
                  {/* <Table.Cell className='userOrderListRatingMobile'><span>{translations.artifacts.OrderListBox.priceOfOrder}: </span>{data.formData.price ? data.formData.price.toLocaleString('sk-SK', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) + ' €' : 'Nevyplnené'}</Table.Cell> */}
                  {data.status === 'paid' && (!data.hasFeedback) &&
                    <Table.Cell className='userOrderListRatingMobile'>
                      <span>{translations.artifacts.OrderListBox.feedBack}</span>
                      <Tooltip title="Ohodnoťte objednávku">
                        <StarFilled className="feedbackStarIcon" onClick={() => { setIsFeedbackModalOpen(true) }} />
                      </Tooltip>
                      {formState?.fieldValues?.company?.ico?.value ? formState.fieldValues.company.ico.value : ''}
                    </Table.Cell>
                  }
                </Table.Row>
              </Segment>
              {showDocuments && (
                <>
                  <Card className='userFiles'>
                    {Object.entries(documentsToSign).map(([signature, doclist]) => {
                      return (
                        <>
                          <Segment className='userProfileWhoSignTitle'>
                            <div >{translations.screens.UserOrderList?.whoSign}</div>
                          </Segment>
                          <div className='userProfileWhoSign'>
                            <h4>{signature}</h4>
                            {doclist.map((documentItem: any) => {
                              return (
                                renderDocumentItem(documentItem, signature)
                              )
                            }
                            )}
                          </div>
                        </>
                      )
                    }
                    )}

                    <Segment className='userProfileOtherDocumentsTitle'>
                      <div>{translations.screens.UserOrderList?.otherDocuments}</div>
                    </Segment>
                    <div className='userProfileWhoSign'>
                      {noneSignedDocuments.map((documentItem: any) => {
                        if (!documentItem) {
                          return null;
                        }
                        return (
                          renderDocumentItem(documentItem, undefined)
                        )
                      })}
                    </div>

                    <div className='documentDownloadButtonContainer'>
                      <Button size="large" type="primary" disabled={!data.downloads}
                        onClick={() => handleTriggerDownloadAll(data.downloads)}
                      >
                        {translations.artifacts.DocumentsDownload?.downloadAll}
                      </Button>
                    </div>

                    <Divider className='ecoFriendlyDivider' />
                    <div className='ecoFriendlyFooter'>
                      <img src={ecofriendly} alt='eco friendly' />
                      <div>
                        <h3>Podpíšte dokumenty ekologicky</h3>
                        <p>Elektronickým podpísaním dokumentov prispievate k zníženiu uhlíkovej stopy aj ochrane lesov. Za rok vieme takto spoločne znížiť <strong>uhlíkovú stopu o cca 75 ton CO<span style={{ fontSize: 10 }}>2</span></strong> a ušetriť <strong>vyrúbanie 1000 stromov.</strong></p>
                      </div>
                    </div>
                  </Card>
                </>
              )}

              {showStatusHistory &&
                data.orderStatus &&
                data.orderStatus.history && (
                  <Card className='userHistory'>
                    {
                      data.orderStatus.history
                      && data.orderStatus.history.length > 0 && (
                        <>
                          <div className='orderStatusFormTable'>
                            <Table celled>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>{translations.artifacts.UserOrderBox.created}</Table.HeaderCell>
                                  <Table.HeaderCell>{translations.artifacts.UserOrderBox.state}</Table.HeaderCell>

                                  <Table.HeaderCell>{translations.artifacts.UserOrderBox.numberInOffice}</Table.HeaderCell>
                                  <Table.HeaderCell>{translations.artifacts.UserOrderBox.dateInOffice}</Table.HeaderCell>
                                  <Table.HeaderCell>{translations.artifacts.UserOrderBox.office}</Table.HeaderCell>
                                  <Table.HeaderCell>{translations.artifacts.UserOrderBox.addedToOffice}</Table.HeaderCell>

                                  <Table.HeaderCell>{translations.artifacts.UserOrderBox.dateOnCourt}</Table.HeaderCell>
                                  <Table.HeaderCell>{translations.artifacts.UserOrderBox.court}</Table.HeaderCell>
                                  <Table.HeaderCell>{translations.artifacts.UserOrderBox.addedToCourt}</Table.HeaderCell>

                                  <Table.HeaderCell>{translations.artifacts.UserOrderBox.urgentToOffice}</Table.HeaderCell>
                                  <Table.HeaderCell>{translations.artifacts.UserOrderBox.urgentToCourt}</Table.HeaderCell>

                                  <Table.HeaderCell>{translations.artifacts.UserOrderBox.writtenToOffice}</Table.HeaderCell>
                                  <Table.HeaderCell>{translations.artifacts.UserOrderBox.writtenToCourt}</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>

                              <Table.Body>
                                {data.orderStatus.history && (
                                  data.orderStatus.history
                                    .slice((historyActivePage - 1) * historyRecordsPerPage, historyActivePage * historyRecordsPerPage)
                                    .map((os: any) => {
                                      return (
                                        <Table.Row>
                                          <Table.Cell>{new Date(os.createdAt).toLocaleString()}</Table.Cell>
                                          <Table.Cell>{OrderStateText[os.state as keyof typeof OrderState]}</Table.Cell>

                                          {os.cisloPodaniaUrad && <Table.Cell>{os.cisloPodaniaUrad}</Table.Cell>}
                                          {os.datumPodaniaUrad && <Table.Cell>{os.datumPodaniaUrad}</Table.Cell>}
                                          {ZivnostenskeUradyText[os.urad as ZivnostenskeUrady] && <Table.Cell>{ZivnostenskeUradyText[os.urad as ZivnostenskeUrady]}</Table.Cell>}
                                          {os.podatelnaUrad && <Table.Cell>{os.podatelnaUrad}</Table.Cell>}
                                          {os.datumPodaniaSud && <Table.Cell>{os.datumPodaniaSud}</Table.Cell>}
                                          {RegistroveSudyText[os.sud as RegistroveSudy] && <Table.Cell>{RegistroveSudyText[os.sud as RegistroveSudy]}</Table.Cell>}
                                          {os.podatelnaSud && <Table.Cell>{os.podatelnaSud}</Table.Cell>}
                                          {os.datumUrgovaniaUrad && <Table.Cell>{os.datumUrgovaniaUrad}</Table.Cell>}
                                          {os.datumUrgovaniaSud && <Table.Cell>{os.datumUrgovaniaSud}</Table.Cell>}
                                          {os.datumZapisaniaUrad && <Table.Cell>{os.datumZapisaniaUrad}</Table.Cell>}
                                          {os.datumZapisaniaSud && <Table.Cell>{os.datumZapisaniaSud}</Table.Cell>}
                                        </Table.Row>
                                      )
                                    })
                                )
                                }
                              </Table.Body>
                            </Table>
                          </div>
                          {/* <div className='orderStatusFormButtons'>
                          <Pagination
                            activePage={historyActivePage}
                            onPageChange={handlePaginationChange}
                            totalPages={Math.floor((data.orderStatus.history.length / historyRecordsPerPage) + 1)}
                          />
                        </div> */}
                        </>)
                    }

                  </Card>
                )}
              {showXMLFormulars && (
                <Card className='userForms'>
                  {isChange && (
                    <>
                      <Button className="addButton" onClick={() => generateORSRFileComponent(data._id, ORSRFileTypes.FUZS)}>
                        Vytvor FUZS súbor
                      </Button>
                      <Button className="addButton" onClick={() => downloadORSRFile(data._id, ORSRFileTypes.FUZS)}>
                        Stiahni FUZS súbor
                      </Button>
                    </>
                  )}
                  {isCreate && (
                    <>
                      <Button className="addButton" onClick={() => generateORSRFileComponent(data._id, ORSRFileTypes.FUPS)}>
                        Vytvor FUPS súbor
                      </Button>
                      <Button className="addButton" onClick={() => downloadORSRFile(data._id, ORSRFileTypes.FUPS)}>
                        Stiahni FUPS súbor
                      </Button>
                    </>
                  )}
                </Card>

              )}
            </Table.Cell>
          </Table.Row >
        )
      }
      <div className='container'>
        <Modal
          className='feedbackModal'
          onCancel={handleCancel}
          title="Poviete nám, ako ste s nami spokojní?"
          visible={isFeedbackModalOpen}
          footer={[
            <Button type="primary" onClick={sendFeedback}>{translations.screens.UserOrderList?.sendFeedback}</Button>
          ]}>
          <div className='ratingStarsContainer'>
            {[...Array(5)].map((star, i) => {
              const currentRating = i + 1
              return (
                <label>
                  <input type="radio" name="rating" value={currentRating} onClick={() => setRating(currentRating)} />
                  <StarTwoTone
                    className='star'
                    twoToneColor={currentRating <= (hover || rating) ? "#a8c230" : "#e4e5e9"}
                    onMouseEnter={() => setHover(currentRating)}
                    onMouseLeave={() => setHover(0)}
                  />
                </label>
              )
            })}
          </div>
          <div className='textareaContainer'>
            <span>{translations.screens.UserOrderList?.yourFeedback}</span>
            <TextArea
              id='feedbackTextarea'
              className='feedbackTextarea'
              value={feedbackMessage}
              onChange={handleChangeFeedback}
            ></TextArea>
          </div>
        </Modal>
      </div>
    </>
  );
}

const mapStateToProps = ({ appState }: { appState: AppState }) => ({
  user: appState.cognitoUser,
});

interface DispatchProps {
  saveNote: (note: string, orderId: string) => void;
  uploadSignedFile: (orderId: string, documentIndex: string, fileData: any) => void;
  saveFeedback: (marketingId: string, rating: number, feedbackMessage: string) => void;
  createFileSignRequest: (fileSignRequest: any) => void;
  deleteFileSignRequest: (fileSignRequest: any) => void;
  uploadSignatureImage: (orderId: string, documentIndex: string, signature: any, fileData: any) => void;
  deleteSignatureImage: (orderId: string, documentIndex: string, signature: any) => void;
  generateORSRFile: (orderId: string, fileType: ORSRFileTypes) => void
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    saveNote: (note: string, orderId: string) => dispatch(UpdateNoteAction(note, orderId)),
    uploadSignedFile: (orderId: string, documentIndex: string, fileData: any) => dispatch(UploadSignedFileCustomerAction(orderId, documentIndex, fileData)),
    uploadSignatureImage: (orderId: string, documentIndex: string, signature: any, fileData: any) => dispatch(UploadSignatureImageCustomerAction(orderId, documentIndex, signature, fileData)),
    deleteSignatureImage: (orderId: string, documentIndex: string, signature: any) => dispatch(DeleteSignatureImageCustomerAction(orderId, documentIndex, signature)),
    saveFeedback: (marketingId: string, rating: number, feedbackMessage: string) => dispatch(SaveFeedbackAction(marketingId, rating, feedbackMessage)),
    createFileSignRequest: (fileSignRequest: any) => dispatch(CreateFileSignRequestAction(fileSignRequest)),
    deleteFileSignRequest: (fileSignRequest: any) => dispatch(DeleteFileSignRequestAction(fileSignRequest)),
    generateORSRFile: (orderId: string, fileType: ORSRFileTypes) => dispatch(GenerateORSRFileAction(orderId, fileType)),
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UserOrderItem);
