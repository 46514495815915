import ReactQuill, { Quill } from 'react-quill-2';
import styled from 'styled-components';
import QuillMention from '../../../../quill-mention/quill.mention.esm'
import QuillBetterTable from "quill-better-table";


import './quill-mentions.css';

Quill.register('modules/mentions', QuillMention);

Quill.register({ "modules/better-table": QuillBetterTable });

export const DocumentEditor = styled(ReactQuill)`
  .ql-editor {
    min-height: ${({ height = '400px' }) => height};
    overflow: hidden;
  }
`;
