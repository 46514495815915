import { Button, Card, Divider } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useCallback, useMemo, useRef, useState } from 'react';
import { ARTIFACT_CONDITIONS_FUCS } from '../artifact/ARTIFACT_CONDITIONS_FUCS';
import { renderIterativeField } from './document-view/template-transforms';
import { DocumentFormBlock } from './DocumentFormBlock';
import { DocumentSnippetItem } from './DocumentSnippetItem';
import VisibilitySensor from 'react-visibility-sensor';
import { createVirtualHTML } from '../../../../api/functions.api';

const templateSnippetStart =
  '<span class="mention" data-denotation-char="#" data-id="snippet-start" data-value="Náhľad Štart">';
const templateSnippetEnd =
  '<span class="mention" data-denotation-char="#" data-id="snippet-stop" data-value="Náhľad Stop">';

const snippetsRegExpression = new RegExp(
  `${templateSnippetStart}(.+?)${templateSnippetEnd}`,
  'g',
);



const snippetStartReplaceResult =
  '<hr class="editor-snippet-start"/><span class="hide-element">';
const snippetStopReplaceResult =
  '<hr class="editor-snippet-end"/><span class="hide-element">';

const htmlParser = new DOMParser();

function DocumentView({
  values,
  documents,
  editorPreview,
  handleFieldChange,
  fields,
  changeOpenedDocument,
  activeDocumentKey,
  currentDocument,
  currentDocumentIndex,
  setAllFoundArtifacts,
  user,
  userProfileData
}) {
  const [fieldsCopy, setFields] = useState(fields);
  const cachedFieldsAsObject = useMemo(() => {
    const asObject = {};

    fieldsCopy?.forEach((field) => (asObject[field.id] = field));
    return asObject;
  }, [fieldsCopy]);

  const cachedDocumentsAsObject = useMemo(() => {
    const asObject = {};
    documents?.forEach((document) => (asObject[document.id] = document));

    return asObject;
  }, [documents]);

  const onDocumentChange = (documentId, visible) => {
    if (activeDocumentKey !== documentId && visible) {
      changeOpenedDocument(documentId, false, true);
    }
  };

  let steps = useRef([]);
  let cachedProcessedDocuments = useRef({});

  const [innerHtmlMap, setInnerHtmlMap] = useState({});
  const [isActiveDocument, setActiveDocument] = useState(false);

  const toggleActiveDocument = () => {
    setActiveDocument(!isActiveDocument);
  };

  const processedDocuments = useMemo(() => {
    let zapisnicaProcessedItems = { current: [] };
    let programIndexes = [];
    let documentOnlyOnceMap = {};

    let allFoundArtifacts = {};

    const data = documents
      .map((itemRaw, index) => {
        // if (zapisnicaIndex.current > -1) {
        //   return;
        // }

        const item = itemRaw.value
          ? itemRaw
          : { name: itemRaw.name, value: itemRaw };
        const isStep = item.value?.isStep || false;
        const isGeneral = item.value?.isGeneral || false;
        const isPointOfProgram = item.value?.isPointOfProgram || false;

        let artifactsUsed = [];
        const artifactSelector = /data-id="[aA-zZ0-9.-]+"/g;

        if (item.value.isRepeatable) {
          artifactsUsed.push(item.id + '-count');
        }

        if (itemRaw.id.split('-')[1]) {
          if (innerHtmlMap[itemRaw.id]) {
            item.value.html = innerHtmlMap[itemRaw.id];
          } else {
            if (!itemRaw.value.preventArtifactRenumbering) {
              let newParser = htmlParser.parseFromString(item.value.html, 'text/html');
              newParser.querySelectorAll('.mention').forEach((artifact) => {
                let key = artifact.getAttribute('data-id');
                if (
                  key !== 'HLASOVANIE-DATA-OBJECT' &&
                  key !== 'snippet-start' &&
                  key !== 'snippet-stop' &&
                  key.split('-')[key.split('-').length - 1] !==
                  itemRaw.id.split('-')[1] &&
                  cachedFieldsAsObject[key] &&
                  cachedFieldsAsObject[key].type &&
                  cachedFieldsAsObject[key].type.indexOf('company') === -1 &&
                  cachedFieldsAsObject[key].type.indexOf('property-type') === -1
                ) {
                  artifact.setAttribute(
                    'data-id',
                    key + '-' + itemRaw.id.split('-')[1],
                  );
                }
                if (key.split(".").length > 1 && cachedFieldsAsObject[key.split(".")[0]] && cachedFieldsAsObject[key.split(".")[0]].type === "person-select") {
                  artifact.setAttribute(
                    'data-id',
                    key.split(".")[0] + '-' + itemRaw.id.split('-')[1] + "." + key.split(".")[1],
                  );
                }
              });
              item.value.html = newParser.documentElement.innerHTML;
              let innerHtmlMapCopy = innerHtmlMap;
              innerHtmlMapCopy[itemRaw.id] = newParser.documentElement.innerHTML;
              setInnerHtmlMap(innerHtmlMapCopy);
            }
          }
        }

        let fieldIds = fieldsCopy.map((field) => field.id);

        let foundArtifacts = item.value.html?.match(artifactSelector) || [];

        allFoundArtifacts[itemRaw.id] = foundArtifacts
        setAllFoundArtifacts(allFoundArtifacts)

        foundArtifacts
          .map((art) => art?.replaceAll('"', '').replace('data-id=', ''))
          .forEach((art) => {
            if (!artifactsUsed.includes(art)) {
              artifactsUsed.push(art);
            }
            let artCopy = cachedFieldsAsObject[art.split('-')[0]];
            if (item.value.isRepeatable) {
              if (artCopy) {
                artCopy = JSON.parse(JSON.stringify(artCopy));

                artCopy.id = art;
                if (fieldIds.indexOf(artCopy.id) === -1) {
                  fieldsCopy.push(artCopy);
                  fieldIds.push(artCopy.id);
                  setFields(JSON.parse(JSON.stringify(fieldsCopy)));
                  cachedFieldsAsObject[artCopy.id] = artCopy;
                }
              }
            }
            if (values[art] === undefined) {
              if (artCopy && (artCopy.default || artCopy.type === "konatel-select"
                || artCopy.type === "spolocnik-select" || artCopy.type === "likvidator-select"
                || artCopy.type === "prokurista-select" || artCopy.type === "select" ||
                artCopy.type === "board-member-select" || artCopy.type === "time")) {
                switch (artCopy.type) {
                  case "time": {
                    values[art] = "10:00"
                    break
                  }
                  case "konatel-select": {
                    values[art] = { konatel: 0 }
                    break
                  }
                  case "spolocnik-select": {
                    values[art] = { spolocnik: 0 }
                    break
                  }
                  case "likvidator-select": {
                    console.log(artCopy, art)
                    values[art] = { likvidator: 0 }
                    break
                  }
                  case "prokurista-select": {
                    values[art] = { prokurista: 0 }
                    break
                  }
                  case "board-member-select": {
                    values[art] = { clen: 0 }
                    break
                  }
                  case "select": {
                    values[art] = artCopy.selectValues[0]
                    break
                  }
                  default: {
                    values[art] = artCopy.default;
                  }
                }
              }
            }
          });

        let results = item.value.html?.match(snippetsRegExpression) ?? [];
        if (isStep) {
          steps.current.push(itemRaw.id);
          results = ['-#-' + item.value.html];
        }

        if (results) {
          results = results.map((snippetRaw) => {
            // Parse Document template into HTML DOM and create a virtual
            // element

            // Set the value for the artifacts
            // If the value is present in `values` replace
            // innerHTML otherwise keep label

            const virtualHTML = createVirtualHTML(values, snippetRaw, cachedFieldsAsObject)


            //
            // Hide all snipet elements
            // (it define the start and stop of the document preview)
            //
            virtualHTML
              .querySelector('[data-id="snippet-start"]')
              ?.classList?.add('hide-element');
            virtualHTML
              .querySelector('[data-id="snippet-stop"]')
              ?.classList?.add('hide-element');
            virtualHTML
              .querySelector('[data-id="PROGRAM-BODY-DATA-OBJECT"]')
              ?.classList?.add('hide-element');
            virtualHTML
              .querySelector('[data-id="PROGRAM-DATA-OBJECT"]')
              ?.classList?.add('hide-element');
            let processedSnippet = virtualHTML.innerHTML;

            // ----------------------------------------------------------------
            //  Iterative Fields
            // ----------------------------------------------------------------

            // Let's find iterative fields and repeat them on values
            const iterativeFields = ['data.spolocnici'];

            const processIterativeFields = function (res) {
              let repeated = ``;

              values?.company?.data?.spolocnici.forEach((spolocnik, index) => {
                repeated = repeated + renderIterativeField(res, spolocnik, index);
              });

              processedSnippet =
                processedSnippet.replaceAll(res, repeated) + '<span';
            };
            iterativeFields.forEach((field) => {
              const regexRepeat =
                /<span class="mention" data-denotation-char="@" data-id="data\.spolocnici-start" data-value="Opakuj:Spoločníci \[START\]">(.*)<span class="mention" data-denotation-char="@" data-id="data\.spolocnici-stop" data-value="Opakuj:Spoločníci \[KONIEC\]">.<span contenteditable="false">Opakuj:Spoločníci \[KONIEC\]<\/span>.<\/span>/g;
              let repeatResults = processedSnippet.match(regexRepeat) ?? [];

              if (
                field === 'data.spolocnici' &&
                Array.isArray(values?.company?.data?.spolocnici)
              ) {
                repeatResults.forEach(processIterativeFields);
              }
            });

            // ----------------------------------------------------------------
            //  Conditional Fields
            // ----------------------------------------------------------------

            fieldsCopy?.forEach((field) => {
              const key = field.id;

              if (cachedFieldsAsObject[key]?.type === 'conditional') {
                const conditionalValue = ARTIFACT_CONDITIONS_FUCS[
                  cachedFieldsAsObject[key].condition
                ](values[key], values)
                  ? cachedFieldsAsObject[key].condition_true
                  : cachedFieldsAsObject[key].condition_false;
                processedSnippet = processedSnippet?.replaceAll(
                  `<span class="mention" data-denotation-char=":" data-id="${key}"`,
                  conditionalValue + '<span class="hide-element"',
                );
              }
            });

            for (let x = 0; x < 100; x++) {
              processedSnippet = processedSnippet.replaceAll(
                'data-index="' + x + '"',
                '',
              );
            }
            return processedSnippet;
          });
        }

        let fieldCounts = 0;

        if (results.length > 0 ? results : ['[PROGRAM]']) {
          programIndexes.push(index);
        }

        cachedProcessedDocuments.current[itemRaw.id] = editorPreview
          ? { documentID: item.id, results }
          : {
            fields: fields.sort((a, b) => {
              return (
                (b.type === 'acknowledge' ? 1 : 0) -
                (a.type === 'acknowledge' ? 1 : 0)
              );
            }),
            fieldCounts,
            artifactsUsed,
            handleFieldChange,
            documentID: item.id,
            results,
          };

        if ((isStep && !isGeneral) || (isGeneral && isPointOfProgram)) {
          zapisnicaProcessedItems.current = [
            ...zapisnicaProcessedItems.current,
            {
              stepProgram: cachedProcessedDocuments.current[itemRaw.id].results,
              name: itemRaw.name,
            },
          ];
        }

        return cachedProcessedDocuments.current[itemRaw.id];
      })
      .filter((a) => a !== undefined);

    if (programIndexes && !editorPreview) {
      for (let documentIndex of programIndexes) {
        data[documentIndex].results = (
          data[documentIndex]?.results || ['[PROGRAM]']
        ).map((htmlBlock) => {
          let replaced = htmlBlock
            .replace(
              '<span contenteditable="false"><span class="ql-mention-denotation-char">#</span>[PROGRAM]</span>',
              zapisnicaProcessedItems.current
                .map(({ stepProgram, name }, index) => {
                  let programPoint = '';
                  programPoint = String(stepProgram)
                    .slice(1)
                    .split('---')[0]
                    .split('-#-')[1];

                  return `${programPoint.replace('[X]', `${index + 1}.`)}`;
                })
                .join(''),
            )
            .replace(
              '<span contenteditable="false"><span class="ql-mention-denotation-char">#</span>[PROGRAM-BODY]</span>',
              zapisnicaProcessedItems.current
                .map(({ stepProgram, name }, index) => {
                  let program = String(stepProgram).split('---');

                  let programPoint =
                    String(stepProgram).slice(1).split('---')[0].split('-#-')[1] ||
                    'Nie je definovaný bod.';
                  programPoint = programPoint.replace('[X]', `${index + 1}.`);
                  const programContent =
                    program.length > 1
                      ? program.slice(1).join('')
                      : 'Nie je definovaný obsah.';
                  return `<br/>${String(programPoint)}<br/>${programContent
                    .replace('  ', '')
                    .replace('[X] ', '')
                    .replace('[X]', index + 1)}`;
                })
                .join(' '),
            );

          return replaced;
        });
      }
    }

    // To ensure we render the document only once
    return data.filter((doc) => {
      if (documentOnlyOnceMap[doc.documentID]) {
        return false;
      }
      documentOnlyOnceMap[doc.documentID] = true;
      return true;
    });
  }, [
    cachedFieldsAsObject,
    documents,
    editorPreview,
    fields,
    fieldsCopy,
    handleFieldChange,
    values,
  ]);


  // let allAccepted =
  //   documents.filter(
  //     (document) =>
  //       document.value.requireLesson &&
  //       (document.id.split('-').length === 1 || document.id.split('-')[1] === '0'),
  //   ).length === acceptedLessonCount;

  let filteredDocuments = processedDocuments;


  return filteredDocuments.map(
    ({ artifactsUsed, fieldCounts, documentID, results }, index) => {
      if (!cachedDocumentsAsObject[documentID]) {
        return null;
      }
      const document = cachedDocumentsAsObject[documentID];

      // ----------------------------------------------------------------
      //  Used to render the document preview when editing
      // ----------------------------------------------------------------
      if (editorPreview) {
        return (
          <div
            key={`${document.name}-docprev`}
            style={{ marginBottom: 15, minHeight: '100vh' }}
            id={`document-${documentID}`}>
            <Card>
              <Title level={4}>{document.name}</Title>
              <Divider />
              <div
                className="ql-snow ql-editor"
                dangerouslySetInnerHTML={{
                  __html: (document.value.html || '')
                    .replaceAll(
                      templateSnippetStart,
                      '<hr class="editor-snippet-start"/><span class="hide-element">',
                    )
                    .replaceAll(
                      templateSnippetEnd,
                      '<hr class="editor-snippet-end"/><span class="hide-element">',
                    ),
                }}
              />
            </Card>
          </div>
        );
      }

      if (!documentID) {
        return null;
      }

      return document.value.isHidden ? (
        <></>
      ) : (
        <VisibilitySensor
          key={`${document.name}-docprev-${documentID}`}
          minTopValue={200}
          resizeCheck
          intervalCheck
          partialVisibility
          scrollCheck
          offset={{ top: 300, bottom: 300 }}
          onChange={(visible) => onDocumentChange(documentID, visible)}>
          <div className="mainUserContainer">
            <div className="switcher-container">
              <input
                className="react-switch-checkbox"
                id={`react-switch-new`}
                type="checkbox"
                checked={isActiveDocument}
                onChange={toggleActiveDocument}
              />
              <label className="react-switch-label" htmlFor={`react-switch-new`}>
                <span className="react-switch-button" />
              </label>
            </div>
            <div
              className={
                isActiveDocument ? 'mainFrontColumn' : 'mainFrontColumn active'
              }>
              <DocumentFormBlock
                user={user}
                userProfileData={userProfileData}
                artifactsUsed={artifactsUsed}
                activeDocumentKey={activeDocumentKey}
                changeOpenedDocument={changeOpenedDocument}
                fieldCounts={fieldCounts}
                handleFieldChange={handleFieldChange}
                values={values}
                fields={fieldsCopy}
                document={cachedDocumentsAsObject[documentID]}
              />
            </div>
            <div
              className={
                isActiveDocument ? 'finalDocument active' : 'finalDocument'
              }>
              <DocumentSnippetItem
                snippets={results}
                name={cachedDocumentsAsObject[documentID].name}
                document={cachedDocumentsAsObject[documentID]}
                id={documentID}
              />
            </div>
          </div>
        </VisibilitySensor>
      );
    },
  );
}

export default DocumentView;
