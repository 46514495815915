import Auth from '@aws-amplify/auth';
import { Button, message, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { connect } from 'react-redux';
import { Form, Icon, Popup, Search } from 'semantic-ui-react';
import { translations } from '../../../utils/LocalizedStrings';
import { isEmailValid, removeAccentsAndLowerCase, validatePassword } from '../../../utils/string';
import { LoginFormState, PartnerType } from '../../admin/pages/Interfaces';
import moment from 'moment';
import { promiseCompanyLookup } from '../hooks/use-company-lookup';
import _ from 'lodash';
import { Box } from '../../../components/Box';
import { isoCountryCodes } from '../../editor/countries/countries';
import AddressAutoCompleteSeperatedInput from './inputs/AddressAutocompleteSeperatedInput';
import Text from 'antd/lib/typography/Text';
import { CreateUserProfileDataAction } from '../../../app/ActionsImpl';
import { Message } from 'semantic-ui-react';

const UserRegistrationForm = ({
  setFormState,
  setIsLoading,
  email,
  createUserProfileData,
  isPartnerRegistration
}
) => {

  const [formData, setFormData] = useState({});
  const [validEmail, validateEmail] = useState(true);
  const [isPhoneNumberValid, setPhoneNumberValid] = useState(true);
  const [registerAsPartner, setRegisterAsPartner] = useState(false);
  const [verifyCognito, setVerifyCognito] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [selectedCompany, setSelectedCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [infoText, setInfoText] = useState('');
  const [infoVisible, setInfoVisible] = useState(false);

  const promises = useRef();

  const showMessage = (message, timeout) => {
    console.log("INFO", message)
    setInfoText(message)
    setInfoVisible(true)
    setTimeout(() => {
      console.log("INFO NOT VISIBLE")
      setInfoVisible(false);
    }, timeout);
  }

  const handleField = (e) => {
    e.persist();
    setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handlePartnerField = (e) => {
    e.persist();
    let partnerData = formData.partnerData || {}
    partnerData[e.target.name] = e.target.value
    setFormData((old) => ({ ...old, partnerData }));
  };

  useEffect(() => {
    if (email !== undefined) {
      let newFormData = JSON.parse(JSON.stringify(formData))
      newFormData.kontakt_email = email
      setFormData(newFormData)
    }
    if (isPartnerRegistration) {
      setRegisterAsPartner(true)
    }
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationMessage = validateData(formData)
    if (validationMessage === '') {
      setIsLoading(true)
      try {
        let userProfileData
        if (registerAsPartner) {
          userProfileData = {
            address: formData.address,
            cin: formData.cin,
            tin: formData.tin,
            vat: formData.vat,
            kontakt_email: formData.kontakt_email,
            email: formData.kontakt_email,
            business_name: formData.business_name,
            partnerData: formData.partnerData
          }
        }
        await createUserProfileData(
          userProfileData, {
          username: formData.kontakt_email,
          password: formData.pass1,
          attributes: {
            given_name: formData.given_name,
            family_name: formData.family_name,
            phone_number: formData.phone_number,
            'custom:marketing_suhlas': formData['custom:marketing_suhlas'] ? formData['custom:marketing_suhlas'].toString() : 'false',
            'custom:customer_role': formData['custom:customer_role'] ? formData['custom:customer_role'].toString() : ''
          }
        })
        setFormState(LoginFormState.LOGIN)
      } catch (error) {
        message.error('Registrácia nebola úspešná.')
      }
      setIsLoading(false)
    } else {
      message.error(validationMessage)
    }
  };

  useEffect(() => {
    if (formData.kontakt_phone && formData.kontakt_phone.length > 0) {
      setPhoneNumberValid(isValidPhoneNumber(formData.kontakt_phone))
    } else {
      setPhoneNumberValid(true)
    }
  }, [formData])

  const validateData = (formData) => {

    let messages = '';

    let errInputs = []

    if (formData.given_name === undefined || formData.given_name === '') {
      messages += "Zadajte krstné meno ";
      errInputs.push('given_name_id')
    }
    if (formData.family_name === undefined || formData.family_name === '') {
      messages += "Zadajte priezvisko ";
      errInputs.push('family_name_id')
    }
    if (formData.kontakt_email === undefined || !isEmailValid(formData.kontakt_email)) {
      messages += "Neplatný email ";
      errInputs.push('emailInputCheckoutForm')
    }
    if (formData.phone_number === undefined ||
      !formData.phone_number.length ||
      !isValidPhoneNumber(formData.phone_number)) {
      messages += "Neplatne telefónne číslo";
      errInputs.push('phoneNumberInputCheckoutForm')
    }
    if (formData.pass1 === undefined || (formData.pass1 !== formData.pass2)) {
      messages += "Heslá sa nezhodujú";
      errInputs.push('pass2_id')
    }
    if (!validatePassword(formData.pass1)) {
      messages += "Heslo musí obsahovať aspoň jedno velké a malé písmeno, aspoň jednu číslicu a aspoň 8 znakov";
      errInputs.push('pass1_id')
    }
    if (registerAsPartner) {
      if (!formData.partnerData) {
        messages += "Zadajte údaje partnera";
        errInputs.push('partner_role_id')
        errInputs.push('partner_search_id')
        errInputs.push('cin_id')
        errInputs.push('tin_id')
      } else {
        if (!formData.partnerData['custom:customer_role']) {
          messages += "Vyberte rolu";
          errInputs.push('partner_role_id')
        }
        if (!formData.partnerData.business_name) {
          messages += "Zadajte obchodné meno";
          errInputs.push('partner_search_id')
        }
        if (!formData.partnerData.cin) {
          messages += "Zadajte IČO";
          errInputs.push('cin_id')
        }
        if (!formData.partnerData.tin) {
          messages += "Zadajte DIČ";
          errInputs.push('tin_id')
        }
        if (!formData.partnerData.address) {
          messages += "Zadajte adresu";
        } else {
          if (!formData.partnerData.address.street) {
            messages += "Zadajte ulicu";
          }
          if (!formData.partnerData.address.number) {
            messages += "Zadajte popisné číslo";
          }
          if (!formData.partnerData.address.city) {
            messages += "Zadajte mesto";
          }
          if (!formData.partnerData.address.zip) {
            messages += "Zadajte PSČ";
          }
          if (!formData.partnerData.address.country) {
            messages += "Zadajte krajinu";
          }
        }
      }
    }



    errInputs.forEach((inputId) => {
      const myElem = document.getElementById(inputId)
      if (myElem) {
        myElem.classList.add("error-validation");
      }
    })
    return messages;
  }


  const handleSearchChange = async (e, data, isCreateMode) => {
    // setResults([]);
    // setFreeCompanyName([]);
    setLoading(true);
    // setNewCompanyName(data.value);
    setSelectedCompany({});
    promises.current = promises.current || [];
    promises.current.forEach((p) => {
      if (p.cancel) {
        p.cancel()
      }
    });
    promises.current = [];
    const responseData = promiseCompanyLookup(data.value, isCreateMode, "");
    promises.current.push(responseData);
    responseData
      .then((responseData) => {
        if (responseData) {
          setResults(
            responseData.map((item) => ({
              title: item.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value,
              description: `IČO: ${item.identifiers.filter(i => moment(i.validFrom).isSameOrBefore(moment()) && !i.validTo)[0].value}`,
              full_data: item,
            })),
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSearchChange = (e, data) => {
    setLoading(true);
    let newSearch = data.value;
    setSearchString(newSearch);
    debounceHandleSearchChange.current(e, data, false);
  };

  const debounceHandleSearchChange = useRef(
    _.debounce(handleSearchChange, 500, {
      loading: true,
    }),
  );

  const isAccountant = (company) => {
    if (company &&
      company.activities) {
      return company.activities.filter(a => {
        const tmpActivity = removeAccentsAndLowerCase(a.economicActivityDescription).replace(',', '').replace(' ', '');
        return tmpActivity === 'vedenieuctovnictva'
      }).length

    } else {
      return false
    }
  }

  useEffect(() => {
    if (selectedCompany.id) {
      let formDataCopy = JSON.parse(JSON.stringify(formData))
      let address = selectedCompany.addresses.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0]
      formDataCopy.partnerData = formDataCopy.partnerData || {}
      formDataCopy.partnerData.cin = selectedCompany.identifiers.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value
      formDataCopy.partnerData.legalForm = selectedCompany.legalForms.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value
      formDataCopy.partnerData.business_name = selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value
      formDataCopy.partnerData.tin = selectedCompany.TIN?.DIC
      formDataCopy.partnerData.vat = selectedCompany.VAT?.IC_DPH
      formDataCopy.partnerData.address = {
        number: address.buildingNumber,
        street: address.street,
        zip: address.postalCodes ? address.postalCodes[0] : "",
        city: address.municipality.value,
        country: isoCountryCodes[address.country.code]
      }

      if (!'partnerData' in formDataCopy) {
        formDataCopy['partnerData'] = ''
      }

      if (selectedCompany &&
        selectedCompany.sourceRegister.value.code === "64") {
        formDataCopy.partnerData['custom:customer_role'] = PartnerType.PARTNER_NOTARY
      } else if (selectedCompany &&
        selectedCompany.sourceRegister?.value?.code === "69" || (
          selectedCompany.statisticalCodes?.mainActivity?.code === "69100" &&
          selectedCompany.statisticalCodes?.mainActivity?.codelistCode === "CL005205"
        )) {
        formDataCopy.partnerData['custom:customer_role'] = PartnerType.PARTNER_LAWYER
      } else if (isAccountant(selectedCompany)) {
        formDataCopy.partnerData['custom:customer_role'] = PartnerType.PARTNER_ACCOUNTANT
      } else {
        formDataCopy.partnerData['custom:customer_role'] = ''
        showMessage(translations.artifacts.UserRegistrationBox.noPartnerWarning, 5000)
      }

      setSearchString(selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value)
      setFormData(formDataCopy)
    }
  }, [selectedCompany])

  function addTextToElementNoResults() {
    const newDiv = document.createElement("div");
    newDiv.classList.add("noResultsMessage");
    const newContent = document.createTextNode(translations.artifacts.NewOwnerComponent.noResult);
    newDiv.appendChild(newContent);
    const el = document.querySelector('.search > div.results.transition.visible > div.empty.message')
    if (el) {
      el.appendChild(newDiv);
    }
  }

  useEffect(() => {
    addTextToElementNoResults()
    addTextToElementSearchingResults()
  }, [results]);

  function addTextToElementSearchingResults() {
    const newDiv = document.createElement("div");
    newDiv.classList.add("resultsParagraph");
    const newContent = document.createTextNode(translations.artifacts.NewOwnerComponent.specifySearchingName);
    newDiv.appendChild(newContent);
    const el = document.querySelector('.ui.search > .results')
    if (el) {
      el.appendChild(newDiv);
    }
  }


  return (
    <main id="login" className='registerForm'>
      <div id='mainWrapper'>
        <span className='stepBackArrow' onClick={() => setFormState(LoginFormState.LOGIN)}>
          <Popup className='stepBackArrowPopup' content='Krok späť' trigger={<Icon name="arrow left" />} />
        </span>
        <header className='loginHeader'>Registrácia</header>
        <Form onSubmit={handleSubmit} className="orderUiForm">
          <Form.Field className="contactField nameSurmanePhone">
            <Form.Input
              id="given_name_id"
              label="Meno"
              name="given_name"
              value={formData.given_name}
              onChange={handleField}
              className='required'

            />
            <Form.Input
              label={translations.artifacts.UserRegistrationBox.surname}
              id="family_name_id"
              name="family_name"
              value={formData.family_name}
              onChange={handleField}
              className='required'
            />
          </Form.Field>
          <Form.Field className="contactField nameSurmanePhone" style={{ position: 'relative' }}>
            {formData.pass1 && formData.pass1.length > 0 &&
              < PasswordStrengthBar scoreWords={[
                translations.artifacts.UserRegistrationBox.passwordTooWeak,
                translations.artifacts.UserRegistrationBox.passwordWeak,
                translations.artifacts.UserRegistrationBox.passwordGood,
                translations.artifacts.UserRegistrationBox.passwordStrong,
                translations.artifacts.UserRegistrationBox.passwordTooStrong
              ]}
                shortScoreWord={translations.artifacts.UserRegistrationBox.passwordShort}
                className='passwordStrengthBar' password={formData.pass1} />
            }
            <Form.Input
              id="pass1_id"
              label="Heslo"
              name="pass1"
              value={formData.pass1}
              onChange={handleField}
              className='required'
              type='password'
            />
            <Form.Input
              id="pass2_id"
              className='repeatPassword required'
              label="Heslo opakovať"
              name="pass2"
              value={formData.pass2}
              onChange={handleField}
              type='password'
              error={
                formData.pass1 === formData.pass2
                  ? false
                  : {
                    content: 'Heslá sa nezhodujú',
                  }
              }
            />
          </Form.Field>
          <Form.Field className="contactField nameSurmanePhone emailInput">
            <Form.Input
              label="E-mail"
              id={'emailInputCheckoutForm'}
              name="kontakt_email"
              className={validEmail ? 'required' : "invalid required"}
              value={formData.kontakt_email}
              disabled={email}
              onChange={(e) => {
                handleField(e);
                validateEmail(isEmailValid(e.target.value));
              }}
              error={
                validEmail
                  ? false
                  : {
                    content: 'Prosím zadajte platný email.',
                  }
              }

            />
            <div className="field">
              <div className={formData.phone_number &&
                !isValidPhoneNumber(formData.phone_number) ? 'phoneInputLabel invalid' : 'phoneInputLabel'}>{translations.artifacts.UserRegistrationBox.telephone}</div>
              <PhoneInput
                id="phoneNumberInputCheckoutForm"
                name="kontakt_phone"
                value={formData.phone_number}
                defaultCountry="SK"
                withCountryCallingCode={true}
                useNationalFormatForDefaultCountryValue={true}
                className={(isPhoneNumberValid) ? 'valid required' : 'invalid required'}
                onChange={(value) => {
                  setFormData((old) =>
                    JSON.parse(
                      JSON.stringify({ ...old, ['phone_number']: value }),
                    ),
                  );
                }}
              />
              {formData.phone_number &&
                !isValidPhoneNumber(formData.phone_number) &&
                <div className='ui pointing above prompt label'>{translations.artifacts.UserRegistrationBox.pleaseEnterFullNumber}</div>
              }
            </div>
          </Form.Field>
          <Form.Field className="registerFormMarketingCheckbox">
            <Form.Input
              name="custom:customer_role"
              checked={registerAsPartner}
              onChange={(e) => {
                setRegisterAsPartner(e.target.checked);
              }}
              type='checkbox'
            />
            <span>
              {translations.artifacts.UserRegistrationBox.registerAsPartner}<br />
              {translations.artifacts.UserRegistrationBox.registerAsPartner2}
            </span>
          </Form.Field>
          {registerAsPartner &&
            <>
              <Form.Field className="contactField nameSurmanePhone"

              >
                <span className='newOwnerSearchContainer gridRow'>
                  <Box>
                    <p className='searchBarLabel'>Obchodné meno</p>
                    <Search
                      id="partner_search_id"
                      loading={loading}
                      onResultSelect={(e, data) => {
                        setSelectedCompany(data.result.full_data);
                      }}
                      onSearchChange={onSearchChange}
                      results={loading ? [] : results}
                      placeholder={translations.artifacts.OwnersComponentBox.searchOrsr}
                      value={searchString}
                      className="personSearch required"
                    />
                  </Box>
                </span>
                <Form.Input
                  id="cin_id"
                  label="IČO"
                  name="cin"
                  value={formData.partnerData?.cin}
                  onChange={handlePartnerField}
                  className="required"
                />
              </Form.Field>
              <Form.Field className="contactField nameSurmanePhone">
                <Form.Input
                  label="IČ DPH"
                  name="vat"
                  value={formData.partnerData?.vat}
                  onChange={handlePartnerField}

                />
                <Form.Input
                  id="tin_id"
                  label="DIČ"
                  name="tin"
                  value={formData.partnerData?.tin}
                  onChange={handlePartnerField}
                  className="required"
                />
              </Form.Field>
              <Form.Field className="contactField nameSurmanePhone">
                <AddressAutoCompleteSeperatedInput
                  placeholder={translations.artifacts.KonatelBox.selectAdress}
                  label={translations.artifacts.KonatelBox.selectAdress}
                  name="address"
                  onBlur={() => { }}
                  onChange={(value) => {
                    let formDataCopy = JSON.parse(JSON.stringify(formData))
                    let partnerData = formDataCopy.partnerData || {}
                    partnerData.address = value.value
                    formDataCopy.partnerData = partnerData
                    setFormData(formDataCopy)
                  }
                  }
                  country={formData.partnerData?.address?.country}
                  number={formData.partnerData?.address?.number}
                  street={formData.partnerData?.address?.street}
                  zip={formData.partnerData?.address?.zip}
                  city={formData.partnerData?.address?.city}
                />
              </Form.Field>
              <Form.Field className="contactField nameSurmanePhone selectTypeOfPartner">
                <Text>Typ partnera</Text>
                <Select
                  disabled
                  id="partner_role_id"
                  name="custom:customer_role"
                  value={formData?.partnerData ? formData.partnerData['custom:customer_role'] : undefined}
                  onChange={(data) => {
                    let formDataCopy = JSON.parse(JSON.stringify(formData))
                    let partnerData = formDataCopy.partnerData || {}
                    partnerData["custom:customer_role"] = data
                    formDataCopy.partnerData = partnerData
                    setFormData(formDataCopy)
                  }}
                  className="required"
                >
                  {Object.keys(PartnerType).map((key, value) => {
                    return (
                      <Select.Option value={key} key={key}>
                        {translations.enums.partnerType[key]}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Field>
            </>
          }
          <Form.Field className="registerFormMarketingCheckbox">
            <Form.Input
              name="custom:marketing_suhlas"
              checked={formData["custom:marketing_suhlas"]}
              onChange={(e) => {
                setFormData((old) => ({ ...old, [e.target.name]: e.target.checked }));
              }}
              type='checkbox'
            />
            {translations.artifacts.UserRegistrationBox.iAgree}
          </Form.Field>
          <Form.Field className="contactField nameSurmanePhone registerButton">
            <Button
              className="ui primary big button"
              type="submit"
              onClick={handleSubmit}
            >Registrovať</Button>
          </Form.Field>
        </Form>
      </div>
      {
        infoVisible &&
        <Message
          className='infoMessage'
          id="infoMessage"
          header='Informácia'
          content={infoText}
        />
      }
    </main>
  );
};

const mapStateToProps = ({ appState }) => ({
  company: appState.company
});

const mapDispatchToProps = {
  createUserProfileData: CreateUserProfileDataAction
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRegistrationForm);
