import axios from 'axios';
import { useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Icon, Search } from 'semantic-ui-react';
import { COMPANY_LOOKUP_HOST, LANDING_PAGE_URI } from '../../constants';
import logo from '../../images/blueLogo.webp';
import { translations } from '../../utils/LocalizedStrings';
import LoginModal from '../frontoffice/components/LoginModal';
import ReactPlayer from 'react-player/lazy'
import backgroundVideo from '../../images/background.mp4'

const LandingPage = ({ user }) => {
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [activeFlow, setActiveFlow] = useState("");
  const [flow, setFlow] = useState("");
  const [selectedSteps, setSelectedSteps] = useState([]);
  const [selectedMunicipalities, setSelectedMunicipalities] = useState([]);

  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState("")
  const [searchString, setSearchString] = useState("")
  const [results, setResults] = useState([])
  const debounceTimeout = useRef(null);


  const handleButtonClick = (buttonNumber) => {
    if (buttonNumber === 1) {
      setActiveFlow("main-town")
      setSearchString('')
      setSelectedMunicipalities([])
    } else if (buttonNumber === 2) {
      setActiveFlow("main-volume")
      setSearchString('')
    }
  };

  useEffect(async () => {
    if (activeFlow) {
      setSelectedSteps([]);
      await axios.post(`${COMPANY_LOOKUP_HOST}/admin/flow/list`)
        .then((r) => r.data)
        .then((json) => {
          let flow = json.documents.find((item) => {
            let searchedFlow = activeFlow;
            return item.metadata?.mapping === searchedFlow;
          });
          if (!flow?.id) {
            alert('Flow nexistuje alebo nieje publikovaný.');
            return;
          }
          setFlow(flow.id);
          setFlow(flow);
          setSelectedSteps([flow?.metadata?.mainStep])
        });
    }
  }, [activeFlow]);

  useEffect(() => {
    if (user) {
      setLoginModalVisible(false)
    } else {
      setLoginModalVisible(true)
    }
  }, [])

  const handleSearchChange = (e, { value }) => {
    setSearchString(value)

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Nastavenie nového timeoutu
    debounceTimeout.current = setTimeout(async () => {
      setLoading(true);
      setValue(value);

      const results = await axios.post(COMPANY_LOOKUP_HOST + "/municipality/list", { name: value });

      setLoading(false);
      console.table('results', results.data);
      setResults(results.data.map(res => ({
        title: res.municipality,
        description: `Okres: ${res.districtID.district}, Kraj: ${res.regionId.name}`,
        id: res._id
      })));
    }, 500);
  };

  const handleResultSelect = (e, { result }) => {
    setValue(result.id)
    setSearchString(result.title)
    setResults([])
  };

  const handleResultSelectMunicipalities = useCallback((e, { result }) => {
    setSelectedMunicipalities(prevState => [...prevState, result]);
    setSearchString("");
    setResults([]);
  }, []);

  const removeMunicipality = (id) => {
    setSelectedMunicipalities(prevState => prevState.filter(municipality => municipality.id !== id));
  };

  return (
    user ?
      <>
        <main className='landingPage'>
          <div className='introVideo'>
            <ReactPlayer
              url={backgroundVideo}
              muted
              playsinline
              loop={true}
              playing={true}
            />
          </div>
          <div className='newLogo'>
            <a onClick={() => window.location.href = LANDING_PAGE_URI ? LANDING_PAGE_URI : ''}>
              <img src={logo} alt="logo" className='logo' />
            </a>
            <span className="user" onClick={() => (window.location.href = '/user-profile')}>
              <Icon name="user" />
              <strong className='iconUserName'>Môj účet</strong>
            </span>
          </div>
          <div className='container'>
            <div className='main-login-container'>
              <h1>Vitajte na stránke SMS AI</h1>
              <div className='landingPage-buttons-container'>
                <button
                  className={`addButton ${activeFlow === "main-town" ? 'active-lp-button' : ''}`}
                  onClick={() => handleButtonClick(1)}>
                  Obec/Město
                </button>
                <button
                  className={`addButton ${activeFlow === "main-volume" ? 'active-lp-button' : ''}`}
                  onClick={() => handleButtonClick(2)}>
                  Viac obcí
                </button>
              </div>
              {activeFlow &&
                <div className='landingPage-search-container'>
                  <h3>Zadejte název obce nebo města:</h3>
                  {activeFlow === "main-town" ?
                    <>
                      <Search
                        fluid
                        loading={loading}
                        onResultSelect={handleResultSelect}
                        onSearchChange={handleSearchChange}
                        results={results}
                        value={searchString}
                        showNoResults={false}
                      />
                      {searchString !== '' &&
                        <button className='addButton continue-button'
                          onClick={() => (window.location = `/flow/${flow?.id}?id=${value}&steps=${selectedSteps.map(step => step)}`)}>
                          Pokračovať
                        </button>
                      }
                    </>
                    :
                    <>
                      <Search
                        fluid
                        loading={loading}
                        onResultSelect={handleResultSelectMunicipalities}
                        onSearchChange={handleSearchChange}
                        results={results}
                        value={searchString}
                        showNoResults={false}
                      />
                      <div className='selected-municipalities'>
                        {selectedMunicipalities.map(municipality => (
                          <span key={municipality.id} className='selected-municipality'>
                            <span>{municipality.title} - {municipality.description}</span>
                            <span className='remove-municipality' onClick={() => removeMunicipality(municipality.id)}>X</span>
                          </span>
                        ))}
                      </div>
                      {selectedMunicipalities.length > 0 &&
                        <button className='addButton continue-button'
                          onClick={() => (window.location = `/flow/${flow?.id}?id=${selectedMunicipalities.map(municipality => municipality.id).join(';')}&steps=${selectedSteps.map(step => step)}`)}>
                          Pokračovať
                        </button>
                      }
                    </>
                  }
                </div>
              }
            </div>
          </div>
          <footer className='landing-page-footer'>
            <article className="footer redirectLinks">
              <span>&copy; SMS AI s.r.o. {new Date().getFullYear()}</span>
              <a href="https://www.matejka.company/docs/kodex%20GDPR%20sk_en.pdf.pdf" target="_blank">{translations.artifacts.LoginModalBox.gdpr}</a>
            </article>
          </footer>
        </main>
      </>
      :
      <>
        <LoginModal
          visible={loginModalVisible}
          handleClose={() => setLoginModalVisible(false)}
        />
      </>
  );
}

const mapStateToProps = ({ appState }) => ({
  user: appState.cognitoUser,
});


export default connect(mapStateToProps)(LandingPage)
