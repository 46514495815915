import { Modal } from 'antd';
import { translations } from '../../../../utils/LocalizedStrings';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { renderAddress } from '../../../../utils/string';
import { useEffect, useState } from 'react';
import NestedArtifactKeys from './NestedArtifactKeys';
import NewCompanyAddress from './NewCompanyAddress';
import ContractServiceClient from './ServiceContractClient';

export default function ContractModal({ visible, formData, handleClose, values, documents, user, userProfileData }) {
  const [contract, setContract] = useState()
  const [finalContract, setFinalContract] = useState()

  const templateSnippetStart =
    '<span class="mention" data-denotation-char="#" data-id="snippet-start" data-value="Náhľad Štart">';
  const templateSnippetEnd =
    '<span class="mention" data-denotation-char="#" data-id="snippet-stop" data-value="Náhľad Stop">';

  const snippetsRegExpression = new RegExp(
    `${templateSnippetStart}(.+?)${templateSnippetEnd}`,
    'g',
  );

  useEffect(() => {
    console.log(formData)
  })

  useEffect(() => {
    for (let document of documents) {
      if (document.value.isContract) {
        console.log(document)
        setContract(document.value.html)
        break
      }
    }
  }, [documents])

  useEffect(() => {
    const htmlParser = new DOMParser();
    if (contract) {
      let newParser = htmlParser.parseFromString(contract.match(snippetsRegExpression), 'text/html');
      newParser.querySelectorAll('.mention').forEach((artifact) => {
        console.log('ARTIFACT', artifact)
        console.log(artifact.getAttribute('data-id'))
        let key = artifact.getAttribute('data-id');
        if (
          key === 'snippet-start' ||
          key === 'snippet-stop'
        ) {
          artifact.classList.remove('mention');
          artifact.innerHTML = ""
        } else if (key === 'CONTRACT-SERVICE-CLIENT-DATA-OBJECT') {
          artifact.innerHTML = ContractServiceClient.HTMLRenderer(values, formData, user, userProfileData)
        }
      });
      setFinalContract(newParser.documentElement.innerHTML)
    }
  }, [contract, values, formData])



  return (
    <>
      <Modal
        className="vopModal"
        title={translations.artifacts.ContractModalBox.title}
        visible={visible}
        onCancel={handleClose}
        footer={null}
        bodyStyle={{ height: '60vh', overflow: 'auto' }}>
        <article style={{ padding: '0 30px', textAlign: 'justify' }}>
          <div dangerouslySetInnerHTML={{ __html: finalContract }} class="frontoffice-docview--snippet">
          </div>
          {/* <div class="frontoffice-docview--snippet">
            <p></p>
            <p class="ql-align-justify"><strong>{translations.artifacts.ContractModalBox.parties}&nbsp; </strong></p>
            <p class="ql-align-justify">&nbsp;</p>
            <p class="ql-align-justify"><strong>{translations.artifacts.ContractModalBox.lawyersOffice}</strong>{translations.artifacts.ContractModalBox.withCompanyAdress}<strong>{translations.artifacts.ContractModalBox.lawyer}</strong>{translations.artifacts.ContractModalBox.orAnd}<strong>{translations.artifacts.ContractModalBox.operator}</strong>“)</p>
            <p class="ql-align-justify"><br /></p>
            <p class="ql-align-justify"><strong>{translations.artifacts.ContractModalBox.and}</strong></p>
            <p class="ql-align-justify"><br /></p>
            {values && values.company &&
              values.company.ico ?
              <p class="ql-align-justify"><strong><span class="mention" data-denotation-char=":" data-id="company.name" data-value="ORSR-Name">{values.company.meno.value}</span></strong>{translations.artifacts.ContractModalBox.withAdress}<span class="mention" data-denotation-char=":" data-id="company.data.adresa" data-value="ORSR-Adresa">{renderAddress(values["company"].adresa)}</span>, IČO: <span class="mention" data-denotation-char=":" data-id="company.data.ico" data-value="ORSR-Ico">{values["company"].ico.value}</span>{translations.artifacts.ContractModalBox.companyWrittenIn}<span class="mention" data-denotation-char=":" data-id="company.data.prislusny_sud" data-value="ORSR-Prislusny Sud">{values["company"].register.registrationOffices[0].value}</span>{translations.artifacts.ContractModalBox.part}<span class="mention" data-denotation-char=":" data-id="company.data.oddiel" data-value="ORSR-Oddiel">{values["company"].register.registrationNumbers[0].value.split("/")[0]}</span>{translations.artifacts.ContractModalBox.insert}<span class="mention" data-denotation-char=":" data-id="company.data.vlozka" data-value="ORSR-Vlozka">{values["company"].register.registrationNumbers[0].value.split("/")[1] + "/" + values["company"].register.registrationNumbers[0].value.split("/")[2]}</span>{translations.artifacts.ContractModalBox.order}<strong>{translations.artifacts.ContractModalBox.client}</strong>{translations.artifacts.ContractModalBox.orAnd}<strong>{translations.artifacts.ContractModalBox.user}</strong>“)</p>
              :
              <p class="ql-align-justify"><strong><span class="mention" data-denotation-char=":" data-id="company.name" data-value="ORSR-Name">{values.company.name}</span></strong>{translations.artifacts.ContractModalBox.withAdress}<span class="mention" data-denotation-char=":" data-id="company.data.adresa.street" data-value="ORSR-Adresa.street">{values.companyAddress && values.companyAddress.address && values.companyAddress.address.street ? values.companyAddress.address.street : "Ulica"}</span> <span class="mention" data-denotation-char=":" data-id="company.data.adresa.number" data-value="ORSR-Adresa.number">{values.companyAddress && values.companyAddress.address && values.companyAddress.address.number ? values.companyAddress.address.number : "Číslo"}</span>, <span class="mention" data-denotation-char=":" data-id="company.data.adresa.zip" data-value="ORSR-Adresa.zip">{values.companyAddress && values.companyAddress.address && values.companyAddress.address.zip ? values.companyAddress.address.zip : "PSČ"}</span> <span class="mention" data-denotation-char=":" data-id="company.data.adresa.city" data-value="ORSR-Adresa.city">{values.companyAddress && values.companyAddress.address && values.companyAddress.address.city ? values.companyAddress.address.city : "Mesto"}</span>{translations.artifacts.ContractModalBox.canActAlone}<strong>{translations.artifacts.ContractModalBox.client}</strong>{translations.artifacts.ContractModalBox.orAnd}<strong>{translations.artifacts.ContractModalBox.user}</strong>“)</p>
            }
            <p><br /></p>
            <p><br /></p>
            <h4 class="ql-align-center">{translations.artifacts.ContractModalBox.articleOne}</h4>
            <p class="ql-align-center"><strong style={{ color: "windowtext" }}>{translations.artifacts.ContractModalBox.baseAgreement}</strong></p>
            <p><br /></p>
            <p class="ql-align-justify">(1) {translations.artifacts.ContractModalBox.firstRule} [<em>{translations.artifacts.ContractModalBox.definatedDown}</em>].</p>
            <p class="ql-align-justify">(2) {translations.artifacts.ContractModalBox.secondRule} </p>
            <p class="ql-align-justify">(3) {translations.artifacts.ContractModalBox.thirdRule} </p>
            <p class="ql-align-justify">(4) {translations.artifacts.ContractModalBox.fourthRule} </p>
            <p class="ql-align-justify">(5) {translations.artifacts.ContractModalBox.fifthRule}[<em>{translations.artifacts.ContractModalBox.definatedDown}</em>]{translations.artifacts.ContractModalBox.fifthRuleTwo}</p>
            <p class="ql-align-justify">(6) {translations.artifacts.ContractModalBox.sixsthRule} </p>
            <p class="ql-align-justify">(7) {translations.artifacts.ContractModalBox.seventhRule}</p>
            <p class="ql-align-justify">(8) {translations.artifacts.ContractModalBox.eightRule} </p>
            <p class="ql-align-justify">(9) {translations.artifacts.ContractModalBox.ninethRule}</p>
            <p><br /></p>
            <p><br /></p>
            <h4 class="ql-align-center">{translations.artifacts.ContractModalBox.articleTwo}</h4>
            <p class="ql-align-center"><strong>{translations.artifacts.ContractModalBox.lawyersPrize}</strong></p>
            <p><br /></p>
            <p class="ql-align-justify">(10) {translations.artifacts.ContractModalBox.tenthRule} </p>
            <p class="ql-align-justify">(11) {translations.artifacts.ContractModalBox.eleventhRule} </p>
            <p class="ql-align-justify">(12) {translations.artifacts.ContractModalBox.eleventhRule}</p>
            <p class="ql-align-justify">(13) {translations.artifacts.ContractModalBox.thirtteenthRule}[<em>{translations.artifacts.ContractModalBox.definatedDown}</em>].</p>
            <p class="ql-align-center"><br /></p>
            <p class="ql-align-center"><br /></p>
            <h4 class="ql-align-center"><span style={{ color: "windowtext" }}>{translations.artifacts.ContractModalBox.articleThree}</span></h4>
            <p class="ql-align-center"><strong>{translations.artifacts.ContractModalBox.termsAndConditions}</strong> </p>
            <p><br /></p>
            <p class="ql-align-justify">(14){translations.artifacts.ContractModalBox.fourthteenthRule}</p>
            <p class="ql-align-justify">{translations.artifacts.ContractModalBox.fourthteenthRuleSecond}<strong>{translations.artifacts.ContractModalBox.VOP}</strong>“). </p>
            <p class="ql-align-justify">(15) {translations.artifacts.ContractModalBox.fifthteenthRule}</p>
            <p class="ql-align-justify">(16) {translations.artifacts.ContractModalBox.sixteenthRule} </p>
            <h4><br /></h4>
            <p><br /></p>
            <h4 class="ql-align-center"><span style={{ color: "windowtext" }}>{translations.artifacts.ContractModalBox.articleFour}</span></h4>
            <p class="ql-align-center"><strong>{translations.artifacts.ContractModalBox.commonProvisions}</strong></p>
            <p><br /></p>
            <p class="ql-align-justify">(17) {translations.artifacts.ContractModalBox.seventeenthRule} </p>
            <p class="ql-align-justify">(18) {translations.artifacts.ContractModalBox.eighteenthRule} </p>
            <p class="ql-align-justify">(19) {translations.artifacts.ContractModalBox.nineteenthRule}</p>
            <p class="ql-align-justify">(20) {translations.artifacts.ContractModalBox.twentiethRule} </p>
            <p class="ql-align-justify">(21) {translations.artifacts.ContractModalBox.twentyFirstRule}</p>
            <p class="ql-align-justify">(22) {translations.artifacts.ContractModalBox.twentySecondRule}</p>
            <p class="ql-align-justify">(23) {translations.artifacts.ContractModalBox.twentyThirdRule}</p>
            <p class="ql-align-justify">(24) {translations.artifacts.ContractModalBox.twentyFourRule}</p>
            <p class="ql-align-justify">(25) {translations.artifacts.ContractModalBox.twentyFiveRule}</p>
            <p><br /></p>
            <p><br /></p>
            <h4 class="ql-align-center">{translations.artifacts.ContractModalBox.articleFive}</h4>
            <p class="ql-align-center"><strong>{translations.artifacts.ContractModalBox.finalProvisions}</strong></p>
            <p><br /></p>
            <p class="ql-align-justify">(26) {translations.artifacts.ContractModalBox.twentySixRule}</p>
            <p class="ql-align-justify">(27) {translations.artifacts.ContractModalBox.twentySevenRule}</p>
            <p class="ql-align-justify">(28) {translations.artifacts.ContractModalBox.twentyEightRule} </p>
            <p class="ql-align-justify">(29) {translations.artifacts.ContractModalBox.twentyNineRule}</p>
            <p class="ql-align-justify">(30) {translations.artifacts.ContractModalBox.thirtyRule} </p>
            <p class="ql-align-justify"><br /></p>
          </div> */}
        </article>
      </Modal>
    </>
  );
}
