export const purposeOptions = [
    { key: '1', value: 'obecní úřad', text: 'obecní úřad' },
    { key: '2', value: 'školská instituce', text: 'školská instituce' },
    { key: '3', value: 'bydlení', text: 'bydlení' },
    { key: '4', value: 'služby (pohostinství, obchody)', text: 'služby (pohostinství, obchody)' },
    { key: '5', value: 'technická infrastruktura (hasiči, zázemí)', text: 'technická infrastruktura (hasiči, zázemí)' },
    { key: '6', value: 'energetika', text: 'energetika' },
    { key: '7', value: 'skladování', text: 'skladování' },
    { key: '8', value: 'obchod', text: 'obchod' },
    { key: '9', value: 'církev', text: 'církev' },
    { key: '10', value: 'zdravotnictví', text: 'zdravotnictví' },
    { key: '11', value: 'jiné', text: 'jiné' },
];

export const constructionYearOptions = [
    { key: '1', value: '19. století a starší', text: '19. století a starší' },
    { key: '2', value: '1900 - 1945', text: '1900 - 1945' },
    { key: '3', value: '1945 - 1969', text: '1945 - 1969' },
    { key: '4', value: '1970 - 1989', text: '1970 - 1989' },
    { key: '5', value: '1990 - 1999', text: '1990 - 1999' },
    { key: '6', value: '2000 - 2009', text: '2000 - 2009' },
    { key: '7', value: '2010 - 2019', text: '2010 - 2019' },
    { key: '8', value: '2020 a novější', text: '2020 a novější' },
];

export const usersCountOptions = [
    { key: '1', value: 'Jednotky', text: 'Jednotky' },
    { key: '2', value: 'Desítky', text: 'Desítky' },
    { key: '3', value: 'Stovky', text: 'Stovky' },
];

export const buildingLoadOptions = [
    { key: '1', value: '1', text: '1' },
    { key: '2', value: '2', text: '2' },
    { key: '3', value: '3', text: '3' },
    { key: '4', value: '4', text: '4' },
    { key: '5', value: '5', text: '5' },
    { key: '6', value: '6', text: '6' },
    { key: '7', value: '7', text: '7' },
];

export const constructionOptions = [
    { key: '1', value: 'zdivo cihlové', text: 'zdivo cihlové' },
    { key: '2', value: 'zdivo kamenné', text: 'zdivo kamenné' },
    { key: '3', value: 'panel betonový', text: 'panel betonový' },
    { key: '4', value: 'masivní dřevo', text: 'masivní dřevo' },
    { key: '5', value: 'lepené dřevo', text: 'lepené dřevo' },
    { key: '6', value: 'dřevěné panely', text: 'dřevěné panely' },
    { key: '7', value: 'monolitický beton', text: 'monolitický beton' },
    { key: '8', value: 'ocel', text: 'ocel' },
    { key: '9', value: 'skleněné fasády', text: 'skleněné fasády' },
];

export const roofInsulationOptions = [
    { key: '1', value: 'Ano', text: 'Ano' },
    { key: '2', value: 'Ne', text: 'Ne' },
];

export const facadeInsulationOptions = [
    { key: '1', value: 'ano', text: 'ano' },
    { key: '2', value: 'ne', text: 'ne' },
];

export const windowTypeOptions = [
    { key: '1', value: 'plast', text: 'plast' },
    { key: '2', value: 'dřevo', text: 'dřevo' },
];

export const glazingOptions = [
    { key: '1', value: '< 20', text: '< 20' },
    { key: '2', value: '20-40', text: '20-40' },
    { key: '3', value: '40-60', text: '40-60' },
    { key: '4', value: '>60', text: '>60' },
];

export const insulationTypeOptions1 = [
    { key: '1', value: 'bílý polystyren', text: 'bílý polystyren' },
    { key: '2', value: 'minerální vata', text: 'minerální vata' },
    { key: '3', value: 'foukaná izolace', text: 'foukaná izolace' },
    { key: '4', value: 'žádné', text: 'žádné' },
];

export const insulationTypeOptions2 = [
    { key: '1', value: 'bílý pěnový polystyren', text: 'bílý pěnový polystyren' },
    { key: '2', value: 'šedý grafitový polystyren', text: 'šedý grafitový polystyren' },
    { key: '3', value: 'extrudovaný polystyren', text: 'extrudovaný polystyren' },
    { key: '4', value: 'žádné', text: 'žádné' },
];

export const glassLayersOptions = [
    { key: '1', value: 'trojsklo', text: 'trojsklo' },
    { key: '2', value: 'dvojsklo', text: 'dvojsklo' },
    { key: '3', value: 'jednoduché', text: 'jednoduché' },
];

export const energyClassOptions = [
    { key: '1', value: 'A', text: 'A' },
    { key: '2', value: 'B', text: 'B' },
    { key: '3', value: 'C', text: 'C' },
    { key: '4', value: 'D', text: 'D' },
    { key: '5', value: 'E', text: 'E' },
    { key: '6', value: 'F', text: 'F' },
    { key: '7', value: 'G', text: 'G' },
];

export const heatingSourceOptions = [
    { key: '1', value: 'zemní plyn', text: 'zemní plyn' },
    { key: '2', value: 'elektrokotel', text: 'elektrokotel' },
    { key: '3', value: 'uhlí', text: 'uhlí' },
    { key: '4', value: 'uhlí se zásobníkem', text: 'uhlí se zásobníkem' },
    { key: '5', value: 'dřevo / krb', text: 'dřevo / krb' },
    { key: '6', value: 'přímotopy', text: 'přímotopy' },
    { key: '7', value: 'tepelné čerpadlo', text: 'tepelné čerpadlo' },
    { key: '8', value: 'biopaliva', text: 'biopaliva' },
    { key: '9', value: 'centrální vytápění', text: 'centrální vytápění' },
    { key: '10', value: 'jiné', text: 'jiné' },
];

export const waterHeatingOptions = [
    { key: '1', value: 'elektrický bojler', text: 'elektrický bojler' },
    { key: '2', value: 'plynový bojler', text: 'plynový bojler' },
    { key: '3', value: 'elektrický průtokový ohřívač', text: 'elektrický průtokový ohřívač' },
    { key: '4', value: 'plynový průtokový ohřívač (karma)', text: 'plynový průtokový ohřívač (karma)' },
    { key: '5', value: 'tepelné čerpadlo', text: 'tepelné čerpadlo' },
    { key: '6', value: 'solární kolektory', text: 'solární kolektory' },
    { key: '7', value: 'dálkový ohřev vody', text: 'dálkový ohřev vody' },
    { key: '8', value: 'jiné', text: 'jiné' },
];

export const lightingSourceOptions = [
    { key: '1', value: 'klasické wolframové žárovky', text: 'klasické wolframové žárovky' },
    { key: '2', value: 'halogenové žárovky', text: 'halogenové žárovky' },
    { key: '3', value: 'kompaktní zářivky (CFL)', text: 'kompaktní zářivky (CFL)' },
    { key: '4', value: 'trubicové zářivky', text: 'trubicové zářivky' },
    { key: '5', value: 'LED žárovky', text: 'LED žárovky' },
    { key: '6', value: 'LED trubicové svítidla', text: 'LED trubicové svítidla' },
    { key: '7', value: 'sodíkové výbojky', text: 'sodíkové výbojky' },
    { key: '8', value: 'jiné výbojky', text: 'jiné výbojky' },
    { key: '9', value: 'neonové', text: 'neonové' },
    { key: '10', value: 'jiné', text: 'jiné' },
];

export const applianceOptions = [
    { key: '1', value: 'Lednice', text: 'Lednice' },
    { key: '2', value: 'Pračka', text: 'Pračka' },
    { key: '3', value: 'Trouba', text: 'Trouba' },
    { key: '4', value: 'Mrazák', text: 'Mrazák' },
    { key: '5', value: 'Konvektomat', text: 'Konvektomat' },
    { key: '6', value: 'Počítač', text: 'Počítač' },
    { key: '7', value: 'Konvice', text: 'Konvice' },
    { key: '8', value: 'Vařič', text: 'Vařič' },
    { key: '9', value: 'Sušička', text: 'Sušička' },
    { key: '10', value: 'Chlazení', text: 'Chlazení' },
    { key: '11', value: 'Myčka', text: 'Myčka' },
];
