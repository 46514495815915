
import { Button } from 'antd';
import { useRef, useState, useEffect } from 'react';
import { ImageOverlay, Map, Polygon, TileLayer } from 'react-leaflet';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import LegendCustom from '../LegendCustom';
import { captureMapImage, getImageUrl, getMapBounds, getRestepImagePotencialKonopeTechnickeUrl, setupLeafletDefaults } from '../../../../utils/MapaImageUtils';

setupLeafletDefaults();

const geoUrl = 'https://restep.vumop.cz/cgi-bin';

const legendData = {
    "title": "Mapa Potenciál konope technické",
    "layers": [
        {
            "label": "do 9.00 t/ha",
            "color": "#FCE4D6"
        },
        {
            "label": "9.00 - 9.50 t/ha",
            "color": "#F8CBAD"
        },
        {
            "label": "9.50 - 10.25 t/ha",
            "color": "#E97C43"
        },
        {
            "label": "nad 10.25 t/ha",
            "color": "#9C3F18"
        },
        {
            "label": "data nejsou k dispozici",
            "color": "#999999"
        }
    ]
};
const MapaPotencialKonopeTechnickeMutator = ({ name }) => {
    const mapUniqueId = "mapaPotencialKonopeTechnicke" + name ? name : "";

    const values = useFormDataContext(({ data, setField }) => ({
        value: data[name] || { position: [49.94297310, 17.03212700], zoom: 13 },
        municipality: data.municipalities[0],
        setField
    }));
    const [fieldValues, setFieldValues] = useState(values);
    const [isActiveMap, setIsActiveMap] = useState(false);
    const [opacity, setOpacity] = useState(0.75);
    const mapRef = useRef();
    const containerRef = useRef();

    const handleChange = () => {
        values.setField({
            target: {
                name,
                value: fieldValues.value
            },
        });
    };

    const onMoveEnd = () => {
        const map = mapRef.current.leafletElement;
        const newCenter = map.getCenter();
        let tmpFieldValues = { ...fieldValues };
        tmpFieldValues.value.position = [newCenter.lat, newCenter.lng];
        tmpFieldValues.value.zoom = map.getZoom();

        captureMapImage(containerRef, setFieldValues, fieldValues, values, name, false);
    };

    useEffect(() => {
        const mapInstance = mapRef.current.leafletElement;
        if (mapInstance && !isActiveMap) {
            mapInstance.dragging.disable();
            mapInstance.touchZoom.disable();
            mapInstance.doubleClickZoom.disable();
            mapInstance.scrollWheelZoom.disable();
            mapInstance.boxZoom.disable();
            mapInstance.keyboard.disable();
        }
    }, [isActiveMap]);

    const enableMapInteractions = () => {
        const mapInstance = mapRef.current.leafletElement;
        if (!isActiveMap && mapInstance) {
            setIsActiveMap(true);
            mapInstance.dragging.enable();
            mapInstance.touchZoom.enable();
            mapInstance.doubleClickZoom.enable();
            mapInstance.scrollWheelZoom.enable();
            mapInstance.boxZoom.enable();
            mapInstance.keyboard.enable();
        }
    };

    const disableMapInteractions = (event) => {
        const mapElement = document.getElementById(mapUniqueId);
        if (!mapElement.contains(event.target)) {
            const mapInstance = mapRef.current.leafletElement;
            if (isActiveMap && mapInstance) {
                setIsActiveMap(false);
                mapInstance.dragging.disable();
                mapInstance.touchZoom.disable();
                mapInstance.doubleClickZoom.disable();
                mapInstance.scrollWheelZoom.disable();
                mapInstance.boxZoom.disable();
                mapInstance.keyboard.disable();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', disableMapInteractions);

        return () => {
            document.removeEventListener('click', disableMapInteractions);
        };
    }, [isActiveMap]);

    const handleOpacityChange = (event) => {
        setOpacity(event.target.value);
    };

    return (
        <div className='open-street-map'>
            <Button className='addButton' onClick={() => captureMapImage(containerRef, setFieldValues, fieldValues, values, name, true)}>Vložiť do náhľadu</Button>
            <div ref={containerRef} className={isActiveMap ? 'open-street-map is-active-map' : 'open-street-map'}>
                <Map
                    onblur={handleChange}
                    center={values?.municipality?.geoCenter}
                    zoom={fieldValues.value.zoom}
                    ref={mapRef}
                    onMoveEnd={onMoveEnd}
                    onZoomEnd={onMoveEnd}
                    id={mapUniqueId}
                    onClick={enableMapInteractions}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png" />

                    <Polygon
                        key={"524862865862466984"}
                        positions={values?.municipality?.geoJson}
                        pathOptions={{
                            color: '#6666FF',
                            fill: true,
                            fillOpacity: .3,
                            opacity: 0.8,
                            weight: 2
                        }} />

                    <ImageOverlay
                        url={getRestepImagePotencialKonopeTechnickeUrl(geoUrl, getMapBounds(mapRef))}
                        bounds={mapRef.current ? mapRef.current.leafletElement.getBounds() : [[0, 0], [0, 0]]}
                        opacity={opacity}
                    />
                </Map>
                <LegendCustom opacity={opacity} legendData={legendData} />
            </div>
            <div className='opacity-slider-map'>
                <label>Viditeľnosť vrstiev</label>
                <input
                    type="range"
                    id="opacity"
                    name="opacity"
                    min="0"
                    max="1"
                    step="0.02"
                    value={opacity}
                    onChange={handleOpacityChange}
                />
            </div>
            {geoUrl &&
                <span><strong>Zdroj:</strong> RESTEP-vlastní zpracování</span>
            }
        </div>
    );
};

function MapaPotencialKonopeTechnickeRenderer(values, key) {
    let returnValue = ""
    if (values[key]) {
        returnValue += `<img src="${values[key].image}" alt="Map view" style="height: 100%; width: 100%; object-fit: cover; margin-top: 3.5rem; border-radius: 4px;" />`;
    }
    return returnValue;
}

function MapaPotencialKonopeTechnickeValidator() {
    return true;
}

export default {
    Mutator: MapaPotencialKonopeTechnickeMutator,
    HTMLRenderer: MapaPotencialKonopeTechnickeRenderer,
    Validator: MapaPotencialKonopeTechnickeValidator
};
