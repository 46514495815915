import App from './App';
import React, { useEffect, useRef, useState } from 'react';
import { Amplify, Hub, Auth } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import axios from 'axios'
import {  ClearUserLoginJWTToken, GetUserProfileDataAction, SaveUserLoginCookiesAction, SetRecentlyConfirmed, SetUser, StoreAction } from './app/ActionsImpl'
import { Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';


import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


import DocumentDownloadContainer from './features/document-download/DocumentDownload.container';
import ErrorPage from './features/frontoffice/ErrorPage';

import FrontOfficePage from './features/frontoffice/FrontOfficePage';
import { connect, useDispatch } from 'react-redux';
import store from './app/store';
import { AppState } from './app/AppState';
import TagManager from 'react-gtm-module';
import { COOKIE_STORAGE, GOOGLE_TAG_MANAGER_ID, PROD_ENV, __DEV__, capiURI } from './constants';
import MaintenancePage from './features/frontoffice/MaintenancePage';
import { languages, translations } from './utils/LocalizedStrings';



console.log('%c STOP! ', 'background: #222; color: red; font-size: 30px');
console.log('%c Toto je funkcia prehliadača určená pre vývojárov. Ak vám niekto povedal, aby ste sem niečo vložili a aktivovali tak nejakú funkciu ORSR.Help-u alebo prelomili zabezpečenie , ide o podvod, vďaka ktorému môže dotyčný získať prístup k vašim informáciám. ', 'background: #222; color: white;');

const cookieStorage = {
    domain: __DEV__ ? window.document.location.hostname : COOKIE_STORAGE,
    secure: !__DEV__,
    path: '/',
    expires: 365,
};

const otherSites: { [Key: string]: string } = {
    // ".amplifyapp.com": 'https://main.dlvz2t3fd3adw.amplifyapp.com/resolve-login'
    // "staging": 'https://staging.app.orsr.help/resolve-login',
    // "develop": 'https://develop.app.orsr.help/resolve-login'
}

Amplify.configure({
    Auth: {
        identityPoolId: 'eu-central-1:5301b368-ce5d-427e-aa96-78da4caa2fc6', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'eu-central-1',
        userPoolId: 'eu-central-1_zhAXmwN9u', //OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: '7987penimnvg1thujnsiubc7hf', //OPTIONAL - Amazon Cognito Web Client ID
    },
    cookieStorage
});


const tagManagerArgs = {
    gtmId: GOOGLE_TAG_MANAGER_ID!
};

TagManager.initialize(tagManagerArgs)

axios.interceptors.request.use(
    config => {
        if (store.getState().appState.cognitoUser) {
            return Auth.currentSession().then(session => {
                const jwtToken = session.getAccessToken().getJwtToken()
                config.headers.Authorization = jwtToken
                return config
            })
        } else {
            return config
        }
    },
    error => Promise.reject(error)
);

Amplify.register(Auth)

const getCookiesStartingWith = (prefix: any) => {
    const cookies = document.cookie.split('; ');
    const result: any = {};

    for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name.startsWith(prefix)) {
            result[name] = value;
        }
    }

    return result;
};

export interface Props extends StateProps, DispatchProps { }

const AuthStateApp = (props: Props) => {

    const [logout, setLogout] = useState(false)
    const [login, setLogin] = useState(false)
    const [openedSites, setOpenedSites] = useState<{ [Key: string]: string }>({})
    const [finishedOrigins, setFinishedOrigins] = useState<any[]>([])
    const iframeRef = useRef<(HTMLDivElement | null)[]>([]);
    const [cookiesToSave, setCookiesToSave] = useState<any>()

    React.useEffect(() => {
        translations.setLanguage(props.language)
    }, [props.language])

    React.useEffect(() => {
        props.SetUser()
        const listener = async (data: any) => {
            switch (data.payload.event) {
                case "signIn": {
                    await props.SetUser()
                    const cookieName = 'CognitoIdentityServiceProvider';
                    const cookieValue = getCookiesStartingWith(cookieName);
                    let domain = __DEV__ ? window.document.location.hostname : COOKIE_STORAGE
                    for (const [name, value] of Object.entries(cookieValue)) {
                        if (name.startsWith('CognitoIdentityServiceProvider')) {
                            document.cookie = `${name}=${value}; domain=${domain}; SameSite=None; path=/; Secure`;
                        }
                    }
                    setOpenedSites(otherSites)
                    setCookiesToSave(cookieValue)
                    break
                }
                case "signOut": {
                    props.SetUser()
                    setOpenedSites(otherSites)
                    setLogout(true)
                    break
                }
            }
        }
        const removeHub = Hub.listen("auth", listener)
        return () => removeHub()
    }, []);

    useEffect(() => {
        if (logout || login) {
            const handleMessage = (event: any) => {
                if (event && event.data && event.data.action === 'LOGIN_FINISHED') {
                    finishedOrigins.push(event.data.origin)
                    let newFinishedOrigins = JSON.parse(JSON.stringify(finishedOrigins))
                    setFinishedOrigins(newFinishedOrigins)
                    if (finishedOrigins.length > 0 && (finishedOrigins.length === Object.keys(openedSites).length)) {
                        window.removeEventListener('message', handleMessage);
                    }
                }
            };
            window.addEventListener('message', handleMessage);
        }

    }, [login, logout]);

    useEffect(() => {
        if (finishedOrigins.length > 0 && (finishedOrigins.length === Object.keys(openedSites).length)) {
            setOpenedSites({})
            setLogout(false)
            setLogin(false)
            setCookiesToSave(undefined)
            iframeRef.current = []
            setFinishedOrigins([])
        }

    }, [finishedOrigins]);

    function waitForGTM(callback: any) {
        const maxAttempts = 10;
        let attempts = 0;

        function checkGTM() {
            attempts++;
            let dataLayer = (window as any).dataLayer
            if (dataLayer && typeof dataLayer.push === 'function') {
                callback();
            } else if (attempts < maxAttempts) {
                setTimeout(checkGTM, 500); // check every half second
            }
        }

        checkGTM();
    }

    useEffect(() => {
        waitForGTM(sendPageViewEvent)
    }, [])

    const sendPageViewEvent = async () => {
        let user: any
        try {
            user = await Auth.currentAuthenticatedUser()
        } catch (e) {
            console.log("No user signed")
        }
        let _fbp = ""
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
            const [name, value] = cookie.split('=');
            if (name === "_fbp") {
                _fbp = value;
            }
        }
        let dataLayer = {}
        let capiPageViewId = uuidv4()
        let email = ''
        let phone_number = ''
        if (user) {
            email = user?.attributes?.email
            phone_number = user?.attributes?.phone_number
        }
        dataLayer = {
            event: "PZ_PAGE_VIEW",
            capiPageViewId,
            userData: {
                email,
                phone_number
            },
            _fbp
        }
        TagManager.dataLayer({
            dataLayer: dataLayer
        });
        if (PROD_ENV) {
            axios.post(capiURI + "/event/push", { ...dataLayer, eventName: "PageView", eventId: capiPageViewId })
        }
    };

    useEffect(() => {
        if (login) {
            iframeRef.current.forEach((ref, index) => {
                const iframe = ref as any;
                if (iframe && cookiesToSave) {
                    iframe.addEventListener("load", function () {
                        iframe.contentWindow.postMessage({ cookies: JSON.stringify(cookiesToSave), origin: window.location.hostname, action: "resolve-login" }, iframe.src);
                    });
                }
            })
        }

    }, [login]);


    useEffect(() => {
        if (cookiesToSave && Object.keys(cookiesToSave).length > 0) {
            setLogin(true)
        }
    }, [cookiesToSave])


    React.useEffect(() => {
        iframeRef.current.forEach((ref, index) => {
            const iframe = ref as any;
            if (iframe && logout) {
                iframe.addEventListener("load", function () {
                    iframe.contentWindow.postMessage({ action: "resolve-logout", origin: window.location.hostname }, iframe.src);
                });
            }
        })
    }, [logout]);

    React.useEffect(() => {
        if (props.recentlyConfirmed) {
            SetRecentlyConfirmed(false)
        }
        if (props.user) {
            props.GetUserProfileData()
        }
    }, [props.user])

    return <>
        <App />
        {(cookiesToSave || logout) &&
            Object.entries(openedSites).map(([key, site], index) => {
                return (
                    <iframe
                        key={key}
                        style={{ display: "none" }}
                        ref={(el) => (iframeRef.current[index] = el)}
                        src={site}
                        title="Login iframe"
                    ></iframe>
                )
            })
        }
    </>

}



const mapStateToProps = ({ appState }: any) => ({
    language: appState.language,
    recentlyConfirmed: appState.recentlyConfirmed,
    user: appState.cognitoUser
});

type StateProps = ReturnType<typeof mapStateToProps>;
export interface DispatchProps {
    SetUser: () => void;
    GetUserProfileData: () => void;
    SetRecentlyConfirmed: (confirmed: boolean) => void;
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
    return {
        SetUser: () => dispatch(SetUser()),
        GetUserProfileData: () => dispatch(GetUserProfileDataAction()),
        SetRecentlyConfirmed: (confirmed: boolean) => dispatch(SetRecentlyConfirmed(confirmed))
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(AuthStateApp)