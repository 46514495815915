import { DeleteOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, ConfigProvider, DatePicker, Popconfirm, Select, Collapse, Input as AInput } from 'antd';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import 'moment/locale/sk';
import React, { useEffect, useState } from 'react';
import { CardContent, Container, Modal } from 'semantic-ui-react';
import { Box } from '../../../components/Box';
import { Input } from '../../../components/Input';
import { DATE_TIME_FORMAT } from '../../../constants';
import { Codes, CodesText, MenaCode, ObchodnyRegisterCode, ObecCode, PravnaFormaCode, StatCode, TypInyIdentifikatorCode, TypVkladuCode } from '../pages/Interfaces';
import Navigation from './Navigation';
import UserRegistrationForm from '../../frontoffice/components/UserRegistrationForm';
import Text from 'antd/lib/typography/Text';
import TextArea from 'antd/lib/input/TextArea';
import { DocumentEditor } from '../../editor/components/document-editor/DocumentEditor';
import Header from '../../../components/Header';

type currentFileType = {
  name: string,
  content: any
};

const SettingsTemplate = ({
  settings,
  isLoading,
  phoneServiceContracts,
  saveSettings,
  saveCodes,
  crafts,
  getCrafts,
  setCrafts,
  lambdaUsage
}: {
  settings: any;
  isLoading: boolean;
  phoneServiceContracts: any;
  saveSettings: (settings: any) => void;
  saveCodes: (codeType: Codes, codes: any) => void
  getCrafts: () => void
  setCrafts: (crafts: any) => void
  crafts: any,
  lambdaUsage: any
}) => {

  const [dateOfMaintenance, setDateOfMaintenance] = useState(0)
  const [phoneServiceContractVersion, setPhoneServiceContractVersion] = useState("")
  const [partnerDiscount, setPartnerDiscount] = useState("")
  const [maintenance, setMaintenance] = useState(false)
  const [stornoFee, setStornoFee] = useState<any>()
  const [lambdaLimit, setLambdaLimit] = useState<any>()
  const [lambdaWarning, setLambdaWarning] = useState<any>()
  const [codeListCurrent, setCodeListCurrent] = useState(Codes.Stat);
  const [addCategoryModalVisible, setAddCategoryModalVisible] = useState(false);
  const [addCraftModalVisible, setAddCraftModalVisible] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [newCraftCode, setNewCraftCode] = useState("");
  const [typIndexValue, setTypIndexValue] = useState<any>();
  const [currentFile, setCurrentFile] = useState<currentFileType>({
    name: '',
    content: undefined
  })

  //const [file2Import, setFile2Import] = useState(new File);
  const [filterValue, setFilterValue] = useState('');
  const [zivnosti, setZivnosti] = useState<any[]>([]);
  const [filterValueOriginal, setFilterValueOriginal] = useState('');
  const categoryNames: any = {
    VOLNE: 'Voľné živnosti',
    REMESELNE: 'Remeselné živnosti',
    VIAZANE: 'Viazané živnosti',
  };
  const [newDescription, setNewDescription] = useState<any>()
  const [openedTypes, setOpenedTypes] = useState<any[]>();
  const [openedFreeCrafts, setOpenedFreeCrafts] = useState<any[]>();
  const { Panel } = Collapse;
  const [openedOtherCrafts, setOpenedOtherCrafts] = useState<any[]>([]);

  useEffect(() => {
    setZivnosti(crafts)
  }, [crafts])

  useEffect(() => {
    if (settings) {
      setDateOfMaintenance(settings.settings['maintenance-date'])
      setMaintenance(settings.settings.maintenance)
      setStornoFee(settings.settings.stornoFee)
      setLambdaLimit(settings.settings.lambdaLimit)
      setLambdaWarning(settings.settings.lambdaWarning)
      setPhoneServiceContractVersion(settings.settings.phoneServiceContractVersion)
      setPartnerDiscount(settings.settings.partnerDiscount)
    }
  }, [settings])

  const save = () => {
    let settings = {
      "maintenance-date": dateOfMaintenance,
      maintenance: maintenance,
      stornoFee: stornoFee,
      lambdaLimit: lambdaLimit,
      lambdaWarning: lambdaWarning,
      phoneServiceContractVersion: phoneServiceContractVersion,
      partnerDiscount: partnerDiscount
    }
    saveSettings(settings)
  }

  const doCalculateZivnosti = (typ: any) => {
    return Object.entries(typ).filter(([code, zivnost]: [any, any]) => {
      return (
        code !== '_id' &&
        code !== 'type' &&
        code !== 'ord' &&
        ((filterValue.length < 3 ||
          (zivnost.name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) !== -1)) ||
          (code
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) !== -1) ||
          ((code + " " + zivnost.name)
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) !== -1))
      );
    }).sort((a, b) => {
      return Number(a[0]) - Number(b[0])
    });
  };

  const doCalculateIneZivnosti = (typ: any) => {
    return Object.entries(typ).filter(([categoryName, category]: [any, any]) => {
      return (
        categoryName !== '_id' &&
        categoryName !== 'type' &&
        categoryName !== 'ord' &&
        (filterValue.length < 3 ||
          categoryName
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) !== -1 ||
          Object.entries(category).filter(([code, zivnost]: [any, any]) => {
            return (
              code
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .indexOf(filterValue.toLowerCase()) !== -1 ||
              zivnost.name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .indexOf(filterValue.toLowerCase()) !== -1
            );
          }).length > 0)
      );
    });
  };


  const handleFileChange = async (event: any) => {
    setCodeListCurrent(event.target.value);
    for (let oneFile of event.target.files) {
      let reader = new FileReader()
      reader.onload = async (loadEvent: any) => {


        await setCurrentFile({
          name: oneFile.name,
          content: reader.result
        });


        // processOneFile(oneFile, reader, saveCodes, codeListCurrent);
      }

      reader.readAsDataURL(oneFile)
    }
  }

  // const processOneFile = (oneFile: any, reader: FileReader, saveCodes: (codeType: Codes, codes: any) => void, code: Codes) => {
  const importCode = () => {

    if (currentFile && currentFile.content && (typeof currentFile.content === 'string' || currentFile.content instanceof String)) {
      const encodedString = currentFile.content.replace(/.*,/, '');

      let base64Decode = Buffer.from(encodedString, "base64").toString();

      let regexp;
      let matches;

      switch (codeListCurrent) {
        case Codes.Stat:
        case Codes.PravnaForma:
        case Codes.TypVkladu:
          regexp = /"([\d]*)",\s"([\w\s\\\\]*)"/gm;
          matches = base64Decode.matchAll(regexp);
          break;

        case Codes.TypInyIdentifikator:
        case Codes.Mena:
          regexp = /"([\d]*)","([\w\sáäčďéíľĺňóôŕšťúýžÁÄČĎÉÍĽĹŇÓÔŔŠŤÚÝŽ]*)","([\w\sáäčďéíľĺňóôŕšťúýžÁÄČĎÉÍĽĹŇÓÔŔŠŤÚÝŽ]*)"/g;
          matches = base64Decode.matchAll(regexp);
          break;

        case Codes.Obec:
          regexp = /"([\d]*)","([\s-\(\)A-Za-záäčďéíľĺňóôŕšťúýžÁÄČĎÉÍĽĹŇÓÔŔŠŤÚÝŽ]*)"/gm;
          matches = base64Decode.matchAll(regexp);
          break;

        case Codes.ObchodnyRegister:
          regexp = /"([\w\sáäčďéíľĺňóôŕšťúýžÁÄČĎÉÍĽĹŇÓÔŔŠŤÚÝŽ]*)","([\w\sáäčďéíľĺňóôŕšťúýžÁÄČĎÉÍĽĹŇÓÔŔŠŤÚÝŽ]*)",([\d]*),"([\w\sáäčďéíľĺňóôŕšťúýžÁÄČĎÉÍĽĹŇÓÔŔŠŤÚÝŽ]*)","([\w\sáäčďéíľĺňóôŕšťúýž]*)",([\d]*),"([\w\sáäčďéíľĺňóôŕšťúýžÁÄČĎÉÍĽĹŇÓÔŔŠŤÚÝŽ]*)"/g;
          matches = base64Decode.matchAll(regexp);
          break;

        default:
          console.log("Unknown code, implementation error.");
          break;
      }

      if (matches) {
        const parsedCodes = prepareStoreCodes(matches, saveCodes, codeListCurrent);
        saveCodes(codeListCurrent, parsedCodes);
      }

    }
  }

  const escapeRegex = (string: any) => {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  };

  const Highlighted = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }

    const highlightNorm = highlight
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    const textNorm = text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    const regex = new RegExp(`(${escapeRegex(highlightNorm)})`, 'gi');
    const parts = textNorm.split(regex);
    let indexNorm = 0;
    let result = '';
    let tmpArrayNorm = [];
    parts
      .filter((part) => part)
      .forEach((part) => {
        result += regex.test(part)
          ? '<mark>' + text.substring(indexNorm, indexNorm + part.length) + '</mark>'
          : text.substring(indexNorm, indexNorm + part.length);
        indexNorm += part.length;
      });

    return <span dangerouslySetInnerHTML={{ __html: result }}></span>;
  };

  function prepareStoreCodes(matches: IterableIterator<RegExpMatchArray>, saveCodes: (codeType: Codes, codes: any) => void, code: Codes) {
    let matchIter = matches.next();

    let parsedCodes: any[] = [];

    while (!matchIter.done) {
      let newParsedCode: any;

      switch (code) {
        // 2 fields to import
        case Codes.Stat:
          let statCode: StatCode = {
            codeId: parseInt(matchIter.value[1]),
            value: JSON.parse('{"body" : "' + matchIter.value[2] + '" }').body
          };
          newParsedCode = statCode;
          break;

        case Codes.PravnaForma:
          let pravnaFormaCode: PravnaFormaCode = {
            codeId: parseInt(matchIter.value[1]),
            value: JSON.parse('{"body" : "' + matchIter.value[2] + '" }').body
          };
          newParsedCode = pravnaFormaCode;
          break;

        case Codes.TypVkladu:
          let typVkladuCode: TypVkladuCode = {
            codeId: parseInt(matchIter.value[1]),
            value: JSON.parse('{"body" : "' + matchIter.value[2] + '" }').body
          };
          newParsedCode = typVkladuCode;
          break;

        // 3 fields to import
        case Codes.TypInyIdentifikator:
          let typInyIdentifikatorCode: TypInyIdentifikatorCode = {
            codeId: parseInt(matchIter.value[1]),
            value: JSON.parse('{"body" : "' + matchIter.value[2] + '" }').body,
            znacka: JSON.parse('{"body" : "' + matchIter.value[3] + '" }').body
          };
          newParsedCode = typInyIdentifikatorCode;
          break;
        case Codes.Mena:
          let menaCode: MenaCode = {
            codeId: parseInt(matchIter.value[1]),
            value: JSON.parse('{"body" : "' + matchIter.value[2] + '" }').body,
            znacka: JSON.parse('{"body" : "' + matchIter.value[3] + '" }').body
          };
          newParsedCode = menaCode;
          break;

        // other fields to import
        case Codes.Obec:
          let obecCode: ObecCode = {
            codeId: parseInt(matchIter.value[1]),
            value: JSON.parse('{"body" : "' + matchIter.value[2] + '" }').body,
            statId: 0,
            obce: []
          };
          newParsedCode = obecCode;
          break;

        case Codes.ObchodnyRegister:
          let obchodnyRegisterCode: ObchodnyRegisterCode = {
            okresnySud: JSON.parse('{"body" : "' + matchIter.value[1] + '" }').body,
            ulica: JSON.parse('{"body" : "' + matchIter.value[2] + '" }').body,
            cislo: JSON.parse('{"body" : "' + matchIter.value[3] + '" }').body,
            obec: JSON.parse('{"body" : "' + matchIter.value[4] + '" }').body,
            psc: JSON.parse('{"body" : "' + matchIter.value[5] + '" }').body,
            codeId: parseInt(matchIter.value[6]),
            kod: JSON.parse('{"body" : "' + matchIter.value[7] + '" }').body
          };
          newParsedCode = obchodnyRegisterCode;
          break;

        default:
          console.log("Unknown code, implementation error.")
          newParsedCode = undefined;
      }

      // TODO return error string, so user can know there is error
      if (newParsedCode) {
        parsedCodes.push(newParsedCode);
      }
      matchIter = matches.next();

    }

    return parsedCodes;

  }

  const saveCrafts = async () => {
    await setCrafts(zivnosti)
    await getCrafts()
  }

  return (
    <div className='backofficeSettingsTemplate'>
      <Modal
        onClose={() => setAddCategoryModalVisible(false)}
        onOpen={() => setAddCategoryModalVisible(true)}
        open={addCategoryModalVisible}
        className="userProfileIntroModal"
      >
        <Modal.Header>Pridať kategóriu</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <AInput style={{ boxSizing: "border-box" }} value={newCategoryName} onChange={(e) => {
              setNewCategoryName(e.target.value)
            }} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setAddCategoryModalVisible(false)}>
            Zavrieť
          </Button>
          <Button onClick={() => {
            let newZivnosti = JSON.parse(JSON.stringify(zivnosti))
            if (typIndexValue !== undefined) {
              newZivnosti[typIndexValue][newCategoryName] = {}
            }
            setNewCategoryName("")
            setTypIndexValue(undefined)
            setAddCategoryModalVisible(false)
            setZivnosti(newZivnosti)
          }}>
            Pridať
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => setAddCraftModalVisible(false)}
        onOpen={() => setAddCraftModalVisible(true)}
        open={addCraftModalVisible}
        className="userProfileIntroModal"
      >
        <Modal.Header>Zadajte kód živnosti</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <AInput title='Kód' style={{ boxSizing: "border-box" }} value={newCraftCode} onChange={(e) => {
              setNewCraftCode(e.target.value)
            }} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setAddCraftModalVisible(false)}>
            Zavrieť
          </Button>
          <Button onClick={() => {
            let newZivnosti = JSON.parse(JSON.stringify(zivnosti))
            if (typIndexValue !== undefined) {
              newZivnosti[typIndexValue][categoryName][newCraftCode] = {}
            }
            setNewCraftCode("")
            setCategoryName("")
            setTypIndexValue(undefined)
            setAddCraftModalVisible(false)
            setZivnosti(newZivnosti)
          }}>
            Pridať
          </Button>
        </Modal.Actions>
      </Modal>
      <div>
        <Navigation />
        <Card style={{ background: '#f0f0f0', padding: "4rem 15% 0 15%" }}>
          <h3
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: '1.375rem'
            }}>
            Nastavenia

          </h3>
        </Card>
        <Container style={{ padding: '2rem' }}>
        <Card className='backofficeSettingsCard'
            title={'Údržba'}
            extra={
              <>
                <Popconfirm
                  title="Chcete uložiť zmeny?"
                  onConfirm={save}
                  okText="Áno"
                  cancelText="Nie">
                  <Button className='addButton' type="primary">
                    Uložiť
                  </Button>
                </Popconfirm>
              </>
            }>
            <Box display="flex">
              <Box width="80%" margin="0 auto">
                <div className="flexGrid" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  <ConfigProvider locale={sk_SK}>
                    <DatePicker
                      showTime={{}}
                      format={DATE_TIME_FORMAT}
                      style={{ height: 42, width: '40%' }}
                      value={dateOfMaintenance ? moment(dateOfMaintenance!) : undefined}
                      placeholder="Dátum nadchádzajúcej údržby"
                      onChange={(date) => { setDateOfMaintenance(moment(date).valueOf()) }}
                    />
                  </ConfigProvider>
                  <Input
                    type='checkbox'
                    label="Prebieha údržba?"
                    style={{ height: 35, width: 30 }}
                    name="maintenance"
                    checked={maintenance}
                    onChange={(e) => { setMaintenance(e.target.checked) }}
                  />
                </div>
              </Box>
            </Box>
          </Card>
          <Card className='backofficeSettingsCard'
            title={'Lambda'}>
            <Box display="flex">
              <Box width="80%" margin="0 auto">
                <div className="flexGrid" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  <Input
                    label="Limit lambdy"
                    name="lambdaLimit"
                    value={lambdaLimit}
                    onChange={(e) => { setLambdaLimit(e.target.value) }}
                  />
                </div>
                <div className="flexGrid" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  <Input
                    label="Varovanie lambdy"
                    name="lambdaWarning"
                    value={lambdaWarning}
                    onChange={(e) => { setLambdaWarning(e.target.value) }}
                  />
                </div>
                <div className="flexGrid" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  Dnešné zaťaženie lambdy: {lambdaUsage}
                </div>
              </Box>
            </Box>
          </Card>
        </Container>
      </div>

    </div>
  );
};

export default SettingsTemplate;
