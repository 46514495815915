import { StyledDocumentItem } from '../../document-download/DocumentDownload.styled';
import axios from 'axios';
import { DocumentItem } from './DocumentsList.atoms';
import {
  DeleteFileDocumentAction,
  StoreAction,
  UpdateNoteAction,
  UploadNewDocumentAction,
  UpdateOrderStatusAction,
  GenerateRefundAction,
  GenerateORSRFileAction,
  GetFlowAction,
  UploadSignedFileAdminAction,
  GetSettingsAction,
  DeleteSignedFileAdminAction,
} from '../../../app/ActionsImpl';

import store from '../../../app/store';
import OrderItemUploadIconsComponent from './OrderItemUploadIconsComponent';
import { ADMIN_API_HOST, COMPANY_LOOKUP_HOST } from '../../../constants';
import { saveAs } from 'file-saver';
import { Button, Card, Form, Col, Row, Checkbox, Spin } from 'antd';
import { CloseOutlined, PlusCircleOutlined, SaveOutlined, EditOutlined, ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Input, Tooltip } from 'antd';
import { Label, Segment } from 'semantic-ui-react';
import { Filter, FilterType, OrderState, UploadsStatus, PaidStatus, ORSRFileTypes } from '../pages/Interfaces';
import React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { AppState } from '../../../app/AppState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { OrderStatusModal } from '../../frontoffice/components/OrderStatusModal';
import { FileToUpload } from '../../../app/RequestInterfaces';
import { useHistory } from "react-router-dom";
import { order } from 'styled-system';
import Modal from 'antd/lib/modal/Modal';
import RefundOverview from './RefundOverview';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';
import { getFlow } from '../../../api/admin.api';
import DitecSigner from '../../../utils/DitecSigner';
import JSZip from 'jszip';

interface DocumentItem {
  name: any;
  link: any;
  uploads?: any;
  uploadsStatus: UploadsStatus;
}

export interface Props extends StateProps, DispatchProps {
  data: { id: string } & any;
  token: any;
  refresh: () => void;
}

function OrderItem({
  user,
  data,
  fileUpload,
  fileDelete,
  token,
  refresh,
  saveNote,
  saveOrderStatus,
  generateORSRFile,
  generateRefund,
  uploadSignedFile,
  deleteSignedFile,
  settings,
  getSettings
}: Props) {
  const [showDetail, setShowDetail] = useState(false);
  const [activeTab, setActiveTab] = useState('contact');
  const [showOrderStatus, setShowOrderStatus] = useState(false);
  const [note, setNote] = React.useState('');
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [formState, setFormState] = useState<any>({});
  const [zivnosti, setZivnosti] = useState<any>([]);
  const [modalVisible, setModalVisible] = useState<any>(false);
  const [refundForm, setRefundForm] = useState<any>({ requireStornoFee: true, zivnosti: {}, amount: 0 });
  const [loading, setLoading] = useState<any>(false);
  const [isCreate, setIsCreate] = useState(false)
  const [isChange, setIsChange] = useState(false)

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  // check also in OrderList.item.tsx
  // check also in UserSignatureRequestsList.page.tsx
  // check also in UserProfileTemplate.js::UserProfile
  const [allSigned, setAllSigned] = useState(false)

  const history = useHistory();

  const toogleNav = (index: string) => {
    setActiveTab(index)
  };

  useEffect(() => {
    getSettings()
  }, [])

  useEffect(() => {
    setFormState(JSON.parse(data.formState))
    getFlow(data.flow_id, onFlow)
  }, [data])

  const onFlow = (flow: any) => {
    if (flow.document.metadata.title.indexOf('Založenie') > -1) {
      setIsCreate(true)
    }
    if (flow.document.metadata.title.indexOf('Zmeny') > -1) {
      setIsChange(true)
    }

  }

  useEffect(() => {
    setNote(data.note);
  }, [data]);

  useEffect(() => {
    if (formState) {
      let newZivnosti: any = [];
      let parsedValues = formState;
      // setZivnosti(data.formData.zivnosti);
    }
  }, [formState])

  const handleTriggerDownload = async (documentItem: any, id: any, extension: any) => {
    const filename = documentItem.name;
    await axios
      .get(
        `${COMPANY_LOOKUP_HOST}/order/download-file-admin/${id}/${documentItem.id}`,
        {
          responseType: 'blob',
        },
      ).then((res) => {
        saveAs(res.data, filename + extension);
      });
  };

  const isUploadsActive = () =>
    user.signInUserSession.accessToken.payload['cognito:groups']
      .indexOf('admin',) !== -1;

  const handleFileDelete = async (orderId: string, documentIndex: number) => {
    setDeleteButtonDisabled(true);
    await fileDelete(orderId, documentIndex.toString())
    await refresh()
    setDeleteButtonDisabled(false)
  }

  const handleTriggerRefundDownload = async (documentItem: any, id: any) => {
    const filename = documentItem.name;
    await axios
      .get(
        `${COMPANY_LOOKUP_HOST}/order/download-refund-admin/${data._id}/${id}/${documentItem.id}`,
        {
          responseType: 'blob',
        },
      ).then((res) => {
        saveAs(res.data, filename);
      });
  };
  const handleFileChange = async (event: any) => {
    let files2Upload: any[] = [];
    for (let oneFile of event.target.files) {
      let reader = new FileReader()
      reader.onload = async (loadEvent: any) => {
        let tmp1 = {
          name: oneFile.name,
          content: reader.result
        }
        files2Upload.push(tmp1)
        if (files2Upload.length === event.target.files.length) {
          await fileUpload(data._id, files2Upload);
          await refresh();
          event.target.value = ''
        }
      }

      await reader.readAsDataURL(oneFile)
    }
  }

  const textarea = Array.from(document.querySelectorAll('textarea'));
  if (textarea) {
    textarea.map((area) => {
      area.addEventListener('focus', (e: any) => {
        area.style.height = '65px';
        let scHeight = e.target.scrollHeight;
        area.style.height = `${scHeight}px`;
      });
      area.addEventListener('blur', (e: any) => {
        area.style.height = '63px';
      });
    });
  }

  const handleStatusModalClose = async () => {
    setShowOrderStatus(false);
    await refresh();
  }

  const generateORSRFileComponent = async (orderId: any, fileType: ORSRFileTypes) => {
    generateORSRFile(orderId, fileType);
  }

  const downloadORSRFile = async (orderId: any, fileType: ORSRFileTypes) => {
    await axios
      .get(
        `${COMPANY_LOOKUP_HOST}/order/download-file-orsr/${fileType}/${orderId}`,
        {
          responseType: 'blob',
        },
      ).then((res) => {
        saveAs(res.data, fileType + '-generovane.xml');
      });
  }


  const setRefund = (e: any) => {
    let newRefundForm = refundForm
    if (e.target.checked) {
      newRefundForm.amount += e.target.name === "representationPrice" ? (data.formData[e.target.name] * 1.2) : data.formData[e.target.name]
      newRefundForm[e.target.name] = data.formData[e.target.name]
    } else {
      newRefundForm.amount -= e.target.name === "representationPrice" ? (data.formData[e.target.name] * 1.2) : data.formData[e.target.name]
      delete newRefundForm[e.target.name]
    }
    setRefundForm(newRefundForm)
    forceUpdate()
  }

  const setRefundCrafts = (index: any, craft: any) => {
    let newRefundForm = refundForm
    if (newRefundForm.zivnosti[index]) {
      newRefundForm.amount -= craft.price
      delete newRefundForm.zivnosti[index]
    } else {
      newRefundForm.amount += craft.price
      newRefundForm.zivnosti[index] = craft
    }
    setRefundForm(newRefundForm)
    forceUpdate()
  }

  const callbackFunction = async (response: any) => {
    console.log("callback", response)
    await uploadSignedFile(response.orderId, response.fileId, response)
    await refresh()
    // let zip = new JSZip();
    // await zip.loadAsync(response.signedContainer, { base64: true });
    // let blob = await zip.generateAsync({ type: "blob" });

    // let element = document.createElement("a");
    // element.setAttribute("href", window.URL.createObjectURL(blob));
    // element.setAttribute("download", "file.asice");
    // element.style.display = "none";
    // document.body.appendChild(element);
    // element.click();
    // document.body.removeChild(element);
  }

  const errorFunction = (error: any) => {
    console.log("callback", error)
  }

  const signFile = async (documentItem: any, id: any) => {
    const filename = documentItem.name;
    if (documentItem.signedFile && documentItem.signedFile.key) {
      axios
        .get(
          `${COMPANY_LOOKUP_HOST}/order/download-file-admin/${id}/${documentItem.signedFile.id}`,
          {
            responseType: 'arraybuffer',
          },
        )
        .then((res) => {
          let base64data = Buffer.from(res.data, 'binary').toString('base64');
          console.log(base64data);
          let dsigner = new DitecSigner(callbackFunction, errorFunction);
          dsigner.asicAddSignature(base64data, filename, id, documentItem.id);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .get(
          `${COMPANY_LOOKUP_HOST}/order/download-file-admin/${id}/${documentItem.id}`,
          {
            responseType: 'arraybuffer',
          },
        )
        .then((res) => {
          let base64data = Buffer.from(res.data, 'binary').toString('base64');
          console.log(base64data);
          let dsigner = new DitecSigner(callbackFunction, errorFunction);
          dsigner.signFile(base64data, filename, id, documentItem.id);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const checkSignatures = (documentItem: any) => {
    console.log(documentItem)
    if (documentItem.requiredKepSignatures) {
      if (!documentItem.signedFile || !documentItem.signedFile.requiredSignatures) {
        return false
      }
      for (let requiredSignature of documentItem.requiredKepSignatures) {
        if (documentItem.signedFile.requiredSignatures.indexOf(requiredSignature) === -1) {
          return false
        }
      }
    }
    if (documentItem.requiredSignatures) {
      if (!documentItem.imageSignatures && (!documentItem.signedFile || !documentItem.signedFile.requiredSignatures)) {
        return false
      }
      for (let signature of documentItem.requiredSignatures) {
        if (documentItem.imageSignatures?.indexOf(signature) === -1) {
          if (!documentItem.signedFile || !documentItem.signedFile.requiredSignatures) {
            return false
          }
          if (documentItem.signedFile.requiredSignatures.indexOf(signature) === -1) {
            return false
          }
        }
      }
    }
    if (documentItem.requiredSignatureImages) {
      if (!documentItem.imageSignatures) {
        return false
      }
      for (let signature of documentItem.requiredSignatureImages) {
        if (documentItem.imageSignatures?.indexOf(signature) === -1) {
          return false
        }
      }
    }
    return true
  }


  // check also in OrderList.item.tsx
  // check also in UserSignatureRequestsList.page.tsx
  // check also in UserProfileTemplate.js::UserProfile
  const alreadySigned = (documentItem: any): boolean => {
    let image: boolean | undefined = undefined
    let kep: boolean | undefined = undefined

    let allSignatures: string[] = []
    if (documentItem.imageSignatures) {
      allSignatures = allSignatures.concat(documentItem.imageSignatures)
    }

    if (documentItem.signedFile?.requiredSignatures) {
      allSignatures = allSignatures.concat(documentItem.signedFile?.requiredSignatures)
    }

    if (documentItem.requiredSignatures) {
      const result = JSON.stringify([...documentItem.requiredSignatures].sort()) === JSON.stringify([...allSignatures].sort());
      return result
    } else {
      return true
    }

  }

  // check also in OrderList.item.tsx
  // check also in UserSignatureRequestsList.page.tsx
  // check also in UserProfileTemplate.js::UserProfile
  useEffect(() => {
    if (data.downloads?.length) {
      console.log("ALL SIGNED, NAME:", data.formData.companyName)
      let tmpDownloadList = data.downloads.map((download: any) => alreadySigned(download)).filter((val: any) => !val)
      console.log("ALL SIGNED, DOCUMENTS:", data.formData.companyName, tmpDownloadList)
      setAllSigned(tmpDownloadList.length ? false : true)
    }

  }, [data.downloads])

  return (
    <Card className={(showDetail ? 'orderListCardContainer active' : 'orderListCardContainer') + (allSigned ? ' signed' : ' notSigned')} id='documentsToSign'>
      <div className="ordersInfoStatus" onClick={() => setShowDetail(!showDetail)}>
        <h4>
          {data.formData?.orderKey ? data.formData?.orderKey.split('_')[0] : ''} <br/> {formState?.municipalities ? formState.municipalities[0].municipality : ''}
        </h4>
        <span className='orderListDate'>{new Date(data.updated_at).toLocaleString()} </span>
        <span><strong style={{ color: data.status === 'unpaid' ? '#f00' : '#0077b6' }}>{PaidStatus[data.status as keyof typeof PaidStatus]}</strong></span>
        <Tooltip title="Upraviť dokument">
          <EditOutlined onClick={() => { window.open("/admin-flow/" + data.flow_id + "?order=" + data._id + "&steps=" + data.steps.steps, "_blank") }} />
        </Tooltip>
        {/* <Button
          className="addButton"
          disabled={data.status === 'unpaid'}
          onClick={() => setShowOrderStatus(!showOrderStatus)}>
          Stav objednávky:{' '}
          {data.orderStatus?.actualStatus?.state
            ? data.orderStatus.actualStatus.state
            : OrderState.VYTVORENE}
        </Button> */}
      </div>
      <div className='orderListNoteContainer'>
        <Form.Item label="Poznámka" className='ordersTextareaContainer'>
          <TextArea
            className="ordersTextarea"
            value={note}
            onChange={(e) => setNote(e.target.value)}></TextArea>
        </Form.Item>
        <Tooltip title="Uložiť poznámku">
          <SaveOutlined onClick={() => saveNote(note, data._id)} />
        </Tooltip>
      </div>
      {showDetail &&
        <>
          <Segment className='ordersTabNavigation'>
            <div className="ordersButtonGroup">
              <Button className={activeTab === 'contact' ? 'active contact' : 'contact'} onClick={() => toogleNav('contact')}>Kontakt</Button>
              <Button className={activeTab === 'documents' ? 'active documents' : 'documents'} onClick={() => toogleNav('documents')}>Dokumenty</Button>
            </div>
          </Segment>

          {activeTab === 'contact' && (
            <Card>
              <b style={{ fontSize: 17, marginBottom: 15, display: 'block' }}>
                Kontakt:
              </b>
              <Form className="orderFormLayout">
                <Row>
                  <Col span={12}>
                    <Form.Item label="Obec">
                      <Input
                        value={formState?.municipalities ? formState.municipalities[0].municipality : ''}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Kód obce">
                      <Input
                        value={formState?.municipalities ? formState.municipalities[0].municipalityID : ''}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Okres">
                      <Input
                        value={formState?.municipalities[0].districtID.district ? formState?.municipalities[0].districtID.district : 'Nevyplnené'}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Kraj">
                      <Input
                        value={formState?.municipalities ? formState.municipalities[0].regionId.name : ''}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="IČO">
                      <Input
                        value={'Nevyplnené'}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Kontakt_meno">
                      <Input
                        value={data.formData?.orderKey ? data.formData.orderKey.split('@')[0] : ''}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Kontakt_email">
                      <Input
                        value={data.formData?.orderKey ? data.formData.orderKey.split('_')[0] : ''}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Kontakt_phone">
                      <Input
                        value={'Nevyplnené'}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          )}

          {activeTab === 'more' && (
            <>
              <Card>
                <div className='fuzsButtonsContainer'>
                  {isChange && (
                    <>
                      <Button className="addButton" onClick={() => generateORSRFileComponent(data._id, ORSRFileTypes.FUZS)}>
                        Generuj FUZS súbor
                      </Button>
                      <Button className="addButton" onClick={() => downloadORSRFile(data._id, ORSRFileTypes.FUZS)}>
                        Stiahni FUZS súbor
                      </Button>
                    </>
                  )}
                  {isCreate && (
                    <>
                      <Button className="addButton" onClick={() => generateORSRFileComponent(data._id, ORSRFileTypes.FUPS)}>
                        Generuj FUPS súbor
                      </Button>
                      <Button className="addButton" onClick={() => downloadORSRFile(data._id, ORSRFileTypes.FUPS)}>
                        Stiahni FUPS súbor
                      </Button>
                    </>
                  )}
                </div>
              </Card>
            </>
          )
          }
          {activeTab === 'documents' && (
            <Card>
              {data.downloads?.map((documentItem: any, documentIndex: number) => {
                if (!documentItem) {
                  return null;
                }
                const { name, uploads, uploadsStatus, signedFile } = documentItem;
                return (
                  <StyledDocumentItem key={`doc-downloadn-${name}`}>
                    <div className="ordersDatabaseRow">
                      <Row style={{ alignItems: 'center' }}>
                        <Col span={6}>
                          <b>{name}</b>
                        </Col>
                        <Col span={1}>
                          {documentItem.custom && (
                            <span className="orderItemUploadIcons">
                              <CloseOutlined
                                className="delete"
                                onClick={() => handleFileDelete(data._id, documentIndex)}
                                disabled={deleteButtonDisabled}
                                hidden={!isUploadsActive()}
                              />
                            </span>
                          )}
                        </Col>
                        <Col span={2}>

                          {documentItem.requiredSignatures && (checkSignatures(documentItem) ? <CheckCircleOutlined style={{ fontSize: '20px', color: 'green' }} /> : <ExclamationCircleOutlined style={{ fontSize: '20px', color: 'red' }} />)}
                        </Col>
                        <Col span={3}>
                          <Button
                            onClick={() => handleTriggerDownload(documentItem, data._id, ".pdf")}
                            size="small"
                            type="primary">
                            PDF
                          </Button>
                          {signedFile &&
                            <Button
                              onClick={() => handleTriggerDownload(signedFile, data._id, ".asice")}
                              size="small"
                              type="primary">
                              ASICe
                            </Button>
                          }
                          <Button
                            onClick={() => signFile(documentItem, data._id)}
                            size="small"
                            type="primary">
                            Podpísať
                          </Button>
                          {signedFile &&
                            <Button
                              onClick={async () => {
                                await deleteSignedFile(data._id, documentItem.id)
                                await refresh()
                              }}
                              size="small"
                              type="primary">
                              Resetovať podpis
                            </Button>
                          }
                        </Col>
                        {/* <Col span={8}> */}
                        <OrderItemUploadIconsComponent
                          orderId={data._id}
                          documentIndex={documentIndex.toString()}
                          uploads={uploads}
                          refresh={refresh}
                          documentId={(documentItem as any).id}
                          token={token}
                          handleDownload={handleTriggerDownload}
                          uploadsStatus={uploadsStatus}
                        />
                        {/* </Col> */}
                      </Row>
                    </div>
                  </StyledDocumentItem>
                );
              })}
              <span className="orderItemUploadIcons">
                <input
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  accept="application/pdf"
                  id={'admin_upload_picker' + data._id}
                  type="file"
                  multiple
                  hidden={!isUploadsActive()}
                />
                <label
                  htmlFor={'admin_upload_picker' + data._id}
                  hidden={!isUploadsActive()}>
                  Pridať dokument
                  <PlusCircleOutlined style={{ marginLeft: 15 }} />
                </label>
              </span>
            </Card>
          )}
        </>}
      <OrderStatusModal
        visible={showOrderStatus}
        orderStatus={data.orderStatus ? data.orderStatus : {}}
        handleClose={() => handleStatusModalClose()}
        handleDone={saveOrderStatus}
        orderId={data._id}
        downloads={data.downloads}
      />
    </Card >
  );
}

const mapStateToProps = ({ appState }: { appState: AppState }) => ({
  user: appState.cognitoUser,
  settings: appState.settings
});

interface DispatchProps {
  uploadSignedFile: (orderId: string, documentIndex: string, fileData: any) => void;
  deleteSignedFile: (orderId: string, documentIndex: string) => void;
  getSettings: () => void
  saveNote: (note: string, orderId: string) => void;
  saveOrderStatus: (orderId: string, orderStatus: any, changedState: boolean, filesToAttach: string[]) => void;
  fileUpload: (orderId: string, files: FileToUpload[]) => void
  fileDelete: (orderId: string, documentIndex: string) => void
  generateORSRFile: (orderId: string, fileType: ORSRFileTypes) => void
  generateRefund: (orderId: string, refundForm: any) => void
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    uploadSignedFile: (orderId: string, documentIndex: string, fileData: any) => dispatch(UploadSignedFileAdminAction(orderId, documentIndex, fileData)),
    deleteSignedFile: (orderId: string, documentIndex: string) => dispatch(DeleteSignedFileAdminAction(orderId, documentIndex)),
    getSettings: () => dispatch(GetSettingsAction()),
    saveNote: (note: string, orderId: string) => dispatch(UpdateNoteAction(note, orderId)),
    saveOrderStatus: (orderId: string, orderStatus: any, changedState: boolean, filesToAttach: string[]) =>
      dispatch(UpdateOrderStatusAction(orderId, orderStatus, changedState, filesToAttach)),
    fileUpload: (orderId: string, files: FileToUpload[]) =>
      dispatch(UploadNewDocumentAction(orderId, files)),
    fileDelete: (orderId: string, documentIndex: string) =>
      dispatch(DeleteFileDocumentAction(orderId, documentIndex)),
    generateORSRFile: (orderId: string, fileType: ORSRFileTypes) => dispatch(
      GenerateORSRFileAction(orderId, fileType)),
    generateRefund: (orderId: string, refundForm: any) =>
      dispatch(GenerateRefundAction(orderId, refundForm))
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
