import { Option, Select, SelectWithAddItemOption } from '../../../../components/Input';
import React, { useEffect, useState } from 'react';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { Box } from '../../../../components/Box';
import { Checkbox, Form, Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import { translations } from '../../../../utils/LocalizedStrings';


function GenerateProtocol({ documentId, name, label = 'Select', ...rest }) {


  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] || { amount: 0 },
    setField
  }));
  const [fieldValues, setFieldValues] = useState(values)

  const handleChange = () => {
    values.setField({
      target: {
        name,
        value: fieldValues.value
      },
    });
  }

  const changeValue = (e) => {
    fieldValues.value = { amount: e.target.checked ? 1 : 0 }
    handleChange()
    forceUpdate()
  }

  return (
    <form id={name} className="selectPayment nestedGrid">
      <span className="">
        <Checkbox
          style={{ display: 'flex', justifyContent: 'end' }}
          value={fieldValues.value}
          name="generate"
          onChange={changeValue}
          className="orderFormCheckbox">
          {translations.artifacts.GenerateProtocol.generateProtocol}
        </Checkbox>
      </span>
    </form>
  );
}

export default {
  Mutator: GenerateProtocol
};
