import { CloseOutlined } from '@ant-design/icons';
import { Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ProcessMyDocumentAction, SetSwitchLoginModal } from "../../../../app/ActionsImpl";
import { Box } from "../../../../components/Box";
import { translations } from "../../../../utils/LocalizedStrings";
import { capitalizeFirstLetter } from "../../../../utils/string";
import { TypInyIdentifikatorEnum } from "../../../admin/pages/Interfaces";

const ProcessDocuments = ({
  parentId,
  setScannedData,
  processMyDocuments
}
) => {

  const [base64, setBase64] = useState()
  const [processSpinVisible, setProcessSpinVisible] = useState(false)
  const [preview, setPreview] = useState(false)

  useEffect(() => {
    async function processBlob() {
      if (base64) {
        setProcessSpinVisible(true)
        const result = await processMyDocuments([base64])
        if (result?.response?.extracted !== undefined) {
          if (result.response.extracted.type === "PASSPORT") {
            let tmpObj = {
              type: 'ownerPerson',
              separatedName: {
                titlesBefore: '',
                name: result.response.extracted.data.given_name,
                surname: result.response.extracted.data.surname,
                titlesAfter: '',
              },
              idType: Object.keys(TypInyIdentifikatorEnum).find(key => TypInyIdentifikatorEnum[key] === TypInyIdentifikatorEnum.CESTOVNY_DOKLAD),
              idNumber: result.response.extracted.data.passport_no,
              dateOfBirth: result.response.extracted.data.date_of_birth,
            }
            setScannedData(tmpObj)
          } else if (result.response.extracted.type === "ID-CARD") {
            Object.entries(TypInyIdentifikatorEnum).forEach(([key, value]) => {
              console.log("ENUM ", key, value)
            })
            let tmpObj = {
              type: 'ownerPerson',
              titlesBefore: '',
              separatedName: {
                name: result.response.extracted.data.given_name,
                surname: result.response.extracted.data.surname,
                titlesAfter: ''
              },
              idType: Object.keys(TypInyIdentifikatorEnum).find(key => TypInyIdentifikatorEnum[key] === TypInyIdentifikatorEnum.RODNE_CISLO),
              idNumber: result.response.extracted.data.personal_no,
              dateOfBirth: result.response.extracted.data.date_of_birth,
            }
            setScannedData(tmpObj)
          } else if (result.response.extracted.type === "ID-CARD-BACK") {
            let tmpObj = {
              address: {
                city: result.response.extracted.data.address_city,
                street: result.response.extracted.data.address_street,
                number: result.response.extracted.data.address_number,
                country: 'SK',
              }
            }
            setScannedData(tmpObj)
          }
        }
        setProcessSpinVisible(false)
        setBase64(undefined)
        setPreview(false)
      }
    }
    processBlob()
  }, [base64])

  function processImageSize(canvas, img, ctx, ratio, step) {
    canvas.width = img.width / ratio; // Example resize logic
    canvas.height = img.height / ratio;
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    try {

      canvas.toBlob((blob) => {
        if (blob.size / 1024 / 1024 < 1) { // Check if image is less than 1MB
          setBase64(canvas.toDataURL('image/jpeg'))
          setPreview(true)
        } else {
          processImageSize(canvas, img, ctx, ratio * step, step)
        }
      }, 'image/jpeg', 0.7)
    } catch (error) {
      console.error('Error converting canvas to Blob:', error);
    }
  }

  const handleFileChange = async (event) => {

    for (let oneFile of event.target.files) {
      let reader = new FileReader();
      reader.onload = async (loadEvent) => {

        let img = new Image();
        img.onload = async () => {

          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');

          processImageSize(canvas, img, ctx, 1.0, 1.1)

        }
        img.src = loadEvent.target.result;
      };
      await reader.readAsDataURL(oneFile);
    }
    event.target.value = ''

  }

  return (
    <Box className="textractUploadPickerContainer">
      <span className="textractUploadPicker">
        <input
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id={"textract_upload_picker" + parentId}
          type="file"
          accept="image/*"
        />
        <Tooltip title={translations.artifacts.ProcessDocuments.uploadImageForScanningTooltip}>
          <label htmlFor={"textract_upload_picker" + parentId} className={"addButton importPicture"}>
            {translations.artifacts.ProcessDocumentsSeparatedName.uploadImageForScanning}
          </label>
        </Tooltip>
      </span>
      {(processSpinVisible && <>
        <Spin />
      </>)}

      {(preview &&
        <div className='previewImageContainer'>
          <img className='previewImage' src={base64} />
          <h1>Spracúvam</h1>
        </div>
      )}
    </Box>
  )
}

const mapStateToProps = ({ appState }) => ({
  user: appState.cognitoUser,
});

const mapDispatchToProps = {
  processMyDocuments: ProcessMyDocumentAction,
  SetSwitchLoginModal: SetSwitchLoginModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessDocuments);


