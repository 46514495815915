// utils/mapUtils.js
import { notification } from 'antd';
import domtoimage from 'dom-to-image';
import L from 'leaflet';

export const setupLeafletDefaults = () => {
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
};

export const captureMapImage = (containerRef, setFieldValues, fieldValues, values, name, save) => {
    if (containerRef.current) {
        domtoimage.toPng(containerRef.current)
            .then((dataUrl) => {
                setFieldValues(prev => ({
                    ...prev,
                    value: {
                        ...prev.value,
                        image: dataUrl
                    }
                }));
                if (save) {
                    values.setField({
                        target: {
                            name,
                            value: {
                                ...fieldValues.value,
                                image: dataUrl
                            }
                        },
                    });
                }
            })
            .catch((error) => {
                console.error('Error capturing map image:', error);
            });
    }
};

export const getMapBounds = (mapRef) => {
    const map = mapRef?.current?.leafletElement;
    if (map) {
        const bounds = map.getBounds();
        const southWest = bounds.getSouthWest();
        const northEast = bounds.getNorthEast();
        return [southWest.lng, southWest.lat, northEast.lng, northEast.lat];
    }
    return null;
};

export const fetchMapLayer = async (prepareLayerFunction, municipalityGeoJson, setFieldValues, fieldValues, containerRef, values, name) => {
    const geoLayer = await prepareLayerFunction(municipalityGeoJson);
    if (geoLayer) {
        const tmpFieldValues = JSON.parse(JSON.stringify(fieldValues));

        tmpFieldValues.value.buildings = geoLayer.buildings;
        tmpFieldValues.value.geoLayer = geoLayer.geoLayer;
        tmpFieldValues.value.geoServer = geoLayer.geoServer;

        if (containerRef.current) {
            domtoimage.toPng(containerRef.current)
                .then((dataUrl) => {
                    tmpFieldValues.image = dataUrl;
                    setFieldValues(tmpFieldValues);
                    values.setField({
                        target: {
                            name,
                            value: tmpFieldValues.value
                        },
                    });
                })
                .catch((error) => {
                    console.error('Error capturing map image:', error);
                });
        }
    } else {
        notification.error({ message: "No data" });
    }
};