import { useState } from 'react';
import { Input as AppInput } from '../../../components/Input';
import CustomSelect from './inputs/Select';
import { DateInput, TimeInput } from './inputs/DateInput';
import FormattedHTML from './inputs/FormattedHtml';
import { Modal } from 'antd';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { useFormDataContext } from '../contexts/form-data.ctx';
import Representation from './inputs/Representation';
import PlnomocenstvoHeader from './inputs/Plnomocenstvo/PlnomocenstvoHeader';
import PlnomocenstvoEstablishmentHeader from './inputs/Plnomocenstvo/PlnomocenstvoEstablishmentHeader';
import AddressAutocompleteArtifact from './inputs/AddressAutocompleteArtifact';
import TitleNameSurnameArtifact from './inputs/TitleNameSurnameArtifact';
import Person from './inputs/Person/Person';
import SignatureCanvas from './inputs/SignatureCanvasArtifact';
import SelectPayment from './inputs/SelectPayment';
import Retribution from './inputs/Payments/Retribution';
import Buyer from './inputs/BuyerSeller/BuyerSeller';
import RegistrationCertificateArtifact from './inputs/RegistrationCertificate/RegistrationCertificateArtifact';
import GenerateProtocol from './inputs/GenerateProtocol';
import AIArtifact from './inputs/AIArtifact';
import MapaOrientaceSvahuNaSvetoveStrany from './inputs/MapaOrientaceSvahuNaSvetoveStrany';
import OpenStreetMap from './inputs/OpenStreetMap';
import BuildingCardMain from './inputs/BuildingCard/BuildingCardMain';
import Charts from './inputs/Charts';
import MapaPotencialnyVyskytDruhuVegetace from './inputs/MapaPotencialnyVyskytDruhuVegetace';
import MapaKrajinnyPokryvUzemiSvazku from './inputs/MapaKrajinnyPokryvUzemiSvazku';
import MapaKlimatickeOblastiNaUzemy from './inputs/MapaKlimatickeOblastiNaUzemy';
import MapaSklonitostiTerenu from './inputs/MapaSklonitostiTerenu';
import MapaPotencialVeternejEnergie from './inputs/MapaPotencialVeternejEnergie';
import MapaPotencialVynosovTpp0kg from './inputs/MapaPotencialVynosovTpp0kg';
import MapaPotencialVynosovTpp120kg from './inputs/MapaPotencialVynosovTpp120kg';
import MapaPotencialLesneTazobneZvysky from './inputs/MapaPotencialLesneTazobneZvysky';
import MapaPotencialKukuricaSilaz from './inputs/MapaPotencialKukuricaSilaz';
import MapaPotencialSlnecnicaTopinambur from './inputs/MapaPotencialSlnecnicaTopinambur';
import MapaPotencialRepkaOzimna from './inputs/MapaPotencialRepkaOzimna';
import MapaPotencialKonopeTechnicke from './inputs/MapaPotencialKonopeTechnicke';
import MapaPotencialRychloRastuceDreviny from './inputs/MapaPotencialRychloRastuceDreviny';
import MapaSpotrebaElektrickejEnergie from './inputs/MapaSpotrebaElektrickejEnergie';
import MapaPotencialPlochyStriech from './inputs/MapaPotencialPlochyStriech';


const StyledAgreement = styled.div`
  background-color: rgba(58, 59, 120, 0.1);
  border: 1px solid rgba(58, 59, 120, 0.35);
  border-radius: 0.5rem;
  padding: 1.33rem;
  color: #000000;
`;
const StyledAgreementDescription = styled.div`
  color: #000000;
`;

const StyledAgreementButtonRow = styled.div`
  display: flex;
  flex: 1;
  margin-top: 2rem;
`;

const StyledAgreementButtonIndicator = styled.div`
  display: inline-flex;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  border: 2px rgba(58, 58, 120, 1) solid;
  margin-right: 0.8rem;
`;

const StyledAgreementButton = styled.button`
  min-height: 60px;

  background: #ffffff;
  color: #000000;
  border: 0;
  border: 2px rgba(212, 212, 212, 0.24) solid;
  padding: 0.6rem 1.4rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  display: inline-flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export function Input({ name: label, type, id: name, parentStepId, ...rest }) {
  const [modalVisible, setModalVisible] = useState(false);
  function handleOpenInfo() {
    Modal.info({
      width: '65%',
      content: (
        <div>
          <pre dangerouslySetInnerHTML={{ __html: rest.description }} />
        </div>
      ),
      onOk() { },
    });
  }

  const values = useFormDataContext(({ data, setField }) => ({
    value: data,

    setField,
  }));

  // useEffect(() => {
  //   if (values && values.value && values.value[name] === undefined && rest.default) {
  //     values.setField({
  //       target: {
  //         name: name,
  //         value: rest.default
  //       }
  //     })
  //   }
  // })

  if (type === 'address-autofill') {
    return (
      <AddressAutocompleteArtifact.Mutator
        {...rest}
        tooltip={rest.tooltip}
        label={label}
        name={name}
        type={type}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'title-name-surname') {
    return (
      <TitleNameSurnameArtifact.Mutator
        {...rest}
        label={label}
        name={name}
        type={type}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'registration-certificate') {
    return (
      <RegistrationCertificateArtifact.Mutator
        {...rest}
        label={label}
        name={name}
        type={type}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'representation_custom') {
    return (
      <Representation
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'formattedHtml') {
    return (
      <FormattedHTML
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'select') {
    return (
      <CustomSelect
        {...rest}
        tooltip={rest.tooltip}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'person-select') {
    if (name.indexOf(".person-head") === -1 &&
      name.indexOf(".person-sentence") === -1 &&
      name.indexOf(".person-name") === -1 &&
      name.indexOf(".person-address") === -1 &&
      name.indexOf(".person-signature") === -1 &&
      name.indexOf(".person-sigimage") === -1 &&
      name.indexOf(".person-sigkep") === -1 &&
      name.indexOf(".person-sigchairman") === -1
    ) {
      return (
        <Person.Mutator
          {...rest}
          tooltip={rest.tooltip}
          name={name}
          label={label}
          handleDescriptionClick={handleOpenInfo}
        />
      );
    } else {
      return (<></>)
    }
  }

  if (type === 'signature-canvas') {
    return (
      <SignatureCanvas.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'payment') {
    return (
      <SelectPayment.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'retribution') {
    return (
      <Retribution.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'date') {
    return (
      <DateInput
        label={label}
        type={type}
        name={name}
        tooltip={rest.tooltip}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
        onChange={(e) => rest.onChange(e)}
      />
    );
  }

  if (type === 'generate-protocol-custom') {
    return (
      <GenerateProtocol.Mutator
        label={label}
        type={type}
        name={name}
        tooltip={rest.tooltip}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
        onChange={(e) => rest.onChange(e)}
      />
    );
  }

  if (type === 'time') {
    return (
      <TimeInput
        label={label}
        type={type}
        name={name}
        tooltip={rest.tooltip}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'buyer_custom') {
    return (
      <Buyer.Mutator
        user={rest.user}
        userProfileData={rest.userProfileData}
        label={label}
        type={type}
        name={name}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'seller_custom') {
    return (
      <Buyer.Mutator
        user={rest.user}
        userProfileData={rest.userProfileData}
        label={label}
        type={type}
        name={name}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'plnomocenstvo_header_custom') {
    return (
      <PlnomocenstvoHeader.Mutator
        user={rest.user}
        userProfileData={rest.userProfileData}
        label={label}
        type={type}
        name={name}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'plnomocenstvo_establishment_header_custom') {
    return (
      <PlnomocenstvoEstablishmentHeader.Mutator
        user={rest.user}
        userProfileData={rest.userProfileData}
        label={label}
        type={type}
        name={name}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }


  if (type === 'acknowledge') {
    return (
      <>
        <StyledAgreement>
          <h3>Poučenie</h3>
          <StyledAgreementDescription>
            {rest.description.slice(0, 200)}
            {rest.description.length > 200 && '...'}

            {rest.description.length > 200 && (
              <StyledAgreementButtonRow>
                <StyledAgreementButton onClick={() => setModalVisible(true)}>
                  <StyledAgreementButtonIndicator>
                    {rest.value === true && <Icon name="check" />}
                  </StyledAgreementButtonIndicator>
                  {rest.value === true ? 'Súhlasim' : 'Zobraziť Celé Znenie'}
                </StyledAgreementButton>
              </StyledAgreementButtonRow>
            )}
          </StyledAgreementDescription>

          {rest.description.length <= 200 && (
            <StyledAgreementButtonRow>
              <StyledAgreementButton
                onClick={() =>
                  rest.onChange({
                    target: {
                      value: true,
                    },
                  })
                }>
                <StyledAgreementButtonIndicator>
                  {rest.value === true && <Icon name="check" />}
                </StyledAgreementButtonIndicator>
                Súhlasim
              </StyledAgreementButton>

              <StyledAgreementButton
                onClick={() => {
                  rest.onChange({
                    target: {
                      value: -2,
                    },
                  });
                  setModalVisible(2);
                }}>
                <StyledAgreementButtonIndicator>
                  {rest.value === -2 && <Icon name="check" />}
                </StyledAgreementButtonIndicator>
                Nesúhlasim
              </StyledAgreementButton>
            </StyledAgreementButtonRow>
          )}
        </StyledAgreement>
        <br />
        {/* .ant-modal-footer {
  display: none;
} */}
        <Modal
          visible={modalVisible || modalVisible === 2}
          style={{ minWidth: '60%' }}
          onCancel={() => {
            if (rest.description.length > 200) {
              setModalVisible(2);
            } else {
              setModalVisible(false);
            }
          }}
          footer={null}>
          {modalVisible === true && (
            <>
              <h1>Celé znenie poučenia</h1>
              <i>Prečítajte si a po prečítaní zaškrtnite, že ste boli oboznámený</i>
              <br />
              <br />
              <div
                style={{
                  maxWidth: '90%',
                  height: 'auto',
                  breakAfter: 'always',
                  whiteSpace: 'pre-wrap',
                }}>
                {rest.description}
              </div>
              <br />
              <br />

              <StyledAgreementButton
                onClick={() => {
                  setModalVisible(false);
                  rest.onChange({
                    target: {
                      value: true,
                    },
                  });
                }}>
                <StyledAgreementButtonIndicator />
                Oboznámil som sa a porozumel som
              </StyledAgreementButton>
            </>
          )}
          {modalVisible === 2 && (
            <>
              <h1>Potvrdenie</h1>
              <i>
                Kliknutím na tlačidlo "Beriem na vedomie" potvrdzujem že som mal
                možnosť sa oboznámiť s poučením, a túto možnosť vedome odmietam.{' '}
              </i>
              <br />
              <br />
              <StyledAgreementButtonRow>
                {rest.description.length > 200 && (
                  <StyledAgreementButton
                    onClick={() => {
                      setModalVisible(true);
                    }}>
                    Späť
                  </StyledAgreementButton>
                )}

                <StyledAgreementButton
                  onClick={() => {
                    setModalVisible(false);
                    rest.onChange({
                      target: {
                        value: true,
                      },
                    });
                  }}>
                  <StyledAgreementButtonIndicator />
                  Beriem na vedomie
                </StyledAgreementButton>
              </StyledAgreementButtonRow>
            </>
          )}
        </Modal>
      </>
    );
  }

  if (type === 'qrcode') {
    return (
      <></>
    )
  }
  if (type === 'chatgpt-generated') {
    return (
      <AIArtifact.Mutator
        {...rest}
        label={label}
        name={name}
        type={type}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'open-street-map') {
    return (
      <OpenStreetMap.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-orientace-svahu-na-svetove-strany') {
    return (
      <MapaOrientaceSvahuNaSvetoveStrany.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-sklonitosti-terenu') {
    return (
      <MapaSklonitostiTerenu.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-potencial-veternej-energie') {
    return (
      <MapaPotencialVeternejEnergie.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-potencial-plochy-striech') {
    return (
      <MapaPotencialPlochyStriech.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-potencial-vynosov-tpp-0kg') {
    return (
      <MapaPotencialVynosovTpp0kg.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-potencial-vynosov-tpp-120kg') {
    return (
      <MapaPotencialVynosovTpp120kg.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-potencial-lesne-tazobne-zvysky-vyhrevnost') {
    return (
      <MapaPotencialLesneTazobneZvysky.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-potencial-kukurica-silaz') {
    return (
      <MapaPotencialKukuricaSilaz.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-potencial-slnecnica-topinambur') {
    return (
      <MapaPotencialSlnecnicaTopinambur.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-potencial-repka-ozimna') {
    return (
      <MapaPotencialRepkaOzimna.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-potencial-konope-technicke') {
    return (
      <MapaPotencialKonopeTechnicke.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-potencial-rychlo-rastuce-dreviny') {
    return (
      <MapaPotencialRychloRastuceDreviny.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-spotreba-elekrickej-energie') {
    return (
      <MapaSpotrebaElektrickejEnergie.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-potencialni-vyskyt-druhu-vegetace') {
    return (
      <MapaPotencialnyVyskytDruhuVegetace.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-krajinny-pokryv-uzemi-svazku') {
    return (
      <MapaKrajinnyPokryvUzemiSvazku.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'mapa-klimaticke-oblasti-na-uzemy') {
    return (
      <MapaKlimatickeOblastiNaUzemy.Mutator
        name={name}
        type={type}
        label={label} />
    )
  }
  if (type === 'building-card') {
    return (
      <BuildingCardMain.Mutator />
    )
  }
  if (type === 'charts') {
    return (
      <Charts.Mutator
        name={name}
        type={type}
        label={label}
      />
    )
  }

  return (
    <div>
      <AppInput
        id={name}
        disabled={rest.disabled}
        placeholder={rest.description}
        type={type}
        name={name}
        value={rest.value}
        tooltip={rest.tooltip}
        onChange={rest.onChange}
        children={rest.children}
        onBlur={rest.onBlur}
        onFocus={rest.onFocus}
        label={label}
      />
    </div>
  );
}

// @todo convert to map:
//
//  [field] : { [nestedKey]: label }
//

export const nestedArtifactKeys = {
  company: {
    'data.ico': 'IČO',
    'data.adresa': 'Adresa',
    'data.den_zapisu': 'Den zapisu',
    'data.oddiel': 'Oddiel',
    'data.vlozka': 'Vlozka',
    'data.pravna_forma': 'Pravna forma',
    'data.obchodne_meno': 'Obchodne meno',
    'data.prislusny_sud': 'Prislusny sud',
    'data.zakladne_imanie.imanie': 'Zakladna imanie',
    'data.zakladne_imanie.splatene': 'Zakladne imanie splatene',
    'data.predmet_cinnosti': 'Predmet cinnosti'
  },
  person: {
    'person-head': 'Hlavička',
    'person-sentence': 'Veta',
    'person-name': 'Meno',
    'person-address': 'Adresa',
    'person-signature': "Podpis",
    'person-sigimage': "Obrázok podpisu",
    'person-sigkep': "Podpis KEP",
    'person-sigchairman': "Podpis predsedu - zápisnica",
  }
};
