import { FilePdfOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { translations } from '../../../utils/LocalizedStrings';
import { hashValues } from '../../editor/components/views/AdminEditor/Constants';

export const ValidatorModal = ({
  visible,
  invalidArtifacts,
  cachedFieldsAsObject,
  hashValuesMap,
  handleClose,
  changeOpenedDocument,
  allDocuments,
  handleDone,
  user
}) => {
  let mapDocs = {};
  allDocuments.forEach((tmpDoc) => {
    mapDocs[tmpDoc.id] = {
      docName: tmpDoc.name,
      artifactIds: new Set(),
      isStep: tmpDoc.value.isStep,
    };
  });

  invalidArtifacts.forEach((a) => {
    if (mapDocs[a.docId]) {
      mapDocs[a.docId].artifactIds.add(a.artifactId);
    }
  });

  let tmpEmptyDocId = Object.entries(mapDocs)
    .filter(([k, v]) => v.artifactIds.size === 0)
    .map(([k1, v1]) => k1);

  tmpEmptyDocId.forEach((id) => delete mapDocs[id]);

  const orderKey = `${user.attributes.email}_${Date.now()}`;
  const handleGenerateDocuments = () => {
  handleDone({
    orderKey
  });
}

  return (
    <Modal
      centered
      visible={visible}
      onCancel={handleClose}
      onOk={() => {
        handleClose();
      }}
      className="stripePaymentModal"
      maskStyle={{ padding: 0 }}
      style={{ margin: 0 }}
      footer={null}>
      <div className="validatorModal">
        <h2>
          {translations.screens.ValidatorModal.title}
        </h2>
        <div className="ant-typography">
          {' '}
          {(mapDocs &&
            Object.entries(mapDocs).map(([key, value]) => {
              return value.artifactIds && [...value.artifactIds].length > 0 ? (
                <React.Fragment key={key}>
                  <hr />
                  <div className='validatorModalContainer'>
                    <Row>
                      <h4>{mapDocs[key].docName}</h4>
                      {[...value.artifactIds].map((a, index) => {
                        return (
                          <div className='stepName'>
                            <Text strong>
                              {(hashValuesMap.has(a) && hashValuesMap.get(a)) ||
                                (cachedFieldsAsObject[a.split('-')[0]] &&
                                  cachedFieldsAsObject[a.split('-')[0]].name &&
                                  (cachedFieldsAsObject[a.split('-')[0]].name.split('_')
                                    .length > 1
                                    ? cachedFieldsAsObject[a.split('-')[0]].name.split(
                                      '_',
                                    )[1]
                                    : cachedFieldsAsObject[a.split('-')[0]].name.split(
                                      '_',
                                    )[0])) ||
                                (hashValues.filter((hv) => hv.id === a.artifactId) &&
                                  hashValues.filter((hv) => hv.id === a.artifactId)
                                    .value) ||
                                a.artifactId}
                            </Text>
                          </div>
                        )
                      })}
                    </Row>
                    <Button
                      onClick={() => {
                        handleClose();
                        changeOpenedDocument(key);
                      }}
                      type="primary"
                      className="error-validation">
                      {translations.screens.ValidatorModal.step}
                      <EditOutlined />
                    </Button>
                  </div>
                </React.Fragment>
              ) : (
                <></>
              );
            })) || <></>}
        </div>
      </div>
      <div className="buttonsGroup">
        <Button
          onClick={() => {
            handleClose();
          }}
          className="stepBackButton addButton">
          Späť
          <EditOutlined />
        </Button>
        <Button
          onClick={() => {
            handleClose();
            handleGenerateDocuments();
          }}
          type="primary"
          className="addButton">
          Vygenerovať dokumenty
          <FilePdfOutlined />
        </Button>
      </div>
    </Modal>
  );
};
