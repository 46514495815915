import { CloseOutlined } from '@ant-design/icons';
import { Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ProcessMyDocumentAction } from "../../../../app/ActionsImpl";
import { Box } from "../../../../components/Box";
import { TypInyIdentifikatorEnum } from "../../../admin/pages/Interfaces";

const ProcessDocuments = (
  { parentId,
    setScannedData,
    processMyDocuments,
    processLabel
  }
) => {

  const [base64, setBase64] = useState()
  const [preview, setPreview] = useState(false)

  function containsOnlyNumbers(inputString) {
    // Use a regular expression to check if the string contains only numbers
    return /^\d+$/.test(inputString);
  }

  useEffect(() => {
    async function processBlob() {
      if (base64) {
        const result = await processMyDocuments([base64])
        if (result?.response?.extracted !== undefined) {
          if (result.response.extracted.type === "PASSPORT") {
            let tmpObj = {
              type: 'ownerPerson',
              separatedName: {
                titlesBefore: '',
                name: result.response.extracted.data.given_name,
                surname: result.response.extracted.data.surname,
                titlesAfter: '',
              },
              idType: Object.keys(TypInyIdentifikatorEnum).find(key => TypInyIdentifikatorEnum[key] === TypInyIdentifikatorEnum.CESTOVNY_DOKLAD),
              idNumber: result.response.extracted.data.passport_no,
              dateOfBirth: result.response.extracted.data.date_of_birth,
            }
            setScannedData(tmpObj)
          } else if (result.response.extracted.type === "ID-CARD") {
            Object.entries(TypInyIdentifikatorEnum).forEach(([key, value]) => {
              console.log("ENUM ", key, value)
            })
            let tmpObj = {
              type: 'ownerPerson',
              titlesBefore: '',
              separatedName: {
                name: result.response.extracted.data.given_name,
                surname: result.response.extracted.data.surname,
                titlesAfter: ''
              },
              idType: Object.keys(TypInyIdentifikatorEnum).find(key => TypInyIdentifikatorEnum[key] === TypInyIdentifikatorEnum.RODNE_CISLO),
              idNumber: result.response.extracted.data.personal_no,
              dateOfBirth: result.response.extracted.data.date_of_birth,
            }
            setScannedData(tmpObj)
          } else if (result.response.extracted.type === "ID-CARD-BACK") {
            let tmpObj = {
              address: {
                city: result.response.extracted.data.address_city,
                street: result.response.extracted.data.address_street,
                number: result.response.extracted.data.address_number,
                country: 'SK',
              }
            }
            setScannedData(tmpObj)
          } else if (result.response.extracted.type === "REGISTRATION-CERTIFICATE") {
            const isOwnerCompany = containsOnlyNumbers(result.response.extracted.data.vlastnik_narodenie_ico)
            const isHolderCompany = containsOnlyNumbers(result.response.extracted.data.drzitel_narodenie_ico)
            const isDifferentHolder = result.response.extracted.data.drzitel_osvedcenia && (result.response.extracted.data.vlastnik_vozidla !== result.response.extracted.data.drzitel_osvedcenia)

            let tmpObj

            // FRONT PAGE
            tmpObj = {
              owner: {
                type: isOwnerCompany ? 'ownerCompany' : 'ownerPerson',
                name: isOwnerCompany ? result.response.extracted.data.vlastnik_vozidla : '',
                separatedName: {
                  name: isOwnerCompany ? '' : result.response.extracted.data.vlastnik_vozidla
                },
                address: {
                  street: result.response.extracted.data.vlastnik_adresa_ulica,
                  city: result.response.extracted.data.vlastnik_adresa_mesto,
                  country: 'SK'
                },
                dateOfBirth: isOwnerCompany ? '' : result.response.extracted.data.vlastnik_narodenie_ico,
                idType: 'idNumber',
                idNumber: isOwnerCompany ? result.response.extracted.data.vlastnik_narodenie_ico : '',
              },
              data: {
                vin: result.response.extracted.data.vin,
                evidencneCislo: result.response.extracted.data.evidencne_cislo,
                cisloTechnickehoPreukazu: result.response.extracted.data.cislo_technickeho_preukazu,
                rokVyrobyVozidla: result.response.extracted.data.rok_vyroby_vozidla,
              }
            }
            if (isDifferentHolder) {
              tmpObj['differentHolder'] = true
              tmpObj['holder'] = {
                type: isHolderCompany ? 'ownerCompany' : 'ownerPerson',
                name: isHolderCompany ? result.response.extracted.data.drzitel_osvedcenia : '',
                separatedName: {
                  name: isHolderCompany ? '' : result.response.extracted.data.drzitel_osvedcenia
                },
                address: {
                  street: result.response.extracted.data.drzitel_adresa_ulica,
                  city: result.response.extracted.data.drzitel_adresa_mesto,
                  country: 'SK'
                },
                dateOfBirth: isHolderCompany ? '' : result.response.extracted.data.drzitel_narodenie_ico,
                idType: 'idNumber',
                idNumber: isHolderCompany ? result.response.extracted.data.drzitel_narodenie_ico : '',
              }
            }
            console.log("REGISTRATION-CERTIFICATE-FRONT", tmpObj)
            setScannedData(tmpObj)
          } else if (result.response.extracted.type === "REGISTRATION-CERTIFICATE-BACK") {

            // BACK PAGE
            const tmpObj = {
              data: {
                druh: result.response.extracted.data.druh,
                znacka: result.response.extracted.data.znacka,
                typ: result.response.extracted.data.typ,
                farba: result.response.extracted.data.farba
              }
            }
            console.log("REGISTRATION-CERTIFICATE-BACK", tmpObj)
            setScannedData(tmpObj)
          }





          //  ['{\n'
          //  '  "type": "REGISTRATION-CERTIFICATE",\n'
          //  '  "data": {\n'
          //  '    "vin": "TMBJJ9NPXK7026918",\n'
          //  '    "evidencne_cislo": "BL147UF",\n'
          //  '    "cislo_technickeho_preukazu": null,\n'
          //  '    "rok_vyroby_vozidla": "20.11.2018",\n'
          //  '    "vlastnik_vozidla": "CSOB Leasing, a.s.",\n'
          //  '    "vlastnik_narodenie_ico": "35704713",\n'
          //  '    "vlastnik_adresa": "OZizkova 7802/11",\n'
          //  '    "drzitel_osvedcenia": "PP Information technology s.r.o.",\n'
          //  '    "drzitel_narodenie_ico": "52104966",\n'
          //  '    "drzitel_adresa": "Bratislava-Star\\u00e9 MestoCintorinska 7669/12"\n'
          //  '  }\n'
          //  '}']

        }
        setBase64(undefined)
        setPreview(false)
      }
    }
    processBlob()
  }, [base64])

  function processImageSize(canvas, img, ctx, ratio, step) {
    canvas.width = img.width / ratio; // Example resize logic
    canvas.height = img.height / ratio;
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    try {

      canvas.toBlob((blob) => {
        if (blob.size / 1024 / 1024 < 1) { // Check if image is less than 1MB
          setBase64(canvas.toDataURL('image/jpeg'))
          setPreview(true)
        } else {
          processImageSize(canvas, img, ctx, ratio * step, step)
        }
      }, 'image/jpeg', 0.7)
    } catch (error) {
      console.error('Error converting canvas to Blob:', error);
    }
  }

  const handleFileChange = async (event) => {

    for (let oneFile of event.target.files) {
      let reader = new FileReader();
      reader.onload = async (loadEvent) => {

        let img = new Image();
        img.onload = async () => {

          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');

          processImageSize(canvas, img, ctx, 1.0, 1.1)

        }
        img.src = loadEvent.target.result;
      };
      await reader.readAsDataURL(oneFile);
    }
    event.target.value = ''

  }

  return (
    <Box className="textractUploadPickerContainer">
      <span className="textractUploadPicker">
        <input
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id={"textract_upload_picker" + parentId}
          type="file"
          accept="image/*"
        />
        <Tooltip title={processLabel}>
          <label htmlFor={"textract_upload_picker" + parentId} className={"addButton importPicture"}>
            {processLabel}
          </label>
        </Tooltip>
      </span>
      {(preview &&
        <div className='previewImageContainer'>
          <img className='previewImage' src={base64} />
          <h1>Spracúvam</h1>
        </div>
      )}
    </Box>
  )
}

const mapStateToProps = ({ appState }) => ({
});

const mapDispatchToProps = {
  processMyDocuments: ProcessMyDocumentAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessDocuments);


