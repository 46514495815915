import '../../maintenance.css';
import maintenance from '../../images/maintenance.webp';
import logo from '../../images/blueLogo.webp';
import { LANDING_PAGE_URI } from '../../constants';

function MaintenancePage() {
  return (
    <article className='maintenancePage'>
      <div className='newLogo'>
      <a onClick={() =>  window.location.href = LANDING_PAGE_URI ? LANDING_PAGE_URI : '' }>
          <img src={logo} alt="logo" className='logo' />
        </a>
      </div>
      <div className='container'>
        <img src={maintenance} alt="maintenance" className='maintenance' />
        <h1>Práve prebieha plánovaná údržba</h1>
      </div>
    </article>
  );
}

export default MaintenancePage;
