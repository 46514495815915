// src/components/MapComponent.jsx
import { Button, Checkbox, Input, notification } from 'antd';
import domtoimage from 'dom-to-image';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Map, Polygon, TileLayer } from 'react-leaflet';
import Text from '../../../../components/Text';
import { convertCoordinates2WG84, prepareLayer } from '../../../../utils/GeometryUtils';
import { translations } from '../../../../utils/LocalizedStrings';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import BuildingCard from './BuildingCard/BuildingCard';

// Fix for default icon not displaying properly
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const OpenStreetMapMutator = ({ name }) => {

    const [activeIndexes, setActiveIndexes] = useState({});

    const setActiveIndex = useCallback((activeIndex, index) => {
        setActiveIndexes(precActiveIndexes => {
            return {
                ...precActiveIndexes,
                [index]: activeIndex
            };
        });
    }, [activeIndexes]);

    const values = useFormDataContext(({ data, setField }) => ({
        value: data[name] || { position: [49.94297310, 17.03212700], zoom: 13 },
        municipality: data.municipalities[0],
        setField
    }));
    const [fieldValues, setFieldValues] = useState(values)

    const handleChange = () => {
        values.setField({
            target: {
                name,
                value: fieldValues.value
            },
        });
    }

    const mapRef = useRef();
    const containerRef = useRef();

    useEffect(() => {

        if (values.municipality.geoBoundingBox && mapRef.current) {
            const map = mapRef.current.leafletElement;
            map.fitBounds(values.municipality.geoBoundingBox);
        }

    }, []);

    useEffect(() => {
        console.log("GIS MUNICIPALITY: ", values.municipality)
        console.log("GIS ZOOM: ", fieldValues.value.zoom)
    }, [])

    const captureImage = (save) => {
        if (containerRef.current) {
            domtoimage.toPng(containerRef.current)
                .then((dataUrl) => {
                    setFieldValues(prev => ({
                        ...prev,
                        value: {
                            ...prev.value,
                            image: dataUrl
                        }
                    }));
                    if (save) {
                        values.setField({
                            target: {
                                name,
                                value: {
                                    ...fieldValues.value,
                                    image: dataUrl
                                }
                            },
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error capturing map image:', error);
                });
        }
    };

    const onMoveEnd = () => {
        const map = mapRef.current.leafletElement;
        const newCenter = map.getCenter();
        let tmpFieldValues = { ...fieldValues };
        tmpFieldValues.value.position = [newCenter.lat, newCenter.lng];
        tmpFieldValues.value.zoom = map.getZoom();

        captureImage()
    };

    const fetchLayer = async () => {
        const geoLayer = await prepareLayer(values.municipality.geoJson) //geoBoundingBoxJson)
        if (geoLayer) {
            const tmpFieldValues = JSON.parse(JSON.stringify(fieldValues))
            tmpFieldValues.value.buildings = geoLayer.features.map(building => { return { ...building, building_card: true } })
            tmpFieldValues.value.geoLayer = geoLayer.features.map(feature =>
                // feature.geometry.paths.map(path =>
                feature.geometry.rings.map(path =>
                    path.map(item => {
                        const tmpCoord = convertCoordinates2WG84(item)
                        return [tmpCoord[1], tmpCoord[0]]
                        // return [item[1], item[0]]
                    }
                    )))
            if (containerRef.current) {
                domtoimage.toPng(containerRef.current)
                    .then((dataUrl) => {
                        tmpFieldValues.image = dataUrl
                        setFieldValues(tmpFieldValues)
                        values.setField({
                            target: {
                                name,
                                value: tmpFieldValues.value
                            },
                        });
                    })
                    .catch((error) => {
                        console.error('Error capturing map image:', error);
                    });
            }
        } else {
            notification.error({ message: "No data" });
        }
    }

    const changeBuilding = useCallback((building, index) => {
        setFieldValues(prevFieldValues => {
            const updatedBuildings = [...prevFieldValues.value.buildings];
            updatedBuildings[index] = building
            values.setField({
                target: {
                    name,
                    value: {
                        ...prevFieldValues.value,
                        buildings: updatedBuildings
                    }
                },
            });
            return {
                ...prevFieldValues,
                value: {
                    ...prevFieldValues.value,
                    buildings: updatedBuildings
                }
            };
        });
    }, [fieldValues]);

    const MemoizedBuildingCardMutator = React.memo(BuildingCard.Mutator, (prevProps, nextProps) => {
        return prevProps.building === nextProps.building;
    });

    const MemoizedBuildingTableRow = React.memo(BuildingTableRow, (prevProps, nextProps) => {
        return prevProps.building === nextProps.building;
    });

    return (
        <div className='open-street-map'>
            <h3>Open Street Map</h3>
            <Button
                className='addButton'
                style={{ marginRight: 20 }}
                onClick={() => {
                    captureImage(true)
                }}>Vložiť do náhľadu</Button>
            <Button
                className='addButton'
                onClick={() => {
                    fetchLayer()
                }}>Stiahnuť údaje</Button>
            <div ref={containerRef} className='open-street-map'>
                <Map onblur={handleChange}
                    center={values?.municipality?.geoCenter}
                    zoom={fieldValues.value.zoom}
                    ref={mapRef}
                    onMoveEnd={onMoveEnd}
                    onZoomEnd={onMoveEnd}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    >
                    </TileLayer>
                    <Polygon key={"524862865862466984"} positions={values?.municipality?.geoJson} />
                    {fieldValues?.value?.geoLayer &&
                        // <Polyline color='#8B4513' key={"524862865862466984"} positions={[fieldValues?.value?.geoLayer]} />
                        <Polygon color='#8B4513' key={"524862865862466984"} positions={[fieldValues?.value?.geoLayer]} />
                        // <GeoJSON
                        //     data={fieldValues.value.geoLayer}
                        //     style={{ color: '#8B4513' }}
                        // ></GeoJSON>
                    }
                </Map>
            </div>
            {fieldValues?.value?.buildings &&
                <>
                    {fieldValues?.value?.buildings.map((building, index) => {
                        return (
                            <MemoizedBuildingTableRow index={index} handleChange={changeBuilding} building={building} />
                        )
                    })
                    }
                    {fieldValues?.value?.buildings.map((building, index) => {
                        return (
                            <React.Fragment key={index} >
                                <MemoizedBuildingCardMutator activeIndex={activeIndexes[index]} setActiveIndex={setActiveIndex} index={index} handleChange={changeBuilding} building={building} />
                            </React.Fragment>
                        )
                    })}
                </>
            }
        </div>
    );
};

const BuildingTableRow = (props) => {
    const [building, setBuilding] = useState(props.building)

    const changeBuilding = useCallback((e) => {
        setBuilding(prevFieldValues => {
            const updatedBuilding = { ...prevFieldValues };
            if (e.target.type === "checkbox") {
                updatedBuilding.building_card = e.target.checked;
            } else {
                if (e.target.name === "address") {
                    updatedBuilding.address.display_name = e.target.value;
                } else {
                    updatedBuilding[e.target.name] = e.target.value;
                }
            }
            return {
                ...prevFieldValues,
                ...updatedBuilding
            };
        });
    }, [building]);

    const handleChange = () => {
        props.handleChange(building, props.index)
    }

    return (
        <section className='open-street-map-grid'>
            <Checkbox
                onChange={(e) => { changeBuilding(e) }}
                onBlur={handleChange}
                checked={building.building_card}
            ></Checkbox>
            <div>
                <Text strong>budova</Text>
                <Input
                    className="ant-input"
                    autoComplete="chrome-off"
                    name={"name"}
                    placeholder={translations.artifacts.TitleNameSurnameArtifact.titlesBegoreName}
                    value={building.name ? building.name : ''}
                    onChange={(e) => { changeBuilding(e) }}
                    onBlur={handleChange}
                />
            </div>
            <div>
                <Text strong>adresa</Text>
                <Input
                    className="ant-input"
                    autoComplete="chrome-off"
                    name={"address"}
                    placeholder={translations.artifacts.TitleNameSurnameArtifact.titlesBegoreName}
                    value={building.address ? building.address.display_name : ''}
                    onChange={(e) => { changeBuilding(e) }}
                    onBlur={handleChange}
                />
            </div>
            <div>
                <Text strong>popis</Text>
                <Input
                    className="ant-input"
                    autoComplete="chrome-off"
                    name={"description"}
                    placeholder={translations.artifacts.TitleNameSurnameArtifact.titlesBegoreName}
                    value={building.description ? building.description : ''}
                    onChange={(e) => { changeBuilding(e) }}
                    onBlur={handleChange}
                />
            </div>
        </section>
    )
}

function OpenStreetMapRenderer(values, key) {
    let returnValue = ""
    if (values[key]) {
        returnValue += `<img src="${values[key].image}" alt="Map view" style="height: 100%; width: 100%; object-fit: cover; margin-top: 3.5rem; border-radius: 4px;" />`;
        if (values[key].buildings) {
            returnValue += "<table>"
            returnValue += `<tr>`
            returnValue += `<td width="150px" rowspan="1" colspan="1">Budova`
            returnValue += "</td>"
            returnValue += `<td width="270px" rowspan="1" colspan="1">Adresa`
            returnValue += "</td>"
            returnValue += `<td width="200px" rowspan="1" colspan="1">Popis budovy`
            returnValue += "</td>"
            returnValue += "</tr>"
            for (let building of values[key].buildings) {
                returnValue += `<tr>`
                returnValue += `<td width="150px" rowspan="1" colspan="1">${building.name ? building.name : ''}`
                returnValue += "</td>"
                returnValue += `<td width="270px" rowspan="1" colspan="1">${building.address.display_name}`
                returnValue += "</td>"
                returnValue += `<td width="200px" rowspan="1" colspan="1">${building.description ? building.description : ''}`
                returnValue += "</td>"
                returnValue += "</tr>"
            }
            returnValue += "</table>"
        }
    }

    return returnValue
}

function OpenStreetMapValidator() {
    return true;
}

export default {
    Mutator: OpenStreetMapMutator,
    HTMLRenderer: OpenStreetMapRenderer,
    Validator: OpenStreetMapValidator
};
