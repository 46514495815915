import { Alert, Card } from 'antd';
import 'moment/locale/sk';
import { useEffect, useState } from 'react';
import BarcodeReader from 'react-barcode-reader';
import Navigation from './Navigation';
import { Auth } from 'aws-amplify';
import OrderListItem from './OrderList.item';


export function OrderPage({ isLoading, documents, invalidJson, refresh }) {
  const [token, setToken] = useState('')
  useEffect(() => {
    Auth.currentSession().then(session => {
      const jwtToken = session.getAccessToken().getJwtToken()
      setToken(jwtToken)
    })
  })

  return (
    <>
      {documents.error &&
        <Alert
          message="Chyba, objednávku sa nepodarilo nájsť. Skúste znovu."
          type="error"
        />
      }
      {documents && documents._id && !documents.error ?
        <OrderListItem
          token={token}
          data={documents}
          key={`docitem-${documents._id || documents.name}`}
          refresh={refresh}
        />
        :
        (!invalidJson &&
          <Alert message="Naskenujte id objednávky." type="warning" />)

      }
      {invalidJson && <Alert message="Chyba, nesprávny QR kód." type="error" />}
    </>
  );


}

export function OrderPageHeader({ setInvalidJson, setOrderId, refresh }) {
  function handleScan(data) {
    setInvalidJson(false);
    if (data) {
      try {
        let json = JSON.parse(data);
        if (json.orderId) {
          setOrderId(json.orderId);
        } else {
          setInvalidJson(true);
        }
      } catch {
        setInvalidJson(true);
      }
    }
  }

  function handleError(err) {
    console.error(err);
  }

  useEffect(() => {
    handleScan()

  }, [])

  return (
    <>
      <Navigation />
      <Card style={{ background: '#f0f0f0', padding: '4rem 15% 0 15%' }}>
        <div>
          <h3
            style={{
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: '1.375rem',
            }}>
            Dokument
            <BarcodeReader onError={handleError} onScan={handleScan} />
          </h3>
        </div>
      </Card>
    </>
  );
}


