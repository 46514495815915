export const SET_LANGUAGE = "SET_LANGUAGE"
export const SET_SWITCH_LOGIN_MODAL = "SET_SWITCH_LOGIN_MODAL"
export const SET_USER = "SET_USER"
export const RECENTLY_CONFIRMED = "RECENTLY_CONFIRMED"
export const SET_USER_PERSONS = "SET_USER_PERSONS"
export const GET_PACKAGES = "GET_PACKAGES"
export const SET_OPENED_PACKAGE = "SET_OPENED_PACKAGE"
export const SAVE_PACKAGE = "SAVE_PACKAGE"
export const UPDATE_PACKAGES = "UPDATE_PACKAGES"
export const DELETE_PACKAGE = "DELETE_PACKAGE"
export const SET_ERROR = "SET_ERROR"
export const GET_FLOW = "GET_FLOW"
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT"
export const SET_FLOW_ID = "SET_FLOW_ID"
export const SET_NEW_DOCUMENT = "SET_NEW_DOCUMENT"
export const DELETE_DOCUMENT = "DELETE_DOCUMENT"
export const SAVE_ARTIFACT = "SAVE_ARTIFACT"
export const DELETE_ARTIFACT = "DELETE_ARTIFACT"
export const SET_ACTIVE_ARTIFACT = "SET_ACTIVE_ARTIFACT"
export const UPDATE_METADATA = "UPDATE_METADATA"
export const UPDATE_DOCUMENT_INDEXES = "UPDATE_DOCUMENT_INDEXES"
export const GET_ORDERS_LIST = "GET_ORDERS_LIST"
export const GET_SIGNATURE_REQUESTS = "GET_SIGNATURE_REQUESTS"
export const GET_FEEDBACKS_LIST = "GET_FEEDBACKS_LIST"
export const GET_USER_ORDERS_LIST = "GET_USER_ORDERS_LIST"
export const GET_USER_ORDERS_TEMPLATES_LIST = "GET_USER_ORDERS_TEMPLATES_LIST"
export const UPLOAD_FILE_FOR_DOCUMENT = "UPLOAD_FILE_FOR_DOCUMENT"
export const UPLOAD_SIGNED_FILE_ADMIN = "UPLOAD_SIGNED_FILE_ADMIN"
export const DELETE_SIGNED_FILE_ADMIN = "DELETE_SIGNED_FILE_ADMIN"
export const UPLOAD_SIGNED_FILE_CUSTOMER = "UPLOAD_SIGNED_FILE_CUSTOMER"
export const UPLOAD_SIGNATURE_IMAGE_CUSTOMER = "UPLOAD_SIGNATURE_IMAGE_CUSTOMER"
export const DELETE_SIGNATURE_IMAGE_CUSTOMER = "DELETE_SIGNATURE_IMAGE_CUSTOMER"
export const DELETE_FILE_FOR_DOCUMENT = "DELETE_FILE_FOR_DOCUMENT"
export const DELETE_FILE_DOCUMENT = "DELETE_FILE_DOCUMENT"
export const UPLOAD_NEW_DOCUMENT = "UPLOAD_NEW_DOCUMENT"
export const UPDATE_UPLOADS_STATUS_FOR_DOCUMENT = "UPDATE_UPLOADS_STATUS_FOR_DOCUMENT"
export const GET_BLOG_CATEGORIES = "GET_BLOG_CATEGORIES"
export const SAVE_BLOG_CATEGORY = "SAVE_BLOG_CATEGORY"
export const DELETE_BLOG_CATEGORY = "DELETE_BLOG_CATEGORY"
export const GET_BLOGS_LIST = "GET_BLOGS_LIST"
export const GET_BLOG = "GET_BLOG"
export const GET_BLOG_BY_LINK = "GET_BLOG_BY_LINK"
export const CLEAR_BLOG_LINK = "CLEAR_BLOG_LINK"
export const CLEAR_BLOG = "CLEAR_BLOG"
export const CREATE_BLOG = "CREATE_BLOG"
export const GET_BLOG_TITLES = "GET_BLOG_TITLES"
export const GET_BLOG_DESCRIPTION = "GET_BLOG_DESCRIPTION"
export const SAVE_BLOG = "SAVE_BLOG"
export const SAVE_BLOG_ORDER = "SAVE_BLOG_ORDER"
export const SAVE_CATEGORIES_ORDER = "SAVE_CATEGORIES_ORDER"
export const DELETE_BLOG = "DELETE_BLOG"
export const SET_BLOG_CATEGORIES = "SET_BLOG_CATEGORIES"
export const SET_BLOGS = "SET_BLOGS"
export const SET_CHOSEN_COMPANY = "SET_CHOSEN_COMPANY"
export const GET_CRAFTS = "GET_CRAFTS"
export const SET_CRAFTS = "SET_CRAFTS"
export const SET_REPRESENTATION_ACTIVE = "SET_REPRESENTATION_ACTIVE"
export const UPDATE_NOTE = "UPDATE_NOTE"
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS"
export const GENERATE_REFUND = "GENERATE_REFUND"
export const CREATE_FILE_SIGN_REQUEST = "CREATE_FILE_SIGN_REQUEST"
export const DELETE_FILE_SIGN_REQUEST = "DELETE_FILE_SIGN_REQUEST"

export const SAVE_ORDER = "SAVE_ORDER"
export const UPDATE_ORDER = "UPDATE_ORDER"
export const GET_TEMPLATE = "GET_TEMPLATE"
export const GET_TEMPLATES = "GET_TEMPLATES"
export const GET_TEMPLATE_USER = "GET_TEMPLATE_USER"
export const GET_TEMPLATE_USER_ADMIN = "GET_TEMPLATE_USER_ADMIN"
export const GET_TEMPLATE_ADMIN = "GET_TEMPLATE_ADMIN"
export const UPDATE_FORMSTATE = "UPDATE_FORMSTATE"
export const ALLOW_INVOICE_OTHER_COMPANY = "ALLOW_INVOICE_OTHER_COMPANY"
export const GET_USER_ORDERS_AMOUNT = "GET_USER_ORDERS_AMOUNT"
export const SAVE_COGNITO_USER_PROPERTIES = "SAVE_COGNITO_USER_PROPERTIES"
export const ACCEPT_PHONE_SERVICE_CONTRACT = "ACCEPT_PHONE_SERVICE_CONTRACT"
export const GET_USERDATA = "GET_USERDATA"
export const SAVE_USERDATA = "SAVE_USERDATA"
export const PROCESS_MY_DOCUMENTS = "PROCESS_MY_DOCUMENTS"
export const SAVE_FEEDBACK = "SAVE_FEEDBACK"
export const SAVE_USER_LOGIN_COOKIES = "SAVE_USER_LOGIN_COOKIES"
export const LOAD_USER_LOGIN_COOKIES = "LOAD_USER_LOGIN_COOKIES"
export const GET_VOPS = "GET_VOPS"
export const SAVE_VOPS = "SAVE_VOPS"
export const DELETE_VOPS = "DELETE_VOPS"
export const GET_PUBLIC_VOPS = "GET_PUBLIC_VOPS"
export const GET_PHONE_SERVICE_CONTRACTS = "GET_PHONE_SERVICE_CONTRACTS"
export const SAVE_PHONE_SERVICE_CONTRACTS = "SAVE_PHONE_SERVICE_CONTRACTS"
export const DELETE_PHONE_SERVICE_CONTRACTS = "DELETE_PHONE_SERVICE_CONTRACTS"

//USER PROFILE
export const GET_USER_PROFILE_DATA = "GET_USER_PROFILE_DATA"
export const CREATE_USER_PROFILE_DATA = "CREATE_USER_PROFILE_DATA"
export const SAVE_USER_PROFILE_DATA = "SAVE_USER_PROFILE_DATA"


//SETTINGS
export const GET_SETTINGS = "GET_SETTINGS"
export const SET_SETTINGS = "SET_SETTINGS"
export const LAMBDA_USAGE = "LAMBDA_USAGE"

//CODES
export const GET_CODES_LIST = "GET_CODES_LIST"
export const SAVE_CODES_LIST = "SAVE_CODES_LIST"

// ORSR FUZS list
export const GENERATE_ORSR_FUZS_FILE = "GENERATE_ORSR_FUZS_FILE"

// USERS
export const GET_USERS_LIST = "GET_USERS_LIST"
export const GET_USER = "GET_USER"
export const IS_USER = "IS_USER"
export const UPDATE_USER = "UPDATE_USER"
export const DELETE_USER = "DELETE_USER"

// GIS
export const GIS_LIST_FEATURES = "GIS_LIST_FEATURES"