export const editor = {
  viewKeys: {
    FORM: 'form',
    DOCUMENT: 'document',
    DEVELOPER: 'developer',
  },
  editorViews: {
    CONFIG_BASIC: 'config.basic',
    CONFIG_ARTIFACTS: 'config.artifacts',
    CONFIG_DOCS: 'config.docs',
    CONFIG_PACKAGES: 'config.packages'
  },
};

export const DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm"
export const DATE_FORMAT = "DD.MM.YYYY"

export const PRODUCTION_API = process.env.REACT_APP_API_URI;
export const __DEV__ = false;
export const PROD_ENV = __DEV__ ? false : (process.env.REACT_APP_PROD_ENV === 'true')

export const LOCAL_API = 'http://localhost:8000';
export const SSO_HELP = 'https://sso.orsr.help';


export const COOKIE_STORAGE = '.sms-ai.cz';
export const __ORDER_PROD_MAIL__ = process.env.REACT_APP_PROD_MAIL;

export const COMPANY_LOOKUP_HOST = __DEV__ ? LOCAL_API : PRODUCTION_API;
export const PDF_GENERATION_HOST = __DEV__ ? LOCAL_API : PRODUCTION_API;
export const TS_POLITICS = __DEV__ ? "1.3.6.1.4.1.23624.10.3.50.2.0" : process.env.REACT_APP_TS_POLITICS;
export const ADMIN_API_HOST = __DEV__ ? LOCAL_API : PRODUCTION_API;

export const COMPANY_LOOKUP_LAMBDA_PATH = 'company/lookup';
export const PDF_GENERATION_LAMBDA_PATH = 'documents/generate';

export const LANDING_PAGE_URI = __DEV__ ? 'http://localhost:3000' : process.env.REACT_APP_LANDING_PAGE_URI

// export const STRIPE_KEY = __DEV__ ?
//   'pk_test_51I8VAuBCjHCZ1VpAWsUVbLWUmdhgNDPCL1ARj8WAywXu47iD7TfcyYsO3Q5dgLLChYtPayPPqdXBV1YQS6C5LStV00A4aTQvrV' : "pk_live_51I8VAuBCjHCZ1VpAHjMO1AZX8t2uaRGUNE51p9DEnEYAEF8q8Oym1zvo2leUuvWbvySERf7VBTgWYbSQr0ugcMnS00Lico567M"
export const STRIPE_KEY = "pk_live_51Ki1SzLqq7yx3BQwTgh0pNGXpZGVmdrbZrXQOJISb60RMCvA1DW1PqMGJkAEAn1GCqnQUNMcOzjDhm4Cl98UlOLt003ltqvYeY"

export const GOOGLE_TAG_MANAGER_ID = __DEV__ ? 'GTM-P8GF2P4' : process.env.REACT_APP_GTM_ID;

export const capiURI = "https://capi.orsr.help"
