import { Auth } from 'aws-amplify';
import axios from 'axios';
import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ADMIN_API_HOST, COMPANY_LOOKUP_HOST, SSO_HELP } from '../constants';
import { Codes, ORSRFileTypes, UploadsStatus } from '../features/admin/pages/Interfaces';
import { copyCognito2User2, createUserDTO } from '../utils/string';
import * as Constants from './ActionConstants';
import * as Interfaces from './ActionsInterfaces';
import { FileToUpload, FileUploadRequest } from './RequestInterfaces';
import { Blog, BlogCategory, Package } from './ResponseInterfaces';

export type StoreAction =
  //USER
  | Interfaces.SetUser
  | Interfaces.SetLanguage
  | Interfaces.SwitchLoadModal
  | Interfaces.SetUserPersons
  | Interfaces.SetRecentlyConfirmed
  //USER PROFILE DATA
  | Interfaces.GetUserProfileData
  | Interfaces.SaveUserProfileData
  | Interfaces.CreateUserProfileData
  //PACKAGES
  | Interfaces.GetPackages
  | Interfaces.DeletePackage
  | Interfaces.SavePackage
  | Interfaces.SetError
  | Interfaces.SetOpenedPackage
  | Interfaces.UpdatePackages
  //Flows
  | Interfaces.GetFlow
  | Interfaces.UpdateDocument
  | Interfaces.SetFlowId
  | Interfaces.SetNewDocument
  | Interfaces.DeleteDocument
  | Interfaces.SaveArtifact
  | Interfaces.DeleteArtifact
  | Interfaces.SetActiveArtifact
  | Interfaces.UpdateMetadata
  | Interfaces.UpdateDocumentIndexes
  | Interfaces.GetOrdersList
  | Interfaces.GetSignatureRequestsList
  | Interfaces.GetFeedbacksList
  | Interfaces.GetUserOrdersList
  | Interfaces.GetUserOrdersTemplatesList
  | Interfaces.GetBlogCategories
  | Interfaces.GetBlogsList
  | Interfaces.GetBlogTitles
  | Interfaces.GetBlog
  | Interfaces.GetBlogByLink
  | Interfaces.ClearBlogLink
  | Interfaces.ClearBlog
  | Interfaces.CreateBlog
  | Interfaces.SaveBlogCategory
  | Interfaces.SaveBlog
  | Interfaces.SaveBlogOrder
  | Interfaces.SetBlogCategories
  | Interfaces.SetBlogs
  | Interfaces.SaveCategoriesOrder
  | Interfaces.SetRepresentationActive
  | Interfaces.UpdateNote
  | Interfaces.UpdateOrderStatus
  | Interfaces.CreateFileSignRequest
  | Interfaces.DeleteFileSignRequest
  | Interfaces.GetPublicVops
  | Interfaces.GetVops
  | Interfaces.SaveVops
  | Interfaces.DeleteVops
  | Interfaces.GetPhoneServiceContracts
  | Interfaces.SavePhoneServiceContract
  | Interfaces.DeletePhoneServiceContract
  //Company
  | Interfaces.SetChosenCompany
  | Interfaces.GetCrafts
  | Interfaces.SetCrafts
  | Interfaces.UploadNewDocument
  | Interfaces.UploadFileForDocument
  | Interfaces.UploadSignedFileAdmin
  | Interfaces.DeleteSignedFileAdmin
  | Interfaces.DeleteFileForDocument
  | Interfaces.DeleteFileDocument
  //Orders
  | Interfaces.SaveOrder
  | Interfaces.SaveFeedback
  | Interfaces.SaveUserLoginCookies
  | Interfaces.LoadUserLoginCookies
  | Interfaces.UpdateOrder
  | Interfaces.GetTemplate
  | Interfaces.GetTemplates
  | Interfaces.GetTemplateUser
  | Interfaces.GetTemplateUserAdmin
  | Interfaces.GetTemplateAdmin
  | Interfaces.GetSettings
  | Interfaces.UploadSignedFileCustomer
  | Interfaces.UploadSignatureImageCustomer
  | Interfaces.DeleteSignatureImageCustomer
  | Interfaces.SetSettings
  | Interfaces.GetLambdaUsage
  | Interfaces.UpdateFormstate
  | Interfaces.AllowInvoiceOtherCompany
  | Interfaces.GenerateRefund
  | Interfaces.GetUserOrdersAmount
  // 
  | Interfaces.SaveCognitoUserProperties
  | Interfaces.AcceptPhoneServiceContract
  | Interfaces.GetUserData
  | Interfaces.SaveUserData
  | Interfaces.ProcessMyDocuments
  // Cognito Users
  | Interfaces.GetUsersList
  | Interfaces.GetUser
  | Interfaces.UpdateUser
  | Interfaces.DeleteUser;

export function SaveUser(user: any): Interfaces.SetUser {
  return {
    type: Constants.SET_USER,
    response: user,
  };
}


export const SetLanguage = (language: string) => {
  return {
    type: Constants.SET_LANGUAGE,
    response: language
  };
};

export function SetSwitchLoginModal(value: string): Interfaces.SwitchLoadModal {
  return {
    type: Constants.SET_SWITCH_LOGIN_MODAL,
    response: value
  };
};

export const SetUser: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (recentlyConfirmed: boolean) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await Auth.currentAuthenticatedUser();
      return dispatch(SaveUser(result));
    } catch (e: any) {
      return dispatch(SaveUser(undefined));
    }
  };
};

export const SetUserAfterUpdate: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (recentlyConfirmed: boolean) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await Auth.currentAuthenticatedUser({ bypassCache: true });
      return dispatch(SaveUser(result));
    } catch (e: any) {
      return dispatch(SaveUser(undefined));
    }
  };
};

//USER PROFILE DATA
export function CreateUserProfileData(response: any): Interfaces.CreateUserProfileData {
  return {
    type: Constants.CREATE_USER_PROFILE_DATA,
    response: response,
  };
}

export const CreateUserProfileDataAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (userProfileData: any, createUserParams: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(ADMIN_API_HOST + `/user-profile-data/create`, { userProfileData, createUserParams })
        .then((response) => response.data);
      return dispatch(CreateUserProfileData(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function GetUserProfileData(response: any): Interfaces.GetUserProfileData {
  return {
    type: Constants.GET_USER_PROFILE_DATA,
    response: response,
  };
}

export const GetUserProfileDataAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = () => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .get(ADMIN_API_HOST + `/user-profile-data/get`)
        .then((response) => response.data);
      return dispatch(GetUserProfileData(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function SaveUserProfileData(response: any): Interfaces.SaveUserProfileData {
  return {
    type: Constants.SAVE_USER_PROFILE_DATA,
    response: response,
  };
}

export const SaveUserProfileDataAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (userProfileData: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(ADMIN_API_HOST + `/user-profile-data/update`, { userData: userProfileData })
        .then((response) => response.data);
      return dispatch(SaveUserProfileData(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function SetRecentlyConfirmed(response: any): Interfaces.SetRecentlyConfirmed {
  return {
    type: Constants.RECENTLY_CONFIRMED,
    response: response,
  };
}

export function GetPublicVops(response: any): Interfaces.GetPublicVops {
  return {
    type: Constants.GET_PUBLIC_VOPS,
    response: response,
  };
}

export const GetPublicVopsAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = () => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .get(ADMIN_API_HOST + `/admin/vops/public-list`)
        .then((response) => response.data);
      return dispatch(GetPublicVops(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function GetVops(response: any): Interfaces.GetVops {
  return {
    type: Constants.GET_VOPS,
    response: response,
  };
}

export const GetVopsAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = () => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .get(ADMIN_API_HOST + `/admin/vops/list`)
        .then((response) => response.data);
      return dispatch(GetVops(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function SaveVops(response: any): Interfaces.SaveVops {
  return {
    type: Constants.SAVE_VOPS,
    response: response
  };
}

export function ClearSaveVops(): Interfaces.SaveVops {
  return {
    type: Constants.SAVE_VOPS,
    response: undefined
  };
}

export const SaveVopsAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (data: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(ADMIN_API_HOST + `/admin/vops/create`, { data })
        .then((response) => response.data);
      return dispatch(SaveVops(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function DeleteVops(response: any): Interfaces.DeleteVops {
  return {
    type: Constants.DELETE_VOPS,
    response: response
  };
}

export const DeleteVopsAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (id: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .delete(ADMIN_API_HOST + `/admin/vops/${id}`)
        .then((response) => response.data);
      return dispatch(DeleteVops(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function GetPhoneServiceContracts(response: any): Interfaces.GetPhoneServiceContracts {
  return {
    type: Constants.GET_PHONE_SERVICE_CONTRACTS,
    response: response,
  };
}

export const GetPhoneServiceContractsAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = () => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .get(ADMIN_API_HOST + `/admin/phone-service-contract/list`)
        .then((response) => response.data);
      return dispatch(GetPhoneServiceContracts(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function SavePhoneServiceContract(response: any): Interfaces.SavePhoneServiceContract {
  return {
    type: Constants.SAVE_PHONE_SERVICE_CONTRACTS,
    response: response
  };
}

export function ClearSavePhoneServiceContract(): Interfaces.SavePhoneServiceContract {
  return {
    type: Constants.SAVE_PHONE_SERVICE_CONTRACTS,
    response: undefined
  };
}

export const SavePhoneServiceContractAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (data: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(ADMIN_API_HOST + `/admin/phone-service-contract/create`, { data })
        .then((response) => response.data);
      return dispatch(SavePhoneServiceContract(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function DeletePhoneServiceContract(response: any): Interfaces.DeletePhoneServiceContract {
  return {
    type: Constants.DELETE_PHONE_SERVICE_CONTRACTS,
    response: response
  };
}

export const DeletePhoneServiceContractAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (id: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .delete(ADMIN_API_HOST + `/admin/phone-service-contract/${id}`)
        .then((response) => response.data);
      return dispatch(DeletePhoneServiceContract(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function GetPackages(response: any): Interfaces.GetPackages {
  return {
    type: Constants.GET_PACKAGES,
    response: response,
  };
}

export const GetPackagesAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (flowId: string) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .get(ADMIN_API_HOST + `/admin/package/private-list/${flowId}`)
        .then((response) => response.data);
      return dispatch(GetPackages(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function SaveUserLoginCookies(response: any): Interfaces.SaveUserLoginCookies {
  return {
    type: Constants.SAVE_USER_LOGIN_COOKIES,
    response: response
  };
}

export const SaveUserLoginCookiesAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (data: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(SSO_HELP + `/shared-login-data/save`, { data })
        .then((response) => response.data);
      return dispatch(SaveUserLoginCookies(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function ClearUserLoginJWTToken(): Interfaces.SaveUserLoginCookies {
  return {
    type: Constants.SAVE_USER_LOGIN_COOKIES,
    response: undefined
  };
}

export function LoadUserLoginCookies(response: any): Interfaces.LoadUserLoginCookies {
  return {
    type: Constants.LOAD_USER_LOGIN_COOKIES,
    response: response
  };
}

export const LoadUserLoginCookiesAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (token: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(SSO_HELP + `/shared-login-data/load`, { token })
        .then((response) => response.data);
      return dispatch(LoadUserLoginCookies(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function GetFlow(response: any): Interfaces.GetFlow {
  return {
    type: Constants.GET_FLOW,
    response: response,
  };
}

export function SetAllowInvoiceOtherCompany(response: any): Interfaces.AllowInvoiceOtherCompany {
  return {
    type: Constants.ALLOW_INVOICE_OTHER_COMPANY,
    response: response
  };
}

export function SetActiveArtifact(response: any): Interfaces.SetActiveArtifact {
  return {
    type: Constants.SET_ACTIVE_ARTIFACT,
    response: response,
  };
}

export const GetFlowAction: ActionCreator<ThunkAction<Promise<Action>, any, void>> =
  (flowId: string) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        let result = await axios
          .post(ADMIN_API_HOST + `/admin/flow/get`, { id: flowId })
          .then((response) => response.data);
        return dispatch(GetFlow(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };

export function UpdateMetadata(response: any): Interfaces.UpdateMetadata {
  return {
    type: Constants.UPDATE_METADATA,
    response: response,
  };
}

export const UpdateMetadataAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (flowId: string, metadata: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(ADMIN_API_HOST + `/admin/flow/update-metadata/${flowId}`, metadata)
        .then((response) => response.data);
      return dispatch(UpdateMetadata(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function UpdateDocumentIndexes(
  response: any,
): Interfaces.UpdateDocumentIndexes {
  return {
    type: Constants.UPDATE_DOCUMENT_INDEXES,
    response: response,
  };
}

export const UpdateDocumentIndexesAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (flowId: string, documents: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(ADMIN_API_HOST + `/admin/flow/update-indexes/${flowId}`, documents)
        .then((response) => response.data);
      return dispatch(UpdateDocumentIndexes(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function SetFlowId(response: any): Interfaces.SetFlowId {
  return {
    type: Constants.SET_FLOW_ID,
    response: response,
  };
}

export function SetNewDocument(response: any): Interfaces.SetNewDocument {
  return {
    type: Constants.SET_NEW_DOCUMENT,
    response: response,
  };
}

export function UpdateDocument(response: any): Interfaces.UpdateDocument {
  return {
    type: Constants.UPDATE_DOCUMENT,
    response: response,
  };
}

export const UpdateDocumentAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (flowId: string, documentId: string, document: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(
          ADMIN_API_HOST + `/admin/flow/update-one/${flowId}/${documentId}`,
          document,
        )
        .then((response) => response.data);
      return dispatch(UpdateDocument(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function GetOrdersList(response: any): Interfaces.GetOrdersList {
  return {
    type: Constants.GET_ORDERS_LIST,
    response: response,
  };
}
/* ? filter should be used */
export const GetOrdersListAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (
  page: number,
  size: number,
  orderBy: string,
  orderDir: string,
  filters: string,
) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        let result = await axios
          .get(
            ADMIN_API_HOST +
            `/order/all/${page}&${size}&${orderBy}&${orderDir}/${filters}`,
          )
          .then((response) => response.data);
        return dispatch(GetOrdersList(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };


export function GetFeedbacksList(response: any): Interfaces.GetFeedbacksList {
  return {
    type: Constants.GET_FEEDBACKS_LIST,
    response: response,
  };
}
/* ? filter should be used */
export const GetFeedbacksListAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (
  page: number,
  size: number,
  orderBy: string,
  orderDir: string,
  filters: string,
) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        let result = await axios
          .get(
            ADMIN_API_HOST +
            `/feedback/all/${page}&${size}&${orderBy}&${orderDir}/${filters}`,
          )
          .then((response) => response.data);
        return dispatch(GetFeedbacksList(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };


export function GetUserOrdersList(response: any): Interfaces.GetUserOrdersList {
  return {
    type: Constants.GET_USER_ORDERS_LIST,
    response: response,
  };
}
/* ? filter should be used */
export const GetUserOrdersListAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (
  page: number,
  size: number,
  orderBy: string,
  orderDir: string,
  filters: string,
) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        let result = await axios
          .get(
            ADMIN_API_HOST +
            `/userorder/all/${page}&${size}&${orderBy}&${orderDir}/${filters}`,
          )
          .then((response) => response.data);
        return dispatch(GetOrdersList(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };


export function GetUserSignatureRequestsList(response: any): Interfaces.GetSignatureRequestsList {
  return {
    type: Constants.GET_SIGNATURE_REQUESTS,
    response: response,
  };
}
/* ? filter should be used */
export const GetUserSignatureRequestsListAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (
  page: number,
  size: number
) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        let result = await axios
          .get(
            ADMIN_API_HOST +
            `/sign-file-requirement/get-my/${page}&${size}`,
          )
          .then((response) => response.data);
        return dispatch(GetUserSignatureRequestsList(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };


export function GetUserOrdersTemplatesList(response: any): Interfaces.GetUserOrdersTemplatesList {
  return {
    type: Constants.GET_USER_ORDERS_TEMPLATES_LIST,
    response: response,
  };
}
/* ? filter should be used */
export const GetUserOrdersTemplatesListAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (
  page: number,
  size: number,
  orderBy: string,
  orderDir: string,
  filters: string,
) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        let result = await axios
          .get(
            ADMIN_API_HOST +
            `/userorder/allOrdersTemplates/${page}&${size}&${orderBy}&${orderDir}/${filters}`,
          )
          .then((response) => response.data);
        return dispatch(GetOrdersList(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };


export function GetFormsList(response: any): Interfaces.GetTemplates {
  return {
    type: Constants.GET_TEMPLATES,
    response: response,
  };
}
/* ? filter should be used */
export const GetFormsListAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (
  page: number,
  size: number,
  orderBy: string,
  orderDir: string,
  filters: string,
) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        let result = await axios
          .get(
            ADMIN_API_HOST +
            `/template/get-templates/${page}&${size}&${orderBy}&${orderDir}/${filters}`,
          )
          .then((response) => response.data);
        return dispatch(GetFormsList(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };

export function UploadFileForDocument(
  response: any,
): Interfaces.UploadFileForDocument {
  return {
    type: Constants.UPLOAD_FILE_FOR_DOCUMENT,
    response: response,
  };
}

export const UploadFileForDocumentAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (orderId: string, documentIndex: string, uploadFiles: FileToUpload[]) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let uplRequest: FileUploadRequest = {
        files: uploadFiles,
      };
      let result = await axios
        .post(
          ADMIN_API_HOST + `/order/upload/${orderId}/${documentIndex}`,
          uplRequest,
        )
        .then((response) => response.data);
      return dispatch(UploadFileForDocument(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function UploadSignedFileAdmin(
  response: any,
): Interfaces.UploadSignedFileAdmin {
  return {
    type: Constants.UPLOAD_SIGNED_FILE_ADMIN,
    response: response,
  };
}

export const UploadSignedFileAdminAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (orderId: string, documentIndex: string, fileData: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let uplRequest: any = {
        fileData
      };
      let result = await axios
        .post(
          ADMIN_API_HOST + `/order/upload-signed-file-admin/${orderId}/${documentIndex}`,
          uplRequest
        )
        .then((response) => response.data);
      return dispatch(UploadSignedFileAdmin(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function DeleteSignedFileAdmin(
  response: any,
): Interfaces.DeleteSignedFileAdmin {
  return {
    type: Constants.DELETE_SIGNED_FILE_ADMIN,
    response: response,
  };
}

export const DeleteSignedFileAdminAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (orderId: string, documentIndex: string, fileData: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .delete(
          ADMIN_API_HOST + `/order/delete-signed-file-admin/${orderId}/${documentIndex}`
        )
        .then((response) => response.data);
      return dispatch(DeleteSignedFileAdmin(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function UploadSignedFileCustomer(
  response: any,
): Interfaces.UploadSignedFileAdmin {
  return {
    type: Constants.UPLOAD_SIGNED_FILE_ADMIN,
    response: response,
  };
}

export const UploadSignedFileCustomerAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (orderId: string, documentIndex: string, fileData: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let uplRequest: any = {
        fileData
      };
      let result = await axios
        .post(
          ADMIN_API_HOST + `/userorder/upload-signed-file/${orderId}/${documentIndex}`,
          uplRequest
        )
        .then((response) => response.data);
      return dispatch(UploadSignedFileAdmin(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function UploadSignatureImageCustomer(
  response: any,
): Interfaces.UploadSignatureImageCustomer {
  return {
    type: Constants.UPLOAD_SIGNATURE_IMAGE_CUSTOMER,
    response: response
  };
}

export const UploadSignatureImageCustomerAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (orderId: string, documentIndex: string, signature: any, signatureImage: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let uplRequest: any = {
        signatureImage,
        signature
      };
      let result = await axios
        .post(
          ADMIN_API_HOST + `/userorder/upload-signature-image/${orderId}/${documentIndex}`,
          uplRequest
        )
        .then((response) => response.data);
      return dispatch(UploadSignatureImageCustomer(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function DeleteSignatureImageCustomer(
  response: any,
): Interfaces.DeleteSignatureImageCustomer {
  return {
    type: Constants.DELETE_SIGNATURE_IMAGE_CUSTOMER,
    response: response
  };
}

export const DeleteSignatureImageCustomerAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (orderId: string, documentIndex: string, signature: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let uplRequest: any = {
        signature
      };
      let result = await axios
        .post(
          ADMIN_API_HOST + `/userorder/delete-signature-image/${orderId}/${documentIndex}`,
          uplRequest
        )
        .then((response) => response.data);
      return dispatch(DeleteSignatureImageCustomer(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function UpdateOrderFormstate(
  response: any,
): Interfaces.UpdateFormstate {
  return {
    type: Constants.UPDATE_FORMSTATE,
    response: response
  };
}

export const UpdateOrderFormstateAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (orderId: string, formState: any, selectedSteps: any, steps: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(
          ADMIN_API_HOST + `/order/regenerate-order/${orderId}`, { formState, selectedSteps, steps },
        )
        .then((response) => response.data);
      return dispatch(UpdateOrderFormstate(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function DeleteFileForDocument(
  response: any,
): Interfaces.DeleteFileForDocument {
  return {
    type: Constants.DELETE_FILE_FOR_DOCUMENT,
    response: response,
  };
}

export const DeleteFileForDocumentAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (orderId: string, documentIndex: string, uploadedFileIndex: number) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .delete(
          ADMIN_API_HOST +
          `/order/delete-file/${orderId}/${documentIndex}/${uploadedFileIndex}`,
        )
        .then((response) => response.data);
      return dispatch(DeleteFileForDocument(result));
    } catch (e: any) {
      return dispatch(SetError(''));
    }
  };
};

export function DeleteFileDocument(
  response: any,
): Interfaces.DeleteFileDocument {
  return {
    type: Constants.DELETE_FILE_DOCUMENT,
    response: response,
  };
}

export const DeleteFileDocumentAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (orderId: string, documentIndex: string) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .delete(
          ADMIN_API_HOST +
          `/order/delete-file/${orderId}/${documentIndex}`,
        )
        .then((response) => response.data);
      return dispatch(DeleteFileDocument(result));
    } catch (e: any) {
      return dispatch(SetError(''));
    }
  };
};

export function UploadNewDocument(
  response: any,
): Interfaces.UploadNewDocument {
  return {
    type: Constants.UPLOAD_NEW_DOCUMENT,
    response: response,
  };
}

export const UploadNewDocumentAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (orderId: string, uploadFiles: FileToUpload[]) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let uplRequest: FileUploadRequest = {
        files: uploadFiles,
      };
      let result = await axios
        .post(
          ADMIN_API_HOST + `/order/upload/${orderId}`,
          uplRequest,
        )
        .then((response) => response.data);
      return dispatch(UploadFileForDocument(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function GenerateRefund(
  response: any,
): Interfaces.GenerateRefund {
  return {
    type: Constants.GENERATE_REFUND,
    response: response,
  };
}

export const GenerateRefundAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (orderId: string, refundForm) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(
          ADMIN_API_HOST + `/order/refund/${orderId}`,
          refundForm,
        )
        .then((response) => response.data);
      return dispatch(GenerateRefund(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function CreateFileSignRequest(
  response: any,
): Interfaces.CreateFileSignRequest {
  return {
    type: Constants.CREATE_FILE_SIGN_REQUEST,
    response: response
  };
}

export const CreateFileSignRequestAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (signFileRequestData) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(
          ADMIN_API_HOST + `/sign-file-requirement/save`,
          signFileRequestData
        )
        .then((response) => response.data);
      return dispatch(CreateFileSignRequest(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function DeleteFileSignRequest(
  response: any,
): Interfaces.DeleteFileSignRequest {
  return {
    type: Constants.DELETE_FILE_SIGN_REQUEST,
    response: response
  };
}

export const DeleteFileSignRequestAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (signFileRequestData) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(
          ADMIN_API_HOST + `/sign-file-requirement/delete`,
          signFileRequestData
        )
        .then((response) => response.data);
      return dispatch(DeleteFileSignRequest(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function UpdateUploadsStatusForDocument(
  response: any,
): Interfaces.UpdateUploadsStatusForDocument {
  return {
    type: Constants.UPDATE_UPLOADS_STATUS_FOR_DOCUMENT,
    response: response,
  };
}
export const UpdateUploadsStatusForDocumentAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (orderId: string, documentIndex: string, uploadsStatus: UploadsStatus) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .patch(
          ADMIN_API_HOST +
          `/order/update-status/${orderId}/${documentIndex}/${uploadsStatus}`,
        )
        .then((response) => response.data);
      return dispatch(UpdateUploadsStatusForDocument(result));
    } catch (e: any) {
      return dispatch(SetError(''));
    }
  };
};

export function SaveArtifact(response: any): Interfaces.SaveArtifact {
  return {
    type: Constants.SAVE_ARTIFACT,
    response: response,
  };
}

export const SaveArtifactAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (flowId: string, artifactKey: string, artifact: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(
          ADMIN_API_HOST + `/admin/flow/update-artifact/${flowId}/${artifactKey}`,
          artifact,
        )
        .then((response) => response.data);
      return dispatch(SaveArtifact(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function UpdateNote(): Interfaces.UpdateNote {
  return {
    type: Constants.UPDATE_NOTE
  };
}

export const UpdateNoteAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (note: string, orderId: string) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(
          ADMIN_API_HOST + `/order/edit-note/${orderId}`,
          { note }
        )
        .then((response) => response.data);
      return dispatch(UpdateNote());
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function UpdateOrderStatus(): Interfaces.UpdateOrderStatus {
  return {
    type: Constants.UPDATE_ORDER_STATUS
  };
}

export const UpdateOrderStatusAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (orderId: string, orderStatus: any, changedState: boolean, filesToAttach: string[]) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      await axios.post(
        ADMIN_API_HOST + `/order/update-order-status`,
        {
          orderId,
          orderStatus,
          changedState,
          filesToAttach
        }
      )
        .then((response) => response.data);
      return dispatch(UpdateOrderStatus());
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};


export function DeleteArtifact(response: any): Interfaces.DeleteArtifact {
  return {
    type: Constants.DELETE_ARTIFACT,
    response: response,
  };
}

export const DeleteArtifactAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (flowId: string, artifactKey: string) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .delete(
          ADMIN_API_HOST + `/admin/flow/delete-artifact/${flowId}/${artifactKey}`,
        )
        .then((response) => response.data);
      return dispatch(DeleteArtifact(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function DeleteDocument(response: any): Interfaces.DeleteDocument {
  return {
    type: Constants.DELETE_DOCUMENT,
    response: response,
  };
}

export const DeleteDocumentAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (flowId: string, documentId: string) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .delete(ADMIN_API_HOST + `/admin/flow/delete-one/${flowId}/${documentId}`)
        .then((response) => response.data);
      return dispatch(DeleteDocument(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function SetOpenedPackage(response: any): Interfaces.SetOpenedPackage {
  return {
    type: Constants.SET_OPENED_PACKAGE,
    response: response,
  };
}

export const SavePackageAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (pack: Package) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(
        SavePackage(
          await axios
            .post(ADMIN_API_HOST + `/admin/package/create`, pack)
            .then((response) => response.data),
        ),
      );
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function SavePackage(response: any): Interfaces.SavePackage {
  return {
    type: Constants.SAVE_PACKAGE,
    response: response,
  };
}

export const UpdatePackagesAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (packs: Package[]) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(
        UpdatePackages(
          await axios
            .post(ADMIN_API_HOST + `/admin/package/update`, packs)
            .then((response) => response.data),
        ),
      );
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function UpdatePackages(response: any): Interfaces.UpdatePackages {
  return {
    type: Constants.UPDATE_PACKAGES,
    response: response,
  };
}

export function SetError(response: any): Interfaces.SetError {
  return {
    type: Constants.SET_ERROR,
    response: response,
  };
}

export const DeletePackageAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (id: string) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(
        DeletePackage(
          await axios
            .delete(ADMIN_API_HOST + `/admin/package/${id}`)
            .then((response) => response.data),
        ),
      );
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function DeletePackage(response: any): Interfaces.DeletePackage {
  return {
    type: Constants.DELETE_PACKAGE,
    response: response,
  };
}

// BLOGS - CATEGORIES
export function GetBlogCategories(response: any): Interfaces.GetBlogCategories {
  return {
    type: Constants.GET_BLOG_CATEGORIES,
    response: response,
  };
}

export const GetBlogCategoriesAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (flowId: string) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .get(ADMIN_API_HOST + `/blogcategory/list`)
        .then((response) => response.data);
      return dispatch(GetBlogCategories(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function GetBlogsList(response: any): Interfaces.GetBlogsList {
  return {
    type: Constants.GET_BLOGS_LIST,
    response: response,
  };
}

export const GetBlogsListAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (blogs: any[]) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .get(ADMIN_API_HOST + `/blog/list/titles`)
        .then((response) => response.data);
      return dispatch(GetBlogsList(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function GetBlog(response: any): Interfaces.GetBlog {
  return {
    type: Constants.GET_BLOG,
    response: response,
  };
}

export const GetBlogAction: ActionCreator<ThunkAction<Promise<Action>, any, void>> =
  (id: string[]) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        let result = await axios
          .get(ADMIN_API_HOST + `/blog/${id}`)
          .then((response) => response.data);
        return dispatch(GetBlog(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };

export function GetBlogByLink(response: any): Interfaces.GetBlogByLink {
  return {
    type: Constants.GET_BLOG_BY_LINK,
    response: response,
  };
}

export function ClearBlogLink(): Interfaces.ClearBlogLink {
  return {
    type: Constants.CLEAR_BLOG_LINK
  };
}

export const GetBlogByLinkAction: ActionCreator<ThunkAction<Promise<Action>, any, void>> =
  (link: string) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        let result = await axios
          .get(ADMIN_API_HOST + `/blog/by-link/${link}`)
          .then((response) => response.data);
        return dispatch(GetBlogByLink(result));
      } catch (e: any) {
        return dispatch(ClearBlogLink());
      }
    };
  };

export function ClearBlog(): Interfaces.ClearBlog {
  return {
    type: Constants.CLEAR_BLOG
  };
}

export function CreateBlog(blog: any): Interfaces.CreateBlog {
  return {
    type: Constants.CREATE_BLOG,
    blog: blog
  };
}

export const CreateBlogAction: ActionCreator<ThunkAction<Promise<Action>, any, void>> =
  (blog: any) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      return dispatch(CreateBlog(blog));
    };
  };


export function GetBlogTitles(response: any): Interfaces.GetBlogTitles {
  return {
    type: Constants.GET_BLOG_TITLES,
    response: response,
  };
}


export const GetBlogTitlesAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (blogTitles: any[]) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .get(ADMIN_API_HOST + `/blog/list/titles`)
        .then((response) => response.data);
      return dispatch(GetBlogTitles(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function GetBlogDescription(response: any): Interfaces.GetBlogDescription {
  return {
    type: Constants.GET_BLOG_DESCRIPTION,
    response: response
  }
}
// not needed in designer, just preparation, used in landing page
export const GetBlogDescriptionAction: ActionCreator<ThunkAction<Promise<Action>, any, void>> = (blogDescription: any[]) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios.get(ADMIN_API_HOST + `/blog/list/description`).then(response => response.data)
      return dispatch(GetBlogDescription(result))
    } catch (e: any) {
      return dispatch(SetError(e))
    }
  }
}

export function SetBlogCategories(response: any): Interfaces.SetBlogCategories {
  return {
    type: Constants.SET_BLOG_CATEGORIES,
    response: response,
  };
}

export function SaveBlogCategory(response: any): Interfaces.SaveBlogCategory {
  return {
    type: Constants.SAVE_BLOG_CATEGORY,
    response: response,
  };
}

export const SaveBlogCategoryAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (category: BlogCategory) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .put(ADMIN_API_HOST + `/blogcategory/update`, category)
        .then((response) => response.data);
      return dispatch(SaveBlogCategory(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function DeleteBlogCategory(response: any): Interfaces.DeleteBlogCategory {
  return {
    type: Constants.DELETE_BLOG_CATEGORY,
    response: response,
  };
}

export const DeleteBlogCategoryAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (id: string) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .delete(ADMIN_API_HOST + `/blogcategory/${id}`)
        .then((response) => response.data);
      return dispatch(DeleteBlogCategory(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function SetBlogs(response: any): Interfaces.SetBlogs {
  return {
    type: Constants.SET_BLOGS,
    response: response,
  };
}

export function SaveBlog(response: any): Interfaces.SaveBlog {
  return {
    type: Constants.SAVE_BLOG,
    response: response,
  };
}

export const SaveBlogAction: ActionCreator<ThunkAction<Promise<Action>, any, void>> =
  (blog: Blog) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        let result = await axios
          .post(ADMIN_API_HOST + `/blog/update`, blog)
          .then((response) => response.data);
        return dispatch(SaveBlog(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };

export function SaveBlogOrder(response: any): Interfaces.SaveBlogOrder {
  return {
    type: Constants.SAVE_BLOG_ORDER,
    response: response
  }
}

export const SaveBlogOrderAction: ActionCreator<ThunkAction<Promise<Action>, any, void>> = (blogOrder: { id: string, order: number }[]) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios.post(ADMIN_API_HOST + `/blog/update/order`,
        blogOrder).then(response => response.data)
      return dispatch(SaveBlogOrder(result))
    } catch (e: any) {
      return dispatch(SetError(e))
    }
  }
}

export function SaveCategoriesOrder(response: any): Interfaces.SaveCategoriesOrder {
  return {
    type: Constants.SAVE_CATEGORIES_ORDER,
    response: response
  }
}

export const SaveCategoriesOrderAction: ActionCreator<ThunkAction<Promise<Action>, any, void>> = (categoriesOrder: { id: string, order: number }[]) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios.post(ADMIN_API_HOST + `/blogcategory/update/order`,
        categoriesOrder).then(response => response.data)
      return dispatch(SaveCategoriesOrder(result))
    } catch (e: any) {
      return dispatch(SetError(e))
    }
  }
}

export function DeleteBlog(response: any): Interfaces.DeleteBlog {
  return {
    type: Constants.DELETE_BLOG,
    response: response,
  };
}

export const DeleteBlogAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (id: string) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .delete(ADMIN_API_HOST + `/blog/${id}`)
        .then((response) => response.data);
      return dispatch(DeleteBlog(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function SetChosenCompany(response: any): Interfaces.SetChosenCompany {
  return {
    type: Constants.SET_CHOSEN_COMPANY,
    response: response,
  };
}

export const SetChosenCompanyAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (company: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(SetChosenCompany(company));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function SetUserPersons(response: any): Interfaces.SetUserPersons {
  return {
    type: Constants.SET_USER_PERSONS,
    response: response
  };
}

export const SetUserPersonsAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (persons: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(SetUserPersons(persons));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function GetCrafts(response: any): Interfaces.GetCrafts {
  return {
    type: Constants.GET_CRAFTS,
    response: response,
  };
}

export function SetRepresentationActive(response: any): Interfaces.SetRepresentationActive {
  return {
    type: Constants.SET_REPRESENTATION_ACTIVE,
    response: response,
  };
}

export const GetCraftsAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = () => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .get(ADMIN_API_HOST + `/admin/flow/get-crafts`)
        .then((response) => response.data);
      return dispatch(GetCrafts(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function SetCrafts(response: any): Interfaces.SetCrafts {
  return {
    type: Constants.SET_CRAFTS,
    response: response,
  };
}

export const SetCraftsAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (crafts: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let result = await axios
        .post(ADMIN_API_HOST + `/admin/flow/set-crafts`, crafts)
        .then((response) => response.data);
      return dispatch(SetCrafts(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function SaveOrder(response: any): Interfaces.SaveOrder {
  return {
    type: Constants.SAVE_ORDER,
    response: response,
  };
}

export const SaveOrderAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (order: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(
        SaveOrder(
          await axios
            .post(ADMIN_API_HOST + `/template/create`, order)
            .then((response) => response.data),
        ),
      );
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function UpdateOrder(response: any): Interfaces.UpdateOrder {
  return {
    type: Constants.UPDATE_ORDER,
    response: response,
  };
}

export const UpdateOrderAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (order: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(
        UpdateOrder(
          await axios
            .post(ADMIN_API_HOST + `/template/update`, order)
            .then((response) => response.data),
        ),
      );
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function SaveFeedback(response: any): Interfaces.SaveFeedback {
  return {
    type: Constants.SAVE_FEEDBACK,
    response: response,
  };
}

export const SaveFeedbackAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (marketingId: string, rating: number, feedbackMessage: string) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(
        SaveFeedback(
          await axios
            .post(ADMIN_API_HOST + '/feedback/create/', { marketingId, rating, feedbackMessage })
            .then((response) => response.data),
        ),
      );
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};

export function GetTemplate(response: any): Interfaces.GetTemplate {
  return {
    type: Constants.GET_TEMPLATE,
    response: response,
  };
}

export const GetTemplateAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (id: any, password: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(
        GetTemplate(
          await axios
            .post(ADMIN_API_HOST + `/template/get-template`, { password, id })
            .then((response) => response.data),
        ),
      );
    } catch (e: any) {
      return dispatch(SetError({ message: "Nesprávne ID formuláru alebo heslo" }));
    }
  };
};

export function GetTemplateUser(response: any): Interfaces.GetTemplateUser {
  return {
    type: Constants.GET_TEMPLATE_USER,
    response: response,
  };
}

export const GetTemplateUserAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (id: any, username: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(
        GetTemplate(
          await axios
            .post(ADMIN_API_HOST + `/template/get-template-user`, { username, id })
            .then((response) => response.data),
        ),
      );
    } catch (e: any) {
      return dispatch(SetError({ message: "Nesprávne ID formuláru alebo meno" }));
    }
  };
};

export function GetTemplateUserAdmin(response: any): Interfaces.GetTemplateUserAdmin {
  return {
    type: Constants.GET_TEMPLATE_USER_ADMIN,
    response: response,
  };
}

export const GetTemplateUserAdminAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (id: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(
        GetTemplate(
          await axios
            .get(ADMIN_API_HOST + `/template/get-template-admin/${id}`)
            .then((response) => response.data),
        ),
      );
    } catch (e: any) {
      return dispatch(SetError({ message: "Nesprávne ID formuláru" }));
    }
  };
};

export function GetTemplateAdmin(response: any): Interfaces.GetTemplateAdmin {
  return {
    type: Constants.GET_TEMPLATE_ADMIN,
    response: response,
  };
}

export const GetTemplateAdminAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (id: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(
        GetTemplateAdmin(
          await axios
            .get(ADMIN_API_HOST + `/order/details/${id}`)
            .then((response) => response.data),
        ),
      );
    } catch (e: any) {
      return dispatch(SetError({ message: "Nesprávne ID" }));
    }
  };
};

export function SetSettings(response: any): Interfaces.SetSettings {
  return {
    type: Constants.SET_SETTINGS,
    response: response,
  };
}

export const SetSettingsAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (settings: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(
        SetSettings(
          await axios
            .post(ADMIN_API_HOST + `/settings/update`, { settings })
            .then((response) => response.data),
        ),
      );
    } catch (e: any) {
      return dispatch(SetError({ message: "Nastavenia neboli uložené" }));
    }
  };
};

export function GetSettings(response: any): Interfaces.GetSettings {
  return {
    type: Constants.GET_SETTINGS,
    response: response,
  };
}

export const GetSettingsAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = () => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(
        GetSettings(
          await axios
            .get(ADMIN_API_HOST + `/settings/get-maintenance`)
            .then((response) => response.data),
        ),
      );
    } catch (e: any) {
      return e;
    }
  };
};

export function GetLambdaUsage(response: any): Interfaces.GetLambdaUsage {
  return {
    type: Constants.LAMBDA_USAGE,
    response: response
  };
}

export const GetLambdaUsageAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = () => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(
        GetLambdaUsage(
          await axios
            .get(ADMIN_API_HOST + `/mydocuments/lambda-count`)
            .then((response) => response.data),
        ),
      );
    } catch (e: any) {
      return e;
    }
  };
};

export function GetCodesList(response: any): Interfaces.GetCodesList {
  return {
    type: Constants.GET_CODES_LIST,
    response: response,
  };
}

/* ? filter should be used */
export const GetCodesListAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (
  page: number,
  size: number,
  orderBy: string,
  orderDir: string,
  filters: string,
) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        let result = await axios
          .get(
            ADMIN_API_HOST +
            `/code/all/${page}&${size}&${orderBy}&${orderDir}/${filters}`,
          )
          .then((response) => response.data);
        return dispatch(GetCodesList(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };

export function SaveCodes(response: any): Interfaces.SaveCodesList {
  return {
    type: Constants.SAVE_CODES_LIST,
    response: response,
  };
}

export const SaveCodesAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (codeType: Codes,
  codes: any) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        return dispatch(
          SaveCodes(
            await axios
              .post(ADMIN_API_HOST + `/code`,
                {
                  type: codeType,
                  codes: codes
                })
              .then((response) => response.data),
          ),
        );
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };

export function GenerateORSRFile(response: any): Interfaces.GenerateORSRFUZSFile {
  return {
    type: Constants.GENERATE_ORSR_FUZS_FILE,
    response: response,
  };
}

export const GenerateORSRFileAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>> = (orderId: any, fileType: ORSRFileTypes) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        return dispatch(
          GenerateORSRFile(
            await axios
              .post(`${COMPANY_LOOKUP_HOST}/order/generate-file-orsr/${fileType}/${orderId}`, '')
              .then((response) => response.data)
          )
        )
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };

export function GetUserOrdersAmount(response: any): Interfaces.GetUserOrdersAmount {
  return {
    type: Constants.GET_USER_ORDERS_AMOUNT,
    response: response,
  };
}

export const GetUserOrdersAmountAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>> = (orderId: any) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        return dispatch(
          GetUserOrdersAmount(
            await axios
              .get(`${COMPANY_LOOKUP_HOST}/order/users-orders-count`)
              .then((response) => response.data)
          )
        )
      } catch (e: any) {
        return dispatch(GetUserOrdersAmount(0));
      }
    };
  };


export function SaveCognitoUserProperties(response: any): Interfaces.SaveCognitoUserProperties {
  return {
    type: Constants.SAVE_COGNITO_USER_PROPERTIES,
    response: response,
  };
}

export const SaveCognitoUserPropertiesAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>> = (userProperties: any) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        return dispatch(
          SaveCognitoUserProperties(
            await axios
              .post(`${COMPANY_LOOKUP_HOST}/cognito/post`, userProperties)
              .then((response) => response.data)
          )
        )
      } catch (e: any) {
        return dispatch(SetError({ message: 'Atribúty cognito užívateľa neboli uložené.' }));
      }
    };
  };

export function AcceptPhoneServiceContract(response: any): Interfaces.AcceptPhoneServiceContract {
  return {
    type: Constants.ACCEPT_PHONE_SERVICE_CONTRACT,
    response: response,
  };
}

export const AcceptPhoneServiceContractAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>> = (userProperties: any) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        return dispatch(
          AcceptPhoneServiceContract(
            await axios
              .post(`${COMPANY_LOOKUP_HOST}/cognito/accept`, userProperties)
              .then((response) => response.data)
          )
        )
      } catch (e: any) {
        return dispatch(SetError({ message: 'Súhlas nebol uložený' }));
      }
    };
  };


export function GetUserdata(response: any): Interfaces.GetUserData {
  return {
    type: Constants.GET_USERDATA,
    response: response,
  };
}

export const GetUserdataAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>> = () => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        const result = await axios
          .get(`${COMPANY_LOOKUP_HOST}/userdata/get`)
          .then((response) => response.data)
        return dispatch(GetUserdata(result))
      } catch (e: any) {
        console.error("GetUserdataAction", e)
        return dispatch(SetError({ message: 'Nevieme zistiť informácie o osobách, chyba servera.' }));
      }
    };
  };

export function SaveUserdata(response: any): Interfaces.SaveUserData {
  return {
    type: Constants.SAVE_USERDATA,
    response: response,
  };
}

export const SaveUserdataAction: ActionCreator<ThunkAction<Promise<Action>, any, void>> = (userPersons: any) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      return dispatch(
        SaveUserdata(
          await axios
            .post(`${COMPANY_LOOKUP_HOST}/userdata/post`, { userPersons: userPersons })
            .then((response) => {
              return response.data.persons
            })
        )
      )
    } catch (e: any) {
      console.error('SaveUserdataAction', e)
      return dispatch(SetError({ message: 'Údaje o osobách neboli uložené, chyba servera.' }));
    }
  };
};

export function GetUsersList(response: any): Interfaces.GetUsersList {
  return {
    type: Constants.GET_USERS_LIST,
    response: response,
  };
}
/* ? filter should be used */
export const GetUsersListAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (
  token: string,
  size: number,
  filters: string,
) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {

        // const tmpToken = token ? encodeURIComponent(token) : 'noToken'
        // const tmpFilters = filters ? encodeURIComponent(filters) : 'noFilters'

        let result = await axios
          .post(
            ADMIN_API_HOST + '/users/all/',
            {
              token: token,
              size: size,
              filters: filters
            }
          )
          .then((response) => response.data);
        return dispatch(GetUsersList(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };

// GET USER
export function GetUser(response: any): Interfaces.GetUser {
  return {
    type: Constants.GET_USER,
    response: response,
  };
}
/* ? filter should be used */
export const GetUserAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>> = (username: string) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        let result = await axios
          .get(
            ADMIN_API_HOST +
            `/users/user/${username}`,
          )
          .then((response) => response.data);
        const tmpDto = createUserDTO()
        copyCognito2User2(result, tmpDto)
        return dispatch(GetUser(tmpDto));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };

// IS USER
export function IsUser(response: any): Interfaces.IsUser {
  return {
    type: Constants.IS_USER,
    response: response,
  };
}

/* ? filter should be used */
export const IsUserAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>> = (email: string) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        let result = await axios
          .get(
            ADMIN_API_HOST +
            `/users/isuser/${email}`,
          )
          .then((response) => response.data);
        return dispatch(IsUser(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };

// UPDATE USER
export function UpdateUser(response: any): Interfaces.UpdateUser {
  return {
    type: Constants.UPDATE_USER,
    response: response,
  };
}

/* ? filter should be used */
export const UpdateUserAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>> = (user: {}) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {

        let result = await axios
          .post(
            ADMIN_API_HOST +
            `/users/update`, user
          )
          .then((response) => response.data);
        return dispatch(UpdateUser(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };

// DELETE USER
export function DeleteUser(response: any): Interfaces.DeleteUser {
  return {
    type: Constants.DELETE_USER,
    response: response,
  };
}

/* ? filter should be used */
export const DeleteUserAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>> = (username: string) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {

        let result = await axios
          .delete(
            ADMIN_API_HOST +
            `/users/delete/${username}`,
          )
          .then((response) => response.data);
        return dispatch(DeleteUser(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };

/* TODO: Probably not needed */
export function GisListFeatures(response: any): Interfaces.GisListFeatures {
  return {
    type: Constants.GIS_LIST_FEATURES,
    response: response,
  };
}

/* TODO: Probably not needed */
export const GisListFeaturesAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>> = (params: {}) => {
    return async (dispatch: Dispatch<any, any>): Promise<Action> => {
      try {
        let result = await axios
          .post(
            ADMIN_API_HOST + `/gis/list`,
            params
          )
          .then((response) => response.data);
        return dispatch(GisListFeatures(result));
      } catch (e: any) {
        return dispatch(SetError(e));
      }
    };
  };



export function ProcessMyDocument(
  response: any,
): Interfaces.ProcessMyDocuments {
  return {
    type: Constants.PROCESS_MY_DOCUMENTS,
    response: response,
  };
}

export const ProcessMyDocumentAction: ActionCreator<
  ThunkAction<Promise<Action>, any, void>
> = (uploadFiles: FileToUpload[]) => {
  return async (dispatch: Dispatch<any, any>): Promise<Action> => {
    try {
      let uplRequest: FileUploadRequest = {
        files: uploadFiles,
      };
      console.log("CALLED TEXTRACT", uploadFiles)
      let result = await axios
        .post(
          ADMIN_API_HOST + `/mydocuments/process/`,
          uplRequest,
        )
        .then((response) => {
          console.log("RESPONSE", response.data)
          return response.data
        })

      return dispatch(ProcessMyDocument(result));
    } catch (e: any) {
      return dispatch(SetError(e));
    }
  };
};
