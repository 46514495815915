import React, { useState } from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
    BarChart, Bar, PieChart, Pie, Cell
} from 'recharts';
import { Input } from 'semantic-ui-react';

const COLORS = ['#00b04e', '#c6e0b5', '#c65a10', '#aeabaa', '#4373c4', '#6f31a0', '#71ad47'];

const ChartsMutator = () => {
    const [chartType, setChartType] = useState('pie');
    const [data, setData] = useState([]);
    const [pieData, setPieData] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [nameValue, setNameValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleNameChange = (e) => {
        setNameValue(e.target.value);
    };

    const handleUpdateData = () => {
        const newValue = Number(inputValue);
        const newName = nameValue.trim();

        if (!isNaN(newValue) && newName) {
            const newEntry = { name: newName, uv: newValue, pv: newValue };

            setData([...data, newEntry]);

            const newPieEntry = { name: newName, value: newValue };
            setPieData([...pieData, newPieEntry]);

            setInputValue('');
            setNameValue('');
        }
    };

    const renderChart = () => {
        switch (chartType) {
            case 'line':
                return (
                    <>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="Trávní porost" stroke="#c6e0b5" />
                                <Line type="monotone" dataKey="Lesní pozemky" stroke="#00b04e" />
                                <Line type="monotone" dataKey='Orná půda' stroke="#c65a10" />
                                <Line type="monotone" dataKey='Ostatní' stroke="#aeabaa" />
                            </LineChart>
                        </ResponsiveContainer>
                        <p style={{ textAlign: 'right', margin: '20px 0' }}>Zdroj: ÚZK-vlastní zpracování</p>
                    </>
                );
            case 'bar':
                return (
                    <>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="Trávní porost" fill="#c6e0b5" />
                                <Bar dataKey="Lesní pozemky" fill="#00b04e" />
                                <Bar dataKey='Orná půda' fill="#c65a10" />
                                <Bar dataKey='Ostatní' fill="#aeabaa" />
                            </BarChart>
                        </ResponsiveContainer>
                        <p style={{ textAlign: 'right', padding: '20px 0' }}>Zdroj: ÚZK-vlastní zpracování</p>
                    </>
                );
            case 'pie':
                return (
                    <>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={pieData}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    fill="#8884d8"
                                    dataKey="value"
                                    label
                                >
                                    {pieData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <ResponsiveContainer className="recharts-container">
            <h1>Graf</h1>
            <div className='recharts-buttons-container'>
                <Input
                    type="text"
                    value={nameValue}
                    onChange={handleNameChange}
                    placeholder="Vložte meno"
                    style={{ marginRight: '10px' }}
                />
                <Input
                    type="number"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Vložte hodnotu"
                    style={{ marginRight: '10px' }}
                />
                <button className='addButton' onClick={handleUpdateData}>Pridať do grafu</button>
            </div>
            {/* <div className='recharts-buttons-container'>
                <button className={chartType === 'line' ? 'addButton active' : 'addButton'} onClick={() => setChartType('line')}>Líniový</button>
                <button className={chartType === 'bar' ? 'addButton active' : 'addButton'} onClick={() => setChartType('bar')}>Sloupcový </button>
                <button className={chartType === 'pie' ? 'addButton active' : 'addButton'} onClick={() => setChartType('pie')}>Koláčový</button>
            </div> */}

            {pieData.length > 0 &&
                <div style={{ marginTop: '4rem', marginLeft: '-2rem' }}>
                    {renderChart()}
                    <p style={{ textAlign: 'right', padding: '20px 0' }}>Zdroj: ÚZK-vlastní zpracování</p>
                </div>

            }

        </ResponsiveContainer>
    );
};

function ChartsRenderer(values, key) {
    if (values[key]) {
        return `<img src="${values[key].image}" alt="Chart view" style="height: 500px; width: 100%; object-fit: cover; margin-top: 3.5rem; border-radius: 4px;" />`;
    }
    return
}

function ChartsValidator() {
    return true;
}

export default {
    Mutator: ChartsMutator,
    HTMLRenderer: ChartsRenderer,
    Validator: ChartsValidator
};
