import Text from '../Text';
import { Clickable } from './Clickable';
import { StyledBox } from './SidebarItem.styled';
import { Icon } from 'semantic-ui-react'

type Props = {
  label: string;
  onClick: (e: React.MouseEvent) => void;
  active?: boolean;
};

export const SidebarItem: React.FC<Props> = ({
  label,
  onClick,
  active
}) => {
  return (
    <Clickable onClick={onClick}>
      <StyledBox
        id='sidebarnav--label-item'
        background={active ? '#a8c230' : ""}
        flexDirection={false ? 'column' : 'row'}
        alignItems={false ? 'flex-start' : 'center'}
        borderBottom={false ? '1px solid #e8e8e8' : null}>
        {Array.isArray(label) ? (
          label.map((labelItem) => (
            <div className={active ? 'leftNavItem active' : 'leftNavItem'}>
              <Icon name='edit outline' />
              <Text color={active ? '#fff' : '#010118'}
                className={active ? 'sidebarnav--label-item active' : 'sidebarnav--label-item'}>
                {labelItem}
              </Text>
            </div>
          ))
        ) : (
          <div className={active ? 'leftNavItem active' : 'leftNavItem'}>
            <Icon name='file alternate outline' />
            <Text color={active ? '#03045e' : '#010118'} className={active ? 'sidebarnav--label-item active' : 'sidebarnav--label-item'}>
              {label}
            </Text>
          </div>
        )}
      </StyledBox>
    </Clickable >
  );
};
