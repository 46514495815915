
import { Button, Spin, message, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Icon, Dimmer, Loader } from 'semantic-ui-react';
import { Box } from '../../../../components/Box';
import { translations } from '../../../../utils/LocalizedStrings';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { fillPromptData, prepareMunicipalitySummary, prepareMunicipalitySummaryExtended, prepareMunicipalitySummaryGemini } from '../../../../api/functions.api';
import { selectArtifactsForFormBuilder } from '../../../editor/editorSlice';
import { useSelector } from 'react-redux';
import { DocumentEditor } from '../../../editor/components/document-editor/DocumentEditor';
import { lessonModulesCreator } from '../../../editor/components/views/AdminEditor/ModulesCreators';
import spinner from '../../../../images/spinner.gif'

// TODO: ARTIFACT MA CHAT PROMPT A GOOGLE PROMPT, TREBA CHECKNUT MENTIONS,
// TREBA LOGIKU, ZE STANDARTNE SA POUZUJE CHAT PROMPT, PRIPADNE SI USER MOZE POUZIT GOOGLE PROMPT,
// ASI CEZ TLACITKA, ZATIAL
// TREBA TO NAPOJIT CEZ 00_OBEC_NAZOV, PREVER ZE PRI VOLANI PROMPTU, SA STIAHNE MENO OBCE

function AIArtifactMutator({ documentId, name, disabled, binding, label = 'Obec meno', ...rest }) {


    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const allValues = useFormDataContext(({ data, setField }) => ({
        values: data,
        setField
    }));

    const fields = useSelector(selectArtifactsForFormBuilder);

    const values = useFormDataContext(({ data, setField }) => ({
        value: (name && name !== "") ? (data[name] || "") : (data[name] || ""),
        setField
    }));
    const [fieldValues, setFieldValues] = useState(values)
    const [loading, setLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const [editorReference, setEditorReference] = useState();

    const handleChange = () => {
        values.setField({
            target: {
                name,
                value: fieldValues.value
            },
        });
    }

    const changeValue = (value) => {
        fieldValues.value = value
        setFieldValues(JSON.parse(JSON.stringify(fieldValues)))
        forceUpdate()
    }

    const onFocus = () => {
        document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
            el.classList.add('mention--focus');
        });
    };

    const onBlur = () => {
        document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
            el.classList.remove('mention--focus');
        });
        handleChange();
    };

    const prepareMunicipalitySummaryAIGemini = async () => {
        setLoading(true)
        const prompt = fillPromptData(allValues.values, rest.promptChatGPT, fields)
        prepareMunicipalitySummaryGemini(prompt, onSuccess, onError)
    }

    const prepareMunicipalitySummaryAI = async () => {
        setLoading(true)
        const prompt = fillPromptData(allValues.values, rest.promptChatGPT, fields)
        const completion = prepareMunicipalitySummary(prompt, onSuccess, onError);
    }

    const prepareMunicipalitySummaryExtendedAI = async () => {
        setLoading(true)
        const prompt = fillPromptData(allValues.values, rest.promptChatGPT, fields)
        const googlePrompt = fillPromptData(allValues.values, rest.promptGoogle, fields)
        const completion = prepareMunicipalitySummaryExtended(prompt, googlePrompt, onSuccess, onError);
    }

    const onSuccess = (summary) => {
        fieldValues.value = summary.data
        const fieldValuesCopy = JSON.parse(JSON.stringify(fieldValues))
        setFieldValues(fieldValuesCopy)
        handleChange()
        setLoading(false)
    }

    const onError = (error) => {
        setLoading(false)
        messageApi.error(error.message)
    }

    useEffect(() => {
        if (JSON.stringify(values.value) !== JSON.stringify(fieldValues.value)) {
            setFieldValues(JSON.parse(JSON.stringify(values)))
        }
    }, [values.value])


    const modules = useMemo(
        () => lessonModulesCreator(myUndo, myRedo),
        editorReference,
    );

    function myUndo() {
        let myEditor = (editorReference).getEditor();
        myEditor.history.undo();
    }

    function myRedo() {
        let myEditor = (editorReference).getEditor();
        myEditor.history.redo();
    }

    return (
        <>
            {contextHolder}
            <form className="municipalitySummaryComponent">
                {loading && <img src={spinner} className={loading ? 'spinLoading loadingActive' : 'spinLoading'} />}
                <form id={'person'} className="fullGridRow" style={{ marginTop: 20 }}>
                    <div className="addressAuto">
                        <Box>
                            <p className='inputLabelTooltip'>
                                <h4>{label}</h4>
                                {rest.tooltip && (
                                    <div style={{ marginBottom: 20 }} className="icon-position">
                                        <Tooltip title={rest.tooltip}>
                                            <span className="icon-info">
                                                <Icon name="info circle" />
                                            </span>
                                        </Tooltip>
                                    </div>
                                )}
                            </p>
                            {fieldValues &&
                                <div>
                                    <div className='buttons-container'>
                                        <Button disabled={loading} onClick={(() => prepareMunicipalitySummaryAI())}>{translations.artifacts.MunicipalitySummaryAIArtifact.prepareDescription}</Button>
                                        <Button disabled={loading} onClick={(() => prepareMunicipalitySummaryAIGemini())}>{translations.artifacts.MunicipalitySummaryAIArtifact.gemini}</Button>
                                        <Button disabled={loading} onClick={(() => prepareMunicipalitySummaryExtendedAI())}>{translations.artifacts.MunicipalitySummaryAIArtifact.prepareDescriptionExtended}</Button>
                                    </div>
                                    <Box className='textarea-container'>
                                        <Text strong>{`${label} - ${translations.artifacts.MunicipalitySummaryAIArtifact.municipalityDescription}`}</Text>
                                        <div className="formattedHtml" onFocus={onFocus} onBlur={onBlur}>
                                            <DocumentEditor
                                                theme="snow"
                                                // modules={modules}
                                                preserveWhitespace
                                                value={fieldValues?.value ? fieldValues.value : ''}
                                                onChange={changeValue}
                                                ref={(el) => {
                                                    setEditorReference(el);
                                                }}
                                                defaultValue={
                                                    fieldValues?.value ? fieldValues.value : ''
                                                }
                                                style={{ minHeight: '200px' }}
                                            />
                                        </div>
                                    </Box>
                                </div>
                            }
                        </Box>
                    </div>
                </form>
            </form>
        </>
    );
}

function AIArtifactRenderer(values, id) {
    let returnValue = ""
    if (
        values &&
        values[id]
    ) {
        returnValue += values[id]
        return returnValue
    }
}

function AIArtifactValidator(values, id) {
    let name = values[id]
    return name ? true : false
}

export default {
    Mutator: AIArtifactMutator,
    HTMLRenderer: AIArtifactRenderer,
    Validator: AIArtifactValidator
};
