import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Modal, Spin, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import { translations } from '../../../../../utils/LocalizedStrings';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import { ProcessMyDocumentAction } from '../../../../../app/ActionsImpl';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../../utils/string';
import { TypInyIdentifikatorEnum } from '../../../../admin/pages/Interfaces';

export function SearchPerson({
    modalVisible,
    selectedPerson,
    setSelectedPerson,
    setModalVisible,
    setEdited,
    handleOk,
    processMyDocuments
}) {

    const values = useFormDataContext(({ data, setField }) => ({
        userPersons: data.userPersons || [],
        setField,
    }));

    const [fieldValues, setFieldValues] = React.useState(values);

    const [filteredPersons, setFilteredPersons] = React.useState([])
    const [searchField, setSearchField] = React.useState("")
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [processSpinVisible, setProcessSpinVisible] = React.useState(false)

    useEffect(() => {
        setFilteredPersons(values.userPersons)
        setSearchField("")
    }, [modalVisible])

    const searchPerson = (e) => {
        setSearchField(e.target.value)
        if (e.target.value) {
            setFilteredPersons(values.userPersons.filter(p => {
                let value = e.target.value.toLowerCase()
                let name = p.separatedName.name.toLowerCase()
                let surname = p.separatedName.surname.toLowerCase()
                let fullName = name + " " + surname
                let idNumber = p.idNumber.toLowerCase()
                return name.indexOf(value) !== -1 || surname.indexOf(value) !== -1 || fullName.indexOf(value) !== -1 || idNumber.indexOf(value) !== -1
            }))
        } else {
            setFilteredPersons(values.userPersons)
        }
        forceUpdate()
    }

    const deletePerson = (person) => {
        let personIndex = values.userPersons.map(a => JSON.stringify(a)).indexOf(JSON.stringify(person))
        if (personIndex !== -1) {
            let newPersons = values.userPersons
            newPersons.splice(personIndex, 1)
            values.setField({
                userPersons: newPersons
            });
            if (searchField) {
                setFilteredPersons(newPersons.filter(p => {
                    let value = searchField
                    let name = p.separatedName.name.toLowerCase()
                    let surname = p.separatedName.surname.toLowerCase()
                    let fullName = name + " " + surname
                    let idNumber = p.idNumber.toLowerCase()
                    return name.indexOf(value) !== -1 || surname.indexOf(value) !== -1 || fullName.indexOf(value) !== -1 || idNumber.indexOf(value) !== -1
                }))
            } else {
                setFilteredPersons(newPersons)
            }
        }
        forceUpdate()
    }

    const handleFileChange = async (event) => {
        let persons = values.userPersons || []

        for (let oneFile of event.target.files) {
            // console.log(event.target.files)
            let reader = new FileReader();
            reader.onload = async (loadEvent) => {
                let tmp1 = {
                    name: oneFile.name,
                    content: reader.result,
                };
                setProcessSpinVisible(true)
                console.log("PROCESSMYDOCUMENTS", processMyDocuments)
                const result = await processMyDocuments([tmp1])

                if (result?.response?.extracted !== undefined) {
                    let personToSave = {}
                    personToSave['type'] = 'ownerPerson'

                    if (result.response.extracted.type === "PASSPORT") {
                        personToSave['idType'] = capitalizeFirstLetter(TypInyIdentifikatorEnum.CESTOVNY_DOKLAD)
                        personToSave['idNumber'] = result.response.extracted.data.passport_no
                        personToSave['separatedName'] = {
                            name: result.response.extracted.data.given_name,
                            surname: result.response.extracted.data.surname
                        }
                        personToSave['dateOfBirth'] = result.response.extracted.data.date_of_birth
                        personToSave['address'] = {
                            country: 'SK'
                        }
                    } else if (result.response.extracted.type === "ID-CARD") {
                        personToSave['idType'] = capitalizeFirstLetter(TypInyIdentifikatorEnum.RODNE_CISLO)
                        personToSave['idNumber'] = result.response.extracted.data.personal_no
                        personToSave['separatedName'] = {
                            name: result.response.extracted.data.given_name,
                            surname: result.response.extracted.data.surname
                        }
                        personToSave['dateOfBirth'] = result.response.extracted.data.date_of_birth
                        personToSave['address'] = {
                            country: 'SK'
                        }
                    } else if (result.response.extracted.type === "ID-CARD-BACK") {
                        personToSave['separatedName'] = {
                            name: 'John',
                            surname: 'Doe'
                        }
                        personToSave['address'] = {
                            city: result.response.extracted.data.address_city,
                            street: result.response.extracted.data.address_street,
                            number: result.response.extracted.data.address_number,
                            country: 'SK'
                        }
                        personToSave['idNumber'] = ""
                    }
                    persons.push(personToSave)
                }
                setProcessSpinVisible(false)
            };
            await reader.readAsDataURL(oneFile);
        }
        event.target.value = ''

        values.setField({
            userPersons: persons
        });

    }

    return (
        <Modal
            className='searchPersonModal'
            title={translations.artifacts.OwnersComponentBox.searchPerson}
            visible={modalVisible}
            okButtonProps={{ disabled: !selectedPerson }}
            onOk={() => {
                handleOk()
                setSelectedPerson(undefined)
                setModalVisible(false)
                setEdited(false)
            }}
            onCancel={() => setModalVisible(false)}>
            <Input
                placeholder={translations.artifacts.OwnersComponentBox.enterNameAndSurname}
                onChange={searchPerson}
                value={searchField}
            />
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {translations.artifacts.OwnersComponentBox.personName}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {translations.artifacts.OwnersComponentBox.personSurname}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {translations.artifacts.OwnersComponentBox.id}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Array.isArray(filteredPersons) && filteredPersons.map((person) => {
                        return (
                            <Tooltip id='searchPersonTooltip' title={"Vybrať osobu"}>
                                <Table.Row className='choosePerson' onClick={() => {
                                    if (selectedPerson === person) {
                                        setSelectedPerson(undefined)
                                    } else {
                                        setSelectedPerson(person)
                                    }
                                }
                                }>
                                    <Table.Cell><Checkbox className='searchPersonConfirmButton' checked={selectedPerson === person}></Checkbox></Table.Cell>
                                    <Table.Cell>{person.separatedName.name}</Table.Cell>
                                    <Table.Cell>{person.separatedName.surname}</Table.Cell>
                                    <Table.Cell>{person.idNumber}</Table.Cell>
                                    <Table.Cell>
                                        <Button
                                            onClick={() =>
                                                deletePerson(person)
                                            }
                                            type="primary"
                                            className="removeButton"
                                        >
                                            <DeleteOutlined onClick={() =>
                                                deletePerson(person)
                                            } />
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            </Tooltip>
                        )
                    })}
                </Table.Body>
            </Table>
        </Modal>
    )
}


const mapStateToProps = ({ appState }) => ({
});

const mapDispatchToProps = {
    processMyDocuments: ProcessMyDocumentAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPerson)