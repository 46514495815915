import { CheckOutlined, SearchOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import Auth from '@aws-amplify/auth';
import { Alert, Button, Card, ConfigProvider, DatePicker, Dropdown, Input, Menu, Select, Tooltip } from 'antd';
import sk_SK from 'antd/lib/locale/sk_SK';
import { Option } from 'antd/lib/mentions';
import moment from 'moment';
import 'moment/locale/sk';
import React, { useEffect, useState } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { translations } from '../../../utils/LocalizedStrings';
import { Filter, FilterType } from '../pages/Interfaces';
import Navigation from './Navigation';
import FeedbackListItem from './FeedbackList.item';


export function FeedbackList({
  isLoading,
  showCreate,
  documents,
  refresh
}: {
  documents: object[];
  showCreate?: boolean;
  isLoading?: boolean;
  refresh: () => void;
}) {
  const [token, setToken] = useState('')
  useEffect(() => {
    Auth.currentSession().then(session => {
      const jwtToken = session.getAccessToken().getJwtToken()
      setToken(jwtToken)
    })
  })
  if (isLoading) {
    return <Alert message="Načítavam" type="info" />;
  }
  if (!isLoading && !showCreate && documents.length > 0) {
    return (
      <Table className='feedbackListTable'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{translations.artifacts.FeedbackListBox?.companyName}</Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.FeedbackListBox?.ratingMessage}</Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.FeedbackListBox?.contactName}</Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.FeedbackListBox?.contactEmail}</Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.FeedbackListBox?.ratingDate}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {documents.map((item: any) => (
            <FeedbackListItem token={token} data={item} key={`docitem-${item.id || item.name}`} refresh={refresh} />
          ))}
        </Table.Body>
      </Table>
    );
  }
  if (!isLoading && !showCreate && (!documents || documents.length === 0)) {
    return <Alert message="Neboli nájdene žiadne hodnotenia." type="warning" />;
  }

  return null;
}

export function FeedbackListHeader({
  sortBy,
  sortDir,
  setSortBy,
  setFilters,
  pageChange,
  filters,
  selectedFilters
}: {
  sortBy: string,
  sortDir: string,
  setSortBy: (value: string) => void,
  setFilters: (filter: string) => void,
  pageChange: (value: number, size?: number) => void,
  filters: Filter[],
  selectedFilters: string[]
}) {
  const [sortMenuVisible, setSortMenuVisible] = useState(false)
  const [filterMenuVisible, setFilterMenuVisible] = useState(false)
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const sortMenu = (
    <Menu>
      <Menu.Item onClick={() => setSortBy("companyName")}>
        Názov spoločnosti {sortBy === 'spolocnost' && (sortDir === "ASC" ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
      </Menu.Item>
      <Menu.Item onClick={() => setSortBy("kontakt_meno")}>
        Meno {sortBy === 'kontakt_meno' && (sortDir === "ASC" ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
      </Menu.Item>

      <Menu.Item onClick={() => setSortBy("created_at")}>
        Vytvorené {sortBy === 'created_at' && (sortDir === "ASC" ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
      </Menu.Item>
    </Menu>
  )

  function filterMenu() {
    return (
      <Menu>

        {filters.map((filter => {
          return (
            <Menu.Item key={filter.name} onClick={() => { setFilters(filter.name); forceUpdate() }}>
              {filter.translation} {selectedFilters.indexOf(filter.name) !== -1 ? <CheckOutlined /> : ""}
            </Menu.Item>
          )
        }))}
      </Menu>)
  }

  const setFilterValue = (filter: Filter, e: any) => {
    if (filter.type === FilterType.DATE_TIME) {
      if (e === null) {
        filter.value = ""
      } else {
        filter.value = e
      }
    } else if (filter.type === FilterType.STRING) {
      filter.value = e.target.value
    } else {
      filter.value = e
    }
    forceUpdate()
  }

  return (
    <>
      <Navigation />
      <Card style={{ background: '#f0f0f0', padding: "4rem 15% 0 15%" }}>
        <div>
          <div style={{ display: 'flex' }}>
            <h3
              style={{
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '1.375rem',
                margin: 0
              }}>
              Hodnotenia{' '}
            </h3>
            <div>
              <Dropdown
                overlay={sortMenu}
                onVisibleChange={setSortMenuVisible}
                visible={sortMenuVisible}
              >
                <a style={{ display: 'flex', marginRight: 40 }} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  <Icon name="sort" size='large' />
                  Zoradiť
                </a>
              </Dropdown>
            </div>
            <div >
              <Dropdown
                overlay={filterMenu}
                onVisibleChange={setFilterMenuVisible}
                visible={filterMenuVisible}
                forceRender
              >
                <a style={{ display: 'flex' }} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  <Icon name="filter" size='large' />
                  Filtre
                </a>
              </Dropdown>
            </div>
          </div>
          <div style={{ marginTop: "5px" }}>
            <Input.Group compact>
              {filters.map((filter, index) => {
                return (
                  selectedFilters.indexOf(filter.name) !== -1 && (
                    filter.type === FilterType.DATE_TIME ?
                      <ConfigProvider locale={sk_SK}>
                        <DatePicker
                          format="HH:mm DD.MM.YYYY"
                          style={{ minWidth: "150px", width: '15%', marginRight: "5px" }}
                          onChange={(e) => {
                            setFilterValue(filter, e)
                            pageChange(1)
                          }}
                          showTime={{ defaultValue: moment(filter.value !== "" ? filter.value : undefined) }}
                        />
                      </ConfigProvider>
                      :
                      filter.type === FilterType.STRING ?
                        <Input onChange={(e) => setFilterValue(filter, e)} style={{ minWidth: "150px", width: '15%', marginRight: "5px" }} value={filter.value} placeholder={filter.translation} onKeyDown={
                          (event) => {
                            if (event.key === 'Enter') {
                              pageChange(1)
                            }
                          }
                        } />
                        :
                        <Select onChange={(e) => {
                          setFilterValue(filter, e)
                          pageChange(1)
                        }} style={{ minWidth: "150px", width: '15%', marginRight: "5px" }} value={filter.value} placeholder={filter.translation} >
                          {filter.values?.map(value => {
                            return (
                              <Option value={value.value} key={value.value}>
                                {value.name}
                              </Option>
                            )
                          })}
                        </Select>
                  )
                )
              })}
              {selectedFilters.length > 0 &&
                <Tooltip title="search">
                  <Button onClick={() => pageChange(1)} shape="circle" icon={<SearchOutlined />} />
                </Tooltip>
              }
            </Input.Group>
          </div>
        </div>
      </Card>
    </>
  );
}

