import { CloseOutlined, HomeOutlined, MenuOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Dropdown, Form, Icon, Modal } from 'semantic-ui-react';
import { setInterval } from 'timers';
import { AcceptPhoneServiceContractAction, GetSettingsAction, GetUserProfileDataAction, GetUserSignatureRequestsListAction, GetUserdataAction, ProcessMyDocumentAction, SaveUserProfileDataAction, SaveUserdataAction, SetUserAfterUpdate } from '../../../app/ActionsImpl';
import { LANDING_PAGE_URI } from '../../../constants';
import avatar from '../../../images/avatar.svg';
import logo from '../../../images/logoWhiteText.webp';
import orders from '../../../images/orders.svg';
import persons from '../../../images/persons.svg';
import signed from '../../../images/signed.svg';
import { isAdmin } from '../../../utils/AuthorizationValidation';
import { translations } from '../../../utils/LocalizedStrings';
import { isAddressValid } from '../../../utils/string';
import { getAllUrlParams } from '../../../utils/url';
import UserOrdersListPage from '../user/UserOrdersList.page';
import UserSignatureRequestsListPage from '../user/UserSignatureRequestsList.page';
import LoginModal from './LoginModal';
import UserProfileSettings from './UserProfileSettings';
import { PersonList } from './inputs/Person/PersonList';


function UserProfile({ user, userProfileData, getUserdata, getUserProfileData, saveUserdata, updateUserProfileData, ordersList, getOrderList, processMyDocuments, language, refreshUser, acceptPhoneServiceContract, getSettings, settings }) {
    const [activeNav, setActiveNav] = useState('Moje objednávky');
    const [activeDrawer, setActiveDrawer] = useState(false);
    const [landingPage, setLandingPage] = useState(undefined);
    const [openIntroModal, setOpenIntroModal] = useState(false)
    const [openUserStatusModal, setOpenUserStatusModal] = useState(false)
    const [activeCard, setActiveCard] = useState(false);
    const [phoneContractModalVisible, setPhoneContractModalVisible] = useState(false)
    const [phoneContractModalPreviewVisible, setPhoneContractModalPreviewVisible] = useState(false)
    const [editUserProfileModalVisible, setEditUserProfileModalVisible] = useState(false)
    const [phoneContractServiceAccepted, setPhoneContractServiceAccepted] = useState(false)
    
    // check also in OrderList.item.tsx
    // check also in UserSignatureRequestsList.page.tsx
    // check also in UserProfileTemplate.js::UserProfile
    const [actualOrdersList, setActualOrdersList] = useState()

    const history = useHistory();


    useEffect(() => {
        let search = window.location.search
        if (getAllUrlParams().adm === undefined && isAdmin()) {
            if (search.indexOf("?") !== -1) {
                search += '&adm=01'
            } else {
                search += '?adm=01'
            }
            if (history) {
                history.push({
                    pathname: window.location.pathname,
                    search: search
                })
            }
        }
    }, [user])

    useEffect(() => {
        if (getAllUrlParams().signfilerequest !== undefined) {
            toggleNav('Žiadosti o podpis')
        }
    }, [])

    useEffect(() => {
        setLandingPage(LANDING_PAGE_URI)
    }, [])

    // check also in OrderList.item.tsx
    // check also in UserSignatureRequestsList.page.tsx
    // check also in UserProfileTemplate.js::UserProfile 
    const alreadySigned = (documentItem) => {
        let filtered = documentItem.imageSignatures?.filter((s) => s === documentItem.signature)
        if (filtered?.length) {
            return true
        } else {
            let requiredKep = documentItem.signedFile?.requiredSignatures.filter((s) => s === documentItem.signature)
            if (requiredKep?.length) {
                return requiredKep?.length
            }
            return false
        }
    }


    // check also in OrderList.item.tsx
    // check also in UserSignatureRequestsList.page.tsx
    // check also in UserProfileTemplate.js::UserProfile
    useEffect(() => {
        if (ordersList?.docs && ordersList.docs.length) {
            let tmpOrderList = JSON.parse(JSON.stringify(ordersList))
            let tmpDocsList = tmpOrderList.docs.filter((doc) => {
                let tmpDownloadList = doc.downloads.filter((download) => !alreadySigned(download))
                doc.downloads = tmpDownloadList
                return tmpDownloadList.length ? true : false
            })
            tmpOrderList.docs = tmpDocsList
            setActualOrdersList(tmpOrderList)
            console.log("here")
        }
    }, [ordersList])


    const toggleNav = (index) => {
        setActiveNav(index)
        setActiveDrawer(false);
    };

    const toggleDrawer = () => {
        setActiveDrawer(!activeDrawer);
    };

    const toggleSettings = () => {
        toggleNav('Nastavenia profilu')
        setOpenUserStatusModal(false)
    }

    const toggleCard = (index) => {
        setActiveCard(index);
    };

    const signOut = async () => {
        try {
            await Auth.signOut();
            window.location = landingPage
        } catch (error) {
            message.error(error)
        }
    }

    const saveUserProfile = async (UPData) => {
        setEditUserProfileModalVisible(false)
        await updateUserProfileData(UPData)
        await getUserProfileData()
        if (UPData && UPData.address && UPData.dateOfBirth && isAddressValid(UPData.address)) {
            setPhoneContractModalVisible(true)
        } else {
            setEditUserProfileModalVisible(true)
        }
    }

    useEffect(() => {
        setInterval(() => {
            // Check if the script is already loaded
            if (document.querySelector("script[src='https://cdn.jsdelivr.net/ghost/signup-form@~0.1/umd/signup-form.min.js']")) {
                return;
            }

            let script = document.createElement('script');
            script.src = "https://cdn.jsdelivr.net/ghost/signup-form@~0.1/umd/signup-form.min.js";
            script.async = true;
            script.dataset.label1 = "orsr-help";
            script.dataset.buttonColor = "#a8c230";
            script.dataset.buttonTextColor = "#FFFFFF";
            script.dataset.site = "https://premium.najpravo.sk";

            // Appending to a specific div by ID
            let targetDiv = document.getElementById("simonov_script");
            if (targetDiv) {
                targetDiv.appendChild(script);
            }

            return () => {
                // Cleanup on component unmount
                if (targetDiv) {
                    targetDiv.removeChild(script);
                }
            };
        }, 100);
    }, []);

    useEffect(() => {
        if (user) {
            getOrderList(0, 10)
        }
    }, [user]);

    const acceptPSContract = async (contract) => {
        let dataToSave = {}
        dataToSave.agreementData = { ...JSON.parse(JSON.stringify(user.attributes)), ...userProfileData, username: user.username, contract }
        dataToSave.PC_version = {
            version: settings?.settings?.phoneServiceContractVersion,
            time: new Date()
        }
        await acceptPhoneServiceContract(dataToSave)
        setPhoneContractModalVisible(false)
        await refreshUser()
    }

    return (
        user ? <>
            <div className="userProfileContainer">
                {/* <Modal
                    onClose={() => setOpenIntroModal(false)}
                    onOpen={() => setOpenIntroModal(true)}
                    open={openIntroModal}
                    className="userProfileIntroModal"
                >
                    <Modal.Header>{translations.artifacts.UserProfileBox.welcomeToProgram}</Modal.Header>
                    <Modal.Content image>
                        <Image size='medium' src={woman} wrapped />
                        <Modal.Description>
                            <Header>{translations.artifacts.UserProfileBox.benefits}</Header>
                            <p>{translations.artifacts.UserProfileBox.subtitle}</p>
                            <ul>
                                <li>{translations.artifacts.UserProfileBox.fastView}</li>
                                <li>{translations.artifacts.UserProfileBox.getAcces}</li>
                                <li>{translations.artifacts.UserProfileBox.specialOffers}</li>
                            </ul>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => {
                            setOpenIntroModal(false)
                            const oneWeekLater = moment().add(1, 'weeks');
                            const formattedDate = oneWeekLater.format('DD MMM YYYY HH:mm:ss');
                            document.cookie = `upModSh=true; expires=Thu, ${formattedDate} GMT;x SameSite=None; path=/; Secure`;
                        }}>
                            {translations.artifacts.UserProfileBox.accept}
                        </Button>
                    </Modal.Actions>
                </Modal> */}

                <Modal
                    onClose={() => setOpenUserStatusModal(false)}
                    onOpen={() => setOpenUserStatusModal(true)}
                    open={openUserStatusModal}
                    className="userStatusModal">
                    <Modal.Content>
                        <Modal.Description>
                            <Form onSubmit={signOut} className="orderUiForm userStatusModalWindow">
                                <div className='userStatusRow'>
                                    <div><img class="avatarLogo" src={avatar} alt="user avatar" width="100" /></div>
                                    <div className='userStatusModalWindowNameEmailContainer'>
                                        <div className='userName'>{user.attributes.given_name} {user.attributes.family_name}</div>
                                        <div className='userEmail'>{user.attributes.email}</div>
                                    </div>
                                </div>
                                <a
                                    className="settingsButton"
                                    onClick={toggleSettings}>
                                    <Icon name="setting" />
                                    {translations.artifacts.UserProfileBox.settings}
                                </a>
                                {/* <a
                                    className="settingsButton"
                                    onClick={() => { setOpenUserStatusModal(false); toggleNav('Moje objednávky') }}>
                                    <Icon name="help circle" />
                                    {translations.artifacts.UserProfileBox.showProfileTour}
                                </a> */}
                                <CloseOutlined className='userStatusModalCloseIcon' onClick={() => setOpenUserStatusModal(false)} />
                                <Button
                                    className="logoutButton"
                                    type="submit" onClick={signOut}>
                                    <Icon name="log out" />
                                    {translations.artifacts.UserProfileBox.logOut}
                                </Button>
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                <div className='userContainer' onClick={() => setOpenUserStatusModal(true)}>
                    <span className='userName'>
                        {user && (
                            <>{user.attributes.given_name} {user.attributes.family_name}</>
                        )
                        }
                    </span>
                    <img
                        className="avatarLogo"
                        src={avatar}
                        alt="user avatar"
                        width={50}
                    />
                </div>
                <div className={activeDrawer ? 'menuIcon active' : 'menuIcon'}
                    onClick={toggleDrawer}>
                    <MenuOutlined />
                </div>
                <aside className={activeDrawer ? 'active' : ''}>
                    <div className="top">
                        <div className="logo">
                        <a onClick={() =>  window.location.href = LANDING_PAGE_URI ? LANDING_PAGE_URI : '' }>
                                <img
                                    className="mainLogo"
                                    src={logo}
                                    alt="orsr logo"
                                />
                            </a>
                        </div>
                        <div className={activeDrawer ? 'close active' : 'close'} onClick={toggleDrawer}>
                            <CloseOutlined />
                        </div>
                    </div>
                    <div className="sidebar">
                        <a className={activeNav === 'Moje objednávky' ? 'active' : ''} onClick={() => toggleNav('Moje objednávky')}>
                            <img src={orders} className="signedDocumentIcon" alt='signedDocumentIcon' />
                            <h3>{translations.artifacts.UserProfileBox.myOrders}</h3>
                        </a>
                        <a className={activeNav === 'Žiadosti o podpis' ? 'active' : ''} onClick={() => toggleNav('Žiadosti o podpis')}>
                            <img src={signed} className="signedDocumentIcon" alt='signedDocumentIcon' />
                            <h3>{translations.artifacts.UserProfileBox.signatureRequests}</h3>
                            {actualOrdersList && actualOrdersList.docs.length > 0 && <span className='userProfileTemplateSignatureNotification'></span>}
                        </a>
                        <a className={activeNav === 'Moje šablóny osôb' ? 'active myPersons' : 'myPersons'} onClick={() => toggleNav('Moje šablóny osôb')}>
                            <img
                                className="handshakeIcon"
                                src={persons}
                                alt="person"
                                width={50}
                            />
                            <h3>{translations.artifacts.UserProfileBox.myPersons}</h3>
                        </a>
                        <a>
                            <ShoppingCartOutlined />
                            <Dropdown className='userProfileDropdownOrders' item text='Nový dokument'>
                                <Dropdown.Menu>
                                    <Dropdown.Item><a href={LANDING_PAGE_URI + '/'} target="_blank">Dokument MEK</a></Dropdown.Item>
                                    {/* <Dropdown.Item><a href={LANDING_PAGE_URI + '/?action=schvalenie-uctovnej-zavierky'} target="_blank">Účtovna závierka</a></Dropdown.Item>
                                    <Dropdown.Item><a href={LANDING_PAGE_URI + '/?action=zalozit-sro'} target="_blank">{translations.artifacts.UserProfileBox.makeSro}</a></Dropdown.Item> */}
                                </Dropdown.Menu>
                            </Dropdown>
                        </a>
                        <a onClick={() => { window.location = LANDING_PAGE_URI }}>
                            <HomeOutlined />
                            <h3>{translations.artifacts.UserProfileBox.homePage}</h3>
                        </a>
                    </div>
                </aside>
                {
                    activeNav === 'Moje objednávky' &&
                    <main>
                        <header>
                            <h2>{translations.artifacts.UserProfileBox.progress}</h2>
                        </header>
                        <div className='ordersTable'>
                            <UserOrdersListPage />
                        </div>
                    </main>
                }
                {
                    activeNav === 'Žiadosti o podpis' &&
                    <main>
                        <header>
                            <h2>{translations.artifacts.UserProfileBox.signatureRequests}</h2>
                        </header>
                        <div className='ordersTable'>
                            <UserSignatureRequestsListPage />
                        </div>
                    </main>
                }
                {
                    activeNav === 'Moje šablóny osôb' &&
                    <main>
                        <header>
                            <h2>{translations.artifacts.UserProfileBox.myPersons}</h2>
                        </header>
                        <PersonList
                            getUserdata={getUserdata}
                            saveUserdata={saveUserdata}
                            processMyDocuments={processMyDocuments} />
                    </main>
                }
                {
                    activeNav === 'Nastavenia profilu' &&
                    <main>
                        <header>
                            <h2>{translations.artifacts.UserProfileBox.mySettingsTitle}</h2>
                        </header>
                        <UserProfileSettings />
                    </main>
                }
            </div >
        </>
            :
            <LoginModal visible={true} handleClose={undefined} isPartnerRegistration={false} />
    );
}

const mapStateToProps = ({ appState }) => ({
    user: appState.cognitoUser,
    userProfileData: appState.userProfileData,
    ordersList: appState.signatureRequestsList,
    language: appState.language,
    settings: appState.settings
});

const mapDispatchToProps = {
    getUserdata: GetUserdataAction,
    saveUserdata: SaveUserdataAction,
    getOrderList: GetUserSignatureRequestsListAction,
    refreshUser: SetUserAfterUpdate,
    processMyDocuments: ProcessMyDocumentAction,
    acceptPhoneServiceContract: AcceptPhoneServiceContractAction,
    getSettings: GetSettingsAction,
    updateUserProfileData: SaveUserProfileDataAction,
    getUserProfileData: GetUserProfileDataAction
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
