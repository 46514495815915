import axios from "axios";
import { ADMIN_API_HOST } from "../constants";
import SignatureCanvasArtifact from "../features/frontoffice/components/inputs/SignatureCanvasArtifact";
import SelectPayment from "../features/frontoffice/components/inputs/SelectPayment";
import TitleNameSurnameArtifact from "../features/frontoffice/components/inputs/TitleNameSurnameArtifact";
import RegistrationCertificateArtifact from "../features/frontoffice/components/inputs/RegistrationCertificate/RegistrationCertificateArtifact";
import AddressAutocompleteArtifact from "../features/frontoffice/components/inputs/AddressAutocompleteArtifact";
import Retribution from "../features/frontoffice/components/inputs/Payments/Retribution";
import AIArtifact from "../features/frontoffice/components/inputs/AIArtifact";
import Person from "../features/frontoffice/components/inputs/Person/Person";
import BuyerSeller from "../features/frontoffice/components/inputs/BuyerSeller/BuyerSeller";
import PlnomocenstvoHeader from "../features/frontoffice/components/inputs/Plnomocenstvo/PlnomocenstvoHeader";
import PlnomocenstvoSignature from "../features/frontoffice/components/inputs/Plnomocenstvo/PlnomocenstvoSignature";
import PlnomocenstvoBody from "../features/frontoffice/components/inputs/Plnomocenstvo/PlnomocenstvoBody";
import ContractPoint from "../features/frontoffice/components/inputs/ContractPoint";
import OpenStreetMap from "../features/frontoffice/components/inputs/OpenStreetMap";
import MunicipalitiesRenderer from "../features/frontoffice/components/inputs/MunicipalitiesRenderer";
import MapaOrientaceSvahuNaSvetoveStrany from "../features/frontoffice/components/inputs/MapaOrientaceSvahuNaSvetoveStrany";
import MapaSklonitostiTerenu from "../features/frontoffice/components/inputs/MapaSklonitostiTerenu";
import MapaPotencialnyVyskytDruhuVegetace from "../features/frontoffice/components/inputs/MapaPotencialnyVyskytDruhuVegetace";
import MapaKrajinnyPokryvUzemiSvazku from "../features/frontoffice/components/inputs/MapaKrajinnyPokryvUzemiSvazku";
import MapaKlimatickeOblastiNaUzemy from "../features/frontoffice/components/inputs/MapaKlimatickeOblastiNaUzemy";
import MunicipalitiesRegionHTMLRenderer from "../features/frontoffice/components/inputs/MunicipalitiesRegionHTMLRenderer";
import MunicipalitiesDistrictHTMLRenderer from "../features/frontoffice/components/inputs/MunicipalitiesDistrictHTMLRenderer";
import MunicipalitiesPopulationHTMLRenderer from "../features/frontoffice/components/inputs/MunicipalitiesPopulationHTMLRenderer";
import MapaPotencialVeternejEnergie from "../features/frontoffice/components/inputs/MapaPotencialVeternejEnergie";
import MapaPotencialVynosovTpp0kg from "../features/frontoffice/components/inputs/MapaPotencialVynosovTpp0kg";
import MapaPotencialVynosovTpp120kg from "../features/frontoffice/components/inputs/MapaPotencialVynosovTpp120kg";
import MapaPotencialLesneTazobneZvysky from "../features/frontoffice/components/inputs/MapaPotencialLesneTazobneZvysky";
import MapaPotencialKukuricaSilaz from "../features/frontoffice/components/inputs/MapaPotencialKukuricaSilaz";
import MapaPotencialSlnecnicaTopinambur from "../features/frontoffice/components/inputs/MapaPotencialSlnecnicaTopinambur";
import MapaPotencialRepkaOzimna from "../features/frontoffice/components/inputs/MapaPotencialRepkaOzimna";
import MapaPotencialKonopeTechnicke from "../features/frontoffice/components/inputs/MapaPotencialKonopeTechnicke";
import MapaPotencialRychloRastuceDreviny from "../features/frontoffice/components/inputs/MapaPotencialRychloRastuceDreviny";
import MapaSpotrebaElektrickejEnergie from "../features/frontoffice/components/inputs/MapaSpotrebaElektrickejEnergie";
import MapaPotencialPlochyStriech from "../features/frontoffice/components/inputs/MapaPotencialPlochyStriech";


export function removeAfterLastDot(id: any) {
    const lastDotIndex = id.lastIndexOf('.');
    if (lastDotIndex === -1) return id; // return original string if no dot is found
    return id.substring(0, lastDotIndex);
}

export const prepareMunicipalitySummary = async (
    chatGPTPrompt: string,
    onSuccess?: (id?: string) => void,
    onError?: (error?: unknown) => void,
) => {
    try {
        axios.post(`${ADMIN_API_HOST}/municipality/summary`, { chatGPTPrompt }).then(response => {
            onSuccess && onSuccess(response.data);
        }).catch(e => {
            console.error(e);
            onError && onError(e);
        })
    } catch (e) {
        console.error(e);
    }
}

export const prepareMunicipalitySummaryGemini = async (
    geminiPrompt: string,
    onSuccess?: (id?: string) => void,
    onError?: (error?: unknown) => void,
) => {
    try {
        axios.post(`${ADMIN_API_HOST}/gemini/summary`, { geminiPrompt }).then(response => {
            onSuccess && onSuccess(response.data);
        }).catch(e => {
            console.error(e);
            onError && onError(e);
        })
    } catch (e) {
        console.error(e);
    }
}

export const prepareMunicipalitySummaryExtended = async (
    chatGPTPrompt: string,
    googlePrompt: string,
    onSuccess?: (id?: string) => void,
    onError?: (error?: unknown) => void,
) => {
    try {
        axios.post(`${ADMIN_API_HOST}/municipality/summary-extended`, { chatGPTPrompt, googlePrompt }).then(response => {
            onSuccess && onSuccess(response.data);
        }).catch(e => {
            console.error(e);
            onError && onError(e);
        })
    } catch (e) {
        console.error(e);
    }
}

export const fillPromptData = (values: any, prompt: string, fields: any) => {
    const virtualHTML: any = createVirtualHTML(values, prompt, fields)
    return virtualHTML.textContent || ""
}
export const createVirtualHTML = (values: any, prompt: string, fields: any) => {    
    const htmlParser = new DOMParser();
    const virtualHTML = document.createElement('div');
    virtualHTML.append(
        htmlParser.parseFromString(prompt, 'text/html').body,
    );
    virtualHTML.querySelectorAll('.mention').forEach((artifact: any) => {
        const key: any = artifact.getAttribute('data-id');
        const cleanKey = removeAfterLastDot(artifact.getAttribute('data-id'));
        try {
            if (values[key]) {
                if (Array.isArray(values[key])) {
                    switch (fields[key].displayMode) {
                        case 'dashedList': {
                            let resultValue = '';
                            let index = 0;
                            for (let singleValue of values[key]) {
                                resultValue += '- ';
                                resultValue += singleValue;
                                if (index !== values[key].length - 1) {
                                    resultValue += '<br>';
                                }
                                index++;
                            }
                            artifact.innerHTML = resultValue;
                            break;
                        }
                        case 'numberList': {
                            let resultValue = '';
                            let index = 0;
                            let subValueIndex = 0;
                            let addedSubValues = 0;
                            let lastParent: any;
                            let lastParentToUse: any;
                            let prefix = fields[key].selectPrefix
                                ? fields[key].selectPrefix + '. '
                                : '';
                            for (let singleValue of values[key]) {
                                let singleValueIndex = fields[key]
                                    .selectValues
                                    ? fields[key].selectValues.indexOf(
                                        singleValue,
                                    )
                                    : -1;
                                if (
                                    singleValueIndex === -1 &&
                                    Array.isArray(
                                        fields[key].selectSubValues,
                                    ) &&
                                    fields[key].selectSubValues.length !== 0
                                ) {
                                    Object.entries(
                                        fields[key].selectSubValues,
                                    ).forEach(([subValueParentKey, subValues]: [any, any]) => {
                                        if (subValues.indexOf(singleValue) !== -1) {
                                            let parentIndex = values[key].indexOf(
                                                fields[key].selectValues[
                                                subValueParentKey
                                                ],
                                            );
                                            if (lastParent === undefined) {
                                                lastParent = parentIndex;
                                                lastParentToUse = parentIndex;
                                            }
                                            if (lastParent !== parentIndex) {
                                                lastParent = parentIndex;
                                                parentIndex -= addedSubValues;
                                                lastParentToUse = parentIndex;
                                                subValueIndex = 1;
                                            } else {
                                                parentIndex = lastParentToUse;
                                                subValueIndex += 1;
                                            }
                                            resultValue +=
                                                prefix +
                                                (parentIndex + 1) +
                                                '.' +
                                                subValueIndex +
                                                '. ' +
                                                singleValue;
                                            resultValue += '<br>';
                                            addedSubValues += 1;
                                        }
                                    });
                                } else {
                                    resultValue += prefix + (index + 1) + '. ';
                                    resultValue += singleValue;
                                    if (index !== values[key].length - 1) {
                                        resultValue += '<br>';
                                    }

                                    index++;
                                }
                            }
                            artifact.innerHTML = resultValue;
                            break;
                        }
                        case 'classicList': {
                            let resultValue = '';
                            let index = 0;
                            for (let singleValue of values[key]) {
                                resultValue += singleValue;
                                if (index !== values[key].length - 1) {
                                    resultValue += ' ';
                                }
                                index++;
                            }
                            artifact.innerHTML = resultValue;
                            break;
                        }
                        case 'letterList': {
                            let resultValue = '';
                            let index = 0;
                            let letterIndex = 'a';
                            let prefix = fields[key].selectPrefix || '';
                            if (prefix !== '') {
                                for (let singleValue of values[key]) {
                                    resultValue += prefix + '.' + letterIndex + ' ';
                                    resultValue += singleValue;
                                    if (index !== values[key].length - 1) {
                                        resultValue += '<br>';
                                    }
                                    index++;
                                    letterIndex = String.fromCharCode(
                                        letterIndex.charCodeAt(letterIndex.length - 1) + 1,
                                    );
                                }
                            } else {
                                for (let singleValue of values[key]) {
                                    resultValue += letterIndex + ') ';
                                    resultValue += singleValue;
                                    if (index !== values[key].length - 1) {
                                        resultValue += '<br>';
                                    }
                                    index++;
                                    letterIndex = String.fromCharCode(
                                        letterIndex.charCodeAt(letterIndex.length - 1) + 1,
                                    );
                                }
                            }
                            artifact.innerHTML = resultValue;
                            break;
                        }
                        default: {
                            let resultValue = '';
                            let index = 0;
                            for (let singleValue of values[key]) {
                                resultValue += singleValue;
                                if (index !== values[key].length - 1) {
                                    resultValue += ', ';
                                }
                                index++;
                            }
                            artifact.innerHTML = resultValue;
                        }
                    }
                } else if (fields[key]) {
                    switch (fields[key].type) {
                        case 'payment': {
                            artifact.innerHTML = SelectPayment.HTMLRenderer(
                                values,
                                key,
                                fields[key].name,
                            );
                            break;
                        }
                        case 'signature-canvas': {
                            artifact.innerHTML = SignatureCanvasArtifact.HTMLRenderer(
                                values,
                                key
                            );
                            artifact.classList.remove('mention');
                            break;
                        }
                        case 'title-name-surname': {
                            artifact.innerHTML = TitleNameSurnameArtifact.HTMLRenderer(values, key);
                            break;
                        }
                        case 'registration-certificate': {
                            artifact.innerHTML = RegistrationCertificateArtifact.HTMLRenderer(values, key);
                            break;
                        }
                        case 'address-autofill': {
                            artifact.innerHTML = AddressAutocompleteArtifact.HTMLRenderer(values, key);
                            break;
                        }
                        case 'retribution': {
                            let retributionHTML = Retribution.HTMLRenderer(
                                values,
                                key,
                                fields[key].displayMode,
                            );
                            if (retributionHTML !== '') {
                                artifact.innerHTML = retributionHTML;
                                artifact.classList.remove('mention');
                            }
                            break;
                        }
                        case "person-select": {
                            artifact.innerHTML = ""
                            artifact.classList.remove('mention');
                            break
                        }
                        case 'chatgpt-generated': {
                            artifact.innerHTML = AIArtifact.HTMLRenderer(values, key);
                            break;
                        }
                        case 'open-street-map': {
                            artifact.innerHTML = OpenStreetMap.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-orientace-svahu-na-svetove-strany': {
                            artifact.innerHTML = MapaOrientaceSvahuNaSvetoveStrany.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-sklonitosti-terenu': {
                            artifact.innerHTML = MapaSklonitostiTerenu.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-potencial-veternej-energie': {
                            artifact.innerHTML = MapaPotencialVeternejEnergie.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-potencial-plochy-striech': {
                            artifact.innerHTML = MapaPotencialPlochyStriech.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-potencial-vynosov-tpp-0kg': {
                            artifact.innerHTML = MapaPotencialVynosovTpp0kg.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-potencial-vynosov-tpp-120kg': {
                            artifact.innerHTML = MapaPotencialVynosovTpp120kg.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-potencial-lesne-tazobne-zvysky-vyhrevnost': {
                            artifact.innerHTML = MapaPotencialLesneTazobneZvysky.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-potencial-kukurica-silaz': {
                            artifact.innerHTML = MapaPotencialKukuricaSilaz.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-potencial-slnecnica-topinambur': {
                            artifact.innerHTML = MapaPotencialSlnecnicaTopinambur.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-potencial-repka-ozimna': {
                            artifact.innerHTML = MapaPotencialRepkaOzimna.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-potencial-konope-technicke': {
                            artifact.innerHTML = MapaPotencialKonopeTechnicke.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-potencial-rychlo-rastuce-dreviny': {
                            artifact.innerHTML = MapaPotencialRychloRastuceDreviny.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-spotreba-elekrickej-energie': {
                            artifact.innerHTML = MapaSpotrebaElektrickejEnergie.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-potencialni-vyskyt-druhu-vegetace': {
                            artifact.innerHTML = MapaPotencialnyVyskytDruhuVegetace.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-krajinny-pokryv-uzemi-svazku': {
                            artifact.innerHTML = MapaKrajinnyPokryvUzemiSvazku.HTMLRenderer(values, key);
                            break;
                        }
                        case 'mapa-klimaticke-oblasti-na-uzemy': {
                            artifact.innerHTML = MapaKlimatickeOblastiNaUzemy.HTMLRenderer(values, key);
                            break;
                        }
                        
                        default: {
                            if (values[key].removeMention) {
                                if (
                                    values[key].value &&
                                    values[key].value !== '' &&
                                    values[key].value !== '<p><br></p>'
                                ) {
                                    artifact.classList.remove('mention');

                                    artifact.innerHTML = `${values[key].value}`;
                                }
                            } else {
                                artifact.innerHTML = `${values[key]}`;
                            }
                            break;
                        }
                    }
                } else {
                    if (values[key].removeMention) {
                        if (
                            values[key].value &&
                            values[key].value !== '' &&
                            values[key].value !== '<p><br></p>'
                        ) {
                            artifact.classList.remove('mention');

                            artifact.innerHTML = `${values[key].value}`;
                        }
                    } else {
                        artifact.innerHTML = `${values[key]}`;
                    }
                }
            } else {
                if (
                    fields[key] &&
                    fields[key].binding
                ) {
                    if (
                        fields[key].type === 'address-autofill'
                    ) {
                        artifact.innerHTML =
                            AddressAutocompleteArtifact.HTMLRenderer(
                                values,
                                fields[key].binding,
                            );
                        artifact.classList.remove('mention');
                    } else {
                        if (values[fields[key].binding]) {
                            artifact.innerHTML =
                                values[fields[key].binding];
                        }
                    }
                }
                if (key.indexOf(".person-head") !== -1 ||
                    key.indexOf(".person-sentence") !== -1 ||
                    key.indexOf(".person-name") !== -1 ||
                    key.indexOf(".person-address") !== -1 ||
                    key.indexOf(".person-signature") !== -1 ||
                    key.indexOf(".person-sigimage") !== -1 ||
                    key.indexOf(".person-sigkep") !== -1 ||
                    key.indexOf(".person-sigchairman") !== -1
                ) {
                    let artifactValue = Person.HTMLRenderer(key, cleanKey, values, fields[cleanKey])
                    if (artifactValue) {
                        artifact.innerHTML = artifactValue
                    }
                }
                if (key === 'MUNICIPALITY-NAMES-DATA-OBJECT') {
                    let municipalities = MunicipalitiesRenderer.HTMLRenderer(values)
                    if (municipalities) {
                        artifact.innerHTML = municipalities;
                        artifact.classList.remove('mention');
                    }
                }
                if (key === 'MUNICIPALITY-NAMES-REGION') {
                    let municipalities = MunicipalitiesRegionHTMLRenderer.HTMLRenderer(values)
                    if (municipalities) {
                        artifact.innerHTML = municipalities;
                        artifact.classList.remove('mention');
                    }
                }
                if (key === 'MUNICIPALITY-NAMES-DISTRICT') {
                    let municipalities = MunicipalitiesDistrictHTMLRenderer.HTMLRenderer(values)
                    if (municipalities) {
                        artifact.innerHTML = municipalities;
                        artifact.classList.remove('mention');
                    }
                }

                if (key === 'MUNICIPALITY-POPULATION') {
                    let municipalities = MunicipalitiesPopulationHTMLRenderer.HTMLRenderer(values)
                    if (municipalities) {
                        artifact.innerHTML = municipalities;
                        artifact.classList.remove('mention');
                    }
                }

                if (key === 'BUYER-CLIENT-DATA-OBJECT') {
                    let buyer =
                        BuyerSeller.HTMLRenderer(key, 'buyer_custom', values, fields[cleanKey]);
                    if (buyer) {
                        artifact.innerHTML = buyer;
                        artifact.classList.remove('mention');
                    }
                }
                if (key === 'SELLER-CLIENT-DATA-OBJECT') {
                    let seller =
                        BuyerSeller.HTMLRenderer(key, 'seller_custom', values, fields[cleanKey]);
                    if (seller) {
                        artifact.innerHTML = seller;
                        artifact.classList.remove('mention');
                    }
                }
                if (key === 'BUYER-CLIENT-SIGNATURE-DATA-OBJECT') {
                    let buyer =
                        BuyerSeller.SignatureHTMLRenderer(key, 'buyer_custom', values, fields[cleanKey]);
                    if (buyer) {
                        artifact.innerHTML = buyer;
                        artifact.classList.remove('mention');
                    }
                }
                if (key === 'SELLER-CLIENT-SIGNATURE-DATA-OBJECT') {
                    let seller =
                        BuyerSeller.SignatureHTMLRenderer(key, 'seller_custom', values, fields[cleanKey]);
                    if (seller) {
                        artifact.innerHTML = seller;
                        artifact.classList.remove('mention');
                    }
                }
                if (
                    key === 'GENERATE-PROTOCOL-DATA-OBJECT'
                ) {
                    artifact.innerHTML = "";
                    artifact.classList.remove('mention');
                }
                if (
                    key === 'CONTRACT-POINT-DATA-OBJECT'
                ) {
                    artifact.innerHTML = ContractPoint.HTMLRenderer(values);
                    artifact.classList.remove('mention');
                }
            }
        } catch (e) {
            console.error(e);
        }
    });
    return virtualHTML
}